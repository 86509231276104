<template>
  <div class="envelope-list">
    <v-alert
      icon="notifications"
      :value="!isExtensionInstalled"
      type="info"
      class="envelope__notification"
    >
      É necessário ter o plugin
      <span class="envelope__notification__highlight">Web PKI</span> para realizar assinaturas,
      <a
        target="_blank"
        @click="redirectToInstallPage"
      >clique aqui para instalar</a>.
    </v-alert>

    <BaseContainer>
      <template slot="header-title">
        Formalizações
      </template>
      <div class="envelope-list__header__actions">
        <div class="mr-2">
          <BaseDatePicker
            name-placeholder="Data Inicial"
            :date-value="filter.start_date"
            :max="filter.end_date"
            :max-width="118"
            locale="pt-br"
            time-zone="UTC"
            @on-input-date-picker="(value) => { updateFilter(value, 'start_date') }"
          />
        </div>
        <div class="mr-2">
          <BaseDatePicker
            name-placeholder="Data Final"
            :date-value="filter.end_date"
            :max-width="118"
            locale="pt-br"
            time-zone="UTC"
            @on-input-date-picker="(value) => { updateFilter(value, 'end_date') }"
          />
        </div>

        <v-select
          v-model="filter.status"
          :items="status"
          label="Status"
          item-value="value"
          item-text="text"
          class="mr-2"
          style="max-width: 150px;"
        />

        <BaseInput
          v-model="filter.name"
          label="Documento"
          class="mr-2 envelope-list__filter--field"
        />

        <BaseInput
          v-model="filter.participant"
          label="Participante"
          class="mr-0 envelope-list__filter--field"
        />

        <BaseButton
          type="text"
          color="gray74"
          context-button="program"
          @click="filterEnvelopes"
        >
          FILTRAR
        </BaseButton>
        <v-spacer />
        <BaseButton
          color="primary"
          context-button="program"
          :is-disabled="!hasSelected"
          button-class="ma-0"
          @click="openSignModal()"
        >
          Assinar
        </BaseButton>
        <v-dialog
          :value="isModalActive"
          persistent
          max-width="560"
        >
          <BaseModal
            :show-indeterminate-progress="modalSignStep.showIndeterminateProgress"
            :top-slot-content-title="modalSignStep.topSlotContentTitle"
            :remove-button-close="modalSignStep.removeButtonClose"
            :middle-image="modalSignStep.middleImage"
            :middle-slot-content-text-highlight="modalSignStep.middleSlotContentTextHighlight"
            :middle-slot-content-text="modalSignStep.middleSlotContentText"
            :middle-check-agree="modalSignStep.middleCheckAgree"
            :bottom-buttons-align="modalSignStep.bottomButtonsAlign"
            :bottom-slot-content-button-cancel-label="modalSignStep.bottomSlotContentButtonCancelLabel"
            :bottom-slot-content-button-confirm-label="modalSignStep.bottomSlotContentButtonConfirmLabel"
            :remove-button-cancel="modalSignStep.removeButtonCancel"
            :remove-button-confirm="modalSignStep.removeButtonConfirm"
            @on-cancel="() => typeof modalSignStep.onCancel === 'function' && modalSignStep.onCancel()"
            @on-confirm="() => typeof modalSignStep.onConfirm === 'function' && modalSignStep.onConfirm()"
            @input="(value) => typeof modalSignStep.onCheck === 'function' && modalSignStep.onCheck(value)"
          >
            <template
              v-if="isSelectActive"
              v-slot:inject-form
            >
              <v-select
                v-model="thumbprint"
                :items="certificates"
                item-text="subjectName"
                item-value="thumbprint"
                filled
                dense
              />
            </template>
          </BaseModal>
        </v-dialog>
      </div>
      <BaseDataTable
        :table-model="selected"
        :headers="headers"
        background-color="transparent"
        :hide-headers="true"
        :items="dataEnvelopeList"
        item-key="uuid"
        classes="envelope-list__table"
        :total-items="envelopeList.total"
        :has-infinite-pagination="true"
        :options="pagination"
        @update-pagination="updatePagination"
        @update-model="updateModel"
      >
        <template v-slot:inject-header="{headers}">
          <tr class="envelope-list__table__header-tr elevation-2">
            <th class="check">
              <v-menu
                offset-y
                :close-on-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-if="!selected.length"
                    color="primary"
                    v-on="on"
                  >
                    check_box_outline_blank
                  </v-icon>
                  <v-icon
                    v-else
                    color="primary"
                    @click="toggleAll(headers.props.someItems, null)"
                  >
                    check_box
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(provider, index) in providerList"
                    :key="index"
                    @click="toggleAll(headers.props.someItems, provider.value)"
                  >
                    <v-list-item-title>{{ provider.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </th>
            <th
              v-for="(header, index) in headers.props.headers"
              :key="index"
              :class="[filter.descending ? 'desc' : 'asc', header.value === filter.sortBy ? 'active' : '', header.class]"
              @click="changeSort(header.value)"
            >
              {{ header.text }}
              <span class="v-data-table-header__icon"><v-icon small>arrow_upward</v-icon></span>
            </th>
          </tr>
        </template>

        <template v-slot:no-result-text-message>
          <p class="font-weight-bold mb-0 body-2">
            Nenhum item para exibir.
          </p>
          <p class="font-weight-regular body-2">
            Você pode refinar a busca na opção "Filtrar" ou
            navegar entre as páginas.
          </p>
        </template>

        <template v-slot:inject-items="scopedItems">
          <tr
            v-for="props in scopedItems"
            :key="props.index"
            class="envelope-list__table__body-tr elevation-2"
          >
            <td style="display: block; padding: 0;">
              <div class="envelope-list__info-overview">
                <div class="envelope-list__info-overview__item check">
                  <v-simple-checkbox
                    :value="props.isSelected"
                    :disabled="props.item.disabled"
                    color="primary"
                    @input="props.select(!props.isSelected), setEnvelopeProvider(props.item)"
                  />
                </div>
                <div class="envelope-list__info-overview__item status">
                  <p class="envelope-list__info-overview__text">
                    <v-chip
                      class="envelope-list__item--status font-weight-medium"
                      :color="props.item.statusColor"
                    >
                      {{ props.item.status }}
                    </v-chip>
                  </p>
                </div>
                <div class="envelope-list__info-overview__item name">
                  <p class="envelope-list__info-overview__text">
                    <span>{{ props.item.name }}</span>
                  </p>
                </div>
                <div class="envelope-list__info-overview__item initiator">
                  <p
                    class="envelope-list__info-overview__text"
                    style="margin-top:5px;"
                  >
                    <BaseTextTooltip
                      :text-item="props.item.initiator"
                      width-item="130"
                    />
                  </p>
                </div>
                <div class="envelope-list__info-overview__item provider pl-1">
                  <p class="envelope-list__info-overview__text">
                    <span>{{ props.item.provider === 'IDTRUST' ? 'Certificado digital' : props.item.provider }}</span>
                  </p>
                </div>
                <div class="envelope-list__info-overview__item participants pl-1">
                  <p class="envelope-list__info-overview__text participants__text">
                    <v-tooltip
                      v-if="props.item.participantsToolTip"
                      bottom
                    >
                      <template v-slot:activator="{ on }">
                        <span v-on="on">{{ props.item.participants }}</span>
                      </template>
                      <span>{{ props.item.participants }}</span>
                    </v-tooltip>
                    <template v-else>
                      {{ props.item.participants }}
                    </template>
                  </p>
                </div>
                <div
                  class="envelope-list__info-overview__item id"
                  style="align-self: center;"
                >
                  <p class="envelope-list__info-overview__text">
                    <BaseLink
                      :to="`/envelopes/${props.item.uuid}`"
                      type-link="programLink"
                      target="_blank"
                    >
                      Ver
                      detalhes
                    </BaseLink>
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </BaseDataTable>
    </BaseContainer>
    <v-dialog
      :value="isModalClicksignActive"
      persistent
      max-width="500"
    >
      <BaseModal
        :show-indeterminate-progress="modalClicksign.showIndeterminateProgress"
        :top-slot-content-title="modalClicksign.topSlotContentTitle"
        :remove-button-close="modalClicksign.removeButtonClose"
        :middle-slot-content-text-highlight="modalClicksign.middleSlotContentTextHighlight"
        :middle-slot-content-text="modalClicksign.middleSlotContentText"
        :bottom-buttons-align="modalClicksign.bottomButtonsAlign"
        :bottom-slot-content-button-cancel-label="modalClicksign.bottomSlotContentButtonCancelLabel"
        :bottom-slot-content-button-confirm-label="modalClicksign.bottomSlotContentButtonConfirmLabel"
        :remove-button-cancel="modalClicksign.removeButtonCancel"
        :remove-button-confirm="modalClicksign.removeButtonConfirm"
        @on-cancel="closeClicksignModal()"
        @on-confirm="sendEnvelopeBatchSign()"
      />
    </v-dialog>
    <BaseSnackbar
      :active-snackbar="feedback"
      :type-snackbar="feedbackColor"
      :text-snackbar="feedbackMessage"
      @closeSnackbar="feedback = !feedback"
    />
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import BaseModal from './BaseModal'
import BaseDatePicker from './BaseDatePicker'
import loader from '../global/loader'
import modalSignSteps from '../mixins/BaseModalSignSteps'
import webpki from "../mixins/webpki";
import BaseDataTable from './BaseDataTable'
import BaseSnackbar from './BaseSnackbar'
import BaseSnackbarConfiguration from '../mixins/BaseSnackbarConfiguration'
import BaseTextTooltip from './BaseTextTooltip'

export default {
  components: { BaseModal, BaseDatePicker, BaseDataTable, BaseSnackbar, BaseTextTooltip },
  mixins: [ modalSignSteps, webpki, BaseSnackbarConfiguration ],
  data: () => ({
    isModalClicksignActive: false,
    providerList: [
      { title: 'ClickSign', value: 'CLICKSIGN' },
      { title: 'Certificado digital', value: 'IDTRUST' }
    ],
    modalClicksign: {
      showIndeterminateProgress: false,
      topSlotContentTitle: "Assinatura em lote",
      removeButtonClose: false,
      middleSlotContentTextHighlight: "",
      middleSlotContentText: `<p>Você será direcionado para outra página para assinar os documentos via Clicksign. <br><b>Após a assinatura retorne para esta página.</b></p>`,
      bottomButtonsAlign: 'right',
      bottomSlotContentButtonCancelLabel: "Cancelar",
      bottomSlotContentButtonConfirmLabel: "Continuar",
      removeButtonCancel: false,
      removeButtonConfirm: false
    },
    status: [
      {text: 'Todos', value: ''},
      {text: 'Em andamento', value: 'IN_PROGRESS'},
      {text: 'Concluída', value: 'DONE'},
      {text: 'Cancelada', value: 'CANCELLED'},
      {text: 'Erro', value: 'ERROR'}
    ],
    disabledFirstLoadUpdateDataTable: true,
    filter: {
      start_date: '',
      end_date: '',
      status: '',
      page: 0,
      size: 0,
      name: '',
      participant: '',
      sortBy: 'name',
      descending: false
    },
    pagination: {
      page: 1,
      itemsPerPage: 5
    },
    headers: [
      { text: 'Status', value: 'status', class: 'status' },
      { text: 'Documento', value: 'name', class: 'name' },
      { text: 'Programa', value: 'initiator', class: 'initiator' },
      { text: 'Assinar com', value: 'provider', class: 'provider' },
      { text: 'Participantes', value: 'participants', class: 'participants' },
      { text: '', value: 'id', class: 'id' }
    ],
    recipientStatus: null,
    isSigner: false,
    envelopeProvider: null
  }),
  computed: {
    ...mapState({
      envelopeList: 'envelopeList',
      keycloak: state => state.auth.keycloak,
    }),
    ...mapGetters({
      filterAllFormalizationsExceptError: 'filterAllFormalizationsExceptError'
    }),
    userIsAdmin() {
      return this.keycloak.hasResourceRole('admin')
    },
    showAllFormalizationsByContextUser() {
      return this.userIsAdmin ? this.envelopeList : this.filterAllFormalizationsExceptError
    },
    dataEnvelopeList() {
      return this.showAllFormalizationsByContextUser.map(element => {
        const {status, name, participants, id, recipients, provider, initiator} = element
        const correctStatus = this.convertStatusToName(status)
        for (let i = 0; i < recipients.length; i++) {
          let recipient = recipients[i];
          if (recipient.email == this.keycloak.tokenParsed.email) {
            this.recipientStatus = recipient.signature_status
            this.isSigner = true
            break
          } else {
            this.isSigner = false
          }
        }
        return {
          status: correctStatus,
          statusColor: this.convertStatus(status),
          name,
          initiator,
          participants: participants.join(", "),
          participantsToolTip: participants.join(", ").length > 17 ? true : false,
          provider,
          uuid: id,
          disabled: correctStatus !== "Em andamento" || (!this.isSigner || this.isSigner
            && this.recipientStatus == 'SIGNED' || (this.envelopeProvider && this.envelopeProvider !== provider)) ? true : false
        }
      });
    },
    hasDataEnvelopeList() {
      return this.dataEnvelopeList.length > 0 ? true : false
    },
    disableButtonSign() {
      return this.isExtensionInstalled
    }
  },
  methods: {
    ...mapActions(['apiGetFormalizations', 'createEnvelopeBatchSign']),
    setEnvelopeProvider(envelope){
      if(!this.envelopeProvider) {
        this.envelopeProvider = envelope.provider
      }
    },
    openSignModal(){
      if(this.envelopeProvider === 'IDTRUST') {
        this.isModalActive = true
      } else {
        this.isModalClicksignActive = true
      }
    },
    closeClicksignModal(){
      this.isModalClicksignActive = false
      this.envelopeProvider = null
      this.selected = []
    },
    async sendEnvelopeBatchSign(){
      let ids = new Array;

      Object.assign(this.modalClicksign, {
        ...this.modalClicksign,
        showIndeterminateProgress: true
      })

      this.selected.forEach(elem => {
        ids.push(elem.uuid)
      });

      const payload = {
        provider: this.envelopeProvider,
        data: {
          ids: ids.join(),
          email: this.keycloak.tokenParsed.email
        }
      }
      try {
        const envelopeId = await this.createEnvelopeBatchSign(payload)
        this.$router.push(`/envelopes/${envelopeId}`)
      } catch (error) {
        this.showFeedback({
          message: 'Erro ao solicitar assinatura em lote. Tente novamente.',
          color: 'error'
        })
      } finally {
        this.isModalClicksignActive = false
      }
    },
    convertStatusToName(value) {
      const names = {
        IN_PROGRESS: "Em andamento",
        DONE: "Concluída",
        CANCELLED: "Cancelada",
        ERROR: "Erro"
      }
      return names[value]
    },
    convertStatus(value) {
      const status = {
        IN_PROGRESS: "done",
        DONE: "running",
        CANCELLED: "cancelled",
        ERROR: "cancelled"
      }
      return status[value]
    },
    toggleAll(someItems, provider) {
      if (someItems) {
        this.selected = []
        this.envelopeProvider = null
      } else {
        this.envelopeProvider = provider
        this.selected = this.dataEnvelopeList.filter(elem => !elem.disabled && elem.provider === provider)
        this.clearEnvelopeProvider()
      }
    },
    clearEnvelopeProvider(){
      if(!this.selected.length){
        this.envelopeProvider = null
      }
    },
    changeSort(column) {
      if (this.filter.sortBy === column) {
        this.filter.descending = !this.filter.descending
      } else {
        this.filter.sortBy = column
        this.filter.descending = false
      }
      this.filterEnvelopes()
    },
    updateFilter(value, attribute) {
      this.filter[attribute] = value
    },
    filterEnvelopes() {
      loader.startProgressLoadPage()
      this.apiGetFormalizations(this.filter).then(() => {
        loader.stopProgressLoadPage()
      })
    },
    updatePagination(pagination) {
      const reducePaginationAttributes = {
        page: pagination.page,
        itemsPerPage: pagination.itemsPerPage,
      }
      this.pagination = reducePaginationAttributes
      this.setPaginationToFilter()
      if (this.disabledFirstLoadUpdateDataTable) {
        this.disabledFirstLoadUpdateDataTable = false
        return
      }
      this.filterEnvelopes()
    },
    updateModel(event) {
      this.selected = event;
      this.clearEnvelopeProvider()
    },
    toLocalDate(date) {
      return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    },
    minusDays(date, days) {
      return new Date(date.setDate(date.getDate() - days))
    },
    setPaginationToFilter() {
      this.filter.page = this.pagination.page;
      this.filter.size = this.pagination.itemsPerPage;
    }
  },
  watch: {
    filter: {
      handler() {
        if (new Date(this.filter.start_date).getTime() > new Date(this.filter.end_date).getTime()) {
          this.filter.start_date = ''
        }
      },
      deep: true
    }
  },
  created() {
    this.modalSignStepsActived = this.modalSignSteps
    this.filter['start_date'] = this.toLocalDate(
      this.minusDays(new Date(), 7)).toISOString().substr(0, 10);
    this.filter['end_date'] = this.toLocalDate(new Date()).toISOString().substr(0, 10);
    this.setPaginationToFilter()
  },
  mounted() {
    this.filterEnvelopes()
  }
}
</script>

<style>
.envelope-list tbody tr:not(.envelope-list__table__body-tr) {
  background: transparent;
}

.envelope-list tbody tr:not(.envelope-list__table__body-tr) td {
  width: 100%;
}

.envelope-list .v-table__overflow + div {
  margin: 3px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12) !important;
  width: calc(100% - 6px);
}

.list-no-itens--container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
}

.list-no-itens--container div {
  display: flex;
  align-items: center;
  flex-flow: column;
}

.list-no-itens--text {
  color: var(--custom-theme-black-054) !important;
  font-weight: bold;
}

.envelope-list__header__actions {
  display: flex;
  align-items: center;
}

.envelope-list__item--status {
  color: var(--custom-theme-black-054) !important;
  display: inline-block;
  font-size: 10px !important;
  height: 24px !important;
  min-width: 120px;
  margin: 4px !important;
  text-align: center;
}

.envelope-list__item--status :first-child {
  height: inherit;
}

.envelope-list__filter {
  display: flex;
}

.envelope-list__filter--field {
  max-width: 170px;
}

.envelope-list table {
  background-color: transparent !important;
  display: flex;
  flex-flow: column wrap;
}

.envelope-list thead,
.envelope-list tbody {
  display: flex;
  flex-flow: row wrap;
  flex: 0 0 100%;
}

.envelope-list th {
  font-weight: 400 !important;
}

.envelope-list tbody {
  align-content: flex-start;
}

.envelope-list tr {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-flow: row wrap;
  flex: 0 0 calc(100% - 6px);
  margin: 3px 3px 8px 3px;
}

.envelope-list tr:hover {
  background: inherit !important;
}

.envelope-list tr[class*="v-datatable__progress"] {
  margin-top: -8px !important;
}

.envelope-list tr[class*="v-datatable__progress"] > * {
  width: 100%;
}

.envelope-list thead tr:not(:last-child) {
  border: none !important;
}

.envelope-list tbody tr {
  height: auto;
}

.envelope-list tbody tr > * {
  height: auto !important;
}

.envelope-list tbody tr:not(:last-child) {
  border: none !important;
}

.envelope-list__info-overview {
  align-items: stretch;
  display: flex;
  padding: 5px 0;
  justify-content: space-between;
}

.envelope-list__info-overview__item {
  align-self: center;
  padding-left: 0px;
}

.envelope-list__info-overview__text {
  margin: 0;
  padding-left: 15px;
}

.envelope-list__message {
  border-top: 1px solid #bebebe;
  background-color: #f2f2f2;
  font-size: 10px !important;
  line-height: 1.8;
}

.envelope-list__table__header-tr {
  text-align: left;
}

.envelope-list__table .check {
  width: 40px;
}

.envelope-list__table .status {
  width: 135px;
}

.envelope-list__table .status .envelope-list__info-overview__text {
  padding-left: 10px;
}

.envelope-list__table .name {
  flex: 1 1 0;
}

.envelope-list__table .participants {
  width: 270px;
}

.envelope-list__table .provider {
  width: 117px;
}
.envelope-list__info-overview__item.provider .envelope-list__info-overview__text {
  text-transform: lowercase;
}
.envelope-list__info-overview__item.provider .envelope-list__info-overview__text::first-letter {
  text-transform: uppercase;
}

.envelope-list__table .initiator  {
  width: 135px;
}

.envelope-list__table .id {
  width: 100px;
}

.participants__text {
  cursor: help;
  display: block;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 270px;
  word-break: keep-all;
  word-wrap: normal;
}

.envelope-list__table__body-tr td {
  font-size: 0.8125rem !important;
  padding: 0;
  width: 100%;
}

.envelope-list__table__body-tr .id {
  font-size: 15px;
}

.envelope-list__table__body-tr .id a {
  color: #264674;
  visibility: hidden;
  width: 125px;
}

.envelope-list__table__body-tr:hover .id a {
  visibility: visible;
}

.envelope-list thead tr {
  padding: 12px 15px !important;
}

.envelope-list thead tr th:first-child {
  padding: 0px !important;
}

.envelope-list thead tr th {
  border-bottom: none !important;
}

.envelope-list tr > th {
  height: unset !important;
}

</style>
