<template>
  <div class="program-assume-role">
    <v-dialog
      v-model="isModalActive"
      transition="slide-y-reverse-transition"
      content-class="v-dialog--fullscreen"
    >
      <BaseModal
        style="height: 100vh; flex-wrap: nowrap;"
        :middle-image="modalInfo.middleImage"
        :show-indeterminate-progress="modalInfo.showIndeterminateProgress"
        :top-slot-content-title="modalInfo.topSlotContentTitle"
        :remove-button-close="modalInfo.removeButtonClose"
        :middle-slot-content-text-highlight="modalInfo.middleSlotContentTextHighlight"
        :middle-slot-content-text="modalInfo.middleSlotContentText"
        :middle-check-agree="modalInfo.middleCheckAgree"
        :bottom-buttons-align="modalInfo.bottomButtonsAlign"
        :bottom-slot-content-button-cancel-label="modalInfo.bottomSlotContentButtonCancelLabel"
        :bottom-slot-content-button-confirm-label="modalInfo.bottomSlotContentButtonConfirmLabel"
        :remove-button-cancel="modalInfo.removeButtonCancel"
        :remove-button-confirm="modalInfo.removeButtonConfirm"
        @on-cancel="() => null"
        @on-confirm="() => null"
      >
        <div style="max-width: 350px; margin: 20px auto;">
          <v-img
            class="mt-12 mb-8"
            :src="logo"
            contain
            :max-width="130"
            :width="130"
          />

          <h2 class="font-weight-bold app-headline mb-4">
            Olá, {{ keycloak.name }}!
          </h2>

          <p class="body-1">
            {{ subtitleMessage }}
          </p>
          <template v-if="context == 'choose-role'">
            <BaseLink
              v-for="(item, i) in programRoles"
              :key="i"
              to=""
              class="mx-2"
              type-link="programLink"
              @click.native="assumeRole(item.role)"
            >
              <BaseCard
                :background-color="'transparent'"
                :is-header-actions="true"
                :has-main-container="false"
                base-card-title-class="app-font-semibold title"
                base-card-container-title="py-3"
                style="width: 320px"
              >
                <template v-slot:header-title>
                  {{ translateRoles(item.role) }}
                </template>
                <template v-slot:header-actions>
                  <v-icon color="primary">
                    keyboard_arrow_right
                  </v-icon>
                </template>
              </BaseCard>
            </BaseLink>
          </template>
          <v-autocomplete
            v-if="context == 'choose-organization'"
            v-model="organization"
            :items="getRoleOrganizations()"
            return-object
            item-text="nameAndTaxnumber"
            item-value="id"
            hint="Filtre por Razão Social ou CNPJ"
            persistent-hint
            style="max-width: 250px;"
            class="mr-2"
            @change="assumeRole(activeRole,organization.id)"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.tax_number | maskCNPJ }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>
      </BaseModal>
    </v-dialog>
    <BaseSnackbar
      :active-snackbar="feedback"
      :type-snackbar="feedbackColor"
      :text-snackbar="feedbackMessage"
      @closeSnackbar="feedback = !feedback"
    />
  </div>
</template>

<script>
import brand from '../../../assets/logo-idtrust.svg'
import {mapState, mapActions, mapGetters} from 'vuex'
import BaseModal from '../../BaseModal'
import BaseSnackbarConfiguration from '../../../mixins/BaseSnackbarConfiguration'
import BaseSnackbar from '../../BaseSnackbar'
import ProgramRoles from '../../../mixins/ProgramRoles'

export default {
  components: { BaseModal, BaseSnackbar },
  mixins:[ BaseSnackbarConfiguration, ProgramRoles ],
  props:{
    context: {
      type: String,
      default: 'choose-role'
    }
  },
  data() {
    return {
      isModalActive: true,
      organization: {},
      modalInfo: {
        showIndeterminateProgress: false,
        topSlotContentTitle: "",
        removeButtonClose: true,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: "",
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "",
        bottomSlotContentButtonConfirmLabel: "",
        removeButtonCancel: true,
        removeButtonConfirm: true,
        onCancel: null,
        onConfirm: null,
      }
    }
  },
  computed: {
    ...mapState({
      keycloak: state => state.auth.keycloak.tokenParsed,
      programLogo: state => state.platformProgram.programLogo,
      programRoles: state => state.platformProgram.programRoles,
      activeRole: state=> state.platformProgram.activeOrganization.active_role
    }),
    logo() {
      return this.programLogo !== null ? this.programLogo : brand
    },
    subtitleMessage() {
      return this.context == 'choose-role' ?
          'Escolha o perfil para entrar no programa: ' :
          'Escolha uma organização para continuar: '
    }
  },
  created(){
    this.organization = {...this.getActiveOrganization()}
  },
  methods: {
    ...mapActions('rolesManagement', ['apiPostAssumeRole']),
    async assumeRole(role, organization_id = ''){
      const data = {
        role: role,
        organization_id: organization_id
      }

      try{
        await this.apiPostAssumeRole(data)
        this.isModalActive = false
        this.$router.go(-1)
      }catch(err){
        this.showFeedback({
          message: 'Erro ao selecionar papel.',
          color: 'error'
        })
      }
    }
  }
}
</script>

<style>
</style>
