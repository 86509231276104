<template>
  <div class="platform-organization-profile-partners">
    <BaseTour
      :tour-name="tourName"
      :steps="dataSteps"
      :options="tourOptions"
      :start-on-mounted="false"
      @onFinish="finishTour"
    />
    <BaseCard
      :has-main-container="false"
      base-card-title-class="app-font-semibold title mb-0"
      base-card-container-classes="mb-6"
      data-v-step="2"
    >
      <template v-slot:header-title>
        Sócios e administradores
      </template>
      <template v-slot:header-actions>
        <v-dialog
          :value="isModalActive"
          persistent
          transition="app-transition-to-right"
          content-class="app-transition-to-right-additional-configs"
          @input="updateModalActive"
        >
          <template v-slot:activator="{ on }">
            <BaseButton
              type="text"
              color="blueff"
              class="app-button__link-text app-button--hide-ripple app-button--no-hover ma-1"
              v-on="on"
              @click="$tours[tourName].stop()"
            >
              Adicionar
            </BaseButton>
          </template>
          <BaseModal
            style="height: 100vh; flex-wrap: nowrap;"
            :show-indeterminate-progress="modalManagePartner.showIndeterminateProgress"
            :top-slot-content-title="modalManagePartner.topSlotContentTitle"
            :remove-button-close="modalManagePartner.removeButtonClose"
            :middle-slot-content-text-highlight="modalManagePartner.middleSlotContentTextHighlight"
            :middle-slot-content-text="modalManagePartner.middleSlotContentText"
            :middle-check-agree="modalManagePartner.middleCheckAgree"
            :bottom-buttons-align="modalManagePartner.bottomButtonsAlign"
            :bottom-slot-content-button-cancel-label="modalManagePartner.bottomSlotContentButtonCancelLabel"
            :bottom-slot-content-button-confirm-label="modalManagePartner.bottomSlotContentButtonConfirmLabel"
            :remove-button-cancel="modalManagePartner.removeButtonCancel"
            :remove-button-confirm="modalManagePartner.removeButtonConfirm"
            body-background-color="#F8F8F8"
            @on-cancel="onCancelForm"
            @on-confirm="() => null"
          >
            <div
              class="mt-6"
              style="max-width: 645px; margin: 0 auto;"
            >
              <PartnersForm
                ref="partnersForm"
                :active="isModalActive"
                :partner-to-edit="partnerSelectedToEdit"
                :is-creating-partner-child="isCreatingPartnerChild"
                @onSubmitForm="onSubmitForm"
                @onCancel="onCancelForm"
              />
            </div>
          </BaseModal>
        </v-dialog>
      </template>
    </BaseCard>
    <div
      class="mt-8 mb-5 app-align-items-center"
    >
      <p>Número mínimo de assinaturas em conjunto:</p>
      <div
        v-if="!isEditMinSignatures"
        class="app-align-items-center font-weight-bold ml-2"
        style="max-height: 32px"
      >
        {{ minSignatures }}
        <BaseButton
          type="text"
          color="blueff"
          class="app-button__link-text app-button--hide-ripple app-button--no-hover my-0"
          @click="isEditMinSignatures = true"
        >
          Editar
        </BaseButton>
      </div>
      <BaseInput
        v-else
        v-model="min_signatures"
        type="number"
        :rules="[isValid, isValidLengthForSignature]"
        class="ml-2 partner-input_append-icon"
        append-icon="check_circle"
        @[preventClickOnInput]="updateMinSignatures(min_signatures)"
      />
    </div>
    <PartnerDetail
      :organization-id="organizationId"
      :partner-list="platformOrganizationPartners"
      data-v-step="partner"
      @onEditingPartner="editingPartner"
      @onCreatingPartnerChild="creatingPartnerChild"
    />

    <BaseSnackbar
      :active-snackbar="feedback"
      :text-snackbar="feedbackMessage"
      :type-snackbar="feedbackColor"
      @closeSnackbar="feedback = !feedback"
    />
  </div>
</template>

<script>
import BaseCard from '../../BaseCard'
import BaseModal from '../../BaseModal'
import BaseSnackbar from '../../BaseSnackbar'
import { mapActions, mapState, mapMutations} from 'vuex'
import UtilsPlatformOrganizationProfileTemplate from '../../../mixins/UtilsPlatformOrganizationProfileTemplate'
import PartnerDetail from './PartnerDetail'
import PartnersForm from './PartnersForm'
import BaseSnackbarConfiguration from '../../../mixins/BaseSnackbarConfiguration'
import ProgramTour from '../../../mixins/ProgramTour'

export default {
  name: 'PlatformOrganizationProfilePartners',
  components: { BaseCard, BaseSnackbar, BaseModal, PartnerDetail, PartnersForm },
  mixins: [ UtilsPlatformOrganizationProfileTemplate, BaseSnackbarConfiguration, ProgramTour ],
  data() {
    return {
      isModalActive: false,
      partnerSelectedToEdit: {},
      isCreatingPartnerChild: {},
      isEditPartner: false,
      organizationId: '',
      min_signatures: '',
      isEditMinSignatures: false,
      hasAddPartner: false,
      organization: {},
      modalManagePartner: {
        showIndeterminateProgress: false,
        topSlotContentTitle: "Dados do representante",
        removeButtonClose: false,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: '',
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "Cancelar",
        bottomSlotContentButtonConfirmLabel: "Salvar",
        removeButtonCancel: true,
        removeButtonConfirm: true,
        onCancel: this.onCancelForm,
        onConfirm: null,
      },
      tourName: 'partners',
      dataStepsProfile: [
        {
          target: '[data-v-step="2"]',
          content: 'Para cadastrar os sócios e administradores  da sua organização, utilize o botão <b>“Adicionar”</b> acima.',
          layoutStep: "simple",
          params: {
            placement: 'bottom',
          }
        },
        {
          target: '[data-v-step="partner"]',
          content: 'Para que sua adesão ao programa seja aceita, você precisa cadastrar os documentos dos seus sócios através do botão <b>“Editar”</b> abaixo.',
          layoutStep: "simple",
          params: {
            placement: 'top',
            enableScrolling: false
          }
        }
      ],
      tourOptions: {
        labels: {
          buttonSkip: 'fechar tutorial',
          buttonPrevious: 'Anterior',
          buttonNext: 'Cadastrar sócios',
          buttonStop: 'Continuar'
        },
        highlight: true
      }
    }
  },
  computed: {
    ...mapState({
      platformOrganizationPartners: state => state.platformOrganization.platformOrganizationPartners,
      platformOrganization: state => state.platformOrganization.platformOrganization,
    }),
    minSignatures(){
      return this.platformOrganization.signature_options && this.platformOrganization.signature_options.min_signatures ? this.platformOrganization.signature_options.min_signatures : '2'
    },
    preventClickOnInput() {
      return this.isValidLengthForSignature(this.min_signatures) ? 'click:append' : 'click.stop';
    },
    dataSteps() {
      return !this.hasAddPartner ? this.dataStepsProfile.filter((step, index) => index != 1) : [this.dataStepsProfile[1]]
    }
  },
  mounted() {
    this.startTour(this.tourName)
  },
  created(){
    this.organizationId = this.$route.params.id
    this.apiGetPlatformOrganizationPartners(this.organizationId)
    this.apiGetPlatformOrganization(this.organizationId)
    this.min_signatures = this.minSignatures
  },
  methods: {
    ...mapActions('platformOrganization', ['apiGetPlatformOrganization','apiGetPlatformOrganizationPartners','apiUpdatePlatformOrganization']),
    ...mapMutations('platformOrganization', ['setPlatformOrganizationPartnerChildContext']),
    finishTour(){
      this.$router.push({ path: `/platform-organization/${this.$route.params.id}/bank-account`})
    },
    updateModalActive(value) {
      this.isModalActive = value
    },
    creatingPartnerChild({active, parentTaxNumber}) {
      this.isCreatingPartnerChild = { isCreating:true, parentTaxNumber}
      this.isModalActive = active
    },
    editingPartner(partner) {
      Object.assign(this.partnerSelectedToEdit, partner)
      this.isModalActive = true
      this.stopTour(this.tourName)
    },
    onSubmitForm(value){
      this.showFeedback(value)
    },
    onCancelForm() {
      this.setPlatformOrganizationPartnerChildContext(false)
      this.$refs.partnersForm.clearFields()
      this.partnerSelectedToEdit = {}
      this.partnerSelectedToEdit.id = ''
      this.isModalActive = false

      if(this.platformOrganizationPartners.length > 0){
        this.hasAddPartner = true
        this.startTour(this.tourName)
      }
      this.stopTour(this.tourName)
    },
    isValidLengthForSignature(value) {
      return !!value && value > 0 || false
    },
    async updateMinSignatures(minSignatures){
      Object.assign(this.organization, {...this.platformOrganization, organizationId: this.organizationId, signature_options: {min_signatures: minSignatures} })

      try {
        await this.apiUpdatePlatformOrganization(this.organization)
        this.showFeedback({color: "success", message: "Número de assinaturas salvo com sucesso!"})
        this.isEditMinSignatures = false
      } catch (error) {
        this.showFeedback({color: "error", message: "Erro ao enviar número de assinaturas."})
      } finally {
        await this.apiGetPlatformOrganization(this.organizationId)
        this.min_signatures = this.minSignatures
      }
    }
  }
}
</script>

<style lang="scss">
.partner-input_append-icon {
  .v-input {
    margin-top: 0px;
    padding-top: 0px;
    width: 65px;
  }
  .v-text-field__details {
    display: none;
  }
  .v-input__slot {
    margin-bottom: 0px;
  }
}
</style>
