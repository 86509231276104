export default {
  state: {
    platformTours: null
  },
  mutations: {
    setPlatformTour(state, payload) {
      localStorage.setItem("platformTours", JSON.stringify(payload))
      state.platformTours = payload
    }
  },
  actions: {
    getPlatformTour({commit}) {
      this.commit("setPlatformTour", JSON.parse(localStorage.getItem("platformTours")))
    },
    createLocalStorageTour({commit}) {
      this.commit("setPlatformTour", {
        firstAccess: false,
        "personal-data": false,
        organization: false,
        profile: false,
        "organization-documents": false,
        "program-documents": false,
        partners: false,
        "bank-account": false
      })
    }
  }
}
