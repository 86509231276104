import {mapState, mapActions, mapMutations} from 'vuex'

export default {
  computed: {
    ...mapState({
      platformTours: state => state.platformTour.platformTours
    })
  },
  methods: {
    ...mapActions(['getPlatformTour']),
    ...mapMutations(["setPlatformTour"]),

    async startTour(tourName){
      this.getPlatformTour()
      if(!this.platformTours || this.platformTours[tourName] === false){
        this.$tours[tourName].start()
      }
    },
    updateTour(tourName, value){
      if(tourName !== 'finishRegistration'){
        this.getPlatformTour()
        const platformTour = this.platformTours
        platformTour[tourName] = value
        this.setPlatformTour(platformTour)
      }
    },
    finishTour(tourName) {
      this.updateTour(tourName, true)
      this.$tours[tourName].skip()
    },
    stopTour(tourName) {
      this.updateTour(tourName, true)
      this.$emit('onFinish')
      this.$tours[tourName].stop()
    },
    findTourValue(value){
      this.getPlatformTour()
      return this.platformTours[value]
    }
  }
};
