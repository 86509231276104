<template>
  <v-btn
    :absolute="absolute"
    :class="concatClasses"
    :[type]="true"
    :[contextButton]="true"
    :name="qaIdentificationName"
    :color="color"
    :dark="dark"
    :disabled="isDisabled || loadPage"
    :icon="hasIcon"
    :href="href"
    :[size]="true"
    :to="to"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    absolute: {
      type: Boolean,
      default: false
    },
    qaIdentificationName: {
      type: String,
      required: false,
    },
    color: {
      type: String,
    },
    dark: {
      type: Boolean,
      default: false
    },
    type:{
      type: String,
      default: 'raised',
      validator: function(value) {
        return ['raised', 'text', 'depressed', 'fab'].includes(value)
      }
    },
    contextButton: {
      type: String,
      default: 'platform',
      validator: (value) => ['program', 'platform'].includes(value)
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    buttonClass: {
      type: String,
      default: ''
    },
    hasIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    href: {
      type: String,
      required: false
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) => ['x-small', 'small', 'medium', 'large', 'x-large'].includes(value)
    },
    to: {
      type: String,
    }
  },
  computed: {
    ...mapState({
      loadPage: (state) => state.loadPage
    }),
    concatClasses(){
      return this.contextButton === 'platform' ? 'body-2 app-font-semibold ' + this.buttonClass : this.buttonClass
    }
  }
}
</script>

<style scoped lang="scss">
@import '../design-system/base-components.scss';

.v-btn {
  margin: 6px 8px;
  padding: 0 16px;
  letter-spacing: unset!important;
}
.v-card__actions>.v-btn {
  padding: 0 16px;
}
.v-btn:not(.v-btn--round).v-size--default {
  min-width: 88px;
  border-radius: 2px;
}
.v-application--is-ltr .v-toolbar__content>.v-btn.v-btn--icon:last-child,
.v-application--is-ltr .v-toolbar__extension>.v-btn.v-btn--icon:last-child {
  margin-right: -6px;
}
.v-toolbar__content .v-btn.v-btn--icon.v-size--default,
.v-toolbar__extension .v-btn.v-btn--icon.v-size--default {
  height: 36px;
  width: 36px;
}


// Base button
.v-btn.v-btn--is-elevated.v-btn--has-bg {
  @extend .ds-base-button;
}

// Base button hover
.v-btn.v-btn--is-elevated.v-btn--has-bg:hover {
  @extend .ds-base-button--hover;
}

// Base button flat
.v-btn.v-size--default:not([disabled="disabled"]):not(.v-btn--has-bg):not(.v-btn--round):not(.button-default-style) {
  @extend .ds-base-button--flat;
}

// Base button flat hover
.v-btn.v-size--default:not([disabled="disabled"]):not(.v-btn--has-bg):not(.v-btn--round):not(.button-default-style):not(.app-button__link-text):hover {
  @extend .ds-base-button--flat--hover;
}

// Base button flat style link
.v-btn.v-size--default.app-button__link-text:not([disabled="disabled"]):not(.v-btn--has-bg):not(.v-btn--round):not(.button-default-style) {
  @extend .ds-base-platform-link;
}

.v-btn.v-size--default.app-button__link-text:not([disabled="disabled"]):not(.v-btn--has-bg):not(.v-btn--round):not(.button-default-style).app-font-regular{
  @extend .ds-base-platform-link;
  font-weight: 400 !important;
}

// Base button disabed
.v-btn.v-size--default[disabled]:not(.v-btn--round):not(.app-button__link-text) {
  @extend .ds-base-button--disabled;
}

</style>
