import api from '../../services/api';

export default {
  namespaced: true,
  state: {
  },
  actions: {
    async apiPostAssumeRole({commit}, payload) {
      await api.post(`/programs/roles/assume`, payload)
    }
  }
}
