import api from '../../services/api';

export default {
  namespaced: true,
  state: {
    programMovements: [],
    programBankAccounts: [],
    programMovementPostings: [],
    programPostings: [],
    programMovementsToExport: [],
    programMovementDynamicAssetFields: [],
    programAmountAvailable: null
  },
  mutations: {
    setProgramMovements(state, payload) {
      state.programMovements = payload
    },
    setProgramBankAccounts(state, payload) {
      state.programBankAccounts = payload
    },
    setProgramMovementPostings(state,payload) {
      state.programMovementPostings = payload
    },
    setProgramPostings(state,payload) {
      state.programPostings = payload
    },
    setProgramMovementsToExport(state,payload) {
      state.programMovementsToExport = payload
    },
    setProgramMovementDynamicAssetFields(state, payload) {
      state.programMovementDynamicAssetFields = payload
    },
    setProgramAmountAvailable(state, payload) {
      state.programAmountAvailable = payload
    }
  },
  actions: {

    async apiGetProgramBankAccounts({commit}) {
      const result = await api.get(`/bank_accounts`)
      commit("setProgramBankAccounts", result.data)
      return result
    },
    async apiCreateMovement({commit}, payload) {
      const {type, data} = payload
      await api.post(`/movements/${type}`, data)
    },
    async apiCreateProgramMovements({commit}, payload) {
      const {id, category, ...data} = payload
      const result = await api.post(`/movements/${category}`, data.data)
      return result
    },
    async apiUpdateProgramMovement({commit}, payload){
      const {id, type, scope, fields, ...data} = payload
      const encodedId = id.replaceAll('#', '%23')
      await api.put(`/movements/${type}/${encodedId}`, data.data, {params: {scope, fields}})
    },
    async apiGetProgramMovementPostings({commit},payload) {
      const {id,category, ...data} = payload
      const encodedId = id.replaceAll('#', '%23')
      const result = await api.get(`/movements/${category}/${encodedId}/postings`, {params: data})
      commit("setProgramMovementPostings", result.data)
      return result
    },
    async apiGetProgramPostings({commit},payload) {
      const result = await api.get(`/acct/postings`, {params: payload})
      commit("setProgramPostings", result.data)
      return result
    },
    async apiGetProgramMovements({commit}, payload) {

      if (payload.page) {
        payload.page = payload.page - 1;
      }

      const {category, ...data} = payload
      const result = await api.get(`/movements/${category}`, {params: data})
      commit("setProgramMovements", result.data)
      return result
    },

    async apiPatchProgramMovements({commit}, payload) {
      const {category, data} = payload
      const pathApi = payload.sync ? `/movements/${category}?sync=${payload.sync}` : `/movements/${category}`
      const result = await api.patch(pathApi, data)
      return result
    },
    async apiGetProgramMovementsChildren({commit}, payload) {
      if (payload.page) {
        payload.page = payload.page - 1;
      }

      const {routeParams, queryParams} = payload
      const encodedId = routeParams.id.replaceAll('#', '%23')

      if (queryParams.page) {
        queryParams.page = queryParams.page - 1
      }

      const result = await api.get(
        `/movements/${routeParams.category}/${encodedId}/children`,
        {params: queryParams})

      return result
    },
    async apiGetProgramMovementsToExport({commit}, payload) {
      const {category, ...data} = payload
      const result = await api.get(`/movements/${category}`, {params: data})
      commit("setProgramMovementsToExport", result.data)
      return result
    },
    async apiGetSchemas({commit}, payload) {
      const { scope, role } = payload
      const path = role ? `/configs/schemas/${scope}?role=${role}` : `/configs/schemas/${scope}`
      const result = await api.get(path)
      commit('setProgramMovementDynamicAssetFields', result.data.fields.sort((a, b) => a.sequence - b.sequence))
      return result
    }
  }
}
