<template>
  <div class="error-500">
    <BaseContainer>
      <div class="error-500__container">
        <v-img
          :src="require('@/assets/error500.gif')"
          contain
          height="157"
          max-width="180"
          width="180"
        />
        <h2 class="error-500__title mt-6 mb-4">
          Ops, algo não funcionou!
        </h2>
        <p class="error-500__text text-sm-center">
          Houve um problema, atualize a página ou tente novamente mais tarde.<br>
          Se o problema persistir, entre em contato pelo e-mail atendimento@idtrust.com.br,
          chat da plataforma ou pelo telefone (16) 3106 9005.
        </p>
        <BaseButton
          type="text"
          color="primary"
          context-button="program"
          :to="origin"
        >
          Atualizar Página
        </BaseButton>
      </div>
    </BaseContainer>
  </div>
</template>
<script>
import BaseContainer from './BaseContainer'
import { mapMutations } from 'vuex'

export default {
  components: { BaseContainer },
  props: {
    origin: String
  },
  methods: {
    ...mapMutations(["setDisableNavigation"])
  },
  mounted() {
    this.setDisableNavigation(true)
  }
}
</script>
<style scoped>
  .error-500 {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
  }

  .error-500__container {
    align-items: center;
    display: flex;
    flex-flow: column wrap;
    padding: 0 12px;
    width: 540px;
  }

  .error-500__title {
    font-size: 24px;
    font-weight: 400;
  }

  .error-500__text {
    color: var(--custom-theme-black-054)
  }
</style>
