import api from '../../services/api';

export default {
  namespaced: true,
  state: {
    programMembers: [],
    memberDocuments: [],
    memberPartners: [],
    memberOrganization: null,
    memberContacts: null,
    memberAddress: null,
    memberBankAccounts: []
  },
  mutations: {
    setProgramMembers(state, payload) {
      state.programMembers = payload
    },
    setMemberDocuments(state, payload) {
      state.memberDocuments = payload
    },
    setMemberPartners(state, payload) {
      state.memberPartners = payload
    },
    setMemberContacts(state, payload) {
      state.memberContacts = payload
    },
    setMemberAddress(state, payload) {
      state.memberAddress = payload
    },
    setMemberOrganization(state, payload) {
      state.memberOrganization = payload
    },
    setMemberBankAccounts(state, payload) {
      state.memberBankAccounts = payload
    }
  },
  getters: {
    hasMemberContacts(state) {
      if (state.memberContacts) {
        return state.memberContacts.length !== 0
      } else {
        return false
      }
    },
    hasMemberAddress(state) {
      if (state.memberAddress) {
        return state.memberAddress.length !== 0
      } else {
        return false
      }
    }
  },
  actions: {
    async apiGetProgramMembers({commit}, payload) {
      const result = await api.get(`/members`, {params: payload})
      commit("setProgramMembers", result.data)
      return result
    },
    async apiStartConnection({commit}, payload) {
      await api.put(`/members/${payload}/connect`)
    },
    async apiDeleteConnection({commit}, payload) {
      await api.delete(`/members/${payload}/disconnect`)
    },
    async apiGetMemberDocuments({commit}, payload){
      const result = await api.get(`/members/${payload}/documents`)
      commit("setMemberDocuments", result.data.content)
    },
    async apiGetMemberPartners({commit}, payload) {
      const result = await api.get(`/members/${payload}/partners`)
      commit("setMemberPartners", result.data.content)
    },
    async apiGetMemberContact({commit}, payload) {
      const result =  await api.get(`/members/${payload}/contacts`)
      commit("setMemberContacts", result.data.content)
    },
    async apiGetMemberAddress({commit}, payload) {
      const result = await api.get(`/members/${payload}/address`)
      commit("setMemberAddress", result.data.content)
    },
    async apiGetMemberBankAccounts({commit}, payload) {
      const result = await api.get(`/members/${payload}/bank_accounts`)
      commit("setMemberBankAccounts", result.data.content)
    },
    async apiSendInviteToProgram({commit}, payload) {
      await api.post("/members/invite", payload)
    },
    async apiMemberAction({commit}, payload){
      const { memberId, action, data } = payload
      await api.patch(`/members/${memberId}/actions/${action}`, { data })
    }
  }
}
