const platformColors = {
  primary: '#293273',
  secondary: '#EBECF0', //hover
  error: '#EB6868',
  //custom colors//
  //gray
  grayf8: '#F8F8F8', // v-app
  graybe: '#bebebe',
  gray9a: '#9C9C9A',
  gray74: '#747474',
  //blue
  blueff: "#0069FF",
  bluefa: "#35A8FA",
  //black and white
  black: '#000000',
  white: '#FFFFFF',
  //state colors
  done: '#BAD371',
  ok: '#BAD371',
  pending: '#CCCCCC',
  executing: '#65B2E2',
  running: '#65B2E2',
  cancelled: '#EB6868',
  progress: '#F5D57E'
}

export default {
  theme: {
    options: {
      customProperties: true
    },
    dark: false,
    themes: {
      //overwrite themes default from vuetify
      light: platformColors,
      dark: platformColors
    }
  },
  icons: {
    iconfont: 'md',
  }
}
