export const rateMask = {
  decimal: ',',
  suffix: ' %',
  precision: 4
}

export const amountMask = {
  decimal: ',',
  thousands: '.',
  precision: 2
}
