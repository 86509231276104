<template>
  <div class="step-program-assets">
    <h2 class="app-headline font-weight-bold title text-center mb-5">
      Configurar ativos
    </h2>
    <h4 class="app-headline app-font-semibold step-name-configuration__title mb-6">
      <v-icon>request_quote</v-icon>
      <span class="ml-2">Ativos que serão negociados</span>
      <p class="body-1 mt-1">
        Escolha um ou mais tipos de ativos que você pretende negociar no seu programa.
      </p>
    </h4>
    <div class="checkbox-content ml-2">
      <v-checkbox
        v-for="asset in assetTypes"
        :key="asset.id"
        v-model="selectedAssets"
        multiple
        :label="asset.name"
        :value="asset.name"
        @change="updateOrDeleteAssetType(asset)"
      />
    </div>
    <BaseActionFooter
      :button-back="backButton"
      :button-cancel="cancelButton"
      :button-confirm="confirmButton"
    />
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import {utilsShowErrorInDevelopmentEnvironment} from '../../../utils'
import BaseActionFooter from '../../BaseActionFooter'

export default {
  name: 'StepProgramName',
  components: {BaseActionFooter},
  data() {
    return {
      backButton: {
        action: () => {
          this.$emit("onPreviousStep", 1)
        },
        text: 'Voltar'
      },
      cancelButton: {
        action: () => {
          this.$router.push('/platform-home')
        },
        text: 'Cancelar',
      },
    }
  },
  computed: {
    ...mapState({
        programId: (state) => {
          return state.platformProgram.programId
        },
        assetTypes: (state) => {
          return state.platformProgram.assetTypes
        },
        selectedAssetTypes: (state) => {
          return state.platformProgram.selectedAssetTypes
        }
    }),
    selectedAssets: {
      get() {
        return this.$store.state.platformProgram.selectedAssets
      },
      set(value) {
        this.setSelectedAssets(value)
      }
    },
    confirmButton() {
      return {
        action: () => this.$emit('onNextStep', 3),
        text: 'Avançar',
        disabled: this.selectedAssets.length === 0
      }
    },
  },
  methods: {
    ...mapActions(['apiUpdateProgramAssetList', 'apiDeleteProgramAssetList', 'apiGetSelectedAssetTypes']),
    ...mapMutations(['setSelectedAssets']),
    async updateOrDeleteAssetType(asset) {
      const assetToSend = this.getSelectedAssetInAssets(asset.name)
      if(this.hasNameInSelectedAssets(asset.name) !== -1) {
        await this.apiUpdateProgramAssetList({
          asset_type: assetToSend[0],
          programId: this.programId
        })
      } else {
        await this.apiDeleteProgramAssetList({
          asset_type: assetToSend[0],
          programId: this.programId
        })
      }
      await this.apiGetSelectedAssetTypes(this.programId)
    },
    hasNameInSelectedAssets(name) {
      return this.selectedAssets.indexOf(name)
    },
    getSelectedAssetInAssets(name) {
      return this.assetTypes.filter(elem => {
        return elem.name == name
      })
    }
  },
}
</script>

<style scoped>
.step-program-assets .checkbox-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, 250px);
  column-gap: 20px;
}

.step-program-assets >>> .v-messages {
  display: none !important;
}
</style>
