import { render, staticRenderFns } from "./StepProgramName.vue?vue&type=template&id=82b872d0&scoped=true"
import script from "./StepProgramName.vue?vue&type=script&lang=js"
export * from "./StepProgramName.vue?vue&type=script&lang=js"
import style0 from "./StepProgramName.vue?vue&type=style&index=0&id=82b872d0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82b872d0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCol,VCombobox,VForm,VIcon,VTextarea})
