<template>
  <div class="base-breadcrumb mb-6">
    <ol class="app-align-items-center">
      <li
        v-for="(path, index) in this.config.paths"
        :key="index"
        class="base-breadcrumb__path"
      >
        <template v-if="!!path.path">
          <BaseLink
            :class="{ 'app-align-items-center': index === 0 }"
            :to="path.path"
            type-link="programLink"
          >
            <template v-if="index === 0">
              <v-icon
                color="primary"
                class="mr-2"
              >
                arrow_back
              </v-icon>
            </template>
            {{ path.name }}
          </BaseLink>
        </template>
        <template v-else>
          <span v-html="path.name" />
        </template>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.base-breadcrumb__path {
  min-width: 1px;
  font-size: 0.875rem;
}

.base-breadcrumb__path:not(:first-child) {
  margin-left: 12px;
  position: relative;
}

.base-breadcrumb__path:not(:first-child):before {
  content: "/";
  position: absolute;
  height: 100%;
  left: -8px;
}
</style>
