<template>
  <div class="dashboard-home">
    <BaseContainer>
      <template v-slot:header-title>
        Dashboard
      </template>
      <template v-slot:header-title-description>
        Acompanhe os números da plataforma.
      </template>

      <div class="app-align-items-center mb-4">
        <v-autocomplete
          id="qa-id-name-program-list"
          v-model="selectedProgram"
          :items="programList"
          return-object
          item-value="id"
          item-text="name"
          label="Selecione o programa"
          :rules="[isValid]"
          style="max-width: 250px;"
          class="mr-2"
          @change="onChangeProgram"
        />
        <v-autocomplete
          v-if="organizationList.length > 0"
          id="qa-id-name-organization-list"
          v-model="selectedOrganization"
          :items="organizationList"
          return-object
          clearable
          item-value="roleAndTaxnumber"
          item-text="nameAndTaxnumber"
          label="Selecione a organização"
          hint="Filtre por Razão Social ou CNPJ"
          no-data-text="Organização não encontrada"
          persistent-hint
          :rules="[isValid]"
          style="max-width: 250px;"
          class="mr-2"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.tax_number | maskCNPJ }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </div>
      <div v-if="!hideDashboard && getDashboardByRole">
        <template v-if="typeof getDashboardByRole === 'string'">
          <iframe
            v-if="showEmbed"
            width="100%"
            height="500"
            seamless
            frameBorder="0"
            scrolling="yes"
            :src="getIframeUrl(getDashboardByRole)"
          />
        </template>
        <template v-else>
          <v-row>
            <v-col
              v-for="chart, index in getDashboardByRole"
              :key="index"
              cols="12"
              md="6"
              class="pl-0"
            >
              <h3 class="mb-2">
                {{ chart.title }}
              </h3>
              <iframe
                v-if="showEmbed"
                width="100%"
                height="400"
                seamless
                frameBorder="0"
                scrolling="yes"
                :src="getIframeUrl(chart.url)"
              />
            </v-col>
          </v-row>
        </template>
      </div>
      <div v-else>
        Não há dados para exibir. Utilize os filtros acima para buscar novos dados.
        <v-row>
          <v-col
            v-for="n, index in 2"
            :key="index"
            cols="12"
            md="6"
            class="pl-0"
          >
            <v-skeleton-loader
              class="mb-6"
              boilerplate
              elevation="1"
              type="card-heading, image"
            />
          </v-col>
        </v-row>
      </div>
    </BaseContainer>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex"
import BaseContainer from '../BaseContainer'

export default {
  components: { BaseContainer },
  data: () => ({
    selectedProgram: null,
    selectedOrganization: null,
    dashboardUrlList: {},
    showEmbed: false
  }),
  computed: {
    ...mapState({
      ticket: state => state.ticket,
      allPrograms: (state) => {
        return state.platformProgram.allPrograms
      }
    }),
    programList() {
      return this.allPrograms.filter(
        elem => elem.is_owner || elem.enrollments.findIndex(enrollment => enrollment.status === 'ENROLLED' ) != -1
      )
    },
    hideDashboard(){
      return !this.selectedProgram || (this.organizationList.length > 0 && !this.selectedOrganization) || !this.dashboardUrlList[this.programRole]
    },
    organizationList(){
      let orgList = []

      if(this.selectedProgram){

        if(this.selectedProgram.is_owner == true){
          this.addItemList(orgList, 'owner', 'Gestor', '')
        }

        if(this.selectedProgram.enrollments.length > 0){
          this.selectedProgram.enrollments.map((enrollment)=> {
            this.addItemList(orgList, enrollment.role, enrollment.organization.name, enrollment.organization.tax_number)
          })
        }
      }
      return orgList
    },
    programRole(){
      return this.selectedProgram && this.selectedOrganization && this.selectedOrganization.role ? this.selectedOrganization.role : ''
    },
    getDashboardByRole(){
      return this.dashboardUrlList[this.programRole] ? this.dashboardUrlList[this.programRole] : null
    }
  },
  watch: {
    getDashboardByRole: {
      async handler(value) {
        if(value){
          let orgTaxNumber = this.selectedOrganization && this.selectedOrganization.tax_number || null
          let payload = { program_id: this.selectedProgram.initiator }

          if(orgTaxNumber){
            payload.organization_tax_number = orgTaxNumber
          }

          await this.apiGetTicket(payload)
          this.showEmbed = true
        } else {
          this.showEmbed = false
        }
      },
      deep: true
    },
  },
  created(){
    this.apiGetAllPrograms()
  },
  methods: {
    ...mapActions(["apiGetAllPrograms", "apiGetTicket"]),
    getIframeUrl(dashUrl){
      let splitUrl = dashUrl.split('?')
      let paramToUrlChart = splitUrl[1] ? `?${splitUrl[1]}` + `&ticket=${this.ticket}` : `?ticket=${this.ticket}`

      let url = splitUrl[0] ? splitUrl[0] + paramToUrlChart : null
      return url
    },
    onChangeProgram(){
      this.selectedOrganization = null
      this.getProgramDashboardUrl()
    },
    addItemList(list, role, name, tax_number){
      list.push({
        role,
        name,
        tax_number,
        nameAndTaxnumber: tax_number ? `${name} - ${tax_number}` : name,
        roleAndTaxnumber: tax_number ? `${role} - ${tax_number}` :  `${role} - ${name}`
      })
      return list
    },
    getProgramDashboardUrl(){
      if(this.selectedProgram && this.selectedProgram.configuration){
        Object.keys(this.selectedProgram.configuration).forEach(key => {
          if (key.includes('dashboard_url')){
            let configDashURL = this.selectedProgram.configuration[key]
            let urlParsed =  configDashURL.startsWith('[') ? JSON.parse(configDashURL) : configDashURL

            this.dashboardUrlList = Object.assign({}, {
              ...this.dashboardUrlList,
              [key.replace('dashboard_url_', '')]: urlParsed
            })
          }
        })
      }
    },
  }
}
</script>

<style lang="scss">
</style>
