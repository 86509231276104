<template>
  <div class="platform-home">
    <BaseTour
      :tour-name="tourName"
      :steps="dataSteps"
      @onFinish="finishTour"
    />
    <BaseBannerAccount
      :banner-title="bannerTitle"
      banner-text="Esta é a sua central de configurações, gerencie sua conta e personalize a sua
            experiência na plataforma Finanfor."
    />
    <div 
      v-if="!userIsSales"
      class="highlight-container"  
    >
      <v-col
        v-for="(card, index) in highlightCards"
        :key="index"
        cols="auto"
        class="highlight-cards mt-6 pt-0 pr-0"
      >
        <v-card
          :height="150"
          :data-v-step="index"
        >
          <div
            class="app-align-items-center"
            style="height: 109px"
          >
            <v-img
              :src="card.image"
              :width="card.imageSize"
              :max-width="card.imageSize"
              style="margin: 17px 13px"
            />

            <v-card-title style="white-space: nowrap">
              <div>
                <h3 class="headline mb-0 platform-home">
                  {{ card.title }}
                </h3>
                <div class="platform-home subtitle-1">
                  {{ card.subtitle }}
                </div>
              </div>
            </v-card-title>
          </div>

          <v-divider />
          <v-card-actions>
            <BaseLink
              :to="card.link"
              class="app-align-items-center ml-6"
            >
              <v-icon class="blueff--text">
                {{ card.icon }}
              </v-icon>
              <span class="ml-1">{{ card.linkLabel }}</span>
            </BaseLink>
          </v-card-actions>
        </v-card>
      </v-col>
    </div>
    <BaseCard
      :base-card-container-classes="'mt-4'"
      :has-main-container="true"
      :base-card-title-class="'headline font-weight-regular'"
      data-v-step="3"
    >
      <template
        v-if="programList.length > 0"
        v-slot:header-title
      >
        Meus programas
      </template>
      <template
        v-if="programList.length > 0"
        v-slot:header-title-description
      >
        Esta é a lista dos seus programas
      </template>
      <div
        v-if="programList.length > 0"
        class="pt-4"
      >
        <div
          v-if="userIsAdmin"
          class="app-align-items-center px-5 d-flex justify-space-between"
        >
          <BaseLink
            to="/platform-configure-program"
            class="app-align-items-center"
          >
            <v-icon color="blueff">
              add
            </v-icon>
            <span class="ml-1">Criar programa</span>
          </BaseLink>
          <BaseInput
            v-if="userIsAdmin"
            ref="filter"
            v-model="filter"
            label="Buscar"
            clearable
            append-outer-icon="check_circle"
            @keyup.enter="filterProgramList()"
            @click:append-outer="filterProgramList()"
            @click:clear="clearFilter()"
          />
        </div>
        <v-divider class="mt-3" />
        <BaseDataTable
          :headers="['', '', '']"
          :items="filteredProgramList || programList"
          :hide-headers="true"
          :single-expand="true"
          :options="pagination"
          background-color="transparent"
        >
          <template v-slot:inject-items="scopedItems">
            <tr
              v-for="value in scopedItems"
              :key="value.index"
            >
              <td class="app-align-items-center">
                <v-chip
                  v-if="isPendingInvitation(value.item)"
                  label
                  color="bluefa"
                  class="app-custom-chip ml-0"
                  text-color="white"
                >
                  Novo
                </v-chip>
                <strong>{{ value.item.name }}</strong>
                <div
                  v-if="showInfoIcon(value.item)"
                  class="gray9a--text"
                >
                  <v-tooltip
                    max-width="285"
                    bottom
                  >
                    <template
                      v-slot:activator="{ on }"
                    >
                      <span
                        v-on="on"
                      >
                        <v-icon
                          color="gray9a"
                          class="ml-2 mr-1"
                        >
                          info
                        </v-icon>
                      </span>
                    </template>
                    <span>{{ parsePendingMessage(value.item)[1] }}</span>
                  </v-tooltip>

                  {{ parsePendingMessage(value.item)[0] }}
                </div>
                <v-spacer />
                <a
                  v-if="isReprovedInvitation(value.item)"
                  class="
                    body-2
                    blueff--text
                    app-font-semibold app-align-items-center
                    link-action-table
                    mx-2"
                  @click="requestApproval(value.item)"
                >
                  <v-icon class="blueff--text mr-1">person_search</v-icon>
                  <span class="text-action-table">Reanálise</span>
                </a>
                <a
                  v-if="userIsAdmin"
                  class="
                    body-2
                    blueff--text
                    app-font-semibold app-align-items-center
                    link-action-table
                    mx-2
                  "
                  @click="editProgram(value.item)"
                >
                  <v-icon class="blueff--text mr-1">edit</v-icon>
                  <span class="text-action-table">Editar</span>
                </a>
                <a
                  v-if="value.item.is_owner"
                  class="
                    body-2
                    blueff--text
                    app-font-semibold app-align-items-center
                    link-action-table
                    mx-2
                  "
                  @click="openModalInvite(value.item)"
                >
                  <v-icon class="blueff--text mr-1">group_add</v-icon>
                  <span class="text-action-table">Convidar</span>
                </a>
                <a
                  v-if="isEnrolledInvitation(value.item)"
                  :href="`${value.item.host}/#/trades`"
                  class="
                    body-2
                    blueff--text
                    app-font-semibold
                    mx-2
                    app-align-items-center
                    link-action-table
                  "
                >
                  <v-icon class="blueff--text mr-1">open_in_new</v-icon>
                  <span class="text-action-table">Acessar</span>
                </a>
                <div
                  v-if="isPendingInvitation(value.item)"
                  class="text-right pl-3 gray9a--text"
                >
                  Você foi convidado para este programa,
                  <a
                    class="body-2 blueff--text app-font-semibold link-action-table"
                    @click="showInvite(value.item); value.expand(!value.isExpanded); $tours[tourName].stop()"
                  >ver mais informações</a>.
                </div>
                <a
                  v-if="!isPendingInvitation(value.item)"
                  class="body-2 app-font-semibold ml-4"
                  @click="expandProgram(value.item); value.expand(!value.isExpanded)"
                >
                  <v-icon
                    v-if="value.isExpanded"
                    class="blueff--text mr-1"
                  >
                    expand_less
                  </v-icon>
                  <v-icon
                    v-else
                    class="blueff--text mr-1"
                  >
                    expand_more
                  </v-icon>
                </a>
              </td>
            </tr>
          </template>
          <template v-slot:inject-expand="{ items }">
            <v-tabs
              v-model="expandTab"
              background-color="transparent"
            >
              <v-tab>Informações do programa</v-tab>
              <v-tab>Documentos</v-tab>
            </v-tabs>

            <v-tabs-items
              v-model="expandTab"
              style="background: transparent"
            >
              <v-tab-item class="body-1 pa-6">
                <p
                  v-if="isPendingInvitation(items.item) && programDetail.enrollments.length > 1 && !statusOnAcceptInvite"
                  class="mb-4"
                >
                  Convite 1 de {{ programDetail.enrollments.length }}
                </p>
                <p v-if="!statusOnAcceptInvite">
                  <b>Descrição do programa:</b> {{ programDetail.description }}
                </p>
                <template v-if="isPendingInvitation(items.item) && !statusOnAcceptInvite">
                  <p
                    class="font-weight-bold mt-3 mb-1"
                    style="display: flex; align-items: end"
                  >
                    <v-icon class="pr-1">
                      domain
                    </v-icon> Organização convidada:
                  </p>
                  <p>{{ programDetail.enrollments[0].organization.name }}</p>
                  <p>
                    CNPJ: {{ programDetail.enrollments[0].organization.tax_number | maskCNPJ }}
                  </p>
                  <p>
                    Perfil de atuação:
                    {{ convertRole(programDetail.enrollments[0].role) }}
                  </p>
                  <v-checkbox
                    v-model="isAgreeChecked"
                    class="mt-4 pb-2"
                    hide-details
                  >
                    <template v-slot:label>
                      <span style="font-size: 0.875rem !important">Li o regulamento do programa e estou de acordo.</span>
                    </template>
                  </v-checkbox>
                </template>
                <template v-else-if="statusOnAcceptInvite === 'success'">
                  <h3 class="subtitle-1 font-weight-bold mb-4">
                    Convite aceito!
                  </h3>
                  <p>Termine de cadastrar as informações de sua organização para continuar o processo de adesão.</p>
                  <template v-if="programDetail.enrollments.length > 1">
                    <p>Você tem outro convite para este programa.</p>
                    <BaseButton
                      :is-disabled="!isAgreeChecked || isLoading"
                      color="primary"
                      button-class="mx-0 my-4"
                      @click="showInvite(items.item)"
                    >
                      Ver convite
                    </BaseButton>
                  </template>
                </template>
                <template v-else-if="statusOnAcceptInvite === 'error'">
                  <h3 class="subtitle-1 font-weight-bold mb-4">
                    Houve um erro ao aceitar o convite!
                  </h3>
                  <p class="mb-1">
                    Houve um problema, atualize a página ou tente novamente mais tarde.<br>Se o problema persistir, entre em contato pelo e-mail atendimento@idtrust.com.br, chat da plataforma ou pelo telefone (16) 3106 9005.
                  </p>
                </template>
              </v-tab-item>
              <v-tab-item class="body-1 pa-6">
                <template v-if="programDocuments.length > 0">
                  <div
                    v-for="document in programDocuments"
                    :key="document.id"
                    class="body-1"
                  >
                    <BaseButton
                      context-button="program"
                      color="primary"
                      :has-icon="true"
                      class="my-0"
                      @click="downloadDocument(document)"
                    >
                      <v-icon>cloud_download</v-icon>
                    </BaseButton>
                    {{ document.name }}
                  </div>
                </template>
                <template v-else>
                  Não existe regulamento ou documentos para esse programa.
                </template>
              </v-tab-item>
            </v-tabs-items>
            <div
              v-if="isPendingInvitation(items.item) && !statusOnAcceptInvite"
              class="text-right mr-6 mb-6"
            >
              <BaseButton
                color="primary"
                button-class="mr-2"
                type="text"
                @click="rejectedModal.active = true"
              >
                Recusar
              </BaseButton>
              <BaseButton
                :is-disabled="!isAgreeChecked || isLoading"
                color="primary"
                button-class="mx-0"
                @click="acceptInvite"
              >
                Aceitar
              </BaseButton>
            </div>
          </template>
        </BaseDataTable>
      </div>
      <div
        v-else
        class="d-flex flex-column align-center py-12 px-6"
      >
        <v-img
          class="base-modal__icon mb-4"
          style="margin: 0 auto"
          :src="require('@/assets/invite-program.svg')"
          contain
          :height="54"
          :max-width="191"
          :width="191"
        />
        <div
          v-if="userIsAdmin"
          class="d-flex flex-column align-center"
        >
          <p class="body-1 text-center mb-4">
            Você <strong>não criou</strong> ou <strong>não participa</strong> de
            um programa, crie um clicando no botão abaixo ou<br><strong>aguarde um convite</strong>
            para participar de um programa existente.
          </p>
          <BaseButton to="/platform-configure-program">
            Criar programa
          </BaseButton>
        </div>
        <div v-else>
          <p class="body-1 text-center mb-4">
            Você <strong>não participa</strong> de nenhum programa,
            <strong>aguarde um convite</strong> para participar de um existente.
          </p>
        </div>
      </div>
    </BaseCard>
    <BaseSnackbar
      :active-snackbar="feedback"
      :type-snackbar="feedbackColor"
      :text-snackbar="feedbackMessage"
      @closeSnackbar="feedback = !feedback"
    />
    <ModalInviteMembers
      :modal-info="modalInfo"
      context="platform"
      :is-modal-active="isModalActive"
      :program="selectedProgram"
      @onCloseModalInvite="closeModalInvite"
    />
    <v-dialog
      v-model="rejectedModal.active"
      max-width="560"
      persistent
    >
      <BaseModal
        :show-indeterminate-progress="rejectedModal.showIndeterminateProgress"
        :top-slot-content-title="rejectedModal.topSlotContentTitle"
        :remove-button-close="rejectedModal.removeButtonClose"
        :middle-image="rejectedModal.middleImage"
        :middle-slot-content-text-highlight="rejectedModal.middleSlotContentTextHighlight"
        :middle-slot-content-text="rejectedModal.middleSlotContentText"
        :middle-check-agree="rejectedModal.middleCheckAgree"
        :bottom-buttons-align="rejectedModal.bottomButtonsAlign"
        :bottom-slot-content-button-cancel-label="rejectedModal.bottomSlotContentButtonCancelLabel"
        :bottom-slot-content-button-confirm-label="rejectedModal.bottomSlotContentButtonConfirmLabel"
        :remove-button-cancel="rejectedModal.removeButtonCancel"
        :remove-button-confirm="rejectedModal.removeButtonConfirm"
        :custom-enable-bottom-button-confirm-label="rejectedModal.customEnableBottomButtonConfirmLabel"
        @on-cancel="closeRejectedModal"
        @on-confirm="() => rejectedInvite(rejectedModal.reason)"
      >
        <template
          v-slot:inject-form
        >
          <BaseInput
            v-model="rejectedModal.reason"
            label="Motivo da recusa"
            :rules="[isValid]"
            @update:error="handleInputReasonIsError"
          />

          <p class="mt-3">
            Ao prosseguir, a ação não poderá ser desfeita.<br> Deseja recusar esse convite?
          </p>
        </template>
      </BaseModal>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import BaseDataTable from "../BaseDataTable"
import BaseBannerAccount from "../BaseBannerAccount"
import ModalInviteMembers from "./ModalInviteMembers"
import permissions from "@/mixins/permissions"
import ProgramTour from '../../mixins/ProgramTour'
import BaseSnackbar from '../BaseSnackbar'
import BaseSnackbarConfiguration from '../../mixins/BaseSnackbarConfiguration'
import BaseModal from '../BaseModal'

export default {
  components: {
    BaseDataTable,
    BaseBannerAccount,
    ModalInviteMembers,
    BaseSnackbar,
    BaseModal
  },
  mixins: [ permissions, ProgramTour, BaseSnackbarConfiguration ],
  data() {
    return {
      isLoading: false,
      filter: '',
      filteredProgramList: '',
      highlightCards: [
        {
          image: `${require("@/assets/manage-account.svg")}`,
          imageSize: "90",
          title: "Minha Conta",
          subtitle: "Editar dados de usuários e organizações.",
          icon: "account_circle",
          link: "/platform-personal-data",
          linkLabel: "Gerenciar Minha Conta",
        },
        {
          image: `${require("@/assets/img-dashboards.svg")}`,
          imageSize: "90",
          title: "Dashboards",
          subtitle: "Acompanhe os números da plataforma.",
          icon: "insert_chart_outlined",
          link: "/dashboard",
          linkLabel: "Gerenciar Dashboards",
        },
      ],
      isModalActive: false,
      modalInfo: {
        showIndeterminateProgress: false,
        topSlotContentTitle: "Convidar Participantes",
        removeButtonClose: false,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: "",
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "Cancelar",
        bottomSlotContentButtonConfirmLabel: "",
        removeButtonCancel: true,
        removeButtonConfirm: true,
        onCancel: null,
        onConfirm: null,
      },
      rejectedModal: {
        active: false,
        reason: '',
        validReason: false,
        showIndeterminateProgress: false,
        topSlotContentTitle: "Recusar convite",
        removeButtonClose: false,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: "<p class='mb-4'>Para recusar o convite, digite o motivo abaixo: </p>",
        middleCheckAgree: null,
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "Cancelar",
        bottomSlotContentButtonConfirmLabel: "Confirmar",
        removeButtonCancel: false,
        removeButtonConfirm: false,
        customEnableBottomButtonConfirmLabel: false
      },
      selectedProgram: {},
      pagination: {
        page: 1,
        itemsPerPage: 5,
        sortBy:['name']
      },
      tourName: 'firstAccess',
      dataStepsFirstAccess: [
        {
          target: '#v-step-0',
          header: {
            title: 'Bem vindo(a) a  Finanfor!'
          },
          content: 'Como é sua primeira vez aqui, vamos te mostrar algumas funções importantes aqui na plataforma.',
          layoutStep: "starter",
          params: {
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="1"]',
          header: {
            title: 'Dashboards'
          },
          content: 'Aqui você pode visualizar os números da plataforma.'
        },
        {
          target: '[data-v-step="3"]',
          header: {
            title: 'Meus programas'
          },
          content: 'Sempre que você for convidado para um programa, o convite ficará disponível aqui, junto com os programas aos quais você já faz parte.',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step="0"]',
          header: {
            title: 'Minha Conta'
          },
          content: 'Aqui você pode alterar sua senha de acesso da plataforma, editar os dados de usuários e acessar suas organizações.'
        }
      ],
      dataStepsPersonalData: [
        {
          target: '[data-v-step="0"]',
          header: {
            title: 'Minha Conta'
          },
          content: 'Aqui você pode alterar sua senha de acesso da plataforma, editar os dados de usuários e acessar suas organizações.'
        }
      ],
      showNewTour: false,
      statusOnAcceptInvite: null,
      isAgreeChecked: false,
      expandTab: 0,
      programDetail: {
        name: "",
        description: "",
      },
    };
  },
  methods: {
    ...mapMutations(["setNavigationMini", "setSelectedProgram"]),
    ...mapActions([
      "apiSendProgramInvitation",
      "apiGetProgramSelectedRoles",
      "apiGetProgramDocuments",
      "apiDownloadDocument",
      "apiAcceptOrRejectedProgramInvitation",
      "apiGetAllPrograms",
    ]),
    ...mapActions("platformOrganization", [
      "apiGetPlatformOrganizationsByTaxnumber",
      "apiReviewOrganization"
    ]),
    finishTour(){
      this.$router.push('/platform-personal-data')
    },
    closeModalInvite() {
      this.isModalActive = false
    },
    showInfoIcon(value) {
     return this.checkStatusInvitation(value, ['UNDER_REVIEW', 'REVIEWED', 'ACCEPTED', 'REPROVED', 'WAITING_FOR_APPROVAL', 'CHANGES_REQUESTED'])
    },
    async openModalInvite(program) {
      await this.apiGetProgramSelectedRoles(program.id)
      this.isModalActive = true
      this.selectedProgram = program
    },
    isPendingInvitation(item) {
      return this.checkStatusInvitation(item, ['PENDING'])
    },
    isEnrolledInvitation(item){
      return item.is_owner || this.checkStatusInvitation(item, ['ENROLLED'])
    },
    isUnderReviewInvitation(item) {
      return this.checkStatusInvitation(item, ['UNDER_REVIEW'])
    },
    isReviewedInvitation(item){
      return this.checkStatusInvitation(item, ['REVIEWED'])
    },
    isAcceptedInvitation(item){
      return this.checkStatusInvitation(item, ['ACCEPTED'])
    },
    isReprovedInvitation(item){
      return this.checkStatusInvitation(item, ['REPROVED'])
    },
    parsePendingMessage(item){
      const statusList = ['UNDER_REVIEW', 'REVIEWED', 'ACCEPTED', 'REPROVED', 'WAITING_FOR_APPROVAL', 'CHANGES_REQUESTED']
      const enrollment = item.enrollments.length > 0 && item.enrollments.find(elem => {
        if(statusList.find(status => status === elem.status))
        return elem.status
      })
      const msg = {
        UNDER_REVIEW: ['Documentos em análise.', 'Os documentos da sua organização estão em fase de análise pela equipe jurídica.'],
        REVIEWED: ['Assinar termo de adesão.', 'Sua adesão foi validada pela equipe jurídica.  Assine o termo disponível na aba Formalizações para liberar o acesso da organização ao programa.'],
        ACCEPTED: ['Cadastrar documentos da organização.', 'Aguardando cadastro dos documentos de sua organização para prosseguir com a adesão. Envie os documentos solicitados em Organizações.'],
        REPROVED: ['Sua adesão não foi aprovada', 'Você pode verificar o motivo em seu email e caso necessário solicitar uma nova análise no botão ao lado.'],
        WAITING_FOR_APPROVAL: ['Adesão em análise', 'A adesão de sua organização está em fase de análise pelo sacado.'],
        CHANGES_REQUESTED: ['Alterações solicitadas', 'O jurídico solicitou alterações em seu cadastro, verifique seu email e realize as correções para uma nova verificação de documentos.']
      }
      return msg[enrollment.status]
    },
    checkStatusInvitation(item, statusList) {
      return item.enrollments.length > 0 && item.enrollments.findIndex(elem => statusList.find(status => status === elem.status)) !== -1 || false;
    },
    async downloadDocument(document) {
      let documentId = document.file_uri.replace("storage://", "")
      await this.apiDownloadDocument({
        id: documentId,
        organizationId: this.programDetail.initiator,
        name: document.name,
      });
    },
    convertRole(value) {
      let role = this.programSelectedRoles.find((role) => role.type === value)
      return role ? role.description : ""
    },
    async requestApproval(program) {
      let organizationId = program.enrollments.length > 0 && program.enrollments.find(elem =>  elem.status === 'REPROVED').organization.id
      try {
        await this.apiReviewOrganization({
          organizationId,
          action: 'confirm',
          status: 'REPROVED',
          data: {}
        })

        this.showFeedback({
          message: 'Solicitação de reanálise enviada!',
          color: 'success'
        })

        await this.apiGetAllPrograms(this.keycloak["account-id"])
      } catch(e){
        this.showFeedback({
          message: 'Erro ao solicitar reanálise.',
          color: 'error'
        })
      }
    },
    async expandProgram(program) {
      this.isAgreeChecked = false
      this.statusOnAcceptInvite = null
      this.expandTab = 0
      this.programDetail = program
      try {
        await this.apiGetProgramSelectedRoles(program.id)
        await this.apiGetProgramDocuments(program.id)
      } catch (e) {
        throw error
      }
    },
    async acceptInvite() {
      this.isLoading = true
      try {
        const data = {
          programId: this.programDetail.id,
          tax_number: this.programDetail.enrollments[0].organization.tax_number,
          role: this.programDetail.enrollments[0].role,
          status: 'ACCEPTED'
        };
        await this.apiAcceptOrRejectedProgramInvitation(data)
        await this.apiGetAllPrograms(this.keycloak["account-id"])
        this.statusOnAcceptInvite = "success"
        this.showNewTour = true
        this.startTour(this.tourName)
      } catch (error) {
        this.statusOnAcceptInvite = "error"
        throw error
      } finally {
        this.isLoading = false
      }
    },
    async rejectedInvite(reason) {
      this.isLoading = true
      try {
        const data = {
          programId: this.programDetail.id,
          tax_number: this.programDetail.enrollments[0].organization.tax_number,
          role: this.programDetail.enrollments[0].role,
          status: 'REJECTED',
          reason: reason
        };
        await this.apiAcceptOrRejectedProgramInvitation(data)
        this.showFeedback({
          message: 'Convite recusado com sucesso!',
          color: 'success'
        })
      } catch (error) {
        this.showFeedback({
          message: 'Erro ao recusar convite!',
          color: 'error'
        })
      } finally {
        this.isLoading = false
        this.closeRejectedModal()
        await this.apiGetAllPrograms(this.keycloak["account-id"])
      }
    },
    closeRejectedModal() {
      Object.assign(this.rejectedModal, {
        ...this.rejectedModal,
        active: false,
        reason: '',
        customEnableBottomButtonConfirmLabel: null
      })
    },
    handleInputReasonIsError(value) {
      Object.assign(this.rejectedModal, {
        ...this.rejectedModal,
        customEnableBottomButtonConfirmLabel: !value
      })
    },
    showInvite(item) {
      const filterPendingInvites = item.enrollments.filter(
        (element) => element.status === "PENDING"
      )
      item = { ...item, enrollments: filterPendingInvites }
      this.expandProgram(item)
    },
    editProgram(item) {
      this.setSelectedProgram(item)
      this.$router.push({ path: `/create-program-steps/${item.id}` })
    },
    filterProgramList() {
      this.pagination.page = 1
      
      this.filteredProgramList = this.programList
      let filteredList = this.filteredProgramList.filter((program) => this.filter !== null ? program.initiator.includes(this.filter.toUpperCase()) : this.filter = '')
      this.filteredProgramList = filteredList
    },
    clearFilter(){
      this.filter = ''
      this.pagination.page = 1
      this.filterProgramList();
    }
  },
  computed: {
    ...mapState({
      keycloak: (state) => state.auth.keycloak.tokenParsed,
      programList: (state) => state.platformProgram.allPrograms,
      platformOrganizationsByTaxnumber: (state) => state.platformOrganization.platformOrganizationsByTaxnumber,
      programSelectedRoles: (state) => state.platformProgram.programSelectedRoles,
      programDocuments: (state) => state.platformProgram.programDocuments,
    }),
    bannerTitle() {
      return `Olá, <span class="font-weight-bold pl-1">${this.hideData(this.keycloak.name, true)}</span>`
    },
    dataSteps(){
      return this.showNewTour ? this.dataStepsPersonalData : this.hasPendingInvitation ? this.dataStepsFirstAccess : this.dataStepsFirstAccess.filter((elem, index) => index !== 2)
    },
    hasPendingInvitation(){
      return this.programList.findIndex(program => this.isPendingInvitation(program)) > -1 || false
    },
  },
  beforeRouteLeave(to, from, next) {
    this.setNavigationMini(false)
    next()
  },
};
</script>

<style>
.highlight-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: calc(100% + 20px);
  margin-left: -20px;
}

.highlight-cards {
  flex-basis: 50%;
  flex-grow: 1;
  padding-left: 20px;
  background-clip: content-box;
}

.platform-home .link-action-table .text-action-table {
  display: none;
}

.platform-home .link-action-table:hover .text-action-table {
  display: block;
}
</style>
