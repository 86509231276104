import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import operationRegister from './modules/operationRegister';
import operationInfo from './modules/operationInfo';
import accounts from './modules/accounts';
import common from './modules/common';
import sign from './modules/sign';
import incomingFiles from "./modules/incomingFiles";
import platformAccount from './modules/platformAccount';
import platformProgram from './modules/platformProgram';
import platformOrganization from './modules/platformOrganization'
import platformConfiguration from './modules/platformConfiguration'
import programMovements from './modules/programMovements'
import programDraftMovements from './modules/programDraftMovements'
import programMembers from "./modules/programMembers";
import programFilters from "./modules/programFilters";
import theHeader from './modules/theHeader'
import theNavigation from './modules/theNavigation'
import rolesManagement from './modules/rolesManagement'
import api from '../services/api';
import {utilsCreateTemporaryLinkToDownload} from '../utils'
import platformTour from './modules/platformTour'
import programAuthz from './modules/programAuthz'
import programNotifications from './modules/programNotifications'


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    operationRegister,
    operationInfo,
    accounts,
    incomingFiles,
    common,
    sign,
    platformAccount,
    platformOrganization,
    platformConfiguration,
    platformProgram,
    programMovements,
    programDraftMovements,
    programMembers,
    programFilters,
    theHeader,
    theNavigation,
    rolesManagement,
    platformTour,
    programAuthz,
    programNotifications
  },
  state: {
    loadPage: false,
    progressLoadPage: 0,
    disableNavigation: false,
    envelopeList: [],
    envelope: [],
    envelopeDetails: [],
    envelopeFiles: [],
    envelopeHasSuccessTokenCancel: false,
    envelopeTokenCancel: {},
    originPath: {
      origin: '*'
    },
    useActionFooter: false,
    permissionErrorMessage: {},
    ticket: null
  },
  getters: {
    envelopeMapUrlFiles({envelope}) {
      return envelope.map(value => value.url)
    },
    filterAllFormalizationsExceptError({envelopeList}) {
      return envelopeList.filter(envelope => envelope.status !== 'ERROR')
    },
  },
  mutations: {
    setLoadPage(state, payload) {
      state.loadPage = payload
    },
    setProgressLoadPage(state, payload) {
      state.progressLoadPage = payload
    },
    setDisableNavigation(state, payload) {
      state.disableNavigation = payload
    },
    setEnvelope(state, payload) {
      state.envelope = payload
    },
    setPermissionErrorMessage(state, payload){
      state.permissionErrorMessage = payload
    },
    setEnvelopeDetails(state, payload) {
      state.envelopeDetails = payload
    },
    setEnvelopeFiles(state, payload) {
      state.envelopeFiles = payload
    },
    setEnvelopeList(state, payload) {
      state.envelopeList = payload
    },
    setEnvelopeHasSuccessTokenCancel(state, payload) {
      state.envelopeHasSuccessTokenCancel = payload
    },
    setEnvelopeTokenCancel(state, payload) {
      state.envelopeTokenCancel = payload
    },
    setOriginPath(state, payload) {
      Object.assign(state.originPath, payload)
    },
    setUseActionFooter(state, payload) {
      state.useActionFooter = payload
    },
    setTicket(state, payload) {
      state.ticket = payload
    }
  },
  actions: {
    apiGetFormalizations({commit}, payload) {

      if (payload.page) {
        payload.page = payload.page - 1;
      }

      return new Promise((resolve, reject) => {
        api.get("/envelopes", {
          params: {
            page: payload.page,
            size: payload.size,
            start_date: payload.start_date.length > 0 ? payload.start_date :
              (() => {
                const [year, month] = new Date().toISOString().substring(0,
                  7).split('-')

                if (parseInt(month) === 1) {
                  return new Date(new Date(new Date().setMonth(11)).setYear(
                    parseInt(year) - 1)).toISOString().substring(0, 10)
                }

                return new Date(new Date().setMonth(
                  parseInt(month) - 2)).toISOString().substring(0, 10)
              })(),
            end_date: payload.end_date.length > 0 ? payload.end_date
              : new Date().toISOString().substring(0, 10),
            status: payload.status,
            name: payload.name,
            participant: payload.participant,
            sortBy: payload.sortBy,
            descending: payload.descending
          },

        }).then((data) => {
          data.data.total = ((payload.page + 1) * payload.size) + 1;
          commit("setEnvelopeList", data.data)
          resolve()
        }).catch((error) => reject(error))
      })
    },
    apiGetEnvelopeDetails({commit}, payload) {
      return new Promise((resolve, reject) => {
        api.get(`/envelopes/${payload}`).then((data) => {
          commit("setEnvelopeDetails", data.data)
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })
    },
    apiGetEnvelope({commit, dispatch, getters}, payload) {
      return new Promise((resolve, reject) => {
        api.get(`/envelopes/${payload}/documents`, {
          params: {
            page: 0,
            size: 10,
            start_date: '',
            end_date: ''
          }
        }).then((data) => {
          commit("setEnvelope", data.data)
          dispatch('apiGetURLFiles', getters.envelopeMapUrlFiles).then(
            (data) => {
              commit("setEnvelopeFiles", data)
              resolve()
            })
        }).catch((error) => {
          reject(error)
        })
      })
    },
    async apiGetURLFiles(context, urls) {
      let files = []
      for (const url of urls) {
        let response = await api.get(url.replace("/v1", ""),
          {responseType: 'blob'});
        let document = new Blob([response.data], {type: 'application/pdf'});
        files.push(URL.createObjectURL(document))
      }
      return files;
    },
    apiGetDownloadFiles(context, payload) {
      return new Promise((resolve, reject) => {
        api.get(
          `/envelopes/${payload.envelopeId}/document/${payload.documentId}/manifest`,
          {responseType: 'blob'}).then(data => {
          utilsCreateTemporaryLinkToDownload(payload.nameEnvelope, data.data, data.data.type, data.data.type.split('/')[1])
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    apiDownloadDocument(context, payload) {
      return new Promise((resolve, reject) => {
        api.get(
          `/documents/${payload.organizationId}/${payload.id}`,
          {responseType: 'blob'}).then(data => {
          utilsCreateTemporaryLinkToDownload(payload.name, data.data, data.data.type, data.data.type.split('/')[1])
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async apiGetTokenCancelEnvelope({commit}, payload) {
      await api.get(`/envelopes/${payload}/cancel`).then(data => {
        commit("setEnvelopeHasSuccessTokenCancel", true)
        commit("setEnvelopeTokenCancel", data.data)

      }).catch(error => {
        const {message} = error.response.data
        commit("setEnvelopeHasSuccessTokenCancel", false)
        commit("setEnvelopeTokenCancel", {message})

      })
    },
    async apiDeleteTokenCancelEnvelope(context, payload) {
      await api.delete(`/envelopes/${payload}/cancel`)
    },
    async apiConfirmTokenCancelEnvelope({state}, payload) {
      const {reason, uuid} = payload

      await api.put(`/envelopes/${uuid}/cancel`, {
        ...state.envelopeTokenCancel,
        reason
      })
    },
    async getAddress(context, payload) {
      let data = null

      return await fetch(`https://viacep.com.br/ws/${payload}/json/`)
      .then(response => response.json())
      .then(json => {
        if (!json.erro) {
          data = json
        }

        return data
      })
    },
    async createEnvelopeBatchSign({commit}, payload){
      const data = await api.post(`/envelopes/action/batchSign`, payload)
      return data.data.envelopeId
    },
    async apiGetTicket({commit}, payload) {
      const result = await api.post('/reports/getTicket', payload)
      commit("setTicket", result.data.ticket)
    }
  }
})
