<template>
  <div class="uploaded-files-list">
    <BaseContainer>
      <div class="app-align-items-center">
        <v-select
          v-if="userIsAdmin"
          v-model="filter.initiator"
          :items="getInitiators"
          :clearable="true"
          label="Originador"
          item-value="value"
          item-text="text"
          class="mr-3"
          style="max-width: 180px;"
        />

        <div class="mr-3">
          <BaseDatePicker
            name-placeholder="Data Inicial"
            :date-value="filter.startDate"
            :max="filter.endDate"
            :max-width="118"
            locale="pt-br"
            class="mr-3"
            time-zone="UTC"
            @on-input-date-picker="(value) => { updateFilter(value, 'startDate') }"
          />
        </div>
        <div class="mr-3">
          <BaseDatePicker
            name-placeholder="Data Final"
            :date-value="filter.endDate"
            :max-width="118"
            locale="pt-br"
            time-zone="UTC"
            @on-input-date-picker="(value) => { updateFilter(value, 'endDate') }"
          />
        </div>
        <BaseButton
          context-button="program"
          type="text"
          color="primary"
          @click="filterUploadedFiles"
        >
          FILTRAR
        </BaseButton>
        <v-spacer />

        <v-dialog
          v-model="isModalActive"
          persistent
          transition="slide-y-reverse-transition"
          content-class="v-dialog--fullscreen"
        >
          <template v-slot:activator="{ on }">
            <div class="d-flex">
              <BaseButton
                color="primary"
                v-on="on"
              >
                Importar
              </BaseButton>
            </div>
          </template>
          <BaseModal
            style="height: 100vh; flex-wrap: nowrap;"
            :middle-image="modalInfo.middleImage"
            :show-indeterminate-progress="modalInfo.showIndeterminateProgress"
            :top-slot-content-title="modalInfo.topSlotContentTitle"
            :remove-button-close="modalInfo.removeButtonClose"
            :middle-slot-content-text-highlight="modalInfo.middleSlotContentTextHighlight"
            :middle-slot-content-text="modalInfo.middleSlotContentText"
            :middle-check-agree="modalInfo.middleCheckAgree"
            :bottom-buttons-align="modalInfo.bottomButtonsAlign"
            :bottom-slot-content-button-cancel-label="modalInfo.bottomSlotContentButtonCancelLabel"
            :bottom-slot-content-button-confirm-label="modalInfo.bottomSlotContentButtonConfirmLabel"
            :remove-button-cancel="modalInfo.removeButtonCancel"
            :remove-button-confirm="modalInfo.removeButtonConfirm"
            @on-cancel="() => isModalActive = false"
            @on-confirm="() => null"
          >
            <div class="my-4">
              <v-tabs
                v-if="programIntegrations.length > 0 || isUserCreateAsset"
                vertical
                fixed-tabs
                class="uploaded-files-list--tabs"
              >
                <template v-if="isUserCreateAsset">
                  <v-tab>
                    Cadastrar Ativo
                  </v-tab>
                  <v-tab-item :transition="false">
                    <v-card>
                      <v-card-text style="max-width: 440px; margin: 0 auto;">
                        <div>
                          <div class="color--text-black-054 body-1 text-center">
                            <v-img
                              :src="require('@/assets/upload-file.svg')"
                              height="89"
                              width="132"
                              class="mb-2 mt-2"
                              style="margin: 0 auto;"
                            />
                            <h3 class="headline mb-4 text-center font-weight-bold">
                              Cadastro de Ativos
                            </h3>
                            <p class="mb-6">
                              Cadastre seus ativos digitando os dados no formulário abaixo.
                            </p>
                          </div>
                          <div
                            class="mt-6"
                            style="max-width: 645px; margin: 0 auto;"
                          >
                            <CreateOrEditAsset
                              context-actions="create"
                              :clear-form="!isModalActive"
                            />
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </template>
                <template v-if="programIntegrations.length > 0">
                  <v-tab
                    v-for="tab in programIntegrations"
                    :key="tab.id"
                  >
                    {{ tab.name }}
                  </v-tab>
                  <v-tab-item
                    v-for="tabItem in programIntegrations"
                    :key="tabItem.id"
                    :transition="false"
                  >
                    <v-card>
                      <v-card-text style="max-width: 440px; margin: 0 auto;">
                        <div>
                          <div class="color--text-black-054 body-1 text-center">
                            <v-img
                              :src="require('@/assets/upload-file.svg')"
                              height="89"
                              width="132"
                              class="mb-2 mt-2"
                              style="margin: 0 auto;"
                            />
                            <h3 class="headline mb-4 text-center font-weight-bold">
                              Upload de {{ tabItem.name }}
                            </h3>
                            <p class="mb-6">
                              {{ tabItem.detail.params.description }}
                            </p>
                            <BaseDragAndDrop
                              :upload-configuration="formatUploadConfiguration(tabItem.detail.params)"
                              :integration-id="tabItem.id"
                            />
                            <p
                              v-if="tabItem.detail.params.warn_message"
                              class="mt-4"
                            >
                              <b>Atenção: {{ tabItem.detail.params.warn_message }}</b>
                            </p>
                            <p class="mt-4">
                              Extensões permitidas: <b>{{ tabItem.detail.params.file_types }}</b>.
                              <br>
                              Tamanho máximo para upload do arquivo: <b>{{ tabItem.detail.params.max_size }}</b>.
                              <br>
                              <span v-if="tabItem.detail.params.example_file_b64">
                                <a
                                  class="mr-1 blueff--text font-weight-bold text-decoration-underline"
                                  @click="downloadExampleFile(tabItem.name, tabItem.detail.params.example_file_b64, tabItem.detail.params.file_types)"
                                >Clique aqui</a>para baixar um arquivo de exemplo.
                              </span>
                            </p>
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </template>
              </v-tabs>
              <div
                v-else
                class="text-center my-12"
              >
                <v-img
                  :src="require('@/assets/integration-not-found.svg')"
                  height="58"
                  width="58"
                  class="my-2"
                  style="margin: 0 auto;"
                />
                <h3 class="headline mb-2 text-center font-weight-bold">
                  Integração não encontrada
                </h3>
                <p>
                  Tente novamente mais tarde ou fale com o administrador do programa.
                </p>
              </div>
            </div>
          </BaseModal>
        </v-dialog>
      </div>

      <BaseDataTable
        :headers="filterHeaders"
        background-color="transparent"
        :items="getUploadedFiles"
        item-key="id"
        classes="mt-6"
        @update-pagination="updatePagination"
      >
        <template v-slot:inject-items="scopedItems">
          <tr
            v-for="props in scopedItems"
            :key="props.index"
          >
            <td class="font-weight-bold">
              {{ props.item.createdAt | getDate }} - {{ props.item.createdAt | getTime }}
            </td>
            <td v-if="userIsAdmin">
              {{ props.item.initiator }}
            </td>
            <td>{{ props.item.context }}</td>
            <td class="text-right">
              {{ props.item.fileName }}
            </td>
            <td class="text-right">
              <BaseButton
                context-button="program"
                color="primary"
                :has-icon="true"
                @click="downloadFile({uuid: props.item.uuid, fileName: props.item.fileName})"
              >
                <v-icon>cloud_download</v-icon>
              </BaseButton>
            </td>
          </tr>
        </template>
      </BaseDataTable>
    </BaseContainer>
  </div>
</template>

<script>

import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import BaseContainer from '../../BaseContainer'
import BaseDatePicker from '../../BaseDatePicker'
import BaseDataTable from '../../BaseDataTable'
import BaseModal from '../../BaseModal.vue'
import BaseDragAndDrop from '../../BaseDragAndDrop.vue'
import CreateOrEditAsset from './CreateOrEditAsset'
import loader from '../../../global/loader'
import permissions from "@/mixins/permissions";
import {utilsCreateTemporaryLinkToDownload} from "@/utils";

export default {
  components: {BaseContainer, BaseDatePicker, BaseDataTable, BaseModal, BaseDragAndDrop, CreateOrEditAsset},
  mixins: [permissions],
  data: () => ({
    selected: [],
    filter: {
      startDate: new Date(),
      endDate: new Date(),
      initiator: '',
      prefix: 'uploaded-files'
    },
    headers: [
      {text: 'Data e Hora', value: 'createdAt', width: 160},
      {text: 'Originador', value: 'initiator', width: 150},
      {text: 'Contexto', value: 'context', width: 180},
      {text: 'Nome', value: 'fileName', width: '100%', align: 'right'},
      {text: '', value: 'action', width: 30, align: 'center', sortable: false}
    ],
    isModalActive: false,
    modalInfo: {
      showIndeterminateProgress: false,
      topSlotContentTitle: "Importar",
      removeButtonClose: false,
      middleSlotContentTextHighlight: "",
      middleSlotContentText: "",
      bottomButtonsAlign: "right",
      bottomSlotContentButtonCancelLabel: "Cancelar",
      bottomSlotContentButtonConfirmLabel: "",
      removeButtonCancel: true,
      removeButtonConfirm: true,
      onCancel: null,
      onConfirm: null
    },
    isUserCreateAsset: false
  }),
  computed: {
    ...mapGetters(['getUploadedFiles', 'getInitiators']),
    ...mapState(['uploadedFiles', 'initiators']),
    ...mapState({
      programIntegrations: (state) => {
        return state.platformProgram.programIntegrations
      }
    }),
    filterHeaders() {
      return this.userIsAdmin ? this.headers : this.headers.filter(e => e.value !== 'initiator')
    },
  },
  methods: {
    ...mapActions(['apiGetProgramFiles', 'apiGetInitiators', 'apiGetDownloadFile', 'apiGetProgramIntegrations']),
    ...mapActions('programMovements',['apiGetSchemas']),
    updatePagination: function (pagination) {
      this.filter.page = pagination.page,
      this.filter.size = pagination.itemsPerPage
      this.filterUploadedFiles()
    },
    updateFilter(value, attribute) {
      this.filter[attribute] = value
    },
    async filterUploadedFiles() {
      loader.startProgressLoadPage()
      if (this.userIsAdmin) {
        await this.apiGetInitiators();
      }
      await this.apiGetProgramFiles(this.filter)
      loader.stopProgressLoadPage()
    },
    toLocalDate(date) {
      return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    },
    minusDays(date, days) {
      return new Date(date.setDate(date.getDate() - days))
    },
    downloadFile(payload) {
      this.apiGetDownloadFile(payload)
    },
    downloadExampleFile(name, data, type) {
      const extension = type.split(',', 1)[0].replace('.', '')
      utilsCreateTemporaryLinkToDownload(name, data, type, extension)
    },
    formatUploadConfiguration(params) {
      const {file_types, multiple, max_size, max_files} = params
      return {
        acceptFiles: file_types,
        deletable: true,
        multiple: multiple,
        maxSizeFile: max_size,
        maxFiles: max_files
      }
    },
  },
  watch: {
    filter: {
      handler() {
        if (new Date(this.filter.startDate).getTime() > new Date(this.filter.endDate).getTime()) {
          this.filter.endDate = ''
        }
      },
      deep: true
    },
    isModalActive() {
      if(this.isModalActive === true) {
        this.apiGetProgramIntegrations({type: "upload"})
      }
    }
  },
  created() {
    this.apiGetProgramIntegrations({type: "upload"})
    this.filter['startDate'] = this.toLocalDate(
      this.minusDays(new Date(), 7)).toISOString().substr(0, 10);
    this.filter['endDate'] = this.toLocalDate(new Date()).toISOString().substr(0, 10);
    this.apiGetSchemas({ scope: 'create-asset' })
      .then((response) => {
        this.isUserCreateAsset = response.data.fields.length > 0 ? true : false
      })
      .catch(err => this.isUserCreateAsset = false)
  },
  mounted() {
    this.filterUploadedFiles()
  }
}
</script>
<style lang="scss">
.uploaded-files-list--tabs {
  .v-tab {
    min-width: 229px;
    height: 40px!important;
    max-height: 40px!important;
    display: block;
    text-align: left;
    padding: 12px 16px;
    text-transform: none;
    letter-spacing: unset;
  }
  .v-tab--active {
    color: var(--custom-theme-black-054)!important;
    background: rgba(0, 0, 0, 0.06);
    font-weight: 600;
  }
  .v-tabs-slider-wrapper {
    display: none;
  }
}
</style>
