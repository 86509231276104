import api from '../../services/api';

export default {
  state: {
    currentOperationInfoDiagram: null,
    currentOperationInfoStepNode: null,
    currentOperationInfoStepNodeElement: null
  },
  getters: {
    getCurrentOperationInfoDiagramParseArray: (state) => {
      const list = [];
      if(state.currentOperationInfoDiagram){
        (function iteratorCurrentOperationInfoDiagramParseArray(obj) {
          const { status, name, updatedAt, message } = obj;

          list.push({ status, name, updatedAt, message });

          if(obj.next){
            return iteratorCurrentOperationInfoDiagramParseArray(obj.next)
          }
          else {
            return
          }
        })(state.currentOperationInfoDiagram);
      }
      return list
    }
  },
  mutations: {
    setCurrentOperationInfoDiagram(state, payload){
      state.currentOperationInfoDiagram = payload
    },
    setCurrentOperationInfoStepNode(state, payload){
      state.currentOperationInfoStepNode = payload
    },
    setCurrentOperationInfoStepNodeElement(state, payload){
      state.currentOperationInfoStepNodeElement = payload
    },
  },
  actions: {
    apiGetOperationInfoDiagram({ commit }, payload){
      return new Promise((resolve, reject) => {
        api.get(`/executions/${ payload }/plan`)
          .then((data) => {
            commit('setCurrentOperationInfoDiagram', data.data[0])
            resolve()
          }).catch(error => { reject(error) })
      })
    },
  }
}
