<template>
  <div class="base-action-footer">
    <v-footer
      padless
      fixed
      color="white base-action-footer--footer"
    >
      <v-row
        justify="center"
        class="base-action-footer--content"
      >
        <BaseButton
          v-if="buttonBack"
          type="text"
          color="primary"
          :is-disabled="buttonBack.disabled"
          @click="buttonBack.action"
        >
          {{ buttonBack.text }}
        </BaseButton>

        <v-spacer />

        <BaseButton
          v-if="buttonCancel"
          type="text"
          color="primary"
          :is-disabled="buttonCancel.disabled"
          @click="buttonCancel.action"
        >
          {{ buttonCancel.text }}
        </BaseButton>

        <BaseButton
          v-if="buttonConfirm"
          color="primary"
          :is-disabled="buttonConfirm.disabled"
          @click="buttonConfirm.action"
        >
          {{ buttonConfirm.text }}
        </BaseButton>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
export default {
  props:{
    buttonBack: {
      type: Object,
      required: false,
      default: () => {}
    },
    buttonCancel: {
      type: Object,
      required: false,
      default: () => {}
    },
    buttonConfirm: {
      type: Object,
      required: false,
      default: () => {}
    }
  }
}
</script>

<style>
.base-action-footer {
  height: 90px;
}
.base-action-footer .base-action-footer--footer {
  box-shadow: rgb(0 0 0 / 27%) 0px -2px 7px 1px!important;
}
.base-action-footer--content {
  max-width:650px; margin: 12px auto!important;
}
</style>
