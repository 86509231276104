<template>
  <section
    class="base-card app-card-bordered"
    :style="{ backgroundColor }"
    :class="baseCardContainerClasses"
  >
    <div
      class="app-align-items-center px-6"
      :class="containerTitleClasses"
    >
      <div>
        <h2
          class="app-align-items-center app-headline"
          :class="baseCardTitleClass"
        >
          <slot name="header-title" />
        </h2>
        <p
          v-if="isHeaderTitleDescription"
          class="body-1 mb-0"
        >
          <slot name="header-title-description" />
        </p>
      </div>
      <v-spacer />
      <div
        v-if="isHeaderActions"
        :class="baseCardHeaderActionsClass"
      >
        <slot name="header-actions" />
      </div>
    </div>
    <v-divider
      v-if="hasDivider"
      :class="dividerClass"
    />
    <div class="app-headline">
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  name: "BaseCard",
  props: {
    backgroundColor: {
      required: false,
      type: String,
      default: "transparent"
    },
    baseCardContainerTitle: {
      required: false,
      type: String,
      default: ''
    },
    baseCardContainerClasses: {
      required: false,
      type: String,
    },
    baseCardTitleClass: {
      required: false,
      type: String,
      default: "title"
    },
    baseCardHeaderActionsClass: {
      required: false,
      type: String,
      default: 'app-align-items-center text-right'
    },
    hasDivider: {
      required: false,
      type: Boolean
    },
    hasMainContainer: {
      required: false,
      type: Boolean
    }
  },
  computed: {
    isHeaderTitleDescription(){
      return !!this.$slots['header-title-description']
    },
    isHeaderActions(){
      return !!this.$slots['header-actions']
    },
    hasTitleOrDescription(){
      return !!this.$slots['header-title-description'] || !!this.$slots['header-title']
    },
    containerTitleClasses() {
      if(this.baseCardContainerTitle) {
        return this.baseCardContainerTitle
      } else {
        return { 'pb-6' : !this.hasMainContainer, 'pt-6' : this.hasTitleOrDescription }
      }

    },
    dividerClass() {
      if(this.baseCardContainerTitle) {
        return 'mt-3'
      } else {
        return 'mt-4'
      }
    }
  }
}
</script>

<style scoped>
  .title {
    font-size: 18px!important;
  }
</style>
