<template>
  <div class="platform-organization-profile-partners">
    <BaseCard
      :has-main-container="true"
      base-card-title-class="ml-4 pb-4"
      :is-header-title-description="true"
    >
      <template
        v-if="partner.partnerNameWhenEditing"
        v-slot:header-title
      >
        <v-icon class="platform-organization-profile-partner-detail__icon pr-2">
          {{ partner.party_type === 'person' ? 'account_circle' : 'domain' }}
        </v-icon>
        {{ partner.partnerNameWhenEditing }}
      </template>
      <template v-slot:header-title-description>
        <div class="pl-4">
          Os dados marcados com * são obrigatórios
        </div>
      </template>
      <v-form
        :ref="formName"
        v-model="partner.valid"
      >
        <v-container>
          <v-radio-group
            v-model="partner.party_type"
            row
            class="ml-3"
            mandatory
            :disabled="disableRadioTypePerson"
            @change="resetNameAndTaxNumberFields"
          >
            <v-radio
              label="Pessoa Física"
              value="person"
              name="qa-id-name-partner-person"
            />
            <v-radio
              label="Pessoa Jurídica"
              value="organization"
              name="qa-id-name-partner-organization"
            />
          </v-radio-group>
          <v-row>
            <v-col
              class="pt-0"
              cols="auto"
              sm="9"
            >
              <BaseInput
                ref="partnerName"
                v-model="partner.name"
                :label="partnerName"
                :rules="[isValid]"
                qa-identification-name="qa-id-name-partner-name"
                ref-name="partnerName"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="auto"
              sm="9"
            >
              <BaseInput
                v-if="isPerson"
                ref="partnerTaxNumberPerson"
                v-model="partner.tax_number"
                :label="partnerTaxNumber"
                :rules="privateRules"
                qa-identification-name="qa-id-name-partner-tax-number"
                mask="###.###.###-##"
                ref-name="partnerTaxNumberPerson"
              />
              <CnpjInput
                v-else
                ref="partnerTaxNumber"
                v-model="partner.tax_number"
                :label="partnerTaxNumber"
                qa-identification-name="qa-id-name-partner-tax-number"
                ref-name="partnerTaxNumber"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="auto"
              sm="9"
            >
              <EmailInput
                v-model="partner.email"
                label="E-mail*"
                :rules="[isValid]"
                qa-identification-name="qa-id-name-partner-email"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="auto"
              sm="4"
            >
              <BaseInput
                ref="share"
                v-model.lazy="partner.share"
                v-money="rateMask"
                label="Participação (%)*"
                :rules="[isValid, isValidLengthForShare]"
                qa-identification-name="qa-id-name-partner-share"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="auto"
              sm="4"
            >
              <v-select
                v-if="isPerson"
                v-model="partner.is_signer"
                label="Assina pela empresa ?*"
                :items="signOptions"
                item-text="text"
                item-value="value"
                :rules="[isValid]"
                name="qa-id-name-partner-signer"
              />
            </v-col>
            <v-col
              cols="auto"
              sm="4"
            >
              <v-select
                v-if="isCosigner"
                v-model="partner.cosigner"
                label="Assina sozinho ?*"
                :items="signOptions"
                item-text="text"
                item-value="value"
                :rules="[isValid]"
                name="qa-id-name-partner-cosigner"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="auto"
              sm="4"
            >
              <v-select
                v-if="isPerson"
                v-model="partner.role"
                label="Papel do Sócio ?*"
                :items="roleOptions"
                item-text="text"
                item-value="value"
                :rules="[isValid]"
                name="qa-id-name-partner-role"
              />
            </v-col>
          </v-row>
          <v-row
            dense
            justify="end"
          >
            <v-col cols="auto">
              <BaseButton
                color="primary"
                qa-identification-name="qa-id-name-partner-btn-cancel"
                type="text"
                @click="onCancelForm"
              >
                Cancelar
              </BaseButton>
              <BaseButton
                data-submit
                qa-identification-name="qa-id-name-partner-btn-confirm"
                type="depressed"
                color="primary"
                :is-disabled="!partner.valid || partner.noFocus"
                @click="submitPartnerForm"
              >
                Salvar
              </BaseButton>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </BaseCard>

    <template v-if="partner.id">
      <h1 class="app-font-semibold title mb-1 mt-6">
        Documentos do sócio
      </h1>
      <p class="mb-6">
        Mantenha seus documentos atualizados para garantir a sua participação nos programas da
        plataforma.
      </p>

      <BaseCard
        :has-main-container="true"
      >
        <DocumentList
          context-actions="manage"
          :document-list="platformOrganizationPartnerDocuments"
          :is-uploading-document="false"
          @onUploadDocument="uploadDocument"
          @onDeleteDocument="deleteDocument"
        />
      </BaseCard>
      <div
        class="mt-6"
        style="max-width: 645px; margin: 0 auto; text-align: center;"
      >
        <p>Tamanho máximo para upload do arquivo: <b>4 MB.</b></p>
        <p>Extensões permitidas: <b>PDF, PNG e JPG</b>.</p>
      </div>
    </template>
  </div>
</template>

<script>
import BaseCard from '../../BaseCard'
import CnpjInput from '../../Input/CnpjInput.vue'
import EmailInput from '../../Input/EmailInput'
import {mapActions, mapGetters, mapState, mapMutations} from 'vuex'
import {
  utilsShowErrorInDevelopmentEnvironment,
  resetFormFields,
  utilsIsValidLength,
  utilsTranformValueToOnlyNumber
} from '../../../utils'
import DocumentList from './DocumentList'

export default {
  name: 'PartnersTemplate',
  components: {BaseCard, DocumentList, CnpjInput, EmailInput},
  props: {
    active: {
      default: false,
      type: Boolean
    },
    partnerToEdit: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    isCreatingPartnerChild: {
      type: Object,
      required: false,
      default: () => {
      }
    },
  },
  data() {
    return {
      disableRadioTypePerson: false,
      formName: 'formToAddOrUpdatePartner',
      rateMask: {
        decimal: ',',
        suffix: ' %',
        precision: 2
      },
      organizationId: '',
      privateRules: [],
      signOptions: [
        {text: 'Sim', value: 'true'},
        {text: 'Não', value: 'false'}
      ],
      roleOptions: [
        {text: 'Procurador', value: 'Procurador'},
        {text: 'Representante', value: 'Representante'}
      ],
      partner: {
        valid: false,
        noFocus: true,
        name: '',
        tax_number: '',
        email: '',
        share: '',
        is_signer: '',
        cosigner: '',
        party_type: '',
        role: '',
        organizationId: '',
        id: ''
      }
    }
  },
  computed: {
    ...mapState({
      platformOrganizationPartnerDocuments: state => state.platformOrganization.platformOrganizationPartnerDocuments,
    }),
    ...mapGetters('platformOrganization', ['isPartnerChild']),
    partnerName() {
      return this.partner.party_type === 'person' ? 'Nome Completo*' : 'Razão Social*'
    },
    partnerTaxNumber() {
      return this.partner.party_type === 'person' ? 'CPF*' : 'CNPJ*'
    },
    isPerson() {
      return this.partner.party_type === 'person';
    },
    isCosigner() {
      return this.partner.party_type === 'person' && this.partner.is_signer === 'true';
    }
  },
  watch: {
    active(currentValue) {
      if (currentValue) {
        this.updatePartnerFormFields()
      }
    }
  },
  created() {
    this.organizationId = this.$route.params.id
    this.partner.organizationId = this.$route.params.id
    this.apiGetPlatformOrganizationPartners(this.organizationId)
    this.updatePartnerFormFields()
    this.privateRules.push(this.isValidLength)
    this.privateRules.push(this.isValid)
  },
  methods: {
    ...mapActions('platformOrganization',
      ['apiGetPlatformOrganizationPartners', 'apiGetPlatformOrganizationPartnerDocuments',
        'apiUploadPlatformOrganizationPartnerPendingDocument',
        'apiDeletePlatformOrganizationPartnerPendingDocument', 'apiCreateOrganizationPartner',
        'apiUpdatePlatformOrganizationPartner']),
    ...mapMutations('platformOrganization', ['setPlatformOrganizationPartnerChildContext', 'setIdPartnerToUpdateDocumentList']),
    isValidLengthForShare(value) {
      const taxValue = this.removePercentageFromRate(value)
      return !!value && taxValue.length <= 6 && this.parseNumberDecimal(taxValue) <= 100 && this.parseNumberDecimal(taxValue) > 0 || "Valor inválido"
    },
    async submitPartnerForm() {
      //masked=false esta retirando a mascara do campo e não somente quando envia o dado para a API
      //por isso este workaround

      this.partner.share = this.parseNumberDecimal(this.removePercentageFromRate(this.partner.share)).toString()
      this.partner.tax_number = utilsTranformValueToOnlyNumber(this.partner.tax_number)

      const {valid, noFocus, partnerNameWhenEditing, ...data} = this.partner
      try {        
        if (this.isCreatingPartnerChild.isCreating) {
          const childPartnerBody = {
            ...data,
            parent_tax_number: this.isCreatingPartnerChild.parentTaxNumber
          }
          await this.apiCreateOrganizationPartner(childPartnerBody)
          this.isCreatingPartnerChild.isCreating = false

          this.$emit('onSubmitForm', {
            message: 'Sócio criado com sucesso',
            color: 'success',
          })

        } else if(this.partner.id) {

          await this.apiUpdatePlatformOrganizationPartner(data)

          this.$emit('onSubmitForm', {
            message: 'Sócio atualizado com sucesso',
            color: 'success',
          })
        } else {
          await this.apiCreateOrganizationPartner(data)

          this.$emit('onSubmitForm', {
            message: 'Sócio criado com sucesso',
            color: 'success',
          })
        }

        Object.assign(this.partner, {...this.partner, noFocus: true})

      } catch (err) {
        this.$emit('onSubmitForm', {
            message: err.response.data.message ? err.response.data.message : 'Erro ao cadastrar sócio.',
            color: 'error',
          })
        utilsShowErrorInDevelopmentEnvironment(err)
      }

      await this.apiGetPlatformOrganizationPartners(this.organizationId)

      if(this.partner.id){
        await this.apiGetPlatformOrganizationPartnerDocuments({
          organizationId: this.organizationId,
          partnerId: this.partner.id,
          scopeMember: false
        })
      }
    },
    async uploadDocument(item) {
      this.isUploadingDocument = true
      let formData = new FormData();
      if (item.file) {
        formData.append('file', item.file);
        formData.append('content-type', item.file.type);
        try {
          await this.apiUploadPlatformOrganizationPartnerPendingDocument({
            organizationId: this.organizationId,
            documentTypeId: item.document_type.id,
            partnerId: this.partnerToEdit.id,
            formData
          })
          this.$emit('onSubmitForm', {
            message: 'Documento enviado com sucesso',
            color: 'success',
          })
        } catch (e) {
          this.$emit('onSubmitForm', {
            message: 'Erro ao enviar documento',
            color: 'error',
          })
          utilsShowErrorInDevelopmentEnvironment(e)
        } finally {
          this.isUploadingDocument = false
          item.file = null
          await this.apiGetPlatformOrganizationPartnerDocuments({
            organizationId: this.organizationId,
            partnerId: this.partnerToEdit.id,
            scopeMember: false
          })
          this.setIdPartnerToUpdateDocumentList(this.partnerToEdit.id)
        }
      }
    },
    async deleteDocument(id) {

      try{
        await this.apiDeletePlatformOrganizationPartnerPendingDocument({
          organizationId: this.organizationId,
          documentId: id,
          partnerId: this.partnerToEdit.id
       })
        this.$emit('onSubmitForm', {
          message: 'Documento removido com sucesso',
          color: 'success',
        })
      }catch(err) {
        this.$emit('onSubmitForm', {
          message: 'Erro ao remover documento.',
          color: 'error',
        })
      }

      await this.apiGetPlatformOrganizationPartnerDocuments({
        organizationId: this.organizationId,
        partnerId: this.partnerToEdit.id,
        scopeMember: false
      })

    },
    updateRadioButton() {
      this.disableRadioTypePerson = this.isPartnerChild || this.partner.partnerNameWhenEditing ? true : false
    },
    updatePartnerFormFields() {
      this.partnerToEdit.share = this.partnerToEdit.share ? parseFloat(this.partnerToEdit.share).toFixed(2) : ''
      Object.assign(this.partner, {...this.partnerToEdit, noFocus: true})
      if(this.$refs.share){
        this.$refs.share.$el.getElementsByTagName('input')[0].value = this.partner.share
      }
      this.partner.partnerNameWhenEditing = this.partner.name
      this.updateRadioButton()
      this.addFocusEventOnInputs()
    },
     addFocusEventOnInputs(){
      this.$nextTick(() => {
        this.$refs.formToAddOrUpdatePartner.$el.querySelectorAll('input').forEach(elem => {
          elem.addEventListener('focus', (ev) => this.onFocusCallback())
        })
      })
    },
    onFocusCallback() {
      this.partner.noFocus = false
    },
    isValidLength() {
      const transformedValue = utilsTranformValueToOnlyNumber(this.partner.tax_number)

      return utilsIsValidLength(transformedValue, 11) || 'CPF inválido'
    },
    resetNameAndTaxNumberFields() {
      this.$set(this.partner, 'tax_number', '')
      resetFormFields(this, ['partnerName'])
    },
    clearFields() {
      this.$refs[this.formName].reset()
      this.forceResetInputValue(this, 'share')
    },
    onCancelForm() {
      this.setPlatformOrganizationPartnerChildContext(false)
      this.partner.id = ''
      this.cancelForm(this, this.formName)
    }

  }
}
</script>

<style></style>
