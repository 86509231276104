<template>
  <div class="platform-organization-connection-configuration">
    <BaseCard
      :is-header-actions="true"
      :has-main-container="true"
      :has-divider="true"
      base-card-title-class="app-font-semibold title mb-1"
      base-card-header-actions-class="d-flex align-self-start text-right"
    >
      <template v-slot:header-title>
        Configurações de relacionamento
      </template>
      <template v-slot:header-title-description>
        Gerencie as configurações de relacionamento das suas plataformas sacado
      </template>
      <template v-slot:header-actions>
        <v-dialog
          :value="isModalActive"
          persistent
          transition="app-transition-to-right"
          content-class="app-transition-to-right-additional-configs"
          @input="updateModalActive"
        >
          <template v-slot:activator="{ on }">
            <BaseButton
              type="text"
              color="blueff"
              class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
              v-on="on"
            >
              Nova configuração
            </BaseButton>
          </template>
          <BaseModal
            style="height: 100vh; flex-wrap: nowrap;"
            :show-indeterminate-progress="modalConnectionConfiguration.showIndeterminateProgress"
            :top-slot-content-title="modalConnectionConfiguration.topSlotContentTitle"
            :remove-button-close="modalConnectionConfiguration.removeButtonClose"
            :middle-slot-content-text-highlight="modalConnectionConfiguration.middleSlotContentTextHighlight"
            :middle-slot-content-text="modalConnectionConfiguration.middleSlotContentText"
            :middle-check-agree="modalConnectionConfiguration.middleCheckAgree"
            :bottom-buttons-align="modalConnectionConfiguration.bottomButtonsAlign"
            :bottom-slot-content-button-cancel-label="modalConnectionConfiguration.bottomSlotContentButtonCancelLabel"
            :bottom-slot-content-button-confirm-label="modalConnectionConfiguration.bottomSlotContentButtonConfirmLabel"
            :remove-button-cancel="modalConnectionConfiguration.removeButtonCancel"
            :remove-button-confirm="modalConnectionConfiguration.removeButtonConfirm"
            body-background-color="#F8F8F8"
            @on-cancel="modalConnectionConfiguration.onCancel"
            @on-confirm="() => null"
          >
            <div
              class="mt-6"
              style="max-width: 730px; margin: 0 auto;"
            >
              <ConnectionConfigurationForm
                :active="isModalActive"
                :is-editing="isEditingConnectionConfiguration"
                @onSubmitForm="showFeedback"
                @onCancel="onCancelForm"
              />
            </div>
          </BaseModal>
        </v-dialog>
      </template>
      <div>
        <BaseDataTable
          :items="connectionConfigurations"
          :hide-actions="true"
          :hide-headers="true"
          background-color="transparent"
        >
          <template v-slot:inject-items="scopedItems">
            <tr
              v-for="value in scopedItems"
              :key="value.index"
            >
              <td style="width: 100%">
                {{ value.item.name }}
              </td>
              <td class="text-right">
                <BaseButton
                  type="text"
                  color="blueff"
                  class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
                  @click="updateBankAccountFormFields(value.item)"
                >
                  Editar
                </BaseButton>
              </td>
            </tr>
          </template>
          <template v-slot:custom-text-no-data>
            <div class="text-center">
              Nenhuma configuração para exibir
            </div>
          </template>
        </BaseDataTable>
      </div>
    </BaseCard>
    <BaseSnackbar
      :active-snackbar="feedback"
      :text-snackbar="feedbackMessage"
      :type-snackbar="feedbackColor"
      @closeSnackbar="feedback = !feedback"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { utilsShowErrorInDevelopmentEnvironment } from '../../../utils'
import BaseCard from '../../BaseCard'
import BaseDataTable from '../../BaseDataTable'
import BaseModal from '../../BaseModal'
import BaseSnackbar from '../../BaseSnackbar'
import ConnectionConfigurationForm from './ConnectionConfigurationForm'
import BaseSnackbarConfiguration from '../../../mixins/BaseSnackbarConfiguration'

export default {
  components: { BaseCard, BaseDataTable, BaseModal, BaseSnackbar, ConnectionConfigurationForm },
  mixins:  [ BaseSnackbarConfiguration ],
  data() {
    return {
      isModalActive: false,
      isEditingConnectionConfiguration: false,
      formName: 'formToAddOrUpdateConnectionConfiguration',
      modalConnectionConfiguration: {
        showIndeterminateProgress: false,
        topSlotContentTitle: "Configurações de relacionamento",
        removeButtonClose: false,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: '',
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "Cancelar",
        bottomSlotContentButtonConfirmLabel: "Salvar",
        removeButtonCancel: true,
        removeButtonConfirm: true,
        onCancel: this.onCancelForm,
        onConfirm: null,
      }
    }
  },
  computed: {
    ...mapState({
      connectionConfigurations: (state) => {
        return state.platformAccount.connectionConfigurations
      },
      accountId: state => state.auth.keycloak.tokenParsed['account-id'],
    })
  },
  created() {
    this.getConnectionConfigurations()
    this.getApisForConnectionConfiguration()
  },
  methods: {
    ...mapActions(['apiGetConnectionConfigurations', 'apiGetConnectionStepsOptions', 'apiGetConnectionParticipantsTypes', 'apiGetAllPrograms', 'apiGetConnectionDocumentsTypes', 'apiGetConnectionTemplates', 'apiGetConnectionDetails']),
    updateModalActive(value) {
      this.isModalActive = value
    },
    onCancelForm() {
      this.getConnectionConfigurations()
      this.isEditingConnectionConfiguration = false
      this.isModalActive = false
    },
    async getConnectionConfigurations() {
      try {
        await this.apiGetConnectionConfigurations(this.accountId)
      } catch(err) {
        utilsShowErrorInDevelopmentEnvironment(err)
      }
    },
    async getApisForConnectionConfiguration(){
      try {
        await this.apiGetConnectionStepsOptions()
        await this.apiGetConnectionParticipantsTypes()
        await this.apiGetConnectionDocumentsTypes()
        await this.apiGetAllPrograms(this.accountId)
        await this.apiGetConnectionTemplates(this.accountId)
      } catch(err) {
        utilsShowErrorInDevelopmentEnvironment(err)
      }
    },
    async updateBankAccountFormFields(item) {
      await this.apiGetConnectionDetails({
        id_account: this.accountId,
        id_configuration: item.id
      })
      this.isEditingConnectionConfiguration = true
      this.isModalActive = true
    }
  }
}
</script>

<style>
</style>
