import Vue from 'vue'
import mask from '../directives/vue-the-mask/directive'
import {VMoney} from 'v-money'
import {Ripple} from 'vuetify/lib/directives'

export default function directive() {
  Vue.directive('mask', mask)
  Vue.directive('Ripple', Ripple)
  Vue.directive('money', VMoney)
}
