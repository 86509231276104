<template>
  <router-link
    :to="to"
    :class="concatClasses"
    :[typeLink]="true"
  >
    <slot />
  </router-link>
</template>

<script>
export default {
  props: {
    linkClasses: {
      type: String,
    },
    to: {
      type: String,
      required: true
    },
    typeLink: {
      type: String,
      default: 'platformLink',
      validator: function (value) {
        return ['programLink', 'platformLink'].includes(value)
      }
    }
  },
  computed: {
    concatClasses() {
      return this.typeLink === 'platformLink' ? 'body-2 blueff--text app-font-semibold '
        + this.linkClasses + ' ds-base-platform-link ' : this.linkClasses
    }
  }
}
</script>

<style lang="scss">
@import '../design-system/base-components.scss';

body .v-application .ds-base-platform-link {
  @extend .ds-base-platform-link;
}

</style>
