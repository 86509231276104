import { mapState, mapActions } from 'vuex'
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";

export default {
  data() {
    return {
      animateNotification: false
    };
  },
  watch:{
    navigationContext(value){
      if(value){
        this.getUnreadNotifications();
        this.getNotifications();
      }
    },
    idSubscribe(value){
      this.connect()
    }
  },
  computed: {
    ...mapState({
      activeOrganization: state => state.platformProgram.activeOrganization,
      programInitiator: state => state.platformProgram.programInitiator,
      navigationContext: state => state.theNavigation.navigationContext,
      accountId: state => state.auth.keycloak.tokenParsed['account-id'],
    }),
    idSubscribe(){
      if(this.navigationContext === 'platform'){
        return this.accountId
      } else if(this.navigationContext === 'program' && this.activeOrganization && this.activeOrganization.active_role) {
        if(this.activeOrganization.active_role === 'owner'){
          return this.programInitiator
        } else {
          return `${this.programInitiator}:${this.activeOrganization.active_organization_id}`
        }
      }
    }
  },
  methods: {
    ...mapActions('programNotifications',['apiGetUnreadNotifications', 'apiGetNotifications']),
    notificationGoTo(context){
      const { initiator, path, navigationContext } = context
      const hostname = window.location.hostname
      let url = null

      if(navigationContext === 'program'){
        url = hostname.replace(`${ hostname.split('.')[0] }.`, "")
      } else {
        url = hostname
      }

      if(hostname === 'localhost'){
        this.$router.push(path.replace('/#', '')).catch(()=>{})
      } else {
        const pathUrl = `https://${initiator}.${url}${path}`
        window.location.replace(pathUrl)
      }
    },
    connect() {
      this.socket = new SockJS(process.env.VUE_APP_NOTIFICATION_URL);
      this.stompClient = Stomp.over(this.socket, { debug: false });
      this.stompClient.connect(
        {
          'login': process.env.VUE_APP_NOTIFICATION_ID,
          'passcode': process.env.VUE_APP_NOTIFICATION_SECRET
        },
        frame => {
          this.stompClient.subscribe(`/notification/platform/${this.idSubscribe}`,
            async(message) => {
              await this.getUnreadNotifications();
              await this.getNotifications();
              this.animateNotification = true
              setTimeout(() => {
                this.animateNotification = false
              }, 800)
            }
          );
        },
        error => {
          this.stompClient.disconnect();
        }
      );
    },
    disconnect() {
      if (this.stompClient) {
        this.stompClient.disconnect();
        this.socket.close();
      }
    },
    send(message) {
      if (this.stompClient && this.stompClient.connected && !message.read) {
        this.stompClient.send("/read", message.id, {});
      }
    },
    async readNotification(message) {
      await this.send(message);
      this.getUnreadNotifications();
      this.getNotifications();
      this.notificationGoTo({ path: message.path, initiator: message.initiator, navigationContext: this.navigationContext })

      if(message.path.includes(this.$route.path)) {
        window.location.reload(true);
      }
    },
    readAllNotifications(messages) {
      messages.forEach((message) => {
        this.send(message)
      });
      this.getUnreadNotifications();
      this.getNotifications();
    },
    async getNotifications(page=1, pageSize=100, q='ALL', context=this.navigationContext){
      const payload = {
        page,
        pageSize,
        q,
        context
      }
      if(this.navigationContext)
      await this.apiGetNotifications(payload)
    },
    async getUnreadNotifications(page=0, pageSize=20, q="UNREAD", context = this.navigationContext) {
      const payload =  {
        page,
        pageSize,
        q,
        context
      }
      if(this.navigationContext)
      await this.apiGetUnreadNotifications(payload)
    }
  }
};
