import api from '../../services/api';

export default {
  state: {
    filter: {
      initiator: '',
      chart_id: ''
    },
    isFilterOpened: false,
    chartOfAccounts: {},
    wallets: [],
    currentWallet: {}
  },
  getters: {
    getWallets(state) {
      return [...state.wallets.map(
        elem => elem.name
      )]
    },
    getChartOfAccounts(state) {
      return state.chartOfAccounts
    },
  },
  mutations: {
    setCharOfAccounts(state, payload) {
      state.chartOfAccounts = payload
    },
    setWallets(state, payload) {
      state.wallets = payload
    },
    setFilterOpened(state, payload) {
      state.isFilterOpened = payload
    },
    setFilter(state, payload) {
      state.filter = payload
    }
  },
  actions: {
    apiGetWallets({state, commit}) {
      return new Promise((resolve, reject) => {
        api.get('accounting/wallets', {
          params: {...state.filter}
        }).then((data) => {
          commit('setWallets', data.data)
          resolve(data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    apiGetChartOfAccounts({state, commit}) {
      return new Promise((resolve, reject) => {
        api.get('accounting/chart_of_accounts', {
          params: {...state.filter}
        }).then((data) => {
          const response = data.data.length > 0 ? [...data.data] : null
          commit('setCharOfAccounts', data.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}
