import api from '../../services/api';

export default {
  state: {
    connectionConfigurations: [],
    connectionStepsOptions: [],
    connectionParticipantsTypes: [],
    connectionDocumentsTypes: [],
    connectionTemplates: [],
    connectionConfigurationDetails: []
  },
  getters: {

  },
  mutations: {
    setConnectionConfigurations(state, payload) {
      state.connectionConfigurations = payload
    },
    setConnectionStepsOptions(state, payload) {
      state.connectionStepsOptions = payload
    },
    setConnectionParticipantsTypes(state, payload) {
      state.connectionParticipantsTypes = payload
    },
    setConnectionDocumentsTypes(state, payload) {
      state.connectionDocumentsTypes = payload
    },
    setConnectionTemplates(state, payload) {
      state.connectionTemplates = payload
    },
    setConnectionConfigurationDetails(state, payload) {
      state.connectionConfigurationDetails = payload
    }
  },
  actions: {
    async apiGetConnectionConfigurations({commit}, payload) {
      const data = await api.get(`/platforms/accounts/${payload}/configurations`)
      commit("setConnectionConfigurations", data.data)
    },
    async apiGetConnectionStepsOptions({commit}) {
      const data = await api.get(`/platforms/steps`)
      commit("setConnectionStepsOptions", data.data)
    },
    async apiGetConnectionParticipantsTypes({commit}) {
      const data = await api.get(`/platforms/participants/types`)
      commit("setConnectionParticipantsTypes", data.data)
    },
    async apiGetConnectionDocumentsTypes({commit}) {
      const data = await api.get(`/platforms/documents/types`)
      commit("setConnectionDocumentsTypes", data.data)
    },
    async apiGetConnectionTemplates({commit}, payload) {
      const data = await api.get(`/platforms/accounts/${payload}/templates`)
      commit("setConnectionTemplates", data.data)
    },
    async apiSaveConnectionConfigurations({commit},payload) {
      const {id, ...data} = payload
      await api.post(`/platforms/accounts/${id}/configurations`, data)
    },
    async apiUpdateConnectionConfigurations({commit},payload) {
      const {id, id_configuration, ...data} = payload
      await api.put(`/platforms/accounts/${id}/configurations/${id_configuration}`, data)
    },
    async apiGetConnectionDetails({commit}, payload) {
      const {id_account, id_configuration} = payload
      const data = await api.get(`/platforms/accounts/${id_account}/configurations/${id_configuration}`)
      commit("setConnectionConfigurationDetails", data.data)
    },
  }
}
