<template>
  <div
    style="font-size: 12px"
    class="d-flex assets-collection__accounts-menu color--text-black-054 pa-2"
  >
    <div class="d-flex align-start">
      <v-icon v-if="hasIcon">
        account_balance_wallet
      </v-icon>
      <span class="font-weight-bold">{{ description }}</span>
    </div>
    <v-spacer />
    <div class="d-flex flex-column align-end pr-6">
      <p>{{ bankName }}</p>
      <p class="body-2 font-weight-bold">
        {{ bankValue }}
      </p>
    </div>
    <div class="d-flex flex-column align-end pr-6">
      <p>{{ agencyName }}</p>
      <p class="body-2 font-weight-bold">
        {{ agencyValue }}
      </p>
    </div>
    <div class="d-flex flex-column align-end">
      <p>{{ accountName }}</p>
      <p class="body-2 font-weight-bold">
        {{ accountValue }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseDataBank",
  props: {
    description: {
      type: String,
      required: false,
      default: ''
    },
    bankName: {
      type: String,
      required: false,
      default: 'Código Banco'
    },
    bankValue: {
      type: [String, Number],
      required: true
    },
    agencyName: {
      type: String,
      required: false,
      default: 'Agência'
    },
    agencyValue: {
      type: String,
      required: true
    },
    accountName: {
      type: String,
      required: false,
      default: 'Conta'
    },
    accountValue: {
      type: String,
      required: true
    },
    hasIcon: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style>

</style>
