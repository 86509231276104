<template>
  <div class="platform-organization-profile">
    <BaseCard
      :has-divider="true"
      :has-main-container="true"
      base-card-title-class="app-font-semibold title mb-1"
      data-v-step="0"
    >
      <template v-slot:header-title>
        Templates de formalização
      </template>
      <template v-slot:header-actions>
        <EnvelopeTemplateModal
          :open-modal-envelope="openModalEnvelope"
          :template-object="templateObject"
          @onCloseModalEdit="onCloseModalEdit()"
        />
      </template>
      <v-col sm="3">
        <BaseInput
          ref="filter"
          v-model="filter"
          label="Buscar"
          clearable
          append-outer-icon="check_circle"
          @keyup.enter="filterEnvelopeList()"
          @click:append-outer="filterEnvelopeList()"
          @click:clear="clearFilter()"
        />
      </v-col>
      <BaseDataTable
        :headers="['', '', '']"
        :items="envelopeTemplateList.content"
        :hide-actions="false"
        :hide-headers="true"
        background-color="transparent"
        :total-items="envelopeTemplateList.page.total_elements"
        :options="pagination"
        @update-pagination="updatePagination"
      >
        <template v-slot:inject-items="scopedItems">
          <tr
            v-for="value in scopedItems"
            :key="value.index"
          >
            <td>
              {{ value.item.name }}
            </td>
            <td>
              {{ value.item.id }}
            </td>
            <td
              class="text-right app-align-items-center"
              style="justify-content: flex-end; min-width: 130px;"
            >
              <BaseButton
                type="text"
                color="blueff"
                class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
                @click="editTemplate(value.item)"
              >
                Editar
              </BaseButton>
              <BaseButton
                type="text"
                color="blueff"
                class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
                @click="openDeleteModal(value.item.name, value.item.id)"
              >
                Excluir
              </BaseButton>
            </td>
          </tr>
        </template>
        <template v-slot:custom-text-no-data>
          <div class="text-center">
            Nenhum template para exibir
          </div>
        </template>
      </BaseDataTable>
    </BaseCard>
    <BaseSnackbar
      :active-snackbar="feedback"
      :text-snackbar="feedbackMessage"
      :type-snackbar="feedbackColor"
      @closeSnackbar="feedback = !feedback"
    />
    <v-dialog
      v-model="deleteTemplateModalActive"
      max-width="560"
      persistent
    >
      <BaseModal
        :show-indeterminate-progress="deleteTemplateModal.showIndeterminateProgress"
        :top-slot-content-title="deleteTemplateModal.topSlotContentTitle"
        :remove-button-close="deleteTemplateModal.removeButtonClose"
        :middle-slot-content-text-highlight="deleteTemplateModal.middleSlotContentTextHighlight"
        :middle-slot-content-text="deleteTemplateModal.middleSlotContentText"
        :bottom-buttons-align="deleteTemplateModal.bottomButtonsAlign"
        :bottom-slot-content-button-cancel-label="deleteTemplateModal.bottomSlotContentButtonCancelLabel"
        :bottom-slot-content-button-confirm-label="deleteTemplateModal.bottomSlotContentButtonConfirmLabel"
        :remove-button-cancel="deleteTemplateModal.removeButtonCancel"
        :remove-button-confirm="deleteTemplateModal.removeButtonConfirm"
        @on-cancel="() => deleteTemplateModalActive=false"
        @on-confirm="() => deleteTemplateModal.onConfirm()"
      />
    </v-dialog>
  </div>
</template>

<script>
import BaseSnackbar from '../../BaseSnackbar'
import BaseSnackbarConfiguration from '../../../mixins/BaseSnackbarConfiguration'
import BaseDataTable from '../../BaseDataTable'
import BaseModal from '../../BaseModal'
import {mapState, mapActions} from 'vuex'
import EnvelopeTemplateModal from './EnvelopeTemplateModal'

export default {
  name: 'EnvelopeTemplateList',
  components: {
    BaseModal,
    BaseDataTable,
    BaseSnackbar,
    EnvelopeTemplateModal
  },
  mixins: [ BaseSnackbarConfiguration ],
  data() {
    return {
      filter: '',
      pagination: {
        page: 1,
        itemsPerPage: 5
      },
      deleteTemplateModalActive: false,
      deleteTemplateModal: {},
      openModalEnvelope: false,
      templateObject: null
    }
  },
  computed: {
    ...mapState({
      envelopeTemplateList: state => state.platformConfiguration.envelopeTemplateList,
      envelopeTemplate: state => state.platformConfiguration.envelopeTemplate
    }),
  },
  methods: {
    ...mapActions('platformConfiguration',
      ['apiGetEnvelopeTemplateList', 'apiGetEnvelopeTemplate', 'apiDeleteEnvelopeTemplate', 'apiCreateEnvelopeTemplate']
    ),
    onCloseModalEdit(){
      this.templateObject = null
      this.openModalEnvelope = false
    },
    async getEnvelopeTemplateList() {
      let payload  = {
        filter: this.filter || '',
        page: this.pagination.page,
        size: this.pagination.itemsPerPage
      }
      await this.apiGetEnvelopeTemplateList(payload);
    },
    async getEnvelopeTemplate(id) {
      await this.apiGetEnvelopeTemplate(id);
    },
    updatePagination(pagination) {
      const reducePaginationAttributes = {
        page: pagination.page,
        itemsPerPage: pagination.itemsPerPage,
      }
      this.pagination = reducePaginationAttributes
      this.getEnvelopeTemplateList()
    },
    openDeleteModal(name, id){
      Object.assign(this.deleteTemplateModal, {
        showIndeterminateProgress: false,
        topSlotContentTitle: "Excluir template",
        removeButtonClose: false,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: `Tem certeza que deseja excluir o template ${name} selecionado?`,
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "Fechar",
        bottomSlotContentButtonConfirmLabel: "Excluir",
        removeButtonCancel: false,
        removeButtonConfirm: false,
        onConfirm: () => this.deleteTemplate(id)
      })

      this.deleteTemplateModalActive = true
    },
    async deleteTemplate(id) {
      try {
        await this.apiDeleteEnvelopeTemplate(id)
        this.showFeedback({
          message: 'Template apagado com sucesso.',
          color: 'success'
        })
      } catch(e){
        this.showFeedback({
          message: 'Erro ao apagar template.',
          color: 'error'
        })
      }
      finally {
        this.deleteTemplateModalActive = false
        this.getEnvelopeTemplateList()
      }
    },
    async editTemplate(data) {
      try {
        await this.getEnvelopeTemplate(data.id)
        this.openModalEnvelope = true
        this.templateObject = this.envelopeTemplate
      } catch(e) {
        this.showFeedback({
          message: 'Erro ao buscar template. Tente novamente.',
          color: 'error'
        })
      }
    },
    filterEnvelopeList(){
      this.pagination.page = 1
      this.getEnvelopeTemplateList()
    },
    clearFilter(){
      this.filter = ''
      this.pagination.page = 1
      this.getEnvelopeTemplateList()
    }
  }
}
</script>

<style scoped>
</style>
