<template>
  <v-tooltip
    bottom
    :disabled="!disableButton"
  >
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <BaseButton
          type="text"
          color="gray74"
          class="pa-0"
          :is-disabled="disableButton"
          @click="executeExportData"
        >
          <download-csv
            ref="csv"
            style="cursor: pointer; letter-spacing: unset;"
            title="Exportar CSV"
            class="v-btn v-size--default app-font-semibold"
            :data="dataMovementsToExport"
            :name="fileName"
            :exported="isExported"
          >
            {{ isExported ? 'Download' : isLoadingData ? 'Carregando dados' : 'Exportar' }}
            <v-icon v-if="!isLoadingData && isExported">
              file_download
            </v-icon>
            <v-progress-circular
              v-if="loadPage && isLoadingData"
              indeterminate
              class="app-button--is-loading mr-0 ml-2"
            />
          </download-csv>
        </BaseButton>
      </span>
    </template>
    Selecione um intervalo de até 90 dias
  </v-tooltip>
</template>

<script>
export default {
  props: {
    loadPage: {
      type: Boolean,
      required: true
    },
    dataMovementsToExport: {
      type: Array,
      required: true
    },
    fileName: {
      type: String,
      required: true
    },
    isExported: {
      type: Boolean,
      required: true
    },
    isLoadingData: {
      type: Boolean,
      required: true
    },
    disableButton: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    executeExportData() {
      this.$emit('executeExportData')
    },
  },
}
</script>
