import Vue from 'vue'

export default function filter() {
  Vue.filter('transformToLowerCase', (text) => {
    return text.toLowerCase()
  })

  Vue.filter('getDate', (datetime, format = false) => {

    if(typeof datetime === 'undefined')
      return '-'

    let date = new Date(datetime)

    if (format) {
      return `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1).toString().padStart(
        2, '0')}-${date.getUTCDate().toString().padStart(2, '0')}`
    } else {
      return `${date.getUTCDate().toString().padStart(2, '0')}/${(date.getUTCMonth()
        + 1).toString().padStart(2, '0')}/${date.getUTCFullYear()}`
    }
  })

  Vue.filter('getTime', (datetime) => {
    let time = new Date(datetime)
    return `${time.getHours().toString().padStart(2,
      '0')}:${time.getMinutes().toString().padStart(2, '0')}`
  })

  Vue.filter('formatCurrency', (currency, withSymbol = true, digits = 2) => {
    const formatCurrency = Intl.NumberFormat('pt-BR',
      {style: 'currency', currency: 'BRL', maximumFractionDigits: digits}).format(currency)
    let signal = ''

    if (formatCurrency.indexOf('-') === 0) {
      signal = '-'
    }

    return withSymbol ? formatCurrency : `${signal} ${formatCurrency.split(
      /\s/)[1]}`
  })

  Vue.filter('maskCNPJ', (value) => {
    return String(value).replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      '$1.$2.$3/$4-$5')
  })

  Vue.filter('maskCPForCNPJ', (value) => {

    if(value == undefined || value == null) {
      return '-'
    }

    if(value.length === 11){
      return String(value).replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/,
      '$1.$2.$3-$4')
    } else if (value.length === 14){
      return String(value).replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      '$1.$2.$3/$4-$5')
    }
  })

  Vue.filter('maskCEP', (value) => {
    return String(value).replace(/^(\d\d\d\d\d)(\d{3,})$/, '$1-$2')
  })

  Vue.filter('maskPhone', (value) => {
    return String(value).replace(/^(\d\d)(\d\d\d\d?\d)(\d{4,})$/, '($1) $2-$3')
  })

  Vue.filter('daysBetween', (d1,d2) => {
    const start = new Date(d1)
    const end = new Date(d2)
    const diffTime = end.getTime() - start.getTime()
    const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24))
    return diffDays 
  })
}
