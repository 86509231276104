<template>
  <div class="step-program-participants">
    <div class="text-center">
      <h1 class="font-weight-bold title mb-1 mt-6 color--text-black-054">
        Participantes do programa
      </h1>
      <p class="mb-6 color--text-black-054 body-2">
        Defina os papéis que cada participante ocupará no programa.
      </p>
    </div>

    <v-row>
      <v-col
        v-for="card, index in cardItems"
        :key="index"
      >
        <BaseCardSimple
          :card-image="card.image"
          :card-title="card.title"
          :card-text="card.text"
        >
          <template v-slot:card-action>
            <v-select
              v-model="card.selected"
              label="Selecione"
              :items="card.options"
              item-text="description"
              item-value="id"
              @change="selectRole(card)"
            />
          </template>
        </BaseCardSimple>
      </v-col>
    </v-row>

    <BaseActionFooter
      :button-back="backButton"
      :button-cancel="cancelButton"
      :button-confirm="confirmButton"
    />
  </div>
</template>

<script>
import {mapMutations, mapActions, mapState} from 'vuex'
import BaseActionFooter from '../../BaseActionFooter'
import BaseCardSimple from '../../BaseCardSimple'

export default {
  components: {BaseActionFooter, BaseCardSimple},
  data() {
    return {
      cardItems: [
        {
          image: "icon-building.svg",
          title: "Âncora",
          text: "Disponibiliza os ativos que serão negociados no programa.",
          type: "buyer",
          options: [],
          selected: null
        },
        {
          image: "icon-bank.svg",
          title: "Comprador",
          text: "Compra os ativos que serão negociados no programa.",
          type: "trading-partner",
          options: [],
          selected: null
        },
        {
          image: "icon-factory.svg",
          title: "Vendedor",
          text: "Toma o crédito da antecipação de ativos no programa.",
          type: "seller",
          options: [],
          selected: null
        }
      ],
      backButton: {
        action: ()=>{ this.$emit("onPreviousStep", 2) },
        text: 'Voltar',
        disabled: false
      },
      cancelButton: {
        action: ()=>{ this.$router.push('/platform-home') },
        text: 'Cancelar',
        disabled: false
      },
      confirmButton: {
        action: this.nextStep,
        text: 'Avançar',
        disabled: true
      },
    }
  },
  computed:{
    ...mapState({
      programId: (state) => {
        return state.platformProgram.programId
      },
      programSelectedRoles: (state) => {
        return state.platformProgram.programSelectedRoles
      }
    }),
  },
  watch: {
    cardItems: {
      handler() {
        this.confirmButton.disabled = this.cardItems.find(item => item.selected === null) ? true : false
      },
      deep: true
    }
  },
  created() {
    this.getProgramSelectedRoles()
    this.getAllAllowedRoles()
  },
  methods: {
    ...mapMutations(["setDisableNavigation", "setUseActionFooter"]),
    ...mapActions(['apiGetAllowedRoles', 'apiUpdateAllowedRoles', 'apiGetProgramSelectedRoles', 'apiGetProgramDocumentTypes']),
    async selectRole(item){
      try {
        await this.apiUpdateAllowedRoles({
          "programId": this.programId,
          "type": item.type,
           "id": item.selected
        })
      } catch(e) {
        this.$emit('onSubmitForm', {
          message: 'Erro ao selecionar papel.',
          color: 'error',
        })
      }
    },
    async nextStep(){
      await this.apiGetProgramSelectedRoles(this.programId)
      await this.apiGetProgramDocumentTypes(this.programId)
      this.$emit("onNextStep", 4)
    },
    async getProgramSelectedRoles(){
      if(this.$route.params.id){
        await this.apiGetProgramSelectedRoles(this.programId)
      }
    },
    getAllAllowedRoles() {
      this.cardItems.map(item => {
        this.apiGetAllowedRoles({type: item.type}).then((data)=> {
          item.options = data.data

          if(this.$route.params.id){
            item.options.filter(option => {
              this.programSelectedRoles.filter(role => {
                if(role.description === option.description){
                  item.selected = option.id
                }
              })
            })
          }

        })
        return item
      })
    }
  },
  beforeRouteLeave(to, from, next) {
    this.setUseActionFooter(false)
    this.setDisableNavigation(false)
    next()
  }
}
</script>

<style scoped>
.step-program-participants {
  max-width: 745px;
  margin: 0 auto;
}
.step-program-participants >>> .v-text-field__details {
  display: none;
}
</style>
