<template>
  <BaseCard 
    :has-main-container="true" 
    :is-header-title-description="true"
  >
    <template v-slot:header-title>
      <div>{{ headerTitle }}</div>
    </template>
    <template v-slot:header-title-description>
      <div class="pb-4">
        {{ headerTitleDescription }}
      </div>
    </template>
    <div 
      v-if="contextRegistration === 'logs'"
      class="d-flex justify-end mb-4 mr-6"
      style="margin-top: -70px;"
    >
      <BaseInput
        ref="filter"
        label="Buscar"
        style="width: 180px;"
        clearable
      />
    </div>
    <div>
      <template v-if="contextRegistration === 'logs'">
        <BaseDataTable
          :headers="headersLogsRegister"
          :items="itemsLogsRegister"
          background-color="transparent"
        />
      </template>
      <template v-if="contextRegistration === 'installments'">
        {{ 'Informações de parcelas' }}
      </template>
    </div>
  </BaseCard>
</template>

<script>
import BaseDataTable from '../BaseDataTable'
export default {
  name: "AssetsDetailsRegister",
  components: {BaseDataTable},
  props: {
      headerTitle: {
        type: String,
        required: true
      },
      headerTitleDescription: {
        type: String,
        required: false,
        default: ""
      },
      contextRegistration: {
        type: String,
        required: true,
        validator: (value) => ['logs', 'installments'].includes(value)
      },
  },

  computed: {
    headersLogsRegister(){
      return [
        {
          text: 'Data e hora',
          value: 'date',
          class: 'date',
          sortable: true
        },
        {
          text: 'Movimento',
          value: 'movement',
          class: 'movement',
          sortable: true,
          width: 500
        }
      ]
    },
    itemsLogsRegister(){
      return []
    }
  },
};
</script>

<style>
</style>