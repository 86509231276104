<template>
  <BaseCard
    class="mb-6"
    :has-main-container="true"
  >
    <template v-slot:header-title>
      Contato
    </template>
    <template v-slot:header-title-description>
      Informe o contato da organização
    </template>
    <v-form
      :ref="formName"
      v-model="contact.valid"
    >
      <v-container class="px-6">
        <v-row
          dense
          align="center"
          style="max-width: 440px;"
        >
          <v-col
            cols="auto"
            sm="12"
          >
            <BaseInput
              v-model="contact.name"
              :rules="[isValid]"
              label="Nome*"
              qa-identification-name="qa-id-name-contact-name"
            />
          </v-col>

          <v-col
            cols="auto"
            sm="12"
          >
            <v-combobox
              v-model="contact.emailList"
              :items="contact.emailList"
              label="Email(s) para notificação"
              hint="Ao digitar cada email aperte 'enter' para confirmar."
              persistent-hint
              multiple
              chips
              name="qa-id-name-contact-email"
              :error-messages="errorMessage"
              class="step-name-configuration_combobox"
              @change="validateEmailList(contact.emailList)"
            />
          </v-col>

          <v-col
            cols="auto"
            sm="4"
          >
            <TelephoneInput
              v-model="contact.phone_number"
              label="Telefone*"
              qa-identification-name="qa-id-name-contact-phone-number"
            />
          </v-col>
        </v-row>
        <v-row
          dense
          justify="end"
        >
          <v-col cols="auto">
            <BaseButton
              color="primary"
              type="text"
              qa-identification-name="qa-id-name-contact-cancel"
              @click="onCancelForm"
            >
              Cancelar
            </BaseButton>
            <BaseButton
              data-submit
              type="depressed"
              qa-identification-name="qa-id-name-contact-confirm"
              color="primary"
              :is-disabled="!contact.valid || contact.noFocus"
              @click="submitContactForm()"
            >
              Salvar
            </BaseButton>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </BaseCard>
</template>

<script>

import BaseCard from '../../BaseCard'
import TelephoneInput from '../../Input/TelephoneInput.vue'
import UtilsPlatformOrganizationProfileForm from '../../../mixins/UtilsPlatformOrganizationProfileForm'

import {mapState, mapActions, mapGetters} from 'vuex'

export default {
  name: 'PlatformOrganizationProfileContactForm',
  components: {BaseCard, TelephoneInput},
  mixins: [UtilsPlatformOrganizationProfileForm],
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formName: 'formAddOrUpdateContact',
      errorMessage: [],
      contact: {
        valid: false,
        phone_number: '',
        noFocus: true,
      },
    }
  },
  computed: {
    ...mapState('platformOrganization', ['platformOrganizationContact']),
    ...mapGetters('platformOrganization', ['hasPlatformOrganizationContact']),
    actionSubmitOrganizationContact() {
      return this.hasPlatformOrganizationContact ? 'PUT' : 'POST'
    }
  },
  methods: {
    ...mapActions('platformOrganization', [
      'apiGetPlatformOrganizationContact', 'apiCreatePlatformOrganizationContact',
      'apiUpdatePlatformOrganizationContact'
    ]),
    updateOrganizationContactFormFields() {
      if (this.hasPlatformOrganizationContact) {
        const {email, name, phone_number, primary} = this.platformOrganizationContact.content[0]
        let emailList = email.split(',')
        Object.assign(this.contact, {emailList, name, phone_number, primary, noFocus: true})
      }
    },
    async submitContactForm() {
      try {
        const {emailList, ...serializeContact} = this.contact
        const data = {
          id: this.$route.params.id,
          email: emailList.join(),
          ...serializeContact
        }

        if (this.actionSubmitOrganizationContact === 'POST') {
          await this.apiCreatePlatformOrganizationContact(data)

        } else {
          data.idContact = this.platformOrganizationContact.content[0].id
          await this.apiUpdatePlatformOrganizationContact(data)
        }

        await this.apiGetPlatformOrganizationContact(data.id)

        this.$emit('onSubmitContact', {
          message: 'Contato salvo com sucesso',
          color: 'success',
        })
        this.updateOrganizationContactFormFields()
      } catch (error) {
        this.$emit('onSubmitContact', {
          message: 'Erro ao salvar contato',
          color: 'error',
        })
      }
    },
    validateEmailList(emailList){
      this.errorMessage = this.isEmailValid(emailList)
    },
    onFocusCallback() {
      this.contact.noFocus = false
    },
    onCancelForm() {
      this.cancelForm(this, 'formAddOrUpdateContact')
    },
    clearFields() {
      this.$refs['formAddOrUpdateContact'].reset()
    }
  },
  watch: {
    active(isCurrentTrue) {
      if (isCurrentTrue) {
        this.updateOrganizationContactFormFields()
      }
    }
  },
  created() {
    this.updateOrganizationContactFormFields()
  }
}
</script>

<style>

</style>
