<template>
  <div>
    <PersonalData
      icon="domain"
      title="Organização"
      :template="getTypeTemplate"
    >
      <template
        v-slot:[getTypeTemplate]
      >
        <p v-html="getPlatformOrganization" />
      </template>
    </PersonalData>
  </div>
</template>

<script>
import PersonalData from './PersonalData'
import UtilsPlatformOrganizationProfileTemplate from '../../../mixins/UtilsPlatformOrganizationProfileTemplate'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'PlatformOrganizationProfileOrganizationTemplate',
  components: { PersonalData },
  mixins: [ UtilsPlatformOrganizationProfileTemplate ],
  props: {
    context: {
      type: String,
      default: '',
      required: false
    }
  },
  computed: {
    ...mapState('platformOrganization', ['platformOrganization']),
    ...mapState('programMembers', ['memberOrganization']),
    ...mapGetters('platformOrganization', ['hasPlatformOrganization', 'getPlatformOrganizationName']),
    isContextMembers(){
      if(this.context == 'members') return true
      else return false
    },
    getOrganizationName() {
      if(this.isContextMembers){
        return this.memberOrganization.name ? this.memberOrganization.name : '-'
      } else {
        return this.platformOrganization.name ? this.platformOrganization.name : '-'
      }
    },
    getPlatformOrganizationCNPJ() {
      if(this.isContextMembers){
        return this.memberOrganization.tax_number ? this.memberOrganization.tax_number : '-'
      } else {
        return this.platformOrganization.tax_number ? this.platformOrganization.tax_number : '-'
      }
    },
    getOrganizationOwnerEmail() {
      if(this.isContextMembers){
        return this.memberOrganization.owner_email ? this.memberOrganization.owner_email : '-'
      } else {
        return null
      }
    },
    getPlatformOrganization(){
      return `
        Razão Social ${this.wrapTextWithHtmlTag(this.getOrganizationName)}<br/>
        CNPJ ${
          this.wrapTextWithHtmlTag(
            this.$options.filters.maskCNPJ(
              this.getPlatformOrganizationCNPJ
            )
          )
        }
        ${ this.getOrganizationOwnerEmail !== null ? `<br/>E-mail da conta ${this.wrapTextWithHtmlTag(this.getOrganizationOwnerEmail)}` : '' }
        <br/>
      `
    },
    getTypeTemplate() {
      return this.hasPlatformOrganization ? 'data-template' : 'no-data-template'
    }
  }
}
</script>

<style>

</style>
