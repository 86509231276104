import {mapState, mapActions} from 'vuex'

export default {
  computed: {
    ...mapState({
      programInfo: (state) => state.platformProgram.programInfo,
      contextNavigation: (state) => state.theNavigation.navigationContext
    }),

    showMenu() {
      let listMenu = []
      if (this.programInfo && this.programInfo.configuration) {
        Object.keys(this.programInfo.configuration).forEach(key => {
          if (key.includes('show_menu_') && this.programInfo.configuration[key] === 'true') {
            listMenu.push(this.clearName(key, 'show_menu_'))
          }
        })
      }
      return listMenu
    },
  },

  async mounted(){
    if(this.contextNavigation === 'program' && !this.programInfo){
      await this.apiGetProgramInfo()
    }
  },

  methods: {
    ...mapActions(['apiGetProgramInfo']),

    clearName(name, changeValue) {
      return name.replace(changeValue, '')
    },
  }
}
