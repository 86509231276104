<template>
  <div class="assets-collection-confirm">
    <MovementDetails
      v-if="!showSuccess"
      :is-loading="isLoading"
      title="Resumo da negociação"
      :section="movementDetailsSections[tradeType]"
      :actions-buttons="movementDetailsActions"
      @click="movementActions"
    >
      <template v-slot:conteudo>
        <v-container class="pa-0 mt-6 primary--text">
          <BaseResumeInfos :resume-infos="infos" />
        </v-container>
      </template>
      <template v-slot:dados-pagamento>
        <div
          v-if="tradeType == 'select'"
          class="d-flex mt-7"
        >
          <BaseDataBank
            :has-icon="false"
            :bank-value="getBankCodeAndName(bankAccountOrTradingPartner)"
            :agency-value="getAgencyAndDigit(bankAccountOrTradingPartner)"
            :account-value="getNumberAndDigit(bankAccountOrTradingPartner)"
            style="border: none; width: 100%; padding: 0px!important"
          />
        </div>
        <div
          v-else-if="tradeType == 'dynamic'"
          class="mt-7"
        >
          <p class="mb-2">
            Nome: <b>{{ bankAccountOrTradingPartner.name }}</b>
          </p>
          <p v-if="bankAccountOrTradingPartner.tax_number">CNPJ: <b>{{ $options.filters.maskCPForCNPJ(bankAccountOrTradingPartner.tax_number) }}</b></p>
          <v-divider class="my-6" />
          <v-checkbox
            v-model="confirmOperation"
            label="Estou de acordo com os dados simulados e desejo solicitar a operação."
          />
        </div>
      </template>
    </MovementDetails>

    <v-card
      v-else
      class="assets-collection-confirm__card--feedback-background"
      elevation="0"
    >
      <div class="app-align-items-center justify-center">
        <v-card-title>
          <div>
            <div
              class="platform-home body-1"
              :class="'text-' + stepDetailInvite.textAlignment"
            >
              <div v-html="stepDetailInvite.content" />
            </div>
          </div>
        </v-card-title>
      </div>
      <v-card-actions>
        <v-col
          :class="'text-' + stepDetailInvite.actions.alignment"
          class="pa-2"
        >
          <BaseButton
            color="primary"
            button-class="mr-2"
            @click="closeAssetsCollectionConfirm"
          >
            {{ stepDetailInvite.actions.cancelButtonText }}
          </BaseButton>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BaseResumeInfos from '../BaseResumeInfos'
import BaseDataBank from '../BaseDataBank'
import MovementDetails from '../MovementDetails'
import UtilsBankAccount from '../../mixins/UtilsBankAccount'

export default {
  components: { BaseResumeInfos, BaseDataBank, MovementDetails },
  mixins: [UtilsBankAccount],
  props: {
    totalOfAssets: {
      default: 0,
      type: Number
    },
    amount: {
      default: 0.00,
      type: Number
    },
    bankAccountOrTradingPartner: {
      default: () => {},
      type: Object
    },
    tradeType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      showSuccess: false,
      infos: [
        {
          icon: 'request_quote',
          iconText: 'Quantidade',
          value: `${this.totalOfAssets} ativos`,
          valueType: 'text'
        },
        {
          icon: 'attach_money',
          iconText: 'Valor total',
          value: this.amount,
          valueType: 'currency'
        }
      ],
      movementDetailsSections: {
        "select": [
          {
            title: 'CONTEÚDO',
            value: 'conteudo'
          },
          {
            title: 'DADOS DE PAGAMENTO',
            subtitle: 'Ao final da operação o pagamento será efetuado na conta abaixo',
            value: 'dados-pagamento'
          }
        ],
        "dynamic": [
          {
            title: 'CONTEÚDO',
            value: 'conteudo'
          },
          {
            title: 'DADOS DO FIDC',
            subtitle: 'Ao final da operação os contratos serão cedidos para o FIDC abaixo',
            value: 'dados-pagamento'
          }
        ]
      },
      confirmOperation: false
    }
  },
  computed: {
    ...mapState({
      programDraftMovementId: state => state.programDraftMovements.programDraftMovementId,
      programDraftMovementData: state => state.programDraftMovements.programDraftMovementData,
      movementDetailsActions(){
        return [
          {
            text: 'VOLTAR',
            type: 'text',
            color: 'primary',
            dispatchEvent: 'onCancel'
          },
          {
            text: 'ENVIAR',
            color: 'primary',
            dispatchEvent: 'onConfirm',
            disabled: this.isLoading || (this.tradeType == 'dynamic' && !this.confirmOperation)
          }
        ]
      }
    }),
    stepDetailInvite() {
        return {
          content: `
            <img src="${require('@/assets/action-success.svg')}" height="148" width="143" class="mb-6 mt-2"/>
            <h3 class="headline mb-4 text-center primary--text font-weight-bold">Negociação enviada!</h3>
            <p class="mb-1">Acesse o menu negociações para acompanhar <br />tudo o que você enviou.</p>
          `,
          textAlignment: 'center',
          actions: {
            alignment: 'center',
            cancelButtonText: 'Fechar',
          }
        }
      }
  },
  methods: {
    ...mapActions('programDraftMovements', ['apiUpdateDraftMovement']),
    movementActions(event) {
      const { dispatchEvent, ev} = event
      switch(dispatchEvent) {
        case 'onConfirm':
          this.sendAssetsCollection()
          break;
        case 'onCancel':
          this.backToAssetsCollection()
          break;
      }
    },
    backToAssetsCollection() {
      this.$emit('back-page')
    },
    closeAssetsCollectionConfirm() {
      this.$emit('close-assets-collection')
      this.$emit('updateAssetList')
    },
    async sendAssetsCollection(){
      this.isLoading = true
      const data = {
        "type": "trade",
        "id": this.programDraftMovementId,
        "data": {
          "action": "",
          "data": {}
        }
    	}
      if(this.tradeType === "select") {
        data.data.action =  "request";
        data.data.data = {
          bank_account: this.bankAccountOrTradingPartner
        }
      } else if (this.tradeType === "dynamic") {
        data.data.action =  "finish";
        data.data.data = {
          'fidc_name': this.bankAccountOrTradingPartner.name,
          'fidc_tax_number': this.bankAccountOrTradingPartner.tax_number
        }
      }
      try {
        await this.apiUpdateDraftMovement(data)
        this.$emit('updateAssetList')
        setTimeout(() => {
          this.showSuccess = true
          this.$emit('updateAssetList')
          this.isLoading = false
        }, 11000)
      } catch (e) {
        throw e
      }
    }
  }
}
</script>

<style scoped>

.card-actions--footer {
  background-color: #F4F5F8;
}

.assets-collection-confirm__card--feedback-background{
  background: transparent;
}

</style>
