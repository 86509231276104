<template>
  <div
    class="d-flex d-flex py-2 px-6"
    style="background: #e3f0f9;"
  >
    <div class="d-flex align-start bluefa--text app-font-semibold text-uppercase py-2">
      {{ description }}
    </div>
    <v-spacer />
    <div class="d-flex flex-column align-end">
      <v-menu
        offset-y
        left
        attach
      >
        <template v-slot:activator="{ on }">
          <BaseButton
            :has-icon="true"
            class="ma-0"
            v-on="on"
          >
            <v-icon>more_vert</v-icon>
          </BaseButton>
        </template>

        <v-list>
          <v-list-item
            v-for="actionMenu,index in actionList"
            :key="index"
            class="pa-0"
          >
            <BaseButton
              type="text"
              button-class="base-action-group--button"
              style="font-weight: 400 !important;"
              @click="onClickAction(actionMenu.action)"
            >
              {{ actionMenu.name }}
            </BaseButton>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseActionGroup",
  props: {
    description: {
      type: String,
      required: false,
      default: ''
    },
    currentObject: {
      type: Object,
      required: true
    },
    actionList: {
      type: Array,
      required: true
    }
  },
  methods: {
    onClickAction(value){
      const param = {
        action: value,
        currentObject: this.currentObject
      }
      this.$emit('onClickAction', param);
    }
  }
}
</script>

<style>
  .base-action-group--button {
    width: 100%;
    margin: 0px !important;
    color: var(--custom-theme-black-087) !important;
    display: flex;
    justify-content: flex-start;
  }
  .base-action-group--button >>> span {
    font-weight: normal;
  }
</style>
