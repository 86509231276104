import api from '../../services/api';

export default {
  namespaced: true,
  state: {
    authzUserList: null
  },
  mutations: {
    setAuthzUserList(state, payload) {
      state.authzUserList = payload
    }
  },
  actions: {
    async apiGetAuthzUsers({commit}) {
      const result = await api.get(`/authz/users`)
      commit("setAuthzUserList", result.data)
    },
    async apiCreateAuthzUsers({commit}, payload) {
      await api.post(`/authz/users`, payload)
    },
    async apiUpdadeAuthzUsers({commit}, payload) {
      const { email } = payload
      await api.put(`/authz/users/${email}`, payload)
    },
    async apiUpdateTermsOfUse({commit}, payload) {
      await api.post(`/authz/accept`, payload)
    }
  }
}
