<template>
  <v-select
    v-model="roleSelected"
    :items="programRoles"
    :item-text="({role}) => `Perfil ${translateRoles(role)}`"
    item-value="role"
    background-color="#808080"
    class="select-role px-4 font-weight-bold body-2 rounded-sm"
    height="45px"
    @change="changeUserRole"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { utilsShowErrorInDevelopmentEnvironment } from '../../../utils'
import ProgramRoles from '../../../mixins/ProgramRoles'

export default {
   mixins: [ProgramRoles],
   computed: {
    ...mapState({
      programRoles: state => state.platformProgram.programRoles,
    }),
    ...mapGetters({
      activeRole: 'getActiveRole'
    }),
    roleSelected: {
      get() {
        return this.activeRole
      },
      set(value){
        this.changeUserRole(value)
      } 
    }
  },
  methods: {
    ...mapActions('rolesManagement', ['apiPostAssumeRole']),
    async changeUserRole(value) {
      try{
        await this.apiPostAssumeRole({role:value, organization_id: ''})
        location.reload()
      }catch(err){
        utilsShowErrorInDevelopmentEnvironment(err)
      }
    }
  }
}
</script>

<style lang="scss">
.select-role {
  .v-select__selections {
    color: white !important;
    padding: 0 10px;
  }

  .v-text-field__details {
    display: none !important;
  }

  .v-icon {
    color: white !important;
  }
}
</style>
