export default {
  methods:{
    getBankLabel(item) {
      return `${item.value} ${item.label}`;
    },

    getBankCodeAndName(item) {
      return `${item.bank_code} ${item.bank_name}`;
    },

    getAgencyAndDigit(item) {
      return `${item.agency}${item.agency_digit ? '-' + item.agency_digit : ''}`;
    },

    getNumberAndDigit(item) {
      return `${item.number}${item.digit ? '-' + item.digit : ''}`;
    }
  }
}
