import {mapActions, mapState} from 'vuex'
import loader from '../global/loader'

export default {
  data: () => ({
    movementsToExportList: [],
    isExported: false,
    isLoadingData: false
  }),
  computed: {
    dataMovementsToExport() {
      return this.movementsToExportList.content
        ? this.movementsToExportList.content.map(
          element => {
            const {type, to, from, data} = element
            return {
              status: data.status.name,
              number: data.number,
              type: type ? type.title : '-',
              seller: from ? from.name : '-',
              seller_tax_number: this.$options.filters.maskCPForCNPJ(
                from.data['tax-number']),
              buyer: to ? to.name : '-',
              buyer_tax_number: to ? this.$options.filters.maskCPForCNPJ(
                to.data['tax-number']) : '-',
              date: data.date,
              'post-date': data['post-date'] ? data['post-date'] : data.date,
              amount: data.amount,
              purchase_order: data.purchase_order,
              fiscal_year: data.fiscal_year,
              payment_terms: data.payment_terms,
              movement_code: data.movement_code,
              vendor_code: data.vendor_code,
              net_value: data.net_value,
              reference_document: data.reference_document,
              company_code: data.company_code,
            }
          }) : []
    }
  },
  methods: {
    ...mapActions('programMovements', ['apiGetProgramMovementsToExport']),
    ...mapActions('programFilters', ['apiPostMovementsFilters']),
    getFileName(category) {
      return  "movements_" + category + "_" + new Date().getTime() + ".csv";
    },
    async exportData(filter, filterData) {
      let response = {}
      if(this.isExported == true){
        this.isExported = false
        this.movementsToExportList = []
      } else {
        this.isLoadingData = true

        loader.startProgressLoadPage()

        if(filterData.filters.length > 0){
          filterData.pagination.size = 4000
          response = await this.apiPostMovementsFilters(filterData)
        } else {
          response = await this.apiGetProgramMovementsToExport({
            start_date: filter.start_date,
            end_date: filter.end_date,
            category: filter.category,
            page: 0,
            size: 4000
          })
        }

        this.movementsToExportList = response.data

        loader.stopProgressLoadPage()

        this.isExported = true
        this.isLoadingData = false
      }
    },
  }
}
