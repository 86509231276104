import store from '../store'
import ProgramRoles from '../mixins/ProgramRoles'

export default function intercom() {

  const keycloak = store.state.auth.keycloak
  const userRole = store.state.platformProgram.activeOrganization.active_role || ''
  const firstHostName = window.location.hostname.split('.')[0]
  const secondHostName = window.location.hostname.split('.')[1]
  const programId = secondHostName === 'finan4' || secondHostName === 'finanfor' ? firstHostName : 'minha-conta'

  /*global Intercom*/
  /*eslint no-undef: "error"*/
  Intercom("boot", {
    app_id: "c652rqli",
    email: keycloak.tokenParsed.email,
    name: keycloak.tokenParsed.name,
    product: "platform",
    user_id: keycloak.tokenParsed.preferred_username,
    user_role: userRole,
    program_id: programId,
    widget: {
      activator: "#IntercomDefaultWidget"
    }
  })

  window.clarity('identify', keycloak.tokenParsed.email, userRole, programId, keycloak.tokenParsed.email)
  window.clarity('set', 'user_role', userRole)
  window.clarity('set', 'user_program', programId)

  window.hj('identify', keycloak.tokenParsed.preferred_username, {
    user_role: userRole
  });
}
