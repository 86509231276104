import api from '../../services/api';

export default {
  state: {
    initiators: []
  },
  getters: {
    getInitiators(state) {
      return [...state.initiators.map(
        elem => elem.name
      )].sort((a, b) => a.localeCompare(b))
    }
  },
  mutations: {
    setInitiators(state, payload) {
      state.initiators = payload;
    },
  },
  actions: {
    apiGetInitiators({commit}) {
      return new Promise((resolve, reject) => {
        api.get('initiators')
        .then((data) => {
          commit('setInitiators', data.data)
          resolve(data.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}
