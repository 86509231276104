export default {
  state: {
    keycloak : null
  },
  getters: {
    logout(state) {
      state.keycloak.logout()
    }
  },
  mutations: {
    setKeycloak(state, payload){
      state.keycloak = payload
    },
  },
  actions: {

  }
}
