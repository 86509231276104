<template>
  <div class="platform-organization-profile">
    <BaseTour
      :tour-name="tourName"
      :steps="dataSteps"
      :start-on-mounted="false"
    />
    <BaseCard
      :has-divider="true"
      :has-main-container="true"
      base-card-title-class="app-font-semibold title mb-1"
      data-v-step="0"
    >
      <template v-slot:header-title>
        Dados de cadastro
      </template>
      <template v-slot:header-actions>
        <v-dialog
          :value="isModalActive"
          persistent
          transition="app-transition-to-right"
          content-class="app-transition-to-right-additional-configs"
          @input="updateModalActive"
        >
          <template v-slot:activator="{ on }">
            <BaseButton
              type="text"
              qa-identification-name="qa-id-name-organization-edit"
              color="blueff"
              class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
              v-on="on"
              @click="finishTour(tourName)"
            >
              Editar
            </BaseButton>
          </template>
          <BaseModal
            style="height: 100vh; flex-wrap: nowrap;"
            :show-indeterminate-progress="modalSignStep.showIndeterminateProgress"
            :top-slot-content-title="modalSignStep.topSlotContentTitle"
            :remove-button-close="modalSignStep.removeButtonClose"
            :middle-slot-content-text-highlight="modalSignStep.middleSlotContentTextHighlight"
            :middle-slot-content-text="modalSignStep.middleSlotContentText"
            :middle-check-agree="modalSignStep.middleCheckAgree"
            :bottom-buttons-align="modalSignStep.bottomButtonsAlign"
            :bottom-slot-content-button-cancel-label="modalSignStep.bottomSlotContentButtonCancelLabel"
            :bottom-slot-content-button-confirm-label="modalSignStep.bottomSlotContentButtonConfirmLabel"
            :remove-button-cancel="modalSignStep.removeButtonCancel"
            :remove-button-confirm="modalSignStep.removeButtonConfirm"
            body-background-color="#F8F8F8"
            @on-cancel="modalSignStep.onCancel"
            @on-confirm="() => null"
          >
            <div
              class="mt-6"
              style="max-width: 645px; margin: 0 auto;"
            >
              <OrganizationForm
                ref="organizationForm"
                :active="isModalActive"
                @onSubmitOrganization="showFeedback"
                @onCancel="closeModal"
              />
              <AddressForm
                ref="addressForm"
                :active="isModalActive"
                @onSubmitAddress="showFeedback"
                @onCancel="closeModal"
              />
              <ContactForm
                ref="contactForm"
                :active="isModalActive"
                @onSubmitContact="showFeedback"
                @onCancel="closeModal"
              />
            </div>
          </BaseModal>
        </v-dialog>
      </template>
      <div class="mx-6 mt-4">
        <OrganizationTemplate />
        <AddressTemplate />
        <ContactTemplate />
      </div>
    </BaseCard>
    <BaseSnackbar
      :active-snackbar="feedback"
      :text-snackbar="feedbackMessage"
      :type-snackbar="feedbackColor"
      @closeSnackbar="feedback = !feedback"
    />
  </div>
</template>

<script>
import BaseModal from '../../BaseModal'
import BaseSnackbar from '../../BaseSnackbar'
import BaseButton from '../../BaseButton'
import BaseSnackbarConfiguration from '../../../mixins/BaseSnackbarConfiguration'

import AddressTemplate from './AddressTemplate'
import ContactTemplate from './ContactTemplate'
import OrganizationTemplate from './OrganizationTemplate'
import AddressForm from './AddressForm'
import ContactForm from './ContactForm'
import OrganizationForm from './OrganizationForm'

import {mapGetters, mapState} from 'vuex'
import ProgramTour from '../../../mixins/ProgramTour'

export default {
  name: 'PlatformOrganizationProfile',
  components: {
    BaseModal,
    BaseSnackbar,
    BaseButton,
    AddressTemplate,
    AddressForm,
    ContactTemplate,
    ContactForm,
    OrganizationTemplate,
    OrganizationForm
  },
  mixins: [ BaseSnackbarConfiguration, ProgramTour ],
  data() {
    return {
      isModalActive: false,
      modalSignStep: {
        showIndeterminateProgress: false,
        topSlotContentTitle: "Editar dados de cadastro",
        removeButtonClose: false,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: `<p>Cadastre uma nova empresa à sua lista de organizações.</p>`,
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "Cancelar",
        bottomSlotContentButtonConfirmLabel: "Salvar",
        removeButtonCancel: true,
        removeButtonConfirm: true,
        onCancel: this.closeModal,
        onConfirm: null,
      },
      tourName: 'profile',
      dataSteps: [
        {
          target: '[data-v-step="0"]',
          content: 'Para cadastrar as informações de localização e contato da sua organização, utilize o botão <b>“Editar”</b> abaixo.',
          layoutStep: "simple",
          params: {
            placement: 'top'
          }
        }
      ]
    }
  },
  computed: {
    ...mapState('platformOrganization', ['platformOrganization']),
    ...mapGetters('platformOrganization',
      ['platformOrganizationAddressCityAndState', 'getPlatformOrganizationName']),
  },
  mounted() {
    this.startTour(this.tourName)
  },
  methods: {
    closeModal() {
      this.isModalActive = false
      this.$refs.organizationForm.clearFields()
      this.$refs.addressForm.clearFields()
      this.$refs.contactForm.clearFields()
    },
    updateModalActive(value) {
      this.isModalActive = value
    }
  }
}
</script>

<style scoped></style>
