import tokens from '../directives/vue-the-mask/tokens'

const computed = {
  configOfVueTheMask() {
    return {
      mask: this.mask,
      masked: this.masked,
      tokens: this.tokens
    }
  },
  inputListeners() {
    return Object.assign({},
      this.$listeners,
      {
        input: (value) => {
          this.inputValue = value

          if (this.masked || !value) {
            this.outputValue = value
          } else {
            this.outputValue = value.replace(new RegExp(this.usePattern, 'g'), '')
          }

          this.$emit("input", this.outputValue)
        },
      }
    )
  }
}

const props = {
  tokens: {
    type: Object,
    default: () => tokens
  },
  masked: {
    type: Boolean,
    default: true
  },
  mask: {
    type: [String, Array],
    required: false,
    default: ''
  },
  usePattern: {
    type: String,
    required: false,
    default: ''
  }
}

const hooks = {
  updated() {
    this.inputValue = this.value
    this.outputValue = this.value
  }
}

const theMask = { computed, props, hooks }

export default theMask
