<template>
  <div class="platform-organization-profile-documents-list">
    <BaseDataTable
      :items="filterDocumentList"
      :hide-actions="true"
      :hide-headers="true"
      background-color="transparent"
    >
      <template v-slot:inject-items="scopedItems">
        <tr
          v-for="value in scopedItems"
          :key="value.index"
          :class="{ 'color--text-black-020' : value.item.status === 'PENDING' }"
        >
          <template v-if="contextActions === 'manage'">
            <td class="truncate-content-text">
              <p>{{ value.item.document_type.name }}</p>
            </td>
            <td
              class="text-right app-align-items-center"
              style="justify-content: flex-end; min-width: 410px;"
            >
              <div v-if="value.item.status === 'PENDING'">
                <v-file-input
                  v-show="false"
                  :ref="value.item.id"
                  v-model="value.item.file"
                  accept="image/*,.pdf"
                  @change="uploadDocument(value.item)"
                />
                <BaseButton
                  type="text"
                  color="blueff"
                  class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
                  @click="selectDocument(value.item.id)"
                >
                  Adicionar
                  <v-progress-circular
                    v-if="isUploadingDocument"
                    indeterminate
                    class="app-button--is-loading mr-0 ml-2"
                  />
                </BaseButton>
              </div>
              <div v-else>
                <div
                  v-if="selectedDocumentToDelete === value.item.id"
                  class="app-align-items-center"
                >
                  Excluir arquivo? Esta ação não poderá ser desfeita:
                  <BaseButton
                    type="text"
                    color="blueff"
                    class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
                    @click="resetSelectedDocumentToDelete"
                  >
                    Não
                  </BaseButton>
                  <BaseButton
                    type="text"
                    color="blueff"
                    class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0 ml-0"
                    @click="deleteDocument(value.item.id)"
                  >
                    Sim
                  </BaseButton>
                </div>
                <BaseButton
                  v-else
                  type="text"
                  color="error"
                  class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
                  @click="selectedDocumentToDelete = value.item.id"
                >
                  Excluir
                </BaseButton>
              </div>
            </td>
          </template>

          <template v-if="contextActions === 'read'">
            <td>
              <div class="app-align-items-center">
                <v-tooltip
                  v-if="value.item.status === 'NOT_SIGNED'"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <span
                      style="cursor: help; vertical-align: middle"
                      v-on="on"
                    >
                      <v-icon
                        color="#FFB44D"
                        class="mr-2"
                        style="font-size: 17px;"
                      >warning</v-icon>
                    </span>
                  </template>
                  <span>Assinatura obrigatória</span>
                </v-tooltip>
                <p>{{ value.item.document_type.name }}</p>
              </div>
            </td>
            <td
              class="text-right"
              style="justify-content: flex-end;"
            >
              <p
                v-if="showRequestedBy(value.item)"
                class="color--text-black-020"
              >
                Solicitado por {{ value.item.requested_by.name }}
              </p>
              <p v-if="value.item.status === 'SIGNED'">
                Assinado
              </p>
            </td>
            <td
              class="text-right"
              style="justify-content: flex-end; width:50px; padding-left:0px!important;"
            >
              <v-tooltip
                v-if="showTooltipIcon(value.item)"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <span
                    style="cursor: help; vertical-align: middle"
                    v-on="on"
                  >
                    <v-icon
                      color="#c9c9c9"
                      class="ml-3 mr-2"
                      style="font-size: 17px;"
                    >info</v-icon>
                  </span>
                </template>
                <span v-if="onlyDownload">Documento pendente de {{ value.item.status === 'PENDING' ? 'upload' : 'assinatura' }}</span>
                <span v-else>Clique em "Editar" para enviar o documento</span>
              </v-tooltip>
              <BaseButton
                v-if="showDownloadButton(value.item)"
                type="text"
                color="blueff"
                class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
                @click="downloadDocument(value.item)"
              >
                Ver
              </BaseButton>
              <BaseButton
                v-if="showSignButton(value.item)"
                color="blueff"
                type="text"
                :href="getEnvelopeUrl(value.item.file_uri)"
                class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
              >
                Assinar
              </BaseButton>
            </td>
          </template>
        </tr>
      </template>
      <template v-slot:custom-text-no-data>
        <div class="text-center">
          Nenhum documento para exibir
        </div>
      </template>
    </BaseDataTable>
  </div>
</template>

<script>
import BaseDataTable from '../../BaseDataTable';
import {mapActions} from "vuex";

export default {
  name: "PlatformOrganizationProfileDocumentList",
  components: {BaseDataTable},
  props: {
    contextActions: {
      required: true,
      type: String,
      validator: (value) => ['read', 'manage'].includes(value)
    },
    documentList: {
      required: true,
      type: Array
    },
    isUploadingDocument: {
      required: false,
      type: Boolean
    },
    onlyDownload: {
      required: false,
      type: Boolean,
      default: false
    },
    organizationId: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      selectedDocumentToDelete: null
    }
  },
  computed: {
    filterDocumentList() {
      return this.documentList.map(document => Object.assign(document, {file: null}))
    }
  },
  methods: {
    ...mapActions(['apiDownloadDocument']),
    showRequestedBy(item) {
      return (item.status === 'PENDING' || item.status === 'NOT_SIGNED') && item.requested_by || false
    },
    showDownloadButton(item){
      return (item.status === 'UPLOADED' || item.status === 'SIGNED') && item.file_uri || false
    },
    showSignButton(item){
      return item.status === 'NOT_SIGNED' && !this.onlyDownload || false
    },
    showTooltipIcon(item){
      return item.status === 'PENDING' || (item.status === 'NOT_SIGNED' && this.onlyDownload) || false
    },
    deleteDocument(id) {
      this.$emit('onDeleteDocument', id)
      this.resetSelectedDocumentToDelete()
    },
    resetSelectedDocumentToDelete() {
      this.selectedDocumentToDelete = null
    },
    selectDocument(id) {
      this.$refs[id][0].$el.querySelector('input').click()
    },
    uploadDocument(item) {
      this.$emit('onUploadDocument', item)
    },
    getEnvelopeUrl(fileUri){
      return fileUri.replace('envelopes://','')
    },
    async downloadDocument(item) {
      let documentId = item.file_uri.replace('storage://','')
      await this.apiDownloadDocument({id: documentId, organizationId: this.organizationId, name: item.document_type.name})
    }
  }
};
</script>

<style>
.platform-organization-profile-documents-list .truncate-content-text {
  max-width: 230px;
  width: 230px;
}

.platform-organization-profile-documents-list .truncate-content-text p {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
</style>
