export default {
  state: {
    navigationContentItens: [],
    navigationBg: '',
    navigationMini: false,
    navigationContext: ''
  },
  mutations: {
    setNavigationContentItens(state, payload) {
      state.navigationContentItens = payload
    },
    setNavigationBg(state, payload) {
      state.navigationBg = payload
    },
    setNavigationMini(state, payload) {
      state.navigationMini = payload
    },
    setNavigationContext(state, payload){
      state.navigationContext = payload
    }
  }
}
