<template>
  <div class="platform-organization-profile-documents">
    <BaseTour
      tour-name="organization-documents"
      :steps="dataSteps"
      :start-on-mounted="false"
    />
    <BaseTour
      tour-name="program-documents"
      :steps="dataSteps"
      :start-on-mounted="false"
    />
    <BaseCard
      :has-divider="true"
      :has-main-container="true"
      base-card-title-class="app-font-semibold title mb-1"
      data-v-step="1"
    >
      <template v-slot:header-title>
        Documentos {{ $route.meta.scope === 'program' ? 'de programa' : 'da organização' }}
      </template>
      <template v-slot:header-actions>
        <v-dialog
          :value="isModalActive"
          persistent
          transition="app-transition-to-right"
          content-class="app-transition-to-right-additional-configs"
          @input="updateModalActive"
        >
          <template v-slot:activator="{ on }">
            <BaseButton
              type="text"
              color="blueff"
              class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
              v-on="on"
              @click="finishTour(tourName)"
            >
              Editar
            </BaseButton>
          </template>
          <BaseModal
            style="height: 100vh; flex-wrap: nowrap;"
            :show-indeterminate-progress="modalManageDocuments.showIndeterminateProgress"
            :top-slot-content-title="modalManageDocuments.topSlotContentTitle"
            :remove-button-close="modalManageDocuments.removeButtonClose"
            :middle-slot-content-text-highlight="modalManageDocuments.middleSlotContentTextHighlight"
            :middle-slot-content-text="modalManageDocuments.middleSlotContentText"
            :middle-check-agree="modalManageDocuments.middleCheckAgree"
            :bottom-buttons-align="modalManageDocuments.bottomButtonsAlign"
            :bottom-slot-content-button-cancel-label="modalManageDocuments.bottomSlotContentButtonCancelLabel"
            :bottom-slot-content-button-confirm-label="modalManageDocuments.bottomSlotContentButtonConfirmLabel"
            :remove-button-cancel="modalManageDocuments.removeButtonCancel"
            :remove-button-confirm="modalManageDocuments.removeButtonConfirm"
            body-background-color="#F8F8F8"
            @on-cancel="modalManageDocuments.onCancel"
            @on-confirm="() => null"
          >
            <div
              class="mt-6"
              style="max-width: 645px; margin: 0 auto;"
            >
              <BaseCard
                :has-main-container="true"
              >
                <DocumentList
                  :document-list="platformOrganizationDocuments"
                  :organization-id="organizationId"
                  context-actions="manage"
                  :is-uploading-document="isUploadingDocument"
                  @onUploadDocument="uploadDocument"
                  @onDeleteDocument="deleteDocument"
                />
              </BaseCard>
              <div
                class="mt-6"
                style="max-width: 645px; margin: 0 auto; text-align: center;"
              >
                <p>Tamanho máximo para upload do arquivo: <b>4 MB.</b></p>
                <p>Extensões permitidas: <b>PDF, PNG e JPG</b>.</p>
              </div>
            </div>
          </BaseModal>
        </v-dialog>
      </template>
      <DocumentList
        :document-list="platformOrganizationDocuments"
        :organization-id="organizationId"
        context-actions="read"
      />
    </BaseCard>
  </div>
</template>

<script>
import BaseModal from '../../BaseModal'
import BaseCard from '../../BaseCard'
import DocumentList from './DocumentList'
import { utilsShowErrorInDevelopmentEnvironment } from '../../../utils'
import { mapActions, mapState } from 'vuex'
import ProgramTour from '../../../mixins/ProgramTour'

export default {
  name: 'PlatformOrganizationProfileDocuments',
  components: { BaseModal, BaseCard, DocumentList },
  mixins: [ProgramTour],
  data() {
    return {
      isUploadingDocument: false,
      isModalActive: false,
      organizationId: '',
      modalManageDocuments: {
        showIndeterminateProgress: false,
        topSlotContentTitle: "Documentos da organização",
        removeButtonClose: false,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: '',
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "",
        bottomSlotContentButtonConfirmLabel: "",
        removeButtonCancel: true,
        removeButtonConfirm: true,
        onCancel: () => this.isModalActive = false,
        onConfirm: null,
      },
      tourSteps: {
        "organization-documents": [
          {
            target: '[data-v-step="1"]',
            content: 'Para cadastrar os documentos da sua organização, utilize o botão <b>“Editar”</b> abaixo.',
            layoutStep: "simple",
            params: {
              placement: 'top'
            }
          }
        ],
        "program-documents": [
          {
            target: '[data-v-step="1"]',
            content: 'Os documentos ficarão disponíveis para assinatura assim que sua organização for aceita por um programa.',
            layoutStep: "simple",
            params: {
              placement: 'top'
            }
          }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      platformOrganizationDocuments: state => state.platformOrganization.platformOrganizationDocuments
    }),
    documentScope() {
      return this.$route.meta.scope
    },
    tourName() {
      return this.$route.path.split('/').reverse()[0]
    },
    dataSteps() {
      return this.tourSteps[this.$route.path.split('/').reverse()[0]]
    }
  },
  watch: {
    documentScope(){
      this.apiGetPlatformOrganizationDocuments({ organizationId: this.organizationId, scope: this.$route.meta.scope})
      this.startTour(this.tourName)
    }
  },
  created(){
    this.organizationId = this.$route.params.id
    this.apiGetPlatformOrganizationDocuments({ organizationId: this.organizationId, scope: this.$route.meta.scope})
  },
  mounted() {
    this.startTour(this.tourName)
  },
  methods: {
    ...mapActions('platformOrganization', ['apiGetPlatformOrganizationDocuments', 'apiUploadPlatformOrganizationPendingDocument', 'apiDeletePlatformOrganizationDocument']),
    updateModalActive(value) {
      this.isModalActive = value
    },
    async uploadDocument(item){
      this.isUploadingDocument = true
      let formData = new FormData();

      if(item.file){
        formData.append('file', item.file);
        formData.append('content-type', item.file.type);
        try {
          await this.apiUploadPlatformOrganizationPendingDocument({
            organizationId: this.organizationId,
            documentTypeId: item.document_type.id,
            formData
          })
          this.$emit('onSubmitForm', {
            message: 'Documento enviado com sucesso',
            color: 'success',
          })
        } catch(e) {
          const errorMsg = item.file.size > 4000000 ? 'O arquivo excede o tamanho máximo de 4MB' : 'Erro ao enviar documento'
          this.$emit('onSubmitForm', {
            message: errorMsg,
            color: 'error',
          })
          utilsShowErrorInDevelopmentEnvironment(e)
        } finally {
          this.isUploadingDocument = false
          item.file = null
          this.apiGetPlatformOrganizationDocuments({ organizationId: this.organizationId, scope: this.$route.meta.scope})
        }
      }
    },
    async deleteDocument(id){
      this.apiDeletePlatformOrganizationDocument({organizationId: this.organizationId, documentId: id})
      this.apiGetPlatformOrganizationDocuments({ organizationId: this.organizationId, scope: this.$route.meta.scope})
    }
  }
}
</script>

<style>
</style>
