import api from '../../services/api';

export default {
  state: {
    currentOperationInfo: null,
    isClickButtonFilterOperationRegister: false,
    listRegisterOperation: {
      executions: [],
      totalItems: 0
    },
    pagination: {
      page: 1,
      rowsPerPage: 5
    },
    filter: {
      initiator: '',
    },
    operationRegisterFilterInitiator: [],
  },
  getters: {
    getCurrentRegisterOperation: (state) => {
      return state.listRegisterOperation.executions.find((elem) =>
        elem.id === state.currentOperationInfo
      )
    },
  },
  mutations: {
    setCurrentOperationInfo(state, payload){
      state.currentOperationInfo = payload
    },
    setIsClickButtonFilterOperationRegister(state, payload){
      state.isClickButtonFilterOperationRegister = payload
    },
    setListResgisterOperation(state, payload){
      state.listRegisterOperation = payload
    },
    setPagination(state, payload){
      state.pagination = payload
    },
    setFilter(state, payload){
      state.filter = payload
    },
    setInitiatorCodeInListRegisterOperation(state, payload) {
      state.operationRegisterFilterInitiator = payload
    },
  },
  actions: {
    apiGetRegisterOperation({ state, commit }){
      return new Promise((resolve, reject) => {
        api.get('executions', {
          params: { ...state.pagination, ...state.filter }
        }).then((data) => {
          commit('setListResgisterOperation', data.data)
          resolve()
        }).catch(error => { reject(error) })
      })
    }
  }
}
