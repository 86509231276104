<template>
  <BaseCard
    class="mb-6"
    :has-main-container="true"
  >
    <template v-slot:header-title>
      Localização
    </template>
    <template v-slot:header-title-description>
      Informe o endereço completo da organização
    </template>
    <v-form
      :ref="formName"
      v-model="address.valid"
      :disabled="isFetchAddress"
    >
      <v-container class="px-6">
        <v-row
          dense
          align="center"
          style="max-width: 440px;"
        >
          <v-col
            cols="auto"
            sm="3"
          >
            <CepInput
              v-model="address.postal_code"
              label="CEP*"
              qa-identification-name="qa-id-name-address-postal-code"
              :error-messages="errorMessageWhenFetchCep"
            >
              <template v-slot:append>
                <LoadingInput :is-loading="isFetchAddress" />
              </template>
            </CepInput>
          </v-col>
          <v-col
            cols="auto"
            sm="9"
          >
            <BaseButton
              type="text"
              qa-identification-name="qa-id-name-address-btn-search"
              color="primary"
              :rules="[isValid]"
              :is-disabled="!postalCodeRules || isFetchAddress"
              @click="fetchAddress"
            >
              Buscar
            </BaseButton>
          </v-col>

          <v-col
            cols="auto"
            sm="12"
          >
            <BaseInput
              v-model="address.street"
              label="Endereço*"
              qa-identification-name="qa-id-name-address-street"
              :rules="[isValid]"
            />
          </v-col>

          <v-col
            cols="auto"
            sm="5"
          >
            <BaseInput
              v-model="address.number"
              label="Número*"
              qa-identification-name="qa-id-name-address-number"
              :rules="[isValid, isOnlyNumber]"
            />
          </v-col>
          <v-col
            cols="auto"
            sm="5"
            style="margin-right: 16.666666666666664%;"
          >
            <BaseInput
              v-model="address.complement"
              label="Complemento"
              qa-identification-name="qa-id-name-address-complement"
            />
          </v-col>

          <v-col
            cols="auto"
            sm="10"
            style="margin-right: 16.666666666666664%;"
          >
            <BaseInput
              v-model="address.neighborhood"
              label="Bairro*"
              qa-identification-name="qa-id-name-address-neighborhood"
              :rules="[isValid]"
            />
          </v-col>

          <v-col
            cols="auto"
            sm="10"
            style="margin-right: 16.666666666666664%;"
          >
            <BaseInput
              v-model="address.city"
              label="Cidade*"
              qa-identification-name="qa-id-name-address-city"
              :rules="[isValid]"
            />
          </v-col>

          <v-col
            cols="auto"
            sm="3"
          >
            <v-select
              v-model="address.state"
              :items="ufs"
              item-text="id"
              item-value="id"
              label="Estado"
              name="qa-id-name-address-state"
              :rules="[isValid]"
              required
            />
          </v-col>
        </v-row>
        <v-row
          dense
          justify="end"
        >
          <v-col cols="auto">
            <BaseButton
              color="primary"
              type="text"
              qa-identification-name="qa-id-name-address-cancel"
              @click="onCancelForm"
            >
              Cancelar
            </BaseButton>
            <BaseButton
              data-submit
              type="depressed"
              qa-identification-name="qa-id-name-address-confirm"
              color="primary"
              :is-disabled="!address.valid || address.noFocus"
              @click="submitAddressForm()"
            >
              Salvar
            </BaseButton>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </BaseCard>
</template>

<script>
import BaseCard from '../../BaseCard'
import CepInput from '../../Input/CepInput.vue'
import LoadingInput from '../../LoadingInput.vue'

import UtilsPlatformOrganizationProfileForm from '../../../mixins/UtilsPlatformOrganizationProfileForm'
import {utilsIsValidLength, getUfs} from '../../../utils'

import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  name: 'PlatformOrganizationProfileAddressForm',
  components: {BaseCard, CepInput, LoadingInput},
  mixins: [UtilsPlatformOrganizationProfileForm],
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formName: 'formAddOrUpdateAddress',
      ufs: (() => getUfs())(),
      address: {
        valid: false,
        postal_code: '',
        noFocus: true
      },
      isFetchAddress: false,
      errorMessageWhenFetchCep: ''
    }
  },
  computed: {
    ...mapState('platformOrganization', ['platformOrganizationAddress']),
    ...mapGetters('platformOrganization', ['hasPlatformOrganizationAddress']),
    postalCodeRules() {
      if (this.isValid(this.address.postal_code)) {
        if (utilsIsValidLength(this.address.postal_code, 9) === true) {
          return true
        }
      }

      return false
    },
    actionSubmitOrganizationAddress() {
      return this.hasPlatformOrganizationAddress ? 'PUT' : 'POST'
    },
  },
  methods: {
    ...mapActions('platformOrganization', [
      'apiGetPlatformOrganizationAddress', 'apiCreatePlatformOrganizationAddress',
      'apiUpdatePlatformOrganizationAddress',
    ]),
    ...mapActions(['getAddress']),
    updateOrganizationAddressFormFields() {
      if (this.hasPlatformOrganizationAddress) {
        const {
          street, number, complement, postal_code,
          neighborhood, type, city, state
        } = this.platformOrganizationAddress.content[0]

        Object.assign(this.address, {
          street, number, complement, postal_code,
          neighborhood, type, city, state, noFocus: true
        })
      }
    },
    async fetchAddress() {
      try {
        this.isFetchAddress = true
        const result = await this.getAddress(this.address.postal_code)

        if (!result) {
          this.showErrorMessageWhenFetchCep('CEP inválido')
          throw null
        } else {
          this.clearErrorMessageWhenFetchCep()
          const {
            cep: postal_code,
            bairro: neighborhood,
            complemento: complement,
            localidade: city,
            uf: state,
            logradouro: street
          } = result

          Object.assign(this.address, {
            postal_code,
            neighborhood,
            complement,
            city,
            state,
            street
          })
        }
      } finally {
        this.isFetchAddress = false
      }
    },
    async submitAddressForm() {
      try {
        const {...serializeAddress} = this.address
        const id = this.$route.params.id
        const data = {
          type: 'main',
          id,
          ...serializeAddress
        }

        if (this.actionSubmitOrganizationAddress === 'POST') {
          await this.apiCreatePlatformOrganizationAddress(data)

        } else {
          data.idAddress = this.platformOrganizationAddress.content[0].id
          await this.apiUpdatePlatformOrganizationAddress(data)
        }

        await this.apiGetPlatformOrganizationAddress(id)

        this.$emit('onSubmitAddress', {
          message: 'Endereço salvo com sucesso',
          color: 'success',
        })
        this.updateOrganizationAddressFormFields()
      } catch (e) {

        this.$emit('onSubmitAddress', {
          message: 'Erro ao salvar endereço',
          color: 'error',
        })
      }
    },
    onCancelForm() {
      this.cancelForm(this, this.formName)

      this.clearErrorMessageWhenFetchCep()
    },
    clearFields() {
      this.$refs[this.formName].reset()
      this.clearErrorMessageWhenFetchCep()
    },
    onFocusCallback() {
      this.address.noFocus = false
    },
    clearErrorMessageWhenFetchCep() {
      this.errorMessageWhenFetchCep = ''
    },
    showErrorMessageWhenFetchCep(errorMessage) {
      this.errorMessageWhenFetchCep = errorMessage
    }
  },
  watch: {
    active(isCurrentTrue) {
      if (isCurrentTrue) {
        this.updateOrganizationAddressFormFields()
      }
    }
  },
  created() {
    this.updateOrganizationAddressFormFields()
  }
}
</script>

<style>

</style>
