import Keycloak from 'keycloak-js'
import store from '../store'
import api from './api'
import intercom from './intercom'

const NODE_ENV = process.env.NODE_ENV

const GET_KEYCLOAK_ADDITIONAL_CONFIG = require(`./keycloak-additional-config.js`)[NODE_ENV]
const KEYCLOAK_ADDITIONAL_CONFIG = GET_KEYCLOAK_ADDITIONAL_CONFIG && GET_KEYCLOAK_ADDITIONAL_CONFIG()

const { VUE_APP_KEYCLOAK_URL, VUE_APP_KEYCLOAK_REALM, VUE_APP_KEYCLOAK_CLIENT_ID } = process.env

const KEYCLOAK_CONFIG = {
  realm: VUE_APP_KEYCLOAK_REALM,
  url: VUE_APP_KEYCLOAK_URL,
  clientId: VUE_APP_KEYCLOAK_CLIENT_ID,
  ...KEYCLOAK_ADDITIONAL_CONFIG
}

export default function keycloak(){

  const keycloak = new Keycloak(KEYCLOAK_CONFIG)

  return new Promise((resolve, reject) => {
    keycloak.init({ onLoad: 'login-required' }).then((auth) => {
      if(!auth) {
        reject()
        window.location.reload()
      }

      store.commit('setKeycloak', keycloak)

      intercom()

      api.defaults.headers.common['Authorization'] = "Bearer " + keycloak.token

      setInterval(() => {
        keycloak.updateToken(70).then(() => {
          api.defaults.headers.common['Authorization'] = "Bearer " + keycloak.token
        })
      },60000)

      resolve(keycloak)
    })
  })
}
