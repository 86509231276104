<template>
  <div class="base-drag-and-drop text-center">
    <div>
      <VueFileAgent
        ref="dragAndDropRef"
        v-model="files"
        :accept="uploadConfiguration.acceptFiles"
        :deletable="uploadConfiguration.deletable"
        :meta="true"
        :multiple="uploadConfiguration.multiple"
        :max-size="uploadConfiguration.maxSizeFile"
        :max-files="uploadConfiguration.maxFiles"
        :help-text="helpText"
        :error-text="errorText"
        theme="list"
        @select="onSelect($event)"
        @beforedelete="onDeleteFile($event)"
      />

      <BaseButton
        v-if="hasFileInDragAndDrop"
        color="primary"
        class="mt-6"
        :is-disabled="disableUploadButton"
        @click="onUpload"
      >
        Enviar
      </BaseButton>
    </div>
    <BaseSnackbar
      :active-snackbar="feedback"
      :type-snackbar="feedbackColor"
      :text-snackbar="feedbackMessage"
      @closeSnackbar="feedback = !feedback"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { utilsShowErrorInDevelopmentEnvironment } from '../utils'
import BaseSnackbarConfiguration from '../mixins/BaseSnackbarConfiguration'
import BaseSnackbar from './BaseSnackbar'

export default {
  name: 'BaseDragAndDrop',
  components: {BaseSnackbar},
  mixins:[ BaseSnackbarConfiguration ],
  props: {
    uploadConfiguration: {
      type: Object,
      default: () => {
        return {
          acceptFiles: "",
          deletable: true,
          multiple: false,
          maxSizeFile: "",
          maxFiles: "1"
        }
      }
    },
    integrationId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      files: [],
      disableUploadButton: false,
      hasFileInDragAndDrop: false,
      helpText: 'Clique ou arraste e solte um novo arquivo aqui',
      errorText: {
        type: 'O arquivo deve ser do formato especificado',
        size: `O tamanho máximo do arquivo deve ser de ${this.uploadConfiguration.maxSizeFile}`
      }
    }
  },
  methods: {
    ...mapActions(['apiGetProgramIntegrations', 'apiUploadIntegration']),
    onSelect(event) {
      this.disableUploadButton = false
      this.validateFiles(event)
      this.hasFileInDragAndDrop = true
    },
    validateFiles(files) {
      files.forEach(file => {
        if(file.error !== false) {
          this.disableUploadButton = true
          return
        }
      })
    },
    async onUpload() {

      if(this.files.length > 0) {
        try{
          for (let i = 0; i < this.files.length; i++) {
            let formData = new FormData()
            formData.append('file', this.files[i].file)
            await this.apiUploadIntegration({id: this.integrationId, type: 'upload', formData})
          }
          this.showFeedback({
            message: 'Arquivo enviado. Confira o resultado do processamento em seu e-mail.',
            color: 'info'
          })
        }catch(err) {
          utilsShowErrorInDevelopmentEnvironment(err)
          this.showFeedback({
            message: 'Ocorreu um erro ao cadastrar o arquivo.',
            color: 'error'
          })
        }finally {
          this.files = []
          this.hasFileInDragAndDrop = false
        }
      }
    },
    onDeleteFile(file) {
      const index = this.files.findIndex(element => element.lastModified === file.lastModified)
      this.files.splice(index, 1)
      if(this.files.length == 0) {
        this.hasFileInDragAndDrop = false
      }
    }
  }
}
</script>

<style scoped>

.base-drag-and-drop >>> .vue-file-agent  {
  width: 440px;
  margin-inline: auto;
}

</style>
