<template>
  <v-app-bar
    app
    fixed
    clipped-left
    clipped-right
    class="app-header px-0"
    :class="applyBoxShadow"
    :color="headerBg"
  >
    <v-app-bar-nav-icon
      v-if="isEnvelopePath"
      @click="onClickButtonMenuHeader"
    >
      <v-icon color="primary">
        menu
      </v-icon>
    </v-app-bar-nav-icon>
    <v-img
      :src="logo"
      contain
      height="30"
      max-width="138"
      width="138"
    />
    <h2
      class="headline font-weight-regular ml-4 mt-1"
      style="color: rgba(0, 0, 0, 0.54);"
    >
      {{ headerTitle }}
    </h2>
    <v-spacer />
    <v-toolbar-items style="align-items: center;">
      <BaseButton
        type="fab"
        :has-icon="true"
        :button-class="hideAllData ? 'button-default-style v-btn--active': 'button-default-style'"
        style="min-width: 40px; max-height: 35px;"
        @click="toogleHideAllData()"
      >
        <v-icon
          color="gray74"
        >
          {{ hideAllData ? 'mdi-eye-off' : 'mdi-eye' }}
        </v-icon>
      </BaseButton>

      <NotificationsMenu />
      <BaseMenu
        menu-options-icon="account_circle"
        :menu-options-text="this.$store.state.auth.keycloak.tokenParsed.name"
        :menu-options-subtitle="navigationContext == 'program' ? showOrganizationInfo.name : ''"
        :menu-options-itens="menuItensFiltered"
      />
      <BaseMenu
        menu-options-icon="apps"
        :menu-options-itens="formatingAllPrograms"
      />
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import brand from '../assets/logo-idtrust.svg'
import BaseMenu from '../components/BaseMenu'
import {mapState, mapActions, mapMutations} from "vuex"
import ProgramRoles from '../mixins/ProgramRoles'
import NotificationsMenu from '../components/NotificationsMenu'

export default {
  name: 'TheHeader',
  components: {
    BaseMenu, NotificationsMenu
  },
  mixins: [ProgramRoles],
  props: {
    isEnvelopePath: {
      type: Boolean,
      default: false
    },
    headerTitle: {
      type: String,
      required: true
    },
    headerBg: {
      type: String,
      required: true
    },
    headerUseBoxShadow: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      programLogo: null
    }
  },
  watch: {
    programLogoId(currValue){
      if(currValue == null){
        this.programLogo = null
      } else {
        this.getProgramLogo()
      }
    }
  },
  created() {
    this.brand = brand
    this.getProgramLogo()
    this.apiGetAllPrograms()
  },
  methods: {
    ...mapActions(['apiGetProgramLogo', 'apiGetAllPrograms']),
    ...mapMutations(['sethideAllData']),
    onClickButtonMenuHeader() {
      this.$emit('onClickButtonMenuHeader')
    },
    async getProgramLogo() {
      if(this.programLogoId !== null){
        this.programLogo = await this.apiGetProgramLogo({id: this.programLogoId, programInitiator: this.programInitiator, name: 'logo'})
      }
    },
    toogleHideAllData(){
      this.sethideAllData(!this.hideAllData)
    }
  },
  computed: {
    ...mapState({
      headerExtraMenu: (state) => {
        return state.theHeader.headerExtraMenu
      },
      allPrograms: (state) => {
        return state.platformProgram.allPrograms
      },
      programLogoId: (state) => {
        return state.platformProgram.programLogoId
      },
      programInitiator: (state) => {
        return state.platformProgram.programInitiator
      },
      programRoles: state => state.platformProgram.programRoles,
      navigationContext: state => state.theNavigation.navigationContext,
      hideAllData: state => state.platformProgram.hideAllData
    }),
    showOrganizationInfo() {
      return this.getActiveOrganization() ? this.getActiveOrganization() : {name: '', tax_number: ''}
    },
    menuItens(){
      return [
        {
          text: "Alterar organização",
          subtitle: `CNPJ ${this.$options.filters.maskCNPJ(this.showOrganizationInfo.tax_number)}`,
          execute: () => {
            this.$router.push({name: 'AssumeRole', params: {context: 'choose-organization'}}).catch(()=>{})
          },
          twoLine: true,
          hasDivider: true
        },
        {
          text: "Home",
          execute: () => {
            this.$router.push('/platform-home').catch(()=>{})
          }
        },
        {
          text: "Sair",
          execute: () => {
            this.$store.state.auth.keycloak.logout()
          }
        }
      ]
    },
    menuItensFiltered() {
      if(this.navigationContext == 'program' && this.programRoles.length > 0 && this.getRoleOrganizations().length > 1){
        return this.menuItens
      } else {
        return this.menuItens.filter((elem, index) => index !== 0)
      }
    },
    applyBoxShadow() {
      if (this.headerUseBoxShadow === false) {
        return 'elevation-0'
      } else {
        return null
      }
    },
    formatingAllPrograms() {
      return this.filterInvitesFromProgramList.map((elem) => {
        return {
          text: elem.name, execute: () => {
            window.location = `${elem.host}/#/trades`
          }
        }
      }).sort((a, b) => a.text.localeCompare(b.text))
    },
    filterInvitesFromProgramList() {
      return this.allPrograms.filter(
        elem => elem.is_owner || elem.enrollments.findIndex(enrollment => enrollment.status === 'ENROLLED' ) != -1
      )
    },
    logo() {
      return this.programLogo !== null ? this.programLogo : this.brand
    }
  }
}
</script>

<style scoped>
.app-header__icon {
  color: var(--custom-theme-black-054) !important
}
</style>
