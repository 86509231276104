<template>
  <v-menu
    v-model="menu"
    transition="scale-transition"
    offset-y
    min-width="290px"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <BaseInput
        :value="parseDate"
        :label="namePlaceholder"
        readonly
        :style="cssMaxWidth"
        :disabled="disabled"
        :qa-identification-name="qaIdName"
        @input="captureDate"
      >
        <template slot="prepend-inner">
          <v-icon
            v-if="!disabled"
            v-on="on"
          >
            event
          </v-icon>
        </template>
      </BaseInput>
    </template>
    <v-date-picker
      :value="dateValue"
      no-title
      :max="verifyMax"
      :min="min"
      :disabled="disabled"
      @input="captureDate"
    >
      <v-spacer />
      <BaseButton
        context-button="program"
        type="text"
        @click="menu = false"
      >
        Confirmar
      </BaseButton>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      menu: false,
    }
  },
  computed: {
    parseDate() {
      if (this.dateValue.length === 0) {
        return ''
      }

      const [year, month, day] = this.dateValue.split('-')

      return `${day}/${month}/${year}`
    },
    verifyMax() {
      return this.max === null ? null : this.max.length > 0 ? this.max : this.getCurrentDate()
    },
    cssMaxWidth() {
      return this.maxWidth === 0 ? "max-width: none;" : `max-width: ${this.maxWidth}px`
    }
  },
  props: {
    qaIdName: {
      type: String,
      required: false,
      default: ''
    },
    namePlaceholder: {
      type: String,
      required: true
    },
    dateValue: {
      type: String,
      required: true,
      default: ""
    },
    max: {
      type: String,
      default: (() => new Date().toLocaleString().substring(0, 10).split('/').reverse().join('-'))()
    },
    min: {
      type: String,
      default: null
    },
    maxWidth: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    captureDate(value) {
      this.$emit('on-input-date-picker', value)
    },
    getCurrentDate() {
      return new Date().toLocaleString().substring(0, 10).split('/').reverse().join('-')
    }
  },
}
</script>

<style>

</style>
