export default {
  methods: {
    onFocus(ev, callback) {
      const { target } = ev
      const tagName = target.tagName.toLowerCase()
      const valueOfAttributeType = target.getAttribute('type')

      if(tagName !== 'button' && valueOfAttributeType !== 'button'){
        callback()
      }
    }
  },
  mounted(){
    Array.from(this.$el.querySelectorAll('form')).forEach(elem => {
      const button = elem.querySelector('[data-submit]')

      if(button) {
        elem.addEventListener('focus', (ev) => this.onFocus(ev, this.onFocusCallback), true)
      }
    })
  }
}

