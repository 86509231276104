<template>
  <div class="text-center">
    <v-menu
      v-model="showMenu"
      offset-y
      max-width="550"
      min-width="550"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <BaseButton
          type="text"
          context-button="program"
          button-class="pa-2 ml-4 button-default-style"
          style="font-weight: 400!important; min-width: 24px;"
          v-on="on"
        >
          <v-badge
            :content="numberOfUnreadNotifications"
            :value="numberOfUnreadNotifications"
            color="red"
            :class="{ 'shake' : animateNotification }"
            overlap
          >
            <v-icon
              color="gray74"
              :class="{ 'rotate' : animateNotification }"
            >
              notifications
            </v-icon>
          </v-badge>
        </BaseButton>
      </template>

      <v-card
        class="overflow-y-auto"
        style="max-height: 400px"
      >
        <v-list class="pa-0">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="headline app-headline font-weight-bold">
                Notificações
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <div>
                <BaseButton
                  v-if="parseNotification.length"
                  type="text"
                  context-button="program"
                  color="primary"
                  button-class="pa-2 button-default-style"
                  style="font-weight: 400!important; min-width: 24px;"
                  @click="readAllNotifications(parseNotification)"
                >
                  Marcar todas como lidas
                </BaseButton>
                <BaseButton
                  v-if="navigationContext === 'program'"
                  type="text"
                  context-button="program"
                  color="primary"
                  button-class="pa-2 button-default-style"
                  style="font-weight: 400!important; min-width: 24px;"
                  to="/notifications"
                  @click="showMenu = false"
                >
                  Ver todas
                </BaseButton>
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-divider />

        <v-list
          v-if="parseNotification.length"
          class="pa-0"
        >
          <template v-for="(item, index) in parseNotification">
            <v-list-item
              :key="`item-${index}`"
              link
              @click="readNotification(item); showMenu = false"
            >
              <v-list-item-title>
                <span
                  style="font-size:12px;"
                  class="color--text-black-054"
                >
                  {{ item.createdAt | getDate }}
                  <span
                    v-if="navigationContext !== 'program'"
                    class="text-uppercase"
                  >
                    - {{ item.initiator }}
                  </span>
                </span>
                <br>
                {{ item.notification }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
        <div
          v-else
          class="text-center pa-10"
        >
          Você não tem notificações não lidas. <br><span v-if="navigationContext === 'program'">Acesse a central de notificações clicando em "VER TODAS" acima.</span>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from "vuex"
import  NotificationService from '../mixins/NotificationService'

export default {
  name: "NotificationsMenu",
  mixins: [ NotificationService ],
  data() {
    return {
      showMenu: false
    };
  },
  computed: {
    ...mapState({
      navigationContext: state => state.theNavigation.navigationContext,
      unreadNotifications: state => state.programNotifications.unreadNotifications,
    }),
    numberOfUnreadNotifications() {
      return this.unreadNotifications && this.unreadNotifications.length > 10 ? '10+' : this.unreadNotifications.length
    },
    parseNotification(){
      return this.unreadNotifications.map(notification => {
        const { id, message, createdAt } = notification
        const parseMessage = JSON.parse(message)
        return {
          id,
          createdAt,
          notification: parseMessage.notification,
          initiator: parseMessage.initiator,
          path: parseMessage.path
        }
      })
    }
  },
  mounted() {
    this.getUnreadNotifications();
  }
};
</script>
<style scoped>
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
  transform: translate3d(0, 0, 0);
}

.rotate {
  animation: rotate 2s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
  transform: rotate(0);
}

@keyframes rotate {
  10%,
  90% {
    transform: rotate(0);
  }

  20%,
  80% {
    transform: rotate(20deg);
  }

  30%,
  50%,
  70% {
    transform: rotate(0);
  }

  40%,
  60% {
    transform: rotate(-20deg);
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
