<template>
  <div class="platform-organization-profile-personal-data mb-4">
    <h4 class="platform-organization-profile-personal-data__title app-align-items-center mb-4">
      <v-icon>{{ icon }}</v-icon>
      <span class="ml-2">{{ title }}</span>
    </h4>
    <div
      v-if="hasDataTemplateSlot"
      class="text mb-0"
    >
      <slot name="data-template" />
    </div>
    <p
      v-else
      class="text mb-0"
    >
      <slot name="no-data-template" />
    </p>
  </div>
</template>

<script>
export default {
  name: "PlatformOrganizationProfilePersonalData",
  props: {
    icon: {
      type: String,
      default: '',
      required: false
    },
    title: {
      type: String,
      required: true
    },
    template: {
      type: String,
      required: true,
      validator(value){
        return ['data-template', 'no-data-template'].indexOf(value) !== -1
      }
    }
  },
  computed: {
    hasDataTemplateSlot(){
      return this.template === 'data-template'
    }
  }
}
</script>

<style scoped>
  .platform-organization-profile-personal-data__title {
    font-size: 18px;
  }
  .platform-organization-profile-personal-data .text {
    font-size: 0.875rem;
  }
</style>
