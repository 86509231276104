<template>
  <div class="platform-organization">
    <BaseTour
      :tour-name="tourName"
      :steps="dataSteps"
      :options="tourOptions"
      :start-on-mounted="false"
      @onFinish="finishTour"
    />
    <BaseBreadcrumb :config="$route.meta.breadcrumb" />
    <BaseContainer :active-spacer="true">
      <template v-slot:header-icon-title>
        domain
      </template>
      <template v-slot:header-title>
        {{ getPlatformOrganizationName }}
      </template>
      <template v-slot:header-title-description>
        {{
          platformOrganizationAddressCityAndState
        }}
      </template>
      <template v-slot:header-actions>
        <BaseButton
          v-if="hasAcceptedEnrollment"
          color="primary"
          @click="checkPendingRegistration"
        >
          <v-icon class="mr-2">
            insert_drive_file
          </v-icon> Verificar documentos
        </BaseButton>
      </template>
      <v-row>
        <v-col
          sm="3"
          xl="2"
          class="pa-0"
        >
          <v-list
            dense
          >
            <v-list-item-group
              v-model="selectedItem"
            >
              <v-list-item
                v-for="(item, index) in menuItems"
                :key="index"
                class="px-0"
              >
                <BaseLink
                  :to="`/platform-organization/${$route.params.id}/${item.to}`"
                  style="width: 100%; height: 100%; min-height:40px;"
                >
                  <v-list-item-content class="px-2 py-3">
                    <v-list-item-title
                      class="color--text-black-054"
                      v-text="item.name"
                    />
                  </v-list-item-content>
                </BaseLink>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>

        <v-col>
          <router-view @onSubmitForm="showFeedback" />
        </v-col>
      </v-row>
    </BaseContainer>
    <BaseSnackbar
      :active-snackbar="feedback"
      :text-snackbar="feedbackMessage"
      :type-snackbar="feedbackColor"
      @closeSnackbar="feedback = !feedback"
    />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'

import BaseBreadcrumb from '../../BaseBreadcrumb'
import BaseSnackbar from '../../BaseSnackbar'
import BaseSnackbarConfiguration from '../../../mixins/BaseSnackbarConfiguration'
import ProgramTour from '../../../mixins/ProgramTour'
import loader from '../../../global/loader'

export default {
  name: 'PlatformOrganization',
  components: { BaseBreadcrumb, BaseSnackbar },
  mixins : [ BaseSnackbarConfiguration, ProgramTour ],
  data() {
    return {
      selectedItem: 0,
      enrollmentStauts: null,
      menuItems : [
        {
          name: "Dados de cadastro",
          to: `profile`
        },
        {
          name: "Documentos da organização",
          to: 'organization-documents'
        },
        {
          name: "Documentos de programa",
          to: 'program-documents'
        },
        {
          name: "Sócios e administradores",
          to: 'partners'
        },
        {
          name: "Contas bancárias",
          to: `bank-account`
        }
      ],
      resultPendingDocuments: null,
      tourName: 'finishRegistration',
      dataSteps: [{
        target: '',
        header: {
          title: ''
        },
        content: '',
        layoutStep: '',
        headerImg: {
          src: '',
          width: ''
        }
      }],
      tourOptions: {
        labels: {
          buttonSkip: 'FECHAR',
          buttonPrevious: 'Anterior',
          buttonNext: 'Continuar',
          buttonStop: 'Voltar ao menu principal'
        },
        highlight: true
      }
    }
  },
  computed: {
    ...mapGetters('platformOrganization',
      ['platformOrganizationAddressCityAndState', 'getPlatformOrganizationName']),
    ...mapState({
      programList: state => state.platformProgram.allPrograms,
    }),
    hasAcceptedEnrollment(){
      return this.programList.find(program =>
        program.enrollments.find(enroll =>{
          if(enroll.organization.id === this.$route.params.id && (enroll.status === 'ACCEPTED' || enroll.status === 'CHANGES_REQUESTED')) {
            this.enrollmentStauts = enroll.status
            return true
          }
        })
      )
    },
  },
  async created() {
    this.changeSelectedItem()
    await this.apiGetAllPrograms()
  },
  methods: {
    ...mapActions('platformOrganization', ['apiCheckPendingRegistration','apiReviewOrganization']),
    ...mapActions(['apiGetAllPrograms']),
    changeSelectedItem(){
      const routePath = this.$route.path.split('/').reverse()[0]
      const routeIndex = this.menuItems.findIndex(el => el.to === routePath)
      this.selectedItem = routeIndex > -1 ? routeIndex : 0
    },
    finishTour(){
      if(this.resultPendingDocuments.length === 0){
        this.$router.push('/platform-home')
      }
    },
    formatPendingList(list, field){
      const pendingList = []

      list.map(el => {
        if(typeof(el[field]) === 'object'){
          pendingList.push(...el[field])
        } else {
          pendingList.push(el[field])
        }
      })

      return pendingList.filter((property, i) => pendingList.indexOf(property) === i)
    },
    async checkPendingRegistration() {
      const organizationId = this.$route.params.id
      try {
        this.resultPendingDocuments = await this.apiCheckPendingRegistration({organizationId})
        if(this.resultPendingDocuments.length === 0) {
          this.dataSteps = [{
            target: '#v-step-0',
            header: {
              title: 'Parabéns!'
            },
            content: 'Você cadastrou os documentos necessários. Caso esteja tudo certo, seu acesso será liberado assim que a equipe jurídica finalizar a validação da sua organização para o programa.',
            layoutStep: 'starter',
            headerImg: {
              src: 'icon-success-onboarding.png',
              width: '126'
            }
          }]

          await this.apiReviewOrganization({
            organizationId,
            action: this.enrollmentStauts === 'ACCEPTED' ? 'confirm' : 'verify',
            status: this.enrollmentStauts,
            data: {}
          })
          await this.apiGetAllPrograms()
          this.tourOptions.labels.buttonStop = 'Voltar ao menu principal'
          this.$tours[this.tourName].start()
        } else {
          this.dataSteps = [{
            target: '#v-step-0',
            header: {
              title: 'Documento faltando!'
            },
            content: `<div style="text-align:left">Para participar do(s) programa(s): <b>${this.formatPendingList(this.resultPendingDocuments, 'program').join(', ')}</b>, você precisa cadastrar os seguintes documentos: <p><br /><b>- ${this.formatPendingList(this.resultPendingDocuments, 'pending_items').join(';<br /> - ')}.</b></p></div>`,
            layoutStep: 'starter',
            headerImg: {
              src: 'icon-alert.png',
              width: '89'
            }
          }]
          this.tourOptions.labels.buttonStop = 'Ok'
          this.$tours[this.tourName].start()
        }
      } catch(e) {
        this.showFeedback({
          message: 'Erro ao verificar organização!',
          color: 'error'
        })
        loader.stopProgressLoadPage()
      }
    },
  }

}
</script>

<style scoped>
.platform-organization .v-list {
  background: transparent;
}

.v-list-item-group .v-list-item--active >>> .v-list-item__title{
  font-weight: bold;
}
</style>
