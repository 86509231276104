<template>
  <p
    class="d-inline-block text-truncate"
    :style="{'width': widthItem + 'px', 'min-width': minWidthItem, 'padding-top': '5px'}"
  >
    <v-tooltip
      v-if="textItem && ((textItem.length / 2) * 14 > widthItem)"
      bottom
    >
      <template
        v-slot:activator="{ on }"
      >
        <span
          v-on="on"
        >
          {{ textItem }}
        </span>
      </template>
      <span>{{ textItem }}</span>
    </v-tooltip>
    <span v-else>{{ textItem }}</span>
  </p>
</template>

<script>
export default {
  props:{
    textItem: {
      type: String,
      required: false,
      default: '-'
    },
    widthItem: {
      type: String,
      required: true
    },
    minWidthItem: {
      type: String,
      required: false,
      default: 'unset'
    }
  }
}
</script>

<style>
</style>
