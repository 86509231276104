<template>
  <BaseContainer
    :active-spacer="true"
    :permission-list="['admin']"
  >
    <template v-slot:header-title>
      {{ headerTitle }}
    </template>
    <template v-slot:header-title-description>
      {{ headerTitleDescription }}
    </template>
    <BaseDataTable
      :headers="tableHeaders"
      background-color="white"
      :hide-headers="true"
      :items="tableHeaders"
      item-key="id"
      classes="elevation-2 mt-6"
    >
      <template v-slot:inject-header="{headers}">
        <tr>
          <template
            v-for="(header, index) in headers.props.headers"
          >
            <th
              v-if="header.value != 'admin-config'"
              :key="index"
              style="white-space: nowrap;"
            >
              {{ translateRoleType(header.text) }}
            </th>
            <th
              v-if="header.value == 'admin-config'"
              :key="index"
              class="text-right"
            >
              <MenuConfigurationSchemaField
                key="configurationTableAsset"
                menu-title="Configurar"
                menu-align="left"
                name="configurationTableAsset"
                :config-list="programMovementDynamicAssetFields"
                :form-field-list="['name', 'hidden']"
                :scope-schema="scopeSchema"
                @removeItemSchema="removeItemSchema"
                @addOrEditItemSchema="addOrEditItemSchema"
                @changeOrderItemSchema="changeOrderItemSchema"
              />
            </th>
          </template>
        </tr>
      </template>

      <template
        v-slot:inject-items="scopedItems"
      >
        <tr
          v-for="props in scopedItems"
          :key="props.index"
          class="app-apply-blur"
        >
          <template
            v-for="header, index in tableHeaders"
          >
            <td
              v-if="header.value !== 'admin-config'"
              :key="index"
              class="text-left"
            >
              <template>
                {{ header.text || '-' }}
              </template>
            </td>
            <td
              v-if="header.value === 'admin-config'"
              :key="index"
              class="text-right"
            >
              <BaseButton
                type="text"
                color="blueff"
                class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0 text-decoration-underline"
                button-class="app-font-regular"
              >
                + detalhes
              </BaseButton>
            </td>
          </template>
        </tr>
      </template>
    </BaseDataTable>
  </BaseContainer>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import permissions from "@/mixins/permissions";
import ProgramRoles from '../../mixins/ProgramRoles'
import BaseDataTable from '../BaseDataTable'
import MenuConfigurationSchemaField from '../Platform/MenuConfigurationSchemaField'
import BaseSnackbarConfiguration from '../../mixins/BaseSnackbarConfiguration'

export default {
  components: { BaseDataTable, MenuConfigurationSchemaField },
  mixins: [ permissions, ProgramRoles, BaseSnackbarConfiguration ],
  props: {
    headerTitle: {
      type: String,
      required: true
    },
    headerTitleDescription: {
      type: String,
      required: true
    },
    scopeSchema: {
      type: String,
      required: false,
      default: ''
    },
  },
  data: () => ({
    tableHeaders: [],
  }),
  computed:{
    ...mapState({
      programMovementDynamicAssetFields: state => state.programMovements.programMovementDynamicAssetFields
    })
  },
  watch: {
    programMovementDynamicAssetFields: {
      handler(val) {
        this.parseHeadersAssetTable()
      },
      deep: true,
    }
  },
  async created() {
    await this.apiGetSchemas({ scope: this.scopeSchema })
    this.parseHeadersAssetTable()
  },
  methods: {
    ...mapActions('programMovements', ['apiGetSchemas']),
    ...mapActions(['apiGetSchemasFields', 'apiUpdateSchemaField', 'apiCreateSchemaField']),
    async removeItemSchema({ field, role, schemaName, programName }) {
      const payloadList = this.programMovementDynamicAssetFields.filter(elem => elem.key !== field.key)
      try {
        await this.apiUpdateSchemaField({
          name: schemaName,
          scope: this.scopeSchema,
          fields: payloadList,
          role: role,
          program: programName
        })
        this.showFeedback({
          message: 'Campo removido com sucesso!',
          color: 'success'
        })
      } catch (error) {
        this.showFeedback({
          message: 'Erro ao remover campo.',
          color: 'error'
        })
      } finally {
        await this.apiGetSchemasFields()
        await this.apiGetSchemas({ scope: this.scopeSchema, role })
        this.parseHeadersAssetTable()
      }
    },
    addOrEditItemSchema({key, role, schemaName, programName, data }){
      const payload = {
        name: schemaName,
        scope: this.scopeSchema,
        fields: [],
        role,
        program: programName
      }

      if(this.programMovementDynamicAssetFields.length) {
        const payloadList = this.programMovementDynamicAssetFields.filter(field => field.key !== key)
        payloadList.push(data)

        Object.assign(payload, {
          ...payload,
          fields: payloadList
        })
        this.updateSchema(payload)
      } else {
        Object.assign(payload, {
          ...payload,
          fields: [data]
        })
        this.createSchema(payload)
      }
    },
    async createSchema(payload) {
      try {
        await this.apiCreateSchemaField(payload)
      } catch (error) {
        this.showFeedback({
          color: "error",
          message: 'Erro ao criar schema!',
        })
      } finally {
        await this.apiGetSchemas({ scope: this.scopeSchema, role: payload.role })
        this.parseHeadersAssetTable()
      }
    },
    async updateSchema(payload){
      try {
        await this.apiUpdateSchemaField(payload)
      } catch (error) {
        this.showFeedback({
          color: "error",
          message: 'Erro ao atualizar schema!',
        })
      } finally {
        await this.apiGetSchemas({ scope: this.scopeSchema, role: payload.role })
        this.parseHeadersAssetTable()
      }
    },
    changeOrderItemSchema({ role, schemaName, programName, data }){
      const payload = {
        name: schemaName,
        scope: this.scopeSchema,
        fields: data,
        role,
        program: programName
      }
      this.updateSchema(payload)
    },
    parseHeadersAssetTable(){
      let tableHeaderFields = this.programMovementDynamicAssetFields || []
      let fields = tableHeaderFields.map(header => {
        return {
          hidden: header.hidden,
          text: header.name,
          value: header.key,
          class: header.key
        }
      })
      fields.push({
        hidden: false,
        text: '',
        value: 'admin-config',
        class: ''
      })
      this.tableHeaders = [...fields]
    },
  }
}
</script>

<style>

</style>