<template>
  <div class="notification-list">
    <BaseContainer
      :active-spacer="true"
    >
      <template v-slot:header-title>
        Central de notificações
      </template>
      <template v-slot:header-title-description>
        Lista com todas as notificações recebidas no programa.
      </template>
      <template v-slot:header-actions>
        <BaseButton
          v-if="dataNotificationList.length"
          type="text"
          context-button="program"
          color="primary"
          button-class="pa-2 ml-4 button-default-style"
          style="font-weight: 400!important; min-width: 24px;"
          @click="readAllNotifications(dataNotificationList)"
        >
          Marcar todas como lidas
        </BaseButton>
      </template>
      <BaseDataTable
        :items="dataNotificationList"
        :headers="headers"
        :total-items="notificationTotalElements"
        :options="pagination"
        :has-infinite-pagination="true"
        classes="elevation-2 mt-6"
        @update-pagination="updatePagination"
      >
        <template v-slot:inject-items="scopedItems">
          <tr
            v-for="props in scopedItems"
            :key="props.item.id"
            style="cursor: pointer;"
            @click="readNotification(props.item)"
          >
            <td>
              {{ props.item.notification }}
            </td>
            <td>
              {{ props.item.createdAt | getDate }}
              <v-icon :color="!props.item.read ? 'red' : 'grey'">
                noise_control_off
              </v-icon>
            </td>
          </tr>
        </template>
      </BaseDataTable>
    </BaseContainer>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import NotificationService from '../mixins/NotificationService'
import BaseDataTable from '../components/BaseDataTable'

export default {
  components: { BaseDataTable },
  mixins: [ NotificationService ],
  data: () => ({
    headers: [
      { text: 'Notificação', sortable: false },
      { text: 'Data', sortable: false, width: 120 }
    ],
    pagination: {
      page: 1,
      itemsPerPage: 25
    }
  }),
  computed: {
    ...mapState({
      notificationList: state => state.programNotifications.notificationList,
    }),
    notificationTotalElements() {
      return ((this.pagination.page + 1) * this.pagination.itemsPerPage) + 1
    },
    dataNotificationList(){
      return this.notificationList.content ? this.notificationList.content.map(notification => {
        const { id, message, createdAt, read } = notification
        const parseMessage = JSON.parse(message)

        return {
          id,
          createdAt,
          read,
          notification: parseMessage.notification,
          initiator: parseMessage.initiator,
          path: parseMessage.path
        }
      }) : []
    }
  },
  methods: {
    ...mapActions('programNotifications', ['apiGetNotifications']),
    updatePagination(pagination) {
      Object.assign(this.pagination, {...pagination})
      this.getNotifications(pagination.page, pagination.itemsPerPage, 'ALL', 'program')
    }
  }
}
</script>
