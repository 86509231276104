import { render, staticRenderFns } from "./AssetsCollection.vue?vue&type=template&id=0c675e12&scoped=true"
import script from "./AssetsCollection.vue?vue&type=script&lang=js"
export * from "./AssetsCollection.vue?vue&type=script&lang=js"
import style0 from "./AssetsCollection.vue?vue&type=style&index=0&id=0c675e12&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c675e12",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAutocomplete,VDivider,VIcon,VList,VListItemContent,VListItemSubtitle,VListItemTitle,VMenu,VNavigationDrawer,VSelect,VSpacer})
