<template>
  <v-dialog
    v-model="isModalActive"
    persistent
    content-class="modal-terms"
  >
    <h2 
      class="mb-4"
    >
      Termos de Uso
    </h2>
    <Warning
      v-if="termFooter == false"
      class="alert-terms"
      icon="warning"
      type="warning"
      theme="light"
    >
      <strong>Atenção!</strong> É necessário aceitar os termos de uso para continuar usando a plataforma.
    </Warning>
    <div 
      class="text-terms" 
      v-html="termsContent" 
    />
    <v-checkbox 
      v-if="termFooter == false"
      v-model="isAgreeChecked"
      class="mt-6 mb-0"
      label="Declaro que li e aceito os termos de uso"
    />
    <v-row
      dense
      justify="end"
      class="mt-6"
    >
      <v-col
        cols="auto"
      >
        <BaseButton
          v-if="termFooter == false"
          type="text"
          color="primary"
          @click="closePlatform()"
        >
          Fechar Plataforma
        </BaseButton>
        <BaseButton
          v-if="termFooter == false"
          type="depressed"
          color="primary"
          :is-disabled="!isAgreeChecked"
          @click="acceptTerms(isAgreeChecked)"
        >
          Aceitar
        </BaseButton>
        <BaseButton
          v-if="termFooter == true"
          type="depressed"
          color="primary"
          @click="closeModal()"
        >
          Fechar
        </BaseButton>
      </v-col>
    </v-row>
    <BaseSnackbar
      :active-snackbar="feedback"
      :type-snackbar="feedbackColor"
      :text-snackbar="feedbackMessage"
      @closeSnackbar="feedback = !feedback"
    />
  </v-dialog>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import Warning from '../Program/Warning'
import BaseSnackbar from '../BaseSnackbar'
import BaseSnackbarConfiguration from '../../mixins/BaseSnackbarConfiguration';

export default {
  components: {
    Warning,
    BaseSnackbar,
  },
  mixins:[ BaseSnackbarConfiguration ],
  props: {
    modalActive: {
      type: Boolean,
      required: true,
      default: false
    },
    termFooter: {
      type: Boolean,
      required: false
    }
  },
  data(){
    return {
      isAgreeChecked: false,
      isModalActive: this.modalActive,
      termsContent: ''
    }
  },

  computed: {
    ...mapState({
      keycloak: (state) => state.auth.keycloak.tokenParsed,
    }),

    closePlatform() {
      return this.$store.state.auth.keycloak.logout()
    }
  },

  watch: {
    modalActive: {
      handler(value) {
        this.isModalActive = value;
      },
      deep: true,
    },
  },

  async mounted(){
    try {
      const htmlContent = await fetch('/template-terms-of-use/terms-use-idtrust.html');
      this.termsContent = await htmlContent.text()
    } catch (error) {
      this.termsContent = 'Não foi possível carregar os termos de uso.'
    }
  },

  methods: {
    ...mapActions('programAuthz', ["apiUpdateTermsOfUse"]),

    async acceptTerms(value){
      try {
        await this.apiUpdateTermsOfUse({
          email: this.keycloak['email'],
          attributes: {
            acceptedTerms: value ? "true" : "false"
          }
        })
        this.closeModal()
      } catch (e) {
        this.showFeedback({
          color: "error",
          message: 'Erro ao aceitar o termo de uso!',
        })
      }
    },

    closeModal(){
      this.isModalActive = false
      this.$emit('update:modal-active', false);
    },
  }

}
</script>

<style lang="scss">
  .modal-terms {
    height: auto;
    width: 650px;
    padding: 40px;
    background-color: rgb(255, 255, 255);
  }
  .alert-terms {
    color: black!important;
    margin-top: 10px;
  }
  .text-terms {
    max-height: 300px;
    overflow-y: auto;
    padding: 16px;
  }
</style>