<template>
  <v-menu
    offset-y
    attach
    left
  >
    <template v-slot:activator="{ on }">
      <BaseButton
        :has-icon="true"
        class="ma-0"
        @click="stopTour()"
        v-on="on"
      >
        <v-icon>more_vert</v-icon>
      </BaseButton>
    </template>

    <v-list>
      <v-list-item
        v-for="menu, index in filteredMenuItens"
        :key="index"
        class="pa-0"
      >
        <BaseButton
          v-if="!menu.disableButton(organizationData)"
          type="text"
          :is-disabled="menu.disableButton(organizationData)"
          button-class="organizations-table-menu--button"
          @click="menu.execute(organizationData, parentOrgId)"
        >
          {{ menu.text }}
        </BaseButton>
        <v-tooltip
          v-else
          bottom
          open-on-hover
        >
          <template v-slot:activator="{ on }">
            <span
              class="pa-0 v-list-item"
              v-on="on"
            >
              <BaseButton
                type="text"
                :is-disabled="menu.disableButton(organizationData)"
                button-class="organizations-table-menu--button"
                @click="menu.execute(organizationData, parentOrgId)"
              >
                {{ menu.text }}
              </BaseButton>
            </span>
          </template>
          <span> {{ menu.tooltipText }}</span>
        </v-tooltip>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    menuItens: {
      type: Array,
      required: true
    },
    organizationData: {
      type: Object,
      required: true
    },
    parentOrgId: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    filteredMenuItens() {
      return this.menuItens.filter(menu => !menu.hideButton)
    }
  },
  methods: {
    stopTour() {
      this.$emit("onStopTour")
    }
  }
}
</script>

<style scoped>
  .organizations-table-menu--button {
    width: 100%!important;
    margin: 0px !important;
    color: var(--custom-theme-black-087) !important;
    display: flex!important;
    justify-content: flex-start!important;
  }

  .organizations-table-menu--button >>> span {
    font-weight: normal!important;
  }
</style>
