<template>
  <div class="platform-organization-list">
    <BaseTour
      :tour-name="tourName"
      :steps="dataSteps"
    />
    <BaseContainer :active-spacer="true">
      <template v-slot:header-title>
        Organizações
      </template>
      <template v-slot:header-title-description>
        Edite os dados das organizações, usuário e perfis.
      </template>
      <template v-slot:header-actions>
        <v-dialog
          v-model="isModalActive"
          max-width="560"
          persistent
        >
          <template v-slot:activator="{ on }">
            <BaseButton
              color="primary"
              v-on="on"
            >
              ADICIONAR MATRIZ
            </BaseButton>
          </template>
          <BaseModal
            :show-indeterminate-progress="modalSignStep.showIndeterminateProgress"
            :top-slot-content-title="modalSignStep.topSlotContentTitle"
            :remove-button-close="modalSignStep.removeButtonClose"
            :middle-slot-content-text-highlight="modalSignStep.middleSlotContentTextHighlight"
            :middle-slot-content-text="modalSignStep.middleSlotContentText"
            :middle-check-agree="modalSignStep.middleCheckAgree"
            :bottom-buttons-align="modalSignStep.bottomButtonsAlign"
            :bottom-slot-content-button-cancel-label="modalSignStep.bottomSlotContentButtonCancelLabel"
            :bottom-slot-content-button-confirm-label="modalSignStep.bottomSlotContentButtonConfirmLabel"
            :remove-button-cancel="modalSignStep.removeButtonCancel"
            :remove-button-confirm="modalSignStep.removeButtonConfirm"
            :custom-enable-bottom-button-confirm-label="valid"
            @on-cancel="modalSignStep.onCancel"
            @on-confirm="submitForm"
          >
            <template v-slot:inject-form>
              <p
                v-if="formAddOrganizationError"
                class="body-1 error--text"
              >
                {{ formAddOrganizationError }}
              </p>
              <v-form
                ref="formAddOrganization"
                v-model="valid"
              >
                <v-container class="pa-0">
                  <v-row dense>
                    <v-col
                      cols="auto"
                      sm="5"
                    >
                      <CnpjInput
                        v-if="!isAddingBranch"
                        v-model="cnpj"
                        label="CNPJ*"
                        class="mr-4"
                        :error-messages="formAddOrganizationErrorFields.cnpj"
                        @input="(value) => validateCnpjInput(value)"
                      >
                        <template v-slot:append>
                          <LoadingInput :is-loading="isSearchingOrganization" />
                        </template>
                      </CnpjInput>

                      <BaseInput
                        v-else
                        v-model="cnpj"
                        :prefix="maskBaseTaxNumber(baseTaxNumber)"
                        label="CNPJ*"
                        class="mr-4"
                        mask="####-##"
                        use-pattern="\D"
                        :error-messages="formAddOrganizationErrorFields.cnpj"
                        @input="(value) => validateCnpjInput(baseTaxNumber + value)"
                      >
                        <template v-slot:append>
                          <LoadingInput :is-loading="isSearchingOrganization" />
                        </template>
                      </BaseInput>
                    </v-col>
                    <v-col
                      cols="auto"
                      sm="12"
                    >
                      <BaseInput
                        ref="companyName"
                        v-model="companyName"
                        label="Razão social*"
                        class="mr-4"
                        :rules="[isValid]"
                        :disabled="disableCompanyNameField"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </template>
          </BaseModal>
        </v-dialog>
      </template>

      <BaseDataTable
        :items="organizations"
        :headers="['', '', '', '', '', '']"
        :hide-headers="true"
        :expand="expand"
        class="organizations-table"
        background-color="transparent"
        :data-v-step="0"
      >
        <template v-slot:inject-items="scopedItems">
          <tr
            v-for="value in scopedItems"
            :key="value.index"
            class="organizations-table-tr"
            :class="{'organizations-table-tr--is-expanded': value.isExpanded}"
          >
            <td style="width: 100%">
              <strong>{{ value.item.name }}</strong>
            </td>
            <td style="min-width: 82px">
              Matriz
            </td>
            <td style="min-width: 184px">
              CNPJ {{ value.item['tax_number'] | maskCNPJ }}
            </td>
            <td
              style="min-width:60px"
              class="text-right organizations-table-td__padding-none"
            >
              <MenuActionOrganization
                :organization-data="value"
                :menu-itens="menuItens"
                @onStopTour="stopTour(tourName)"
              />
            </td>
            <td class="organizations-table-td__padding-none">
              <BaseButton
                :ref="value.item.id"
                type="text"
                :has-icon="true"
                color="#264674"
                :is-disabled="!value.item.hasBranch"
              >
                <v-icon
                  v-if="value.isExpanded"
                  @click="value.expand(!value.isExpanded)"
                >
                  expand_less
                </v-icon>
                <v-icon
                  v-else
                  @click="showOrganizationBranches(value.item.id); value.expand(!value.isExpanded);"
                >
                  expand_more
                </v-icon>
              </BaseButton>
            </td>
          </tr>
        </template>
        <template v-slot:inject-expand="scopedItems">
          <BaseDataTable
            v-if="expandOrganizationsBranches[scopedItems.items.item.id]"
            :items="expandOrganizationsBranches[scopedItems.items.item.id]"
            :hide-headers="true"
            background-color="transparent"
            class="pl-12"
          >
            <template v-slot:inject-items="items">
              <tr
                v-for="field in items"
                :key="field.item.id"
              >
                <td style="width: 1136px; padding: 0px 10px !important;">
                  <strong>{{ field.item.name }}</strong>
                </td>
                <td
                  style="min-width: 82px"
                  class="text-right"
                >
                  Filial
                </td>
                <td style="min-width: 184px">
                  CNPJ {{ field.item['tax_number'] | maskCNPJ }}
                </td>
                <td
                  style="min-width:60px"
                  class="text-right"
                >
                  <MenuActionOrganization
                    :organization-data="field"
                    :parent-org-id="scopedItems.items.item.id"
                    :menu-itens="menuBranchItens"
                  />
                </td>
              </tr>
            </template>
          </BaseDataTable>
          <v-divider />
        </template>
      </BaseDataTable>

      <v-dialog
        v-model="isModalDeleteOrg"
        persistent
        max-width="560"
      >
        <BaseModal
          :show-indeterminate-progress="loadPage"
          :top-slot-content-title="modalDeleteOrg.topSlotContentTitle"
          :remove-button-close="modalDeleteOrg.removeButtonClose"
          :middle-slot-content-text-highlight="modalDeleteOrg.middleSlotContentTextHighlight"
          :middle-slot-content-text="modalDeleteOrg.middleSlotContentText"
          :middle-check-agree="modalDeleteOrg.middleCheckAgree"
          :is-middle-slot-content-text-centered="false"
          :bottom-buttons-align="modalDeleteOrg.bottomButtonsAlign"
          :bottom-slot-content-button-cancel-label="modalDeleteOrg.bottomSlotContentButtonCancelLabel"
          :bottom-slot-content-button-confirm-label="modalDeleteOrg.bottomSlotContentButtonConfirmLabel"
          :remove-button-cancel="modalDeleteOrg.removeButtonCancel"
          :remove-button-confirm="modalDeleteOrg.removeButtonConfirm"
          :custom-enable-bottom-button-confirm-label="!isLoading && modalDeleteOrg.middleCheckAgree.isChecked"
          @on-cancel="closeModalDeleteOrg()"
          @on-confirm="modalDeleteOrg.onConfirm"
          @input="(value) => checkAgree(value)"
        />
      </v-dialog>
    </BaseContainer>
    <BaseSnackbar
      :active-snackbar="feedback"
      :type-snackbar="feedbackColor"
      :text-snackbar="feedbackMessage"
      @closeSnackbar="feedback = !feedback"
    />
  </div>
</template>

<script>
import BaseDataTable from '../BaseDataTable'
import BaseModal from '../BaseModal'
import CnpjInput from '../Input/CnpjInput.vue'
import LoadingInput from '../LoadingInput'
import BaseSnackbarConfiguration from '../../mixins/BaseSnackbarConfiguration'
import BaseSnackbar from '../BaseSnackbar.vue'
import {utilsTranformValueToOnlyNumber, utilsShowErrorInDevelopmentEnvironment} from '../../utils/index'
import permissions from "@/mixins/permissions";
import {mapState, mapActions, mapMutations} from 'vuex'
import ProgramTour from '../../mixins/ProgramTour'
import MenuActionOrganization from './MenuActionOrganization'

export default {
  components: {BaseDataTable, BaseModal, BaseSnackbar, CnpjInput, LoadingInput, MenuActionOrganization},
  mixins: [ BaseSnackbarConfiguration, permissions, ProgramTour ],
  data() {
    return {
      isLoading: false,
      isAddingBranch: false,
      parentOrganizationId: null,
      baseTaxNumber: '',
      organizations: [],
      valid: false,
      companyName: '',
      cnpj: '',
      isModalActive: false,
      expand: false,
      expandOrganizationsBranches: {},
      formAddOrganizationError: '',
      formAddOrganizationErrorFields: {
        cnpj: []
      },
      modalSignStep: {
        showIndeterminateProgress: false,
        topSlotContentTitle: "Adicionar organização",
        removeButtonClose: false,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: `<p>Cadastre uma nova empresa à sua lista de organizações.</p>`,
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "Cancelar",
        bottomSlotContentButtonConfirmLabel: "Salvar",
        removeButtonCancel: false,
        removeButtonConfirm: false,
        onCancel: this.closeModal,
        onConfirm: null,
      },
      isModalDeleteOrg: false,
      modalDeleteOrg: {
        showIndeterminateProgress: true,
        topSlotContentTitle: "Excluir Organização",
        removeButtonClose: false,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: "",
        middleCheckAgree: {
          "text": "Estou ciente de que após clicar no botão confirmar, a ação não poderá ser desfeita.",
          "isChecked": false,
        },
        customEnableBottomButtonConfirmLabel: false,
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "Cancelar",
        bottomSlotContentButtonConfirmLabel: "Confirmar",
        removeButtonCancel: false,
        removeButtonConfirm: false,
        onConfirm: () => null
      },
      isSearchingOrganization: false,
      indexOrganization: '',
      tourName: 'organization',
      dataSteps: [
        {
          target: '#v-step-0',
          header: {
            title: 'Organizações'
          },
          content: 'Agora que você aceitou o convite para o programa, precisamos preencher algumas informações para que sua adesão possa ser avaliada.',
          layoutStep: "starter"
        },
        {
          target: '[data-v-step="0"]',
          header: {
            title: 'Gerenciar organizações'
          },
          content: 'Sempre que precisar gerenciar os dados e informações da sua organização, utilize o botão acima ',
          contentImg: {
            src: "icon-manage-org.png",
            height: "29"
          }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      platformOrganizations: (state) => {
        return state.platformOrganization.platformOrganizations
      },
      platformOrganizationBranches: (state) => {
        return state.platformOrganization.platformOrganizationBranches
      },
      loadPage: (state) => {
        return state.loadPage
      }
    }),
    disableCompanyNameField() {
      return this.isSearchingOrganization || this.formAddOrganizationErrorFields.cnpj.length > 0
    },
    menuItens() {
      return [
        {
          text: "Gerenciar",
          execute: (item) => {
            this.$router.push(`/platform-organization/${item.item.id}/profile`)
            this.setIsOrganizationBranch(false)
          },
          disableButton(item) {
            return false
          }
        },
        {
          text: "Adicionar filial",
          execute: (item) => {
            this.openModalToAddBranch(item.item.base_tax_number, item.item.id, item.index)
          },
          disableButton(item) {
            return false
          }
        },
        {
          text: "Excluir",
          execute: (item, parentOrgId) => {
            Object.assign(this.modalDeleteOrg, {
              ...this.modalDeleteOrg,
              middleSlotContentText: `<p class="mb-3">Tem certeza que deseja excluir ${item.item.name} - CNPJ: ${this.$options.filters.maskCNPJ(item.item.tax_number)}?</p>`,
              onConfirm: () => {
                this.deleteOrganization(item, parentOrgId)
              }
            })
            this.isModalDeleteOrg = true
          },
          tooltipText: "É necessário excluir as filiais desta organização.",
          disableButton(item) {
            return item.item.hasBranch
          },
          hideButton: !this.userHasGroup(['master'])
        }
      ]
    },
    menuBranchItens() {
      return [
        {
          text: "Gerenciar",
          execute: (item) => {
            this.$router.push(`/platform-organization/${item.item.id}`)
            this.setIsOrganizationBranch(true)
          },
          disableButton(item) {
            return false
          }
        },
        {
          text: "Excluir",
          execute: (item, parentOrgId) => {
            Object.assign(this.modalDeleteOrg, {
              ...this.modalDeleteOrg,
              middleSlotContentText: `<p class="mb-3">Tem certeza que deseja excluir ${item.item.name} - CNPJ: ${this.$options.filters.maskCNPJ(item.item.tax_number)}?</p>`,
              onConfirm: () => {
                this.deleteOrganization(item, parentOrgId)
              }
            })
            this.isModalDeleteOrg = true
          },
          disableButton(item) {
            return false
          },
          hideButton: !this.userHasGroup(['master'])
        }
      ]
    }
  },
  watch: {
    platformOrganizations: {
      handler() {
        this.addHasBranchInOrganizationData()
      },
      deep: true
    }
  },
  created() {
    this.addHasBranchInOrganizationData()
  },
  methods: {
    ...mapActions('platformOrganization',
      ['apiCreatePlatformOrganization', 'apiGetPlatformOrganizationsByTaxnumber', 'apiGetOrganizationBranches', 'apiUpdatePlatformOrganization', 'apiCreatePlatformOrganizationBranch', 'apiDeletePlatformOrganizationBranch', 'apiDeletePlatformOrganization', 'apiGetPlatformOrganizations']),
    ...mapMutations('platformOrganization', ['setIsOrganizationBranch']),
    clearFormAddOrganizationErrorFields(name) {
      this.updateFormAddOrganizationErrorFields(name, [])
    },
    checkAgree(value) {
      this.modalDeleteOrg.middleCheckAgree.isChecked = value
    },
    async deleteOrganization(item, parentOrgId){
      try {
        this.isLoading = true
        if(parentOrgId) {
          this.$refs[parentOrgId][0].$el.querySelector('button').click()
          await this.apiDeletePlatformOrganizationBranch({organizationId: parentOrgId, branchId: item.item.id})
          await this.addHasBranchInOrganizationData()
        } else {
          await this.apiDeletePlatformOrganization(item.item.id)
          await this.apiGetPlatformOrganizations()
        }
        this.closeModalDeleteOrg()
        this.showFeedback({color: "success", message: "Organização excluída com sucesso!"})
      } catch(e) {
        this.showFeedback({color: "error", message: e.response.data.message || "Erro ao excluir organização!"})
      } finally {
        this.isLoading = false
      }
    },
    maskBaseTaxNumber(value){
      return value.replace(/^(\d{2})(\d{3})(\d{3})$/,'$1.$2.$3/')
    },
    openModalToAddBranch(baseTaxNumber, parentId, index) {
      this.isModalActive = true
      this.isAddingBranch = true
      this.parentOrganizationId = parentId
      this.baseTaxNumber = baseTaxNumber
      this.indexOrganization = index
    },
    closeModalDeleteOrg(){
      this.isModalDeleteOrg = false
      this.checkAgree(false)
    },
    closeModal() {
      this.isModalActive = false
      this.isAddingBranch = false
      this.indexOrganization = ''
      this.setFormAddOrganizationError('')

      Object.keys(this.formAddOrganizationErrorFields).forEach(elem => {
        this.clearFormAddOrganizationErrorFields(elem, [])
      })

      this.$refs.formAddOrganization.reset()
    },
    async showOrganizationBranches(organizationId) {
      await this.apiGetOrganizationBranches(organizationId);
      this.$set(this.expandOrganizationsBranches, organizationId, this.platformOrganizationBranches)
    },
    async getOrganizationByTaxnumber(value) {
      try {
        this.isSearchingOrganization = true

        let result = await this.apiGetPlatformOrganizationsByTaxnumber(value)

        if (result.length > 0) {
          this.companyName = result[0].name;
          this.setErrorWhenOrganizationAlreadyExists()
        } else {
          this.companyName = '';
          this.setFormAddOrganizationError('')
          this.updateFormAddOrganizationErrorFields('cnpj', [])
        }

      } catch (error) {
        if (error.response.status === 400) {
          this.companyName = '';
          this.setErrorWhenOrganizationIsInvalid()
        }
      } finally {
        this.isSearchingOrganization = false;
      }
    },
    setErrorWhenOrganizationAlreadyExists() {
      this.setFormAddOrganizationError('Já existe uma organização cadastrada com este CNPJ.')
      this.updateFormAddOrganizationErrorFields('cnpj', ['CNPJ já existe'])
    },
    setErrorWhenOrganizationIsInvalid() {
      this.setFormAddOrganizationError('')
      this.updateFormAddOrganizationErrorFields('cnpj', ['CNPJ inválido'])
    },
    setFormAddOrganizationError(value) {
      this.formAddOrganizationError = value
    },
    async addHasBranchInOrganizationData(){
      const organizationList = []
      let result = {}
      for(let i in this.platformOrganizations) {
        try {
          result = await this.apiGetOrganizationBranches(this.platformOrganizations[i].id)
        } catch(err) {
          utilsShowErrorInDevelopmentEnvironment(err)
        } finally {
          organizationList.push({
            ...this.platformOrganizations[i],
            hasBranch: !!result && result.data.content.length > 0 ? true : false
          })
        }
      }
      this.organizations = organizationList.sort((a, b) => a.name.localeCompare(b.name))
    },
    async submitForm() {
      try {
        const payload = {
          name: this.companyName,
          tax_number: utilsTranformValueToOnlyNumber(this.cnpj)
        }

        if(!this.isAddingBranch){
          const result = await this.apiCreatePlatformOrganization(payload)
          this.$router.push(
          `platform-organization/${result.headers.location.split('/').reverse()[0]}`)
          this.closeModal()
        } else {
          await this.apiCreatePlatformOrganizationBranch({id: this.parentOrganizationId, ...payload, tax_number: utilsTranformValueToOnlyNumber(this.baseTaxNumber + this.cnpj)})
          await this.showOrganizationBranches(this.parentOrganizationId)
          this.showFeedback({color: "success", message: "Filial adicionada com sucesso!"})
          this.$set(this.organizations[this.indexOrganization], 'hasBranch', true)
          this.closeModal()
        }
      } catch (error) {
        switch (error.response.status) {
          case 409:
            this.setErrorWhenOrganizationAlreadyExists()
            break
          default:
            this.setFormAddOrganizationError('Erro ao criar organização.')
        }
      }
    },
    updateFormAddOrganizationErrorFields(name, errors) {
      Object.assign(this.formAddOrganizationErrorFields, {[name]: errors})
    },
    validateCnpjInput(data) {
      if (data) {
        this.getOrganizationByTaxnumber(utilsTranformValueToOnlyNumber(data))
      }
    }
  }
}
</script>

<style scoped>
 .organizations-table >>> .organizations-table-tr.organizations-table-tr--is-expanded + tr:hover {
    background-color: transparent !important;
  }

  .organizations-table .organizations-table-td__padding-none {
    padding: 0px !important;
  }

  .organizations-table-menu--button {
    width: 100%;
    margin: 0px !important;
    color: var(--custom-theme-black-087) !important;
    display: flex;
    justify-content: flex-start;
  }

  .organizations-table-menu--button >>> span {
    font-weight: normal;
  }
</style>
