import api from '../../services/api';
import router from '../../router'
import intercom from '../../services/intercom'

export default {
  state: {
    allPrograms: [],
    selectedProgram: null,
    programRoles: [],
    activeOrganization: {},
    assetTypes: [],
    selectedAssetTypes: [],
    urlProgramLogo: null,
    programId: null,
    programDocuments: [],
    programSelectedRoles: [],
    programDocumentTypes: [],
    programIntegrations: [],
    programRoleTypes: [],
    selectedAssets: [],
    programLogoId: null,
    programInitiator: null,
    programLogo: null,
    programInfo: null,
    programOwners: [],
    schemasFields: [],
    hideAllData: true
  },
  getters: {
    getActiveRole: state => state.activeOrganization.active_role,
  },
  mutations: {
    setAllPrograms(state, payload) {
      state.allPrograms = payload
    },
    setSelectedProgram(state, payload) {
      state.selectedProgram = payload
    },
    setProgramRoles(state, payload) {
      state.programRoles = payload
    },
    setActiveOrganization(state, payload) {
      state.activeOrganization = payload
    },
    setSelectedAssetTypes(state, payload) {
      state.selectedAssetTypes = payload
    },
    setAssetTypes(state, payload) {
      state.assetTypes = payload
    },
    setUrlProgramLogo(state, payload) {
      state.urlProgramLogo = payload
    },
    setProgramId(state, payload) {
      state.programId = payload
    },
    setProgramDocuments(state, payload) {
      state.programDocuments = payload
    },
    setProgramSelectedRoles(state, payload) {
      state.programSelectedRoles = payload
    },
    setProgramRoleTypes(state, payload) {
      state.programRoleTypes = payload
    },
    setProgramDocumentTypes(state, payload) {
      state.programDocumentTypes = payload
    },
    setProgramIntegrations(state, payload) {
      state.programIntegrations = payload
    },
    setSelectedAssets(state, payload) {
      state.selectedAssets = payload
    },
    setProgramLogoId(state, payload) {
      state.programLogoId = payload
    },
    setProgramInitiator(state, payload) {
      state.programInitiator = payload
    },
    setProgramLogo(state, payload) {
      state.programLogo = payload
    },
    setProgramInfo(state, payload) {
      state.programInfo = payload
    },
    setProgramOwners(state, payload) {
      state.programOwners = payload
    },
    setSchemasFields(state, payload){
      state.schemasFields = payload
    },
    sethideAllData(state, payload) {
      state.hideAllData = payload
    },
  },
  actions: {
    async apiGetAllPrograms({ commit }, payload) {
      const data = await api.get(`/programs`)
      commit("setAllPrograms", data.data)
    },
    async apiSendProgramInvitation({ commit }, payload) {
      const { programId, accountId, ...data } = payload
      await api.post(`/programs/${programId}/invite`, data)
    },
    async apiAcceptOrRejectedProgramInvitation({ commit }, payload) {
      const { programId, ...data } = payload
      await api.put(`/programs/${programId}/accept`, data)
    },
    async apiGetProgramRoles({ commit }) {
      const data = await api.get(`/programs/roles`)
      const activeOrganization = {
        active_organization_id: data.data.active_organization_id,
        active_role: data.data.active_role
      }
      commit("setProgramRoles", data.data.roles)
      commit("setActiveOrganization", activeOrganization)
      intercom()
    },
    async apiGetProgramRoleTypes({ commit }) {
      const data = await api.get(`/programs/role_types`)
      commit("setProgramRoleTypes", data.data)
    },
    async apiGetCheckProgramUri({ commit }, payload) {
      const data = await api.get(`/programs/check`, {
        params: { subdomain: payload },
        validateStatus: (status) => status == 200 || status == 404
      })
      return data.status
    },
    async apiGetSelectedAssetTypes({ commit }, payload) {
      const data = await api.get(`/programs/${payload}/asset_types`)
      commit("setSelectedAssetTypes", data.data)
      const listOfNames = data.data ? data.data.map(asset => asset.name) : []
      commit("setSelectedAssets", listOfNames)
    },
    async apiGetAssetTypes({ commit }) {
      const data = await api.get(`/platforms/asset_types`)
      commit("setAssetTypes", data.data)
    },
    async apiSendProgramLogo({ commit }, payload) {
      const { id, file } = payload
      const response = await api.post(`/programs/${id}/logo`, file, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response.data.id
    },
    async apiUpdateProgramAssetList({ commit }, payload) {
      const { programId, asset_type } = payload
      await api.put(`programs/${programId}/asset_types`, asset_type)
    },
    async apiDeleteProgramAssetList({ commit }, payload) {
      const { programId, asset_type } = payload
      await api.delete(`programs/${programId}/asset_types`, asset_type)
    },
    async apiSendProgramConfiguration({ commit }, payload) {
      const { programId, ...data } = payload
      await api.put(`/programs/${programId}`, data)
    },
    async apiCreateProgram({ commit }, payload) {
      const data = await api.post(`/programs`, payload)
      commit("setProgramId", data.headers.location.split('/').reverse()[0])
    },
    async apiGetAllowedRoles({ commit }, payload) {
      const data = await api.get(`/platforms/allowed_roles`, { params: payload })
      return data
    },
    async apiUpdateAllowedRoles({ commit }, payload) {
      const { programId, ...data } = payload
      await api.put(`/programs/${programId}/roles`, data)
    },
    async apiGetProgramSelectedRoles({ commit }, payload) {
      const data = await api.get(`/programs/${payload}/roles`)
      commit("setProgramSelectedRoles", data.data)
      return data.data
    },
    async apiGetProgramDocumentTypes({ commit }, payload) {
      const data = await api.get(`/programs/${payload}/document_types`)
      commit("setProgramDocumentTypes", data.data)
      return data.data
    },
    async apiGetDocumentOptionsByScope({ commit }, payload) {
      const data = await api.get(`/platforms/document_types`, { params: payload })
      return data.data
    },
    async apiAddProgramDocumentRequired({ commit }, payload) {
      const { programId, ...data } = payload
      const result = await api.put(`/programs/${programId}/document_types`, data)
      return result.data
    },
    async apiUpdateProgramDocumentRequired({ commit }, payload) {
      const { programId, ...data } = payload
      await api.patch(`/programs/${programId}/document_types`, data)
    },
    async apiDeleteProgramDocumentRequired({ commit }, payload) {
      const { programId, typeId } = payload
      await api.delete(`/programs/${programId}/document_types/${typeId}`)
    },
    async apiGetProgramDocuments({ commit }, payload) {
      const data = await api.get(`/programs/${payload}/documents`)
      commit("setProgramDocuments", data.data)
    },
    async apiCreateProgramDocuments({ commit }, payload) {
      const { programId, ...data } = payload
      await api.put(`/programs/${programId}/documents`, data)
    },
    async apiUpdateProgramDocuments({ commit }, payload) {
      const { programId, ...data } = payload
      await api.patch(`/programs/${programId}/documents`, data)
    },
    async apiDeleteProgramDocuments({ commit }, payload) {
      const { programId, documentId } = payload
      await api.delete(`/programs/${programId}/documents/${documentId}`)
    },
    async apiUploadProgramDocument({ commit }, payload) {
      const data = await api.post(`/documents/${payload.programInitiator}`, payload.formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return data.headers.location.split('/').reverse()[0]
    },
    async apiUploadIntegration({ commit }, payload) {
      const { id, type, formData } = payload
      const data = await api.post(`/integrations/${type}/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    async apiGetProgramIntegrations({ commit }, payload) {
      const data = await api.get(`/integrations`, { params: payload })
      commit("setProgramIntegrations", data.data)
    },
    async apiGetProgramInfo({ commit }) {
      const response = await api.get('/self')
      const logoId = response.data ? response.data.logo_uri.replace('storage://', '') : null
      commit("setProgramInfo", response.data)
      commit("setProgramLogoId", logoId)
      commit("setProgramInitiator", response.data.initiator)
    },
    async apiGetProgramLogo({ commit }, payload) {
      const response = await api.get(`/documents/${payload.programInitiator}/${payload.id}`, { responseType: 'blob' })
      const data = response.data.size !== 0 ? URL.createObjectURL(new Blob([response.data], { type: 'image/png' })) : null
      commit("setProgramLogo", data)
      return data
    },
    async apiGetProgramOwners({commit}, payload) {
      const response = await api.get(`/programs/${payload}/owners`)
      commit("setProgramOwners", response.data)
    },
    async apiUpdateProgramOwners({ commit }, payload) {
      const { programId, data } = payload
      await api.put(`/programs/${programId}/owners`, data)
    },
    async apiGetSchemasFields({ commit }, payload){
      const path = payload ? `/configs/fields?q=${ payload }` : `/configs/fields`
      const response = await api.get(path)
      commit("setSchemasFields", response.data)
      return response
    },
    async apiUpdateSchemaField({commit}, payload) {
      const { scope, role, name, fields, program } = payload
      await api.put(`configs/schemas/${scope}?role=${role}`, { name, scope, program, fields })
    },
    async apiCreateSchemaField({commit}, payload) {
      const { scope, role, name, fields, program } = payload
      await api.post(`configs/schemas/${scope}?role=${role}`, { name, scope, program, fields })
    },
    async apiDeleteSchemaField({ commit }, payload) {
      const { schemaId } = payload
      await api.delete(`configs/schemas/${schemaId}`)
    }
  }
}
