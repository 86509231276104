<template>
  <div class="incoming-files-list">
    <BaseContainer>
      <div class="app-align-items-center">
        <div class="mr-3">
          <BaseDatePicker
            name-placeholder="Data Inicial"
            :date-value="filter.startDate"
            :max="filter.endDate"
            :max-width="118"
            locale="pt-br"
            class="mr-3"
            time-zone="UTC"
            @on-input-date-picker="(value) => { updateFilter(value, 'startDate') }"
          />
        </div>
        <div class="mr-3">
          <BaseDatePicker
            name-placeholder="Data Final"
            :date-value="filter.endDate"
            :max-width="118"
            locale="pt-br"
            time-zone="UTC"
            @on-input-date-picker="(value) => { updateFilter(value, 'endDate') }"
          />
        </div>
        <BaseButton
          context-button="program"
          type="text"
          color="primary"
          @click="filterReportFiles"
        >
          FILTRAR
        </BaseButton>
      </div>
      <BaseDataTable
        :headers="headers"
        background-color="transparent"
        :items="reportFiles"
        item-key="id"
        classes="mt-6"
        @update-pagination="updatePagination"
      >
        <template v-slot:inject-items="scopedItems">
          <tr
            v-for="props in scopedItems"
            :key="props.index"
          >
            <td class="font-weight-bold">
              {{ props.item.createdAt | getDate }} - {{ props.item.createdAt | getTime }}
            </td>
            <td class="text-right">
              {{ props.item.fileName }}
            </td>
            <td class="text-right">
              <BaseButton
                context-button="program"
                color="primary"
                :has-icon="true"
                @click="downloadFile({uuid: props.item.uuid, fileName: props.item.fileName})"
              >
                <v-icon>cloud_download</v-icon>
              </BaseButton>
            </td>
          </tr>
        </template>
      </BaseDataTable>
    </BaseContainer>
  </div>
</template>

<script>

import {mapState, mapActions} from 'vuex'
import BaseContainer from '../../BaseContainer'
import BaseDatePicker from '../../BaseDatePicker'
import BaseDataTable from '../../BaseDataTable'
import loader from '../../../global/loader'

export default {
  components: {BaseContainer, BaseDatePicker, BaseDataTable},
  data: () => ({
    filter: {
      startDate: new Date(),
      endDate: new Date(),
      prefix: 'report-files'
    },
    headers: [
      {text: 'Data e Hora', value: 'createdAt', width: 160},
      {text: 'Nome', value: 'fileName', width: '100%', align: 'right'},
      {text: '', value: 'action', width: 30, align: 'center', sortable: false}
    ]
  }),
  computed: {
    ...mapState({
      reportFiles: state => state.incomingFiles.reportFiles
    })
  },
  watch: {
    filter: {
      handler() {
        if (new Date(this.filter.startDate).getTime() > new Date(this.filter.endDate).getTime()) {
          this.filter.endDate = ''
        }
      },
      deep: true
    }
  },
  created() {
    this.filter['startDate'] = this.toLocalDate(
      this.minusDays(new Date(), 7)).toISOString().substr(0, 10);
    this.filter['endDate'] = this.toLocalDate(new Date()).toISOString().substr(0, 10);
  },
  mounted() {
    this.filterReportFiles()
  },
  methods: {
    ...mapActions(['apiGetProgramFiles', 'apiGetDownloadFile']),
    updatePagination: function (pagination) {
      this.filter.page = pagination.page,
      this.filter.size = pagination.itemsPerPage
      this.filterReportFiles()
    },
    updateFilter(value, attribute) {
      this.filter[attribute] = value
    },
    async filterReportFiles() {
      loader.startProgressLoadPage()
      await this.apiGetProgramFiles(this.filter)
      loader.stopProgressLoadPage()
    },
    toLocalDate(date) {
      return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    },
    minusDays(date, days) {
      return new Date(date.setDate(date.getDate() - days))
    },
    downloadFile(payload) {
      this.apiGetDownloadFile(payload)
    }
  }
}
</script>
