<template>
  <div class="assets-collection">
    <v-navigation-drawer
      :value="showAssetsCollection"
      app
      right
      clipped
      floating
      class="px-4 mb-12 pt-7"
      color="#f1f1f1"
      :width="tradeType == 'dynamic' && !draftMovementId ? '50%' : '495'"
      mobile-breakpoint="990"
      disable-resize-watcher
    >
      <BaseButton
        :has-icon="true"
        class="mb-6"
        :class="{ 'app-apply-blur' : isAdminEditing }"
        @click="hideAssetsCollection"
      >
        <v-icon>close</v-icon>
      </BaseButton>

      <template v-if="tradeType == 'dynamic'">
        <DynamicTradeCriteria
          v-if="!draftMovementId"
        />
        <template v-if="draftMovementId && !showAssetsCollectionConfirm">
          <div class="color--text-black-054">
            <h2>Negociação Simulada</h2>
            <p class="body-1">
              Simulação criada de acordo com os critérios selecionados
            </p>
            <v-divider class="my-4" />
          </div>
          <div class="app-align-items-center mb-6">
            <v-spacer />
            <div
              class="d-flex text-caption text-right"
              :class="{ 'app-apply-blur' : isAdminEditing }"
            >
              <div>
                <p class="font-weight-bold primary--text">
                  Valor total selecionado
                </p>
                <p class="font-weight-bold primary--text text-h4">
                  {{ programDraftMovementAmount | formatCurrency }}
                </p>
              </div>
            </div>
          </div>

          <Warning
            v-if="showReportMessage"
            icon="notifications"
            type="info"
            class="ma-1"
          >
            O relatório está sendo gerado e em breve estará disponível na página de Relatórios,
            <a
              @click="goToReports()"
            >
              clique aqui para ir
            </a>.
          </Warning>

          <div class="app-align-items-center">
            <v-autocomplete
              v-if="destinationField && destinationField.datasource && destinationField.datasource.includes('members')"
              id="qa-id-name-organization-list"
              v-model="fidcSelected"
              :items="fidcList"
              clearable
              item-value="id"
              item-text="nameAndTaxnumber"
              return-object
              :label="destinationField.name"
              hint="Filtre por Razão Social ou CNPJ"
              no-data-text="Organização não encontrada"
              persistent-hint
              :rules="[isValid]"
              style="max-width: 350px;"
              class="my-6"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.tax_number | maskCNPJ }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <v-select
              v-else
              v-model="fidcSelected"
              :items="fidcList"
              item-text="name"
              item-value="value"
              return-object
              :label="destinationField.name"
            />
            <v-spacer />
            <BaseButton
              type="text"
              color="blueff"
              class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0 text-decoration-underline text-uppercase"
              button-class="app-font-regular"
              :is-disabled="loadPage"
              @click="executeExportData"
            >
              Exportar
            </BaseButton>
          </div>
          <h4 class="mt-4 color--text-black-054">Segmentação de ativos e valor</h4>
        </template>
      </template>

      <template v-if="tradeType == 'select'">
        <template v-if="draftMovementId && !showAssetsCollectionConfirm">
          <div class="app-align-items-center mb-6">
            <v-spacer />
            <div
              class="d-flex text-caption text-right"
              :class="{ 'app-apply-blur' : isAdminEditing }"
            >
              <div>
                <p class="font-weight-bold primary--text">
                  Valor da negociação
                </p>
                <p class="font-weight-bold primary--text text-h4">
                  {{ programDraftMovementAmount | formatCurrency }}
                </p>
              </div>
              <div class="align-self-end pl-2">
                <v-icon class="assets-collection__header--icon primary--text">
                  storefront
                </v-icon>
              </div>
            </div>
          </div>
          <Warning
            v-if="!hasBankAccount"
            class="mx-1"
            icon="warning"
            type="warning"
            theme="light"
          >
            <p :class="{ 'app-apply-blur' : isAdminEditing }">
              <strong>Conta bancária:</strong> Cadastre uma conta
              bancária no perfil da sua organização antes de realizar uma negociação.
              <a
                class=" app-font-semibold text-decoration-underline"
                :href="createLinkToBankAccount"
              >
                Cadastrar conta.
              </a>
            </p>
          </Warning>

          <v-menu
            v-else-if="hasBankAccount && accountSelected"
            v-model="menu"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                style="font-size: 12px"
                class="d-flex assets-collection__accounts-menu color--text-black-054 pa-1 mx-1"
              >
                <div class="d-flex align-start">
                  <v-icon>account_balance_wallet</v-icon>
                  <span class="font-weight-bold">Conta Desembolso</span>
                </div>
                <v-spacer />
                <div
                  class="d-flex flex-column align-end pr-6"
                  :class="{ 'app-apply-blur' : isAdminEditing }"
                >
                  <p>Código Banco</p>
                  <p
                    class="body-2 font-weight-bold truncate-content-text"
                    style="max-width: 88px;"
                  >
                    {{ getBankCodeAndName(accountSelected) }}
                  </p>
                </div>
                <div
                  class="d-flex flex-column align-end pr-6"
                  :class="{ 'app-apply-blur' : isAdminEditing }"
                >
                  <p>Agência</p>
                  <p class="body-2 font-weight-bold">
                    {{ getAgencyAndDigit(accountSelected) }}
                  </p>
                </div>
                <div
                  class="d-flex flex-column align-end"
                  :class="{ 'app-apply-blur' : isAdminEditing }"
                >
                  <p>Conta</p>
                  <p class="body-2 font-weight-bold">
                    {{ getNumberAndDigit(accountSelected) }}
                  </p>
                </div>
                <BaseButton
                  type="text"
                  context-button="program"
                  button-class="my-0"
                  :has-icon="true"
                  v-bind="attrs"
                  :class="{ 'app-apply-blur' : isAdminEditing }"
                  v-on="on"
                >
                  <v-icon color="primary">
                    expand_more
                  </v-icon>
                </BaseButton>
              </div>
            </template>
            <v-list>
              <template v-for="bankInfo in programBankAccounts">
                <div
                  :key="bankInfo.id"
                  style="font-size:12px"
                  class="d-flex assets-collection__accounts-menu--select color--text-black-054 justify-end px-4 py-1"
                  @click="selectAccount(bankInfo)"
                >
                  <BaseDataBank
                    :has-icon="false"
                    :bank-value="getBankCodeAndName(bankInfo)"
                    :agency-value="getAgencyAndDigit(bankInfo)"
                    :account-value="getNumberAndDigit(bankInfo)"
                    style="border: none; width: 100%; padding: 0px!important"
                  />
                </div>
              </template>
            </v-list>
          </v-menu>
        </template>
      </template>

      <template v-if="draftMovementId && !showAssetsCollectionConfirm">
        <div class="assets-collection__actions--container mt-6 mx-1 mb-2">
          <div class="assets-collection__actions d-flex align-center justify-space-between">
            <p class="font-weight-bold text-body-2 primary--text pl-6">
              {{ showTotalOfAssetsSelected }} selecionados
            </p>
            <div :class="{ 'app-apply-blur' : isAdminEditing }">
              <BaseButton
                type="text"
                class="primary--text app-font-semibold"
                @click="deleteDraftMovement"
              >
                Excluir
              </BaseButton>
              <BaseButton
                color="primary"
                class="white--text app-font-semibold"
                :is-disabled="isButtonDisabled"
                @click="() => showAssetsCollectionConfirm = true"
              >
                Avançar
              </BaseButton>
            </div>
          </div>
          <BaseDataTable
            :headers="tableAssetHeaders"
            :hide-headers="true"
            :is-admin-editing="isAdminEditing"
            :items="dataDraftMovementsChildren"
            class="color--text-black-054"
          >
            <template v-slot:inject-header="{headers}">
              <tr>
                <template
                  v-for="(header, index) in headers.props.headers"
                >
                  <th
                    v-if="header.value != 'admin-config'"
                    :key="index"
                    style="white-space: nowrap;"
                  >
                    {{ translateRoleType(header.text) }}
                  </th>
                  <th
                    v-if="header.value == 'admin-config'"
                    :key="index"
                    class="text-right"
                    :class="header.class"
                  />
                </template>
              </tr>
            </template>
            <template v-slot:inject-items="scopedItems">
              <tr
                v-for="props in scopedItems"
                :key="props.item.id"
              >
                <template
                  v-for="header, index in tableAssetHeaders"
                >
                  <td
                    v-if="header.value != 'admin-config'"
                    :key="index"
                    class="text-left"
                  >
                    <div>
                      {{ formatFields(header.value, props.item, header.input_type) }}
                    </div>
                  </td>
                  <td
                    v-if="header.value == 'admin-config'"
                    :key="index"
                    class="text-right"
                  >
                    <BaseButton
                      v-if="tradeType === 'select'"
                      type="text"
                      :has-icon="true"
                      @click="$emit('deleteDraftMovementChild', props.item.score)"
                    >
                      <v-icon class="primary--text">
                        delete
                      </v-icon>
                    </BaseButton>
                  </td>
                </template>
              </tr>
            </template>
          </BaseDataTable>
        </div>
      </template>

      <AssetsCollectionConfirm
        v-if="showAssetsCollectionConfirm"
        :total-of-assets="showTotalOfAssetsSelected"
        :amount="programDraftMovementAmount"
        :bank-account-or-trading-partner="accountSelected || fidcSelected"
        :trade-type="tradeType"
        @back-page="backPage"
        @close-assets-collection="hideAssetsCollection"
        @updateAssetList="updateAssetList"
        @showError="showError"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>

import store from '../../store'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'

import BaseDataTable from '../BaseDataTable.vue'
import AssetsCollectionConfirm from './AssetsCollectionConfirm'
import BaseDataBank from '../BaseDataBank.vue'
import UtilsBankAccount from '../../mixins/UtilsBankAccount'
import Warning from '../Program/Warning.vue'
import ProgramRoles from "@/mixins/ProgramRoles"
import DynamicTradeCriteria from './DynamicTradeCriteria'

export default {
  components: {BaseDataTable, AssetsCollectionConfirm, BaseDataBank, Warning, DynamicTradeCriteria},
  mixins: [UtilsBankAccount, ProgramRoles],
  props: {
    showAssetsCollection: {
      type: Boolean,
      required: true
    },
    isAdminEditing: {
      type: Boolean,
      required: false,
      default: false
    },
    tradeType: {
      type: String,
      required: true
    },
    draftMovementId: {
      type: String,
      required: false,
      default: () => ''
    }
  },
  data() {
    return {
      showAssetsCollectionConfirm: false,
      fieldsAssetColumn: [],
      tableAssetHeaders: [],
      menu: false,
      accountSelected: null,
      showAccountList: false,
      destinationField: {},
      fidcList: [],
      fidcSelected: null,
      buyerOrganizationList: [],
      sellerOrganizationList: [],
      tradingPartnerOrganizationList: [],
      showReportMessage: false
    }
  },
  computed: {
    ...mapState({
      accountId: state => state.auth.keycloak.tokenParsed['account-id'],
      programDraftMovementAmount: state => state.programDraftMovements.programDraftMovementAmount,
      programDraftMovementId: state => state.programDraftMovements.programDraftMovementId,
      programDraftMovementData: state => state.programDraftMovements.programDraftMovementData,
      programDraftMovementsChildren: state => state.programDraftMovements.programDraftMovementsChildren,
      programDraftMovementTotalAssets: state => state.programDraftMovements.programDraftMovementTotalAssets,
      suggestions: state => state.programFilters.suggestions,
      programMembers: state => state.programMembers.programMembers,
      loadPage: (state) => state.loadPage
    }),
    ...mapState('programMovements', ['programBankAccounts']),
    dataDraftMovementsChildren() {
      return this.programDraftMovementsChildren ? this.programDraftMovementsChildren.map(element => {
        const {type, id, score, data} = element
        const asset = {
          type,
          score,
          ...data
        }
        return asset
      }) : []
    },
    showTotalOfAssetsSelected() {
      return this.programDraftMovementTotalAssets || this.programDraftMovementsChildren.length;
    },
    hasBankAccount() {
      return this.programBankAccounts.length > 0
    },
    isButtonDisabled() {
      return (!this.hasBankAccount && !this.fidcSelected) || this.showTotalOfAssetsSelected == 0
    },
    roleOrganizationId() {
      return this.getRoleOrganizationId()
    },
    createLinkToBankAccount() {
      const url = window.location.hostname
      let index = url.indexOf('.')
      const newUrl = url.slice(index + 1)
      return `https://${newUrl}/#/platform-organization/${this.roleOrganizationId}/bank-account`
    }
  },
  methods: {
    ...mapActions('programMovements', ['apiGetProgramBankAccounts', 'apiGetSchemas', 'apiPatchProgramMovements']),
    ...mapActions('programDraftMovements',
      ['apiGetDraftMovementsChildren', 'apiDeleteDraftMovement']),
    ...mapActions("programMembers", ["apiGetProgramMembers"]),
    ...mapMutations('programDraftMovements', ['setDraftMovementsChildren']),
    ...mapMutations(["setDisableNavigation"]),
    parseHeadersAssetTable(){
      let tableAssetColumn = this.fieldsAssetColumn.sort((a, b) => a.sequence - b.sequence) || []
      let fields = tableAssetColumn.map(header => {
        return {
          hidden: header.hidden,
          text: header.name,
          value: header.key,
          class: header.key,
          'input_type': header['input_type'],
          sortable: true
        }
      })
      fields.push({
        hidden: false,
        text: '',
        value: 'admin-config',
        class: 'delete-icon',
        sortable: false
      })
      this.tableAssetHeaders = [...fields]
    },
    goToReports(){
      this.setDisableNavigation(false)
      this.$router.push('/files/reports')
    },
    hideAssetsCollection() {
      this.$emit("hideAssetsCollection");
      this.showAssetsCollectionConfirm = false
    },
    updateAssetList(){
      this.$emit('updateAssetList')
    },
    showError(value){
      this.$emit("showError", value)
    },
    async deleteDraftMovement() {
      await this.apiDeleteDraftMovement({type: 'trade'})
      this.setDraftMovementsChildren([])
      this.$emit("hideAssetsCollection");
      this.showAssetsCollectionConfirm = false
    },
    backPage() {
      this.showAssetsCollectionConfirm = false
    },
    selectAccount(account) {
      this.accountSelected = account;
    },
    setPrimaryAccount() {
      this.accountSelected = this.programBankAccounts.filter(
        account => account.primary === true)[0] || this.programBankAccounts[0]
    },
    async getBankAccounts() {
      try {
        await this.apiGetProgramBankAccounts();
        this.setPrimaryAccount()
      } catch (error) {
        throw error;
      }
    },
    async getMemberOrganizationList(field) {
      if(field.datasource.includes('members')){
        await this.apiGetProgramMembers()
        this.programMembers.forEach(member => {
          let newMember = member.organization
          newMember.nameAndTaxnumber = `${newMember.name ? newMember.name.toUpperCase() : ''} - ${newMember.tax_number}`
          if(member.role == 'buyer')
            this.buyerOrganizationList.push(newMember)
          else if(member.role == 'seller')
            this.sellerOrganizationList.push(newMember)
          else if(member.role == 'trading-partner')
            this.tradingPartnerOrganizationList.push(newMember)
        })
      } else if (field.datasource.includes('bank')){
        await this.getBankAccounts()
      }

    },
    async getOptionsList(){
      if(this.tradeType === 'select'){
        await this.getBankAccounts()
      } else if (this.tradeType === 'dynamic') {
        const field = await this.apiGetSchemas({ scope: 'destination' })
        this.destinationField = field.data.fields[0]
        await this.getMemberOrganizationList(this.destinationField)

        let optionsList = {
          "/v1/members?role=buyer": this.buyerOrganizationList,
          "/v1/members?role=seller": this.sellerOrganizationList,
          "/v1/members?role=trading-partner": this.tradingPartnerOrganizationList,
          "/v1/programs/roles": this.getRoleOrganizations(),
          "/v1/movements/assets/suggestions/values": this.suggestions,
          "/v1/bank_accounts": this.programBankAccounts
        }
        if(this.destinationField.datasource.startsWith('[')){
          this.fidcList = JSON.parse(this.destinationField.datasource)
        } else {
          this.fidcList = optionsList[this.destinationField.datasource]
        }
      }
    },
    async executeExportData() {
      const { total_assets, amount, id, ...criteriaPayload} = this.programDraftMovementData
      const data = {
        action: 'simulate-report',
        data: {
          ...criteriaPayload
        }
      }
      await this.apiPatchProgramMovements({category: 'assets', data})
      this.showReportMessage = true
    }
  },
  watch: {
    tradeType(value) {
      if(value){
        this.getOptionsList()
      }
    }
  },
  async mounted() {
    if (this.programDraftMovementId) {
      this.apiGetDraftMovementsChildren({type: 'trade', id: this.programDraftMovementId})
    }
    let result = await this.apiGetSchemas({ scope: 'criteria-table' })
    this.fieldsAssetColumn = result.data && result.data.fields || []
    this.parseHeadersAssetTable()
    this.getOptionsList()
  },

}
</script>

<style scoped>
.assets-collection__header--icon {
  font-size: 40px
}

.assets-collection__actions {
  background-color: #F4F5F8;
  padding: 10px 10px 10px;
}

.assets-collection__accounts-menu {
  border: 1px solid rgba(38, 70, 116, 0.102);
  border-radius: 2px;
}

.assets-collection__accounts-menu > div .v-icon {
  font-size: 1.125rem;
}

.assets-collection__accounts-menu--select {
  background: #fff;
}

.assets-collection__accounts-menu--select:hover {
  background: #e9ecf1;
}

/* TABLE */
.theme--light.v-data-table >>> .v-data-footer {
  border-top: thin solid rgba(0, 0, 0, 0.12) !important;
}

.v-data-table >>> th {
  font-weight: 600;
}

.assets-collection__actions--container {
  box-shadow: 0px 1px 3px #00000061;
}

.delete-icon {
  width: 40px;
}
</style>
