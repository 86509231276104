<template>
  <v-dialog
    v-model="isModalActive"
    persistent
    transition="slide-y-reverse-transition"
    content-class="v-dialog--fullscreen"
  >
    <BaseModal
      style="height: 100vh; flex-wrap: nowrap"
      :middle-image="modalInfo.middleImage"
      :show-indeterminate-progress="modalInfo.showIndeterminateProgress"
      :top-slot-content-title="modalInfo.topSlotContentTitle"
      :remove-button-close="modalInfo.removeButtonClose"
      :middle-slot-content-text-highlight="
        modalInfo.middleSlotContentTextHighlight
      "
      :middle-slot-content-text="modalInfo.middleSlotContentText"
      :middle-check-agree="modalInfo.middleCheckAgree"
      :bottom-buttons-align="modalInfo.bottomButtonsAlign"
      :bottom-slot-content-button-cancel-label="
        modalInfo.bottomSlotContentButtonCancelLabel
      "
      :bottom-slot-content-button-confirm-label="
        modalInfo.bottomSlotContentButtonConfirmLabel
      "
      :remove-button-cancel="modalInfo.removeButtonCancel"
      :remove-button-confirm="modalInfo.removeButtonConfirm"
      @on-cancel="closeModalInvite"
      @on-confirm="() => null"
    >
      <div style="max-width: 645px; margin: 0 auto">
        <v-img
          class="base-modal__icon mb-4"
          style="margin: 0 auto"
          :src="require('@/assets/invite-program.svg')"
          contain
          :height="54"
          :max-width="191"
          :width="191"
        />

        <h2 class="font-weight-bold app-headline text-center mb-4">
          Convide os participantes para {{ program.name }}.
        </h2>

        <p class="body-1 text-center">
          Você pode enviar convites individualmente para os participantes.
        </p>

        <h3
          class="font-weight-bold app-headline mb-1 mt-12"
          style="font-size: 18px"
        >
          Convite individual
        </h3>

        <p class="body-1">
          Forneça os dados abaixo para convidar cada participante.
        </p>

        <v-form
          ref="formSendInvite"
          v-model="valid"
        >
          <v-container class="pa-0">
            <v-row
              dense
              style="max-width: 320px"
            >
              <v-col sm="12">
                <CnpjInput
                  ref="companyTaxNumber"
                  v-model="invite.tax_number"
                  label="CNPJ*"
                  :error-messages="formAddOrganizationErrorFields.cnpj"
                  :masked="false"
                  @blur="isValidCnpj"
                  @focus="() => clearFormAddOrganizationErrorFields('cnpj')"
                >
                  <template v-slot:append>
                    <LoadingInput :is-loading="isSearchingOrganization" />
                  </template>
                </CnpjInput>
              </v-col>

              <v-col
                v-show="showFormFields"
                sm="12"
              >
                <BaseInput
                  ref="companyName"
                  v-model="invite.name"
                  label="Razão social*"
                  :rules="[isValid]"
                  :disabled="hasFindOrganization"
                  ref-name="companyName"
                />
              </v-col>

              <v-col
                v-show="showFormFields"
                sm="12"
              >
                <v-combobox
                  ref="email"
                  v-model="invite.email"
                  :items="invite.email"
                  label="Email*"
                  multiple
                  chips
                  :rules="[isValidArray]"
                  name="qa-id-name-email"
                  :error-messages="errorMessage"
                  :validate-on-blur="!hasFindOrganization"
                  :disabled="hasFindOrganization && hasFindOrganizationEmail"
                  class="step-name-configuration_combobox"
                  @change="validateEmailList(invite.email)"
                />
              </v-col>

              <v-col sm="12">
                <v-select
                  v-model="invite.role"
                  :items="roleOptions"
                  item-text="description"
                  item-value="type"
                  label="Tipo de participante"
                  :rules="[isValid]"
                  required
                />
              </v-col>

              <v-col
                sm="12"
                class="text-right"
              >
                <BaseButton
                  :is-disabled="!valid || isLoading"
                  color="primary"
                  button-class="mx-0"
                  @click="sendInviteToProgram"
                >
                  Convidar
                </BaseButton>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <BaseSnackbar
          :active-snackbar="feedback"
          :text-snackbar="feedbackMessage"
          :type-snackbar="feedbackColor"
          @closeSnackbar="feedback = !feedback"
        />
      </div>
    </BaseModal>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex"
import BaseModal from "../BaseModal"
import BaseSnackbar from "../BaseSnackbar"
import BaseSnackbarConfiguration from "../../mixins/BaseSnackbarConfiguration"
import CnpjInput from "../Input/CnpjInput"
import LoadingInput from "../LoadingInput"

export default {
  components: {
    BaseModal,
    BaseSnackbar,
    CnpjInput,
    LoadingInput
  },
  mixins: [BaseSnackbarConfiguration],
  props: {
    modalInfo: {
      type: Object,
      required: true
    },
    context: {
      type: String,
      required: true,
      validator: (value) => ["program", "platform"].includes(value)
    },
    isModalActive: {
      type: Boolean,
      required: true
    },
    program: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      valid: false,
      invite: {},
      showFormFields: false,
      isLoading: false,
      isSearchingOrganization: false,
      hasFindOrganization: false,
      hasFindOrganizationEmail: false,
      formAddOrganizationErrorFields: {
        cnpj: [],
      },
      errorMessage: []
    };
  },
  computed: {
    ...mapState({
      keycloak: (state) => state.auth.keycloak.tokenParsed,
      platformOrganizationsByTaxnumber: (state) =>
        state.platformOrganization.platformOrganizationsByTaxnumber,
      programSelectedRoles: (state) =>
        state.platformProgram.programSelectedRoles,
      programRoleTypes: (state) => state.platformProgram.programRoleTypes
    }),
    roleOptions() {
      return this.context === "platform"
        ? this.programSelectedRoles
        : this.programRoleTypes.filter((role) => role.type === "seller")
    },
  },
  methods: {
    ...mapActions(["apiSendProgramInvitation"]),
    ...mapActions("platformOrganization", [
      "apiGetPlatformOrganizationsByTaxnumber",
    ]),
    ...mapActions("programMembers", ["apiSendInviteToProgram"]),
    closeModalInvite() {
      this.$emit("onCloseModalInvite")
      this.showFormFields = false
      this.invite = {}
      this.$refs.formSendInvite.reset()
      Object.keys(this.formAddOrganizationErrorFields).forEach((elem) => {
        this.clearFormAddOrganizationErrorFields(elem, [])
      });
    },
    validateEmailList(emailList){
      this.errorMessage = this.isEmailValid(emailList)
    },
    clearFormAddOrganizationErrorFields(name) {
      this.updateFormAddOrganizationErrorFields(name, [])
    },
    updateFormAddOrganizationErrorFields(name, errors) {
      Object.assign(this.formAddOrganizationErrorFields, { [name]: errors })
    },
    setErrorWhenOrganizationIsInvalid() {
      this.setFormAddOrganizationError('')
      this.updateFormAddOrganizationErrorFields('cnpj', ['CNPJ inválido'])
    },
    setFormAddOrganizationError(value) {
      this.formAddOrganizationError = value
    },
    isValidCnpj(data) {
      this.hasFindOrganization = false
      this.showFormFields = false

      this.resetFormFields(["companyName", "email"])

      if (data.valid) {
        this.getOrganizationByTaxnumber(this.invite.tax_number)
      }
    },
    resetFormFields(fields) {
      fields.forEach((elem) => {
        this.$refs[elem].reset()
      });
    },
    async sendInviteToProgram() {
      this.isLoading = true
      let inviteData = {
        ...this.invite,
        email: this.invite.email.join()
      }

      try {
        const data = {
          accountId: this.keycloak["account-id"],
          programId: this.program.id,
          ...inviteData,
        };
        if(this.context === "platform"){
          await this.apiSendProgramInvitation(data)
        } else {
          await this.apiSendInviteToProgram(inviteData)
        }
        this.isLoading = false
        this.showFeedback({
          color: "success",
          message: "Convite enviado com sucesso!",
        });
        this.$refs.formSendInvite.reset()
        this.forceResetInputValue(this, "companyTaxNumber")
      } catch (error) {
        let errorMsg = "Erro ao enviar convite."
        this.isLoading = false
        this.showFeedback({
          color: "error",
          message: errorMsg,
        });
        throw error
      }
    },
    async getOrganizationByTaxnumber(value) {
      try {
        this.isSearchingOrganization = true

        const result = await this.apiGetPlatformOrganizationsByTaxnumber(value)

        if (result.length > 0) {
          this.hasFindOrganization = true
          this.invite.name = result[0].name
          this.invite.email = this.platformOrganizationsByTaxnumber[0].primary_email.split(',')
          this.hasFindOrganizationEmail = this.invite.email ? true : false
        }

        this.showFormFields = true
      } catch (error) {
        if (error.response.status === 400) {
          this.setErrorWhenOrganizationIsInvalid()
          this.showFormFields = false
        }
      } finally {
        this.isSearchingOrganization = false
      }
    },
    convertRole(value) {
      let role = this.programSelectedRoles.find((role) => role.type === value)
      return role ? role.description : ""
    },
  },
};
</script>
