<template>
  <v-card
    class="base-modal pa-0"
    :class="{ 'base-modal--body-not-centered': !isMiddleSlotContentTextCentered }"
  >
    <v-progress-linear
      v-show="showIndeterminateProgress"
      class="base-modal__progress"
      :indeterminate="true"
    />
    <v-card-title class="headline px-6 py-4">
      <h5
        class="headline"
        :class="{'pl-4 order-1': labelTopAction}"
      >
        {{ topSlotContentTitle }}
      </h5>
      <v-spacer :class="{'order-2': labelTopAction}" />
      <BaseButton
        v-if="!removeButtonClose"
        type="text"
        :has-icon="true"
        color="grey darken-1"
        class="ma-0"
        :class="{'order-0': labelTopAction}"
        @click="$emit('on-cancel', false)"
      >
        <v-icon>close</v-icon>
      </BaseButton>
      <div :class="{'order-3': labelTopAction}">
        <BaseButton
          v-if="!!labelTopAction"
          type="text"
          @click="$emit('header-button-action')"
        >
          {{ labelTopAction }}
        </BaseButton>
      </div>
    </v-card-title>
    <v-card-text
      :class="{ 'base-modal__body': isMiddleSlotContentTextCentered, 'base-modal__body-not-centered': !isMiddleSlotContentTextCentered }"
      class="px-6 pt-0 pb-6"
      :style="{'background-color': bodyBackgroundColor}"
    >
      <template v-if="!isDefault">
        <template v-if="!hasMiddleImage">
          <v-img
            class="base-modal__icon mb-4"
            :src="require(`@/assets/${this.middleImage.src}`)"
            contain
            :height="middleImage.height"
            :max-width="middleImage.maxWidth"
            :width="middleImage.width"
          />
        </template>
        <div class="base-modal__text">
          <slot name="middleSlotTextHighlight">
            <div
              class="base-modal__text__highlight"
              v-html="middleSlotContentTextHighlight"
            />
          </slot>
          <slot name="middleSlotText">
            <div v-html="middleSlotContentText" />
          </slot>
          <slot name="inject-form" />
        </div>
        <template v-if="hasMiddleCheckAgree">
          <v-checkbox
            class="envelope__agree-terms mt-1"
            :input-value="middleCheckAgree.isChecked"
            @change="changeIsAgreeAssign"
          >
            <template v-slot:label>
              <p v-html="middleCheckAgree.text" />
            </template>
          </v-checkbox>
        </template>
      </template>
      <slot v-else />
    </v-card-text>
    <v-card-actions
      v-if="!removeButtonCancel || !removeButtonConfirm"
      :class="bottomButtonAlignStatement"
      class="px-6 pt-2 pb-2 app-modal-footer-background"
    >
      <BaseButton
        v-if="!removeButtonCancel"
        :is-disabled="showIndeterminateProgress"
        color="primary"
        type="text"
        @click="$emit('on-cancel', false)"
      >
        <slot name="bottomSlotButtonCancelLabel">
          {{ bottomSlotContentButtonCancelLabel }}
        </slot>
      </BaseButton>

      <BaseButton
        v-if="!removeButtonConfirm"
        :is-disabled="!enableBottomButtonConfirmLabel || showIndeterminateProgress"
        type="depressed"
        color="primary"
        button-class="base-modal__button__highlight"
        @click="$emit('on-confirm')"
      >
        <slot name="bottomSlotButtonConfirmLabel">
          {{ bottomSlotContentButtonConfirmLabel }}
        </slot>
      </BaseButton>
    </v-card-actions>
  </v-card>
</template>

<script>

import {utilsTestTypeAttribute} from '../utils/index'

export default {
  props: {
    showIndeterminateProgress: {
      type: Boolean,
      required: true
    },
    topSlotContentTitle: {
      type: String,
      required: true
    },
    removeButtonClose: {
      type: Boolean,
      required: true
    },
    labelTopAction: {
      type: String,
      required: false,
      validator: value => value !== ''
    },
    bodyBackgroundColor: {
      type: String,
      required: false,
      validator: value => value !== ''
    },
    middleImage: {
      type: Object,
      validator: (object) => {
        if (object === null) {
          return;
        }

        const listToValidateInObject = [
          {name: 'src', type: 'string'},
          {name: 'height', type: 'string'},
          {name: 'maxWidth', type: 'string'},
          {name: 'width', type: 'string'}
        ]

        listToValidateInObject.forEach((item) => {
          const {name, type} = item
          const value = object[name]

          utilsTestTypeAttribute(name, value, type)
        })

        return true
      },
      required: false,
      default: null,
    },
    middleSlotContentTextHighlight: {
      type: String,
      required: true
    },
    middleSlotContentText: {
      type: String,
      required: true
    },
    isMiddleSlotContentTextCentered: {
      type: Boolean,
      required: false,
      default: false
    },
    middleCheckAgree: {
      type: Object,
      validator: (object) => {
        if (object === null) {
          return;
        }

        const listToValidateInObject = [
          {name: 'isChecked', type: 'boolean'},
          {name: 'text', type: 'string'},
        ]

        listToValidateInObject.forEach((item) => {
          const {name, type} = item
          const value = object[name]

          utilsTestTypeAttribute(name, value, type)
        })

        return true
      },
      required: false,
      default: null
    },
    bottomButtonsAlign: {
      type: String,
      required: true,
      validator: value => {
        return "'center','left','right'".indexOf(value) > -1
      }
    },
    bottomSlotContentButtonCancelLabel: {
      type: String,
      required: true
    },
    bottomSlotContentButtonConfirmLabel: {
      type: String,
      required: true
    },
    removeButtonCancel: {
      type: Boolean,
      required: true
    },
    removeButtonConfirm: {
      type: Boolean,
      required: true
    },
    customEnableBottomButtonConfirmLabel: {
      type: Boolean,
      required: false,
      default: null
    }
  },
  computed: {
    hasMiddleImage() {
      return this.middleImage === null ? true : false
    },
    hasMiddleCheckAgree() {
      return this.middleCheckAgree !== null && Object.keys(this.middleCheckAgree).length > 0
    },
    enableBottomButtonConfirmLabel() {
      if (this.customEnableBottomButtonConfirmLabel === null) {
        return this.hasMiddleCheckAgree ? !!this.middleCheckAgree.isChecked : true
      } else {
        return this.customEnableBottomButtonConfirmLabel
      }
    },
    bottomButtonAlignStatement() {
      if (this.bottomButtonsAlign === 'left') {
        return "align-left"
      } else if (this.bottomButtonsAlign === 'center') {
        return "align-center"
      } else {
        return "align-rigth"
      }
    },
    isDefault() {
      return !!this.$slots.default
    },
  },
  mounted() {
    document.body.classList.remove('v-tour--active')
  },
  updated() {
    document.body.classList.remove('v-tour--active')
  },
  methods: {
    changeIsAgreeAssign(value) {
      this.$emit('input', value)
    },
  }
}
</script>

<style scoped>
.align-left {
  justify-content: flex-start;
}

.align-center {
  justify-content: center;
}

.align-rigth {
  justify-content: flex-end;
}

.base-modal__progress {
  margin-bottom: 0;
  margin-top: 0;
}

.base-modal {
  min-height: 400px;
  flex-flow: column wrap;
  display: flex;
}

.base-modal.base-modal--body-not-centered {
  min-height: auto;
}

.base-modal__body {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  flex: 2 1 auto;
}

.base-modal__body-not-centered {
  flex: 2 1 auto;
  overflow-y: auto;
}

.base-modal__icon {
  flex: 0 1 auto;
  margin: 0 auto 20px;
}

.base-modal__text {
  color: rgba(0, 0, 0, 0.54);
  flex: 0 1 auto;
  font-size: 16px;
  margin: 0 auto;
}

.base-modal__text__highlight {
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
}

.base-modal__button__highlight {
  font-weight: 400;
  height: 36px;
  min-width: 96px;
  padding: 9px 23px;
}

/* Ordenar elementos */
.order-0 {
  order: 0
}

.order-1 {
  order: 1
}

.order-2 {
  order: 2
}

.order-3 {
  order: 3
}
</style>
