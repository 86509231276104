export function production() {
  return {
    initOptions: {
      checkLoginIframe: false
    },
    bearerExcludedUrls: [
      '/assets',
      '/clients/public'
    ],
  }
}
