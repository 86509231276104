import Vue from 'vue'
import BaseContainer from '../components/BaseContainer'
import BaseCard from '../components/BaseCard'
import BaseLink from '../components/BaseLink'
import BaseButton from '../components/BaseButton'
import BaseInput from '../components/Input/BaseInput'
import JsonCSV from 'vue-json-csv'
import ButtonExportData from '../components/ButtonExportData'
import BaseTour from '../components/BaseTour'

export default function component() {
  Vue.component('BaseContainer', BaseContainer)
  Vue.component('BaseCard', BaseCard)
  Vue.component('BaseLink', BaseLink)
  Vue.component('BaseButton', BaseButton)
  Vue.component('BaseInput', BaseInput)
  Vue.component('downloadCsv', JsonCSV)
  Vue.component('ButtonExportData', ButtonExportData)
  Vue.component('BaseTour', BaseTour)
}
