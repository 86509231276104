<template>
  <div>
    <BaseInput
      :mask="['(##) ####-####', '(##) #####-####']"
      use-pattern="\D"
      v-bind="{ ...$attrs, ...propsToApply }"
      v-on="$listeners"
    >
      <template
        v-for="(slotValue, slotName) in $slots"
        v-slot:[slotName]
      >
        <slot :name="slotName" />
      </template>
    </BaseInput>
  </div>
</template>

<script>
import BaseInput from './BaseInput.vue'

import { utilsTranformValueToOnlyNumber, utilsIsValidLength } from '../../utils'

export default {
  name: 'TelephoneInput',
  components: { BaseInput },
  props: {
    ...BaseInput.props
  },
  data() {
    return {
      privateRules: []
    }
  },
  computed: {
    rulesOfProps() {
      const { rules } = this.$props

      return [...this.privateRules, ...rules]
    },
    propsToApply() {
      const { ...props } = this.$props

      Object.assign(props, { rules: this.rulesOfProps })

      return props
    }
  },
  created() {
    this.privateRules.push(this.isValid)
    this.privateRules.push((value) => {
      const transformedValue = utilsTranformValueToOnlyNumber(value)

      return (utilsIsValidLength(transformedValue, 10) || utilsIsValidLength(transformedValue, 11)) || 'Telefone inválido'
    })
  }
}
</script>

<style>

</style>
