export default {
  state: {
    headerTitle: '',
    headerUseBoxShadow: false,
    headerBg: '',
    headerMenuOptionsIcon: '',
    headerMenuOptionsItems: [],
    headerExtraMenu: false
  },
  mutations: {
    setHeaderTitle(state, payload) {
      state.headerTitle = payload
    },
    setHeaderUseBoxShadow(state, payload) {
      state.headerUseBoxShadow = payload
    },
    setHeaderBg(state, payload) {
      state.headerBg = payload
    },
    setHeaderExtraMenu(state, payload) {
      state.headerExtraMenu = payload
    }
  }
}
