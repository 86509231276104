<template>
  <div class="movement-detail">
    <v-card
      elevation="2"
      outlined
      :loading="isLoading"
    >
      <v-card-title
        class="text-h5 primary--text font-weight-bold pb-0"
      >
        {{ title }}
      </v-card-title>

      <v-card-text class="subtitle-2">
        <template v-for="(item, index) in section">
          <p
            :key="item.title"
            class="color--text-black-054 font-weight-bold mb-2 mt-5"
          >
            {{ item.title }}
          </p>
          <p
            v-if="item.subtitle"
            :key="item.subtitle"
            class="my-1"
            v-html="item.subtitle"
          />
          <v-divider :key="index" />
          <slot :name="item.value" />
        </template>
      </v-card-text>
      <v-card-actions
        class="mt-7 card-actions--footer"
        :class="applyDisplayFlexOnActionsButtons"
      >
        <template v-for="button in actionsButtons">
          <BaseButton
            :key="button.text"
            :type="button.type"
            :color="button.color"
            :is-disabled="button.disabled || false"
            @click="(ev) => handleClick(ev, button.dispatchEvent)"
          >
            {{ button.text }}
          </BaseButton>
        </template>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    section: {
      type: Array,
      required: true,
      validator: (value) => {
        if(value.length === 0) return false;
        const result = value.every(elem => {
          return elem.title && elem.value
        })

        return result
      }
    },
    actionsButtons: {
      type: Array,
      required: true
    },
  },
  computed: {
    applyDisplayFlexOnActionsButtons() {
      return this.actionsButtons.length > 1 ? 'd-flex justify-space-between' : 'd-flex justify-end'
    }
  },
  methods: {
    handleClick(ev, dispatchEvent) {
      const customEv = Object.assign({ dispatchEvent }, ev);
      this.$emit('click', customEv)
    }
  }
}
</script>

<style scoped>
.card-actions--footer {
  background-color: #F4F5F8;
}

</style>
