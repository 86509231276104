<template>
  <BaseContainer>
    <div class="card-data-table">
      <BaseDataTable
        v-bind="{ ...$attrs, ...$props }"
        class="card-data-table-base"
        v-on="$listeners"
      >
        <template
          v-slot:inject-header="{ headers }"
        >
          <tr class="card-data-table__table__header-tr elevation-2 rounded">
            <slot
              name="inject-header"
              :headers="headers.props"
            />
          </tr>
        </template>

        <template v-slot:no-result-text-message>
          <p class="font-weight-bold mb-0 body-2">
            Nenhum item para exibir.
          </p>
          <p class="font-weight-regular body-2">
            Você pode refinar a busca na opção "Filtrar" ou
            navegar entre as páginas.
          </p>
        </template>

        <template v-slot:inject-items="scopedItems">
          <tr
            v-for="props in scopedItems"
            :key="props.index"
            class="card-data-table_tr--is-expanded"
            :class="{ 'app-apply-blur' : isAdminEditing }"
          >
            <template
              v-for="header, index in headers"
            >
              <td
                v-if="header.value !== 'admin-config'"
                :key="index"
                class="text-left"
                :class="{ 'app-apply-blur' : isAdminEditing || isAdminEditingItem }"
              >
                <div
                  v-if="header.hidden === 'true' && hideAllData"
                >
                  {{ hideData(props.item[header.value], header.hidden) }}
                </div>
                <div
                  v-else-if="header.value === 'status.name'"
                >
                  <v-chip
                    class="platform-trades__item--status font-weight-medium ma-0"
                    :color="convertStatus(props.item, header.value)"
                  >
                    {{ formatFields(header.value, props.item, header.input_type) || '-' }}
                  </v-chip>
                </div>

                <div v-else-if="header.value === 'type.title'">
                  <BaseTextTooltip
                    :text-item="formatFields(header.value, props.item, header.input_type) || '-'"
                    width-item="65"
                  />
                </div>

                <div v-else-if="header.value === 'to.name' || header.value === 'from.name' || header.value === 'parties'">
                  <BaseTextTooltip
                    :text-item="formatFields(header.value, props.item, header.input_type) || '-'"
                    width-item="100"
                    min-width-item="100%"
                  />
                </div>

                <div v-else>
                  {{ formatFields(header.value, props.item, header.input_type) || '-' }}
                </div>
              </td>
              <td
                v-if="header.value === 'admin-config'"
                :key="index"
                class="text-right"
                style="padding:0px!important;"
              >
                <template v-if="props.item.actions && props.item.actions.length">
                  <BaseButton
                    v-for="action in props.item.actions"
                    :key="action.text"
                    :ref="action.text"
                    :type="action.type"
                    :has-icon="false"
                    :is-disabled="action.disabled"
                    color="blueff"
                    button-class="app-button__link-text app-button--hide-ripple app-button--no-hover mx-0 px-0"
                    @click="(ev) => dispatchEvent(ev, { type: action.dispatchEvent, value: props.item })"
                  >
                    {{ action.text }}
                  </BaseButton>
                </template>
                <BaseButton
                  id="expand-table-trade"
                  :ref="props.item.id"
                  type="text"
                  :has-icon="true"
                  color="#264674"
                  @click="(ev) => handleClickExpand(ev, props)"
                >
                  <v-icon v-if="props.isExpanded">
                    expand_less
                  </v-icon>
                  <v-icon v-else>
                    expand_more
                  </v-icon>
                </BaseButton>
              </td>
            </template>
          </tr>
        </template>

        <template
          v-slot:inject-expand="props"
        >
          <div
            class="card-data-table--expanded"
            style="background-color: #F4F5F8"
          >
            <slot
              name="inject-expand"
              :items="props"
            />
          </div>
        </template>
      </BaseDataTable>
    </div>
  </BaseContainer>
</template>

<script>
import BaseDataTable from './BaseDataTable.vue'
import BaseButton from './BaseButton.vue'
import BaseTextTooltip from './BaseTextTooltip'

import { transformToKebabCase } from '../utils'

export default {
  name: "CardDataTable",
  components: { BaseDataTable, BaseButton, BaseTextTooltip },
  props: {
    ...BaseDataTable.props,
    isAdminEditingItem: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    transformToKebabCase,
    convertStatus(item, name) {
      let category = item[this.splitProp(name)[0]] ? item[this.splitProp(name)[0]].category : ''

      const status = {
        'in-progress': "progress",
        done: "running",
        created: "ok",
        error: "cancelled",
        "on-hold": "pending"
      }
      return status[category]
    },
    isObject(value) {
      return !Array.isArray(value) && typeof value == 'object'
    },
    splitProp(key) {
      let splittedKey = key.split('.')
      return splittedKey
    },
    isArray(value) {
      return Array.isArray(value)
    },
    dispatchEvent(ev, dispatchEvent) {
      this.$emit(dispatchEvent.type,
        Object.assign(ev, { dispatchEvent }))
    },
    handleClickExpand(ev, props, forcedDispacth=false) {
      const { item } = props

      if(forcedDispacth) {
        if(item.expand === undefined) {
          props.expand(!props.isExpanded)
        } else {
          props.expand(item.expand)
        }
      } else {
        props.expand(!props.isExpanded)
      }
    },
    getInternalRefs(name) {
      return this.$refs[name]
    }
  }
}
</script>

<style scoped>

.card-data-table-base >>> table {
  width: calc(100% - 20px)!important;
  border-spacing: 0 10px!important;
  margin: 0 10px !important;
}

.card-data-table--expanded >>> table {
  width: 100% !important;
  border-spacing: 0px!important;
  margin: 0px !important;
}

  .card-data-table tbody tr:not(.card-data-table__table__body-tr) {
    background: transparent;
  }

  .card-data-table .v-table__overflow + div,
  .card-data-table .card-data-table--expanded {
    margin: -10px 0px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12) !important;
    width: 100%;
  }

  .card-data-table  .card-data-table--expanded {
    margin-bottom: 24px;
  }

  .list-no-itens--container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
  }

  .list-no-itens--container div {
    display: flex;
    align-items: center;
    flex-flow: column;
  }

  .list-no-itens--text {
    color: var(--custom-theme-black-054) !important;
    font-weight: bold;
  }

  .card-data-table__header__actions {
    display: flex;
    align-items: center;
  }

  .card-data-table table {
    background-color: transparent !important;
  }

  .card-data-table th {
    font-weight: 400 !important;
  }

  .card-data-table .base-data-table >>> tr > th {
    padding: 15px !important;
  }

  .card-data-table >>> .card-data-table_tr--is-expanded {
    border-bottom: none !important;
    background: #fff !important;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  }

  .card-data-table >>> .card-data-table_tr--is-expanded:hover {
    background-color: transparent !important;
    background: #eeeeee!important;
  }

  .card-data-table >>> .tr--child-expanded:hover {
    background-color: transparent !important;
    background: transparent!important;
  }
  .card-data-table >>> .v-data-table__wrapper > table > tbody > tr > td {
    border-bottom: none!important;
  }

  .card-data-table >>> .card-data-table__table__body-tr:hover {
    background-color: white !important;
  }

  .card-data-table tr[class*="v-datatable__progress"] {
    margin-top: -8px !important;
  }

  .card-data-table tr[class*="v-datatable__progress"] > * {
    width: 100%;
  }

  .card-data-table thead tr:not(:last-child) {
    border: none !important;
  }

  .card-data-table tbody tr {
    height: auto;
    margin-bottom: 10px;
  }

  .card-data-table tbody tr > * {
    height: auto !important;
  }

  .card-data-table tbody tr:not(:last-child) {
    border: none !important;
  }

  .card-data-table__message {
    border-top: 1px solid #bebebe;
    background-color: #f2f2f2;
    font-size: 10px !important;
    line-height: 1.8;
  }

  .card-data-table__table__header-tr {
    text-align: left;
    background: #fff;
  }

  .card-data-table thead tr {
    height: 48px !important;
    padding: 0 !important;
    margin-bottom: 10px;
  }

  .card-data-table thead tr th {
    border-bottom: none !important;
  }

  .card-data-table tr > th {
    height: unset !important;
  }

  .card-data-table [data-toogle-text] {
    position: relative;
  }

  .card-data-table [data-toogle-text]:after {
    color: var(--v-blueff-base);
    font-weight: 600;
    content: attr(data-toogle-text);
    position: absolute;
    right: 0;
    top: 0;
    display: block;
  }
</style>
