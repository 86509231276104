<template>
  <v-alert
    :icon="icon"
    :type="type"
    :class="getCssClass"
    :width="width"
    :value="value"
  >
    <slot />
  </v-alert>
</template>

<script>
export default {
  name: 'Warning',
  props: {
    classes: {
      type: String,
      default: 'subtitle-2 pa-2'
    },
    icon: {
      type: String,
      required: false,
      default: 'warning'
    },
    type: {
      type: String,
      required: false,
      default: 'warning'
    },
    value: {
      type: Boolean,
      default: true
    },
    width: {
      type: Number,
      required: false,
    },
    theme: {
      type: String,
      required: false,
      default: 'dark',
       validator: function(value) {
        return ['light', 'dark'].includes(value)
      }
    }
  },
  computed: {
    getCssClass() {
      let css = {
        "light": "color--text-black-054 app-theme-light",
        "dark": "text--white app-theme-dark"
      }
      return `${this.classes} ${css[this.theme]}`
    }
  }
}
</script>
<style scoped>
  .app-theme-dark >>> .v-alert__icon {
    color: white!important;
  }
  .app-theme-light >>> .v-alert__icon {
    color: var(--custom-theme-black-054)!important;
  }
</style>
