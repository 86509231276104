export default {
  data() {
    return {
      feedback: false,
      feedbackMessage: '',
      feedbackColor: 'default',
    }
  },
  methods: {
    showFeedback(result) {
      const {message, color} = result

      this.feedbackMessage = message
      this.feedbackColor = color
      this.feedback = true
    }
  }
}
