<template>
  <div class="bank-account-list">
    <BaseDataTable
      :headers="['', '', '']"
      :items="bankAccountList"
      :hide-actions="true"
      :hide-headers="true"
      background-color="transparent"
    >
      <template v-slot:inject-items="scopedItems">
        <tr
          v-for="value in scopedItems"
          :key="value.index"
        >
          <td style="width: 100%">
            <v-switch
              v-if="contextActions == 'manage'"
              v-model="value.item.primary"
              class="platform-organization-profile-account--switch"
              color="primary"
              :label="accountName(value.item)"
              readonly
              @click="switchToPrimaryAccount(value.item)"
            />
            <span v-else>
              {{ accountName(value.item) }}
            </span>
          </td>
          <td
            class="text-right app-align-items-center"
            style="justify-content: flex-end; min-width: 130px;"
          >
            <a
              v-if="value.isExpanded && contextActions == 'manage'"
              class="body-2 blueff--text app-font-semibold"
              @click="updateBankAccountFormFields(value.item)"
            >
              <span>Editar</span>
            </a>
            <a
              class="body-2 app-font-semibold ml-4"
              @click="value.expand(!value.isExpanded)"
            >
              <v-icon
                v-if="value.isExpanded"
                class="primary--text mr-1"
              >
                expand_less
              </v-icon>
              <v-icon
                v-else
                class="primary--text mr-1"
              >
                expand_more
              </v-icon>
            </a>
          </td>
        </tr>
      </template>
      <template v-slot:inject-expand="{items}">
        <div class="pl-6 py-6 subtitle-2">
          <span class="color--text-black-054 mr-2">Banco</span> <span class="font-weight-bold">{{ getBankCodeAndName(items.item) }}</span> <br>
          <span class="color--text-black-054 mr-2">Agência</span> <span class="font-weight-bold">{{ getAgencyAndDigit(items.item) }}</span> <br>
          <span class="color--text-black-054 mr-2">Conta</span> <span class="font-weight-bold">{{ getNumberAndDigit(items.item) }}</span> <br>
        </div>
      </template>
      <template v-slot:custom-text-no-data>
        <div class="text-center">
          Nenhuma conta para exibir
        </div>
      </template>
    </BaseDataTable>
  </div>
</template>

<script>
import BaseDataTable from '../../BaseDataTable';
import UtilsBankAccount from '../../../mixins/UtilsBankAccount'

export default {
  name: "BankAccountList",
  components: {BaseDataTable},
  mixins: [UtilsBankAccount],
  props: {
    bankAccountList: {
      type: Array,
      required: true
    },
    contextActions: {
      type: String,
      required: true,
      validator: (value) => ['read', 'manage'].includes(value)
    },
    isModalChangeAccount: {
      type: Boolean,
      required: false
    },
    modalChangeAccount: {
      type: Object,
      required: false,
      default: ()=> {}
    }
  },
  computed: {
    modelChangeAccount: {
      get(){
        return this.isModalChangeAccount
      },
      set(){
        this.modelChangeAccount = this.isModalChangeAccount
      }
    }
  },
  methods: {
    accountName({name, primary}) {
      return primary ? `${name} (Conta Principal)` : name
    },
    switchToPrimaryAccount(value){
      this.$emit("onSwitchToPrimaryAccount", value)
    },
    updateBankAccountFormFields(value){
      this.$emit("onUpdateBankAccountFormFields", value)
    }
  }
};
</script>
