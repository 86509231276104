<template>
  <v-tour
    id="v-step-0"
    :name="tourName"
    :steps="steps"
    :options="options"
  >
    <template slot-scope="tour">
      <transition name="fade">
        <v-step
          v-if="tour.steps[tour.currentStep]"
          :key="tour.currentStep"
          :step="tour.steps[tour.currentStep]"
          :previous-step="tour.previousStep"
          :next-step="tour.nextStep"
          :stop="tour.stop"
          :skip="tour.skip"
          :is-first="tour.isFirst"
          :is-last="tour.isLast"
          :labels="tour.labels"
          :highlight="tour.highlight"
          :stop-on-fail="false"
          :class="`layout-step-${tour.steps[tour.currentStep].layoutStep ? tour.steps[tour.currentStep].layoutStep : 'default'}`"
        >
          <template
            v-if="tour.steps[tour.currentStep].headerImg"
            slot="header"
          >
            <div class="v-step__header">
              <img
                :src="require(`@/assets/${tour.steps[tour.currentStep].headerImg.src}`)"
                :width="tour.steps[tour.currentStep].headerImg.width"
                class="mb-2"
              >
              <p>{{ tour.steps[tour.currentStep].header.title }}</p>
            </div>
          </template>
          <template
            v-if="tour.steps[tour.currentStep].contentImg"
            slot="content"
          >
            <p class="v-step__content">
              {{ tour.steps[tour.currentStep].content }}
              <img
                :src="require(`@/assets/${tour.steps[tour.currentStep].contentImg.src}`)"
                :height="tour.steps[tour.currentStep].contentImg.height"
                style="vertical-align: bottom;"
              >
            </p>
          </template>
          <template>
            <div slot="actions">
              <div
                v-if="tour.steps[tour.currentStep].layoutStep !== 'simple'"
                class="mt-4"
              >
                <BaseButton
                  v-if="!tour.isLast"
                  color="primary"
                  class="mx-0"
                  @click="tour.nextStep"
                >
                  {{ options.labels.buttonNext }}
                </BaseButton>
                <BaseButton
                  v-if="tour.isLast"
                  color="primary"
                  class="mx-0"
                  @click="stopTour(tourName)"
                >
                  {{ options.labels.buttonStop }}
                </BaseButton>
                <br>
                <BaseButton
                  type="text"
                  color="gray74"
                  class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0 mx-0 text-decoration-underline"
                  button-class="app-font-regular"
                  @click="finishTour(tourName)"
                >
                  {{ options.labels.buttonSkip }}
                </BaseButton>
              </div>
            </div>
          </template>
        </v-step>
      </transition>
    </template>
  </v-tour>
</template>

<script>
import ProgramTour from '../mixins/ProgramTour'

export default {
  mixins: [ProgramTour],
  props: {
    tourName: {
      type: String,
      required: true
    },
    steps: {
      type: Array,
      required: true
    },
    options: {
      type: Object,
      required: false,
      default: () => {
        return {
          labels: {
            buttonSkip: 'fechar tutorial',
            buttonPrevious: 'Anterior',
            buttonNext: 'Continuar',
            buttonStop: 'Continuar'
          },
          highlight: true
        }
      }
    },
    startOnMounted: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  mounted(){
    if(this.startOnMounted){
      this.startTour(this.tourName)
    }
  }
}
</script>

<style lang="scss">
  .v-tour__target--highlighted {
    box-shadow: 0 0 0 99999px rgba(0,0,0,.4)!important;
    z-index: 997!important;
  }
  .v-step__header {
    background-color: #fff!important;
    color: #4A4A4A!important;
    font-size: 1.875rem;
    font-weight: 700;
    margin: 0px 0px 10px 0px!important;
    padding: 0px!important;
  }
  .v-step__arrow--dark:before, .v-step {
    background: #fff!important;
    color: #666666!important;
  }
  .v-step__arrow[data-v-54f9a632]:before {
    margin-left: unset!important;
  }
  .v-step__content {
    font-size: 1,25rem;
    margin:0px!important;
  }
  .v-step {
    z-index: 998!important;
  }
  .v-step.layout-step-starter {
    max-width: 613px!important;
    text-align: center!important;
    padding: 1.875rem 6.25rem!important;
    position: fixed!important;
    top: 50%!important;
    left: 50%!important;
    transform: translate(-50%, -50%)!important;
  }
  .v-step.layout-step-starter .v-step__arrow:before {
    visibility: hidden!important;
  }
  .v-step.layout-step-default {
    max-width: 538px!important;
    text-align: left!important;
    padding: 1.875rem 6.25rem 1.875rem 1.875rem!important;
  }
   .v-step.layout-step-simple {
    max-width: 395px!important;
    text-align: left!important;
    padding: 2.0625rem 2.1875rem!important;
  }
</style>
