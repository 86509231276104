<template>
  <div>
    <BaseContainer
      :active-spacer="true"
      base-container-classes="mb-6"
    >
      <template v-slot:header-title>
        {{ headerTitle }}
      </template>
      <template v-slot:header-title-description>
        {{ headerTitleDescription }}
      </template>

      <div
        class="d-flex text-caption text-right"
      >
        <v-spacer />
        <div :class="{ 'app-apply-blur' : isAdminEditing }">
          <p class="font-weight-bold primary--text">
            Valor disponível
          </p>
          <p class="font-weight-bold primary--text text-h4">
            {{ amountAvailable | formatCurrency }}
          </p>
        </div>
      </div>

      <v-divider class="my-2" />

      <div class="d-flex">
        <v-spacer />
        <BaseButton
          :class="{ 'app-apply-blur' : isAdminEditing }"
          color="primary"
          :is-disabled="disableSendCriteriaButton"
          @click="sendCriteria"
        >
          Simular
        </BaseButton>
      </div>

      <div class="app-align-items-center">
        <div
          class="app-align-items-center"
          style="max-width: 80%;"
        >
          <BaseFilters
            scope="criteria"
            filter-scope="criteria-table"
            label-add-filter="Critério"
            :values-validation="{ 'amount': amountAvailable }"
            :show-action-buttons="false"
            :load-page="loadPage || sendingSimulation"
            :pagination="{}"
            :is-admin-editing="isAdminEditing"
            attachmenu="#criteria"
            @executeActionFilter="getFiltersCriteria"
          />
        </div>
      </div>

      <div
        class="d-flex align-center justify-center text-center mb-4"
        style="height: 200px;"
      >
        <div :class="{ 'app-apply-blur' : isAdminEditing }">
          <v-icon
            :class="{'loading-animation' : sendingSimulation }"
          >
            {{ getBodyText.icon }}</v-icon>
          <h4>{{ getBodyText.title }}</h4>
          <p>{{ getBodyText.subtitle }}</p>
        </div>
      </div>
      <BaseSnackbar
        :active-snackbar="feedback"
        :type-snackbar="feedbackColor"
        :text-snackbar="feedbackMessage"
        @closeSnackbar="feedback = !feedback"
      />
    </BaseContainer>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BaseFilters from '../BaseFilters'
import BaseSnackbarConfiguration from '../../mixins/BaseSnackbarConfiguration'
import BaseSnackbar from '../BaseSnackbar'

export default {
  components: { BaseFilters, BaseSnackbar },
  mixins:[ BaseSnackbarConfiguration ],
  props: {
    isAdminEditing: {
      type: Boolean,
      required: false,
      default: false
    },
    headerTitle: {
      type: String,
      required: false,
      default: 'Simule uma negociação'
    },
    headerTitleDescription: {
      type: String,
      required: false,
      default: 'Crie uma segmentação de ativos para realizar uma simulação.'
    },
  },
  data() {
    return {
      filterList: [],
      sendingSimulation: false,
      textBody: [
        { icon: 'mdi mdi-wallet-bifold', title: 'Inicie uma simulação', subtitle: 'Adicione os critérios para realizar uma simulação' },
        { icon: 'mdi mdi-loading', title: 'Simulação está sendo calculada', subtitle: 'Aguarde o resultado e não feche essa tela' }
      ]
    }
  },
  computed: {
    ...mapState({
      amountAvailable: state => state.programMovements.programAmountAvailable,
      programDraftMovementId: state => state.programDraftMovements.programDraftMovementId,
      loadPage: (state) => state.loadPage,
    }),
    disableSendCriteriaButton(){
      return !this.filterList || (this.filterList.filters && !this.filterList.filters.length) || this.sendingSimulation
    },
    getBodyText(){
      return this.sendingSimulation ? this.textBody[1] : this.textBody[0]
    }
  },
  methods: {
    ...mapActions('programMovements', ['apiPatchProgramMovements']),
    ...mapActions('programDraftMovements', ['apiCreateDraftMovement', 'apiSendDraftMovementChildren', 'apiGetDraftMovements', 'apiGetDraftMovementsChildren']),
    getFiltersCriteria(value){
      this.filterList = Object.assign({}, value)
    },
    dataAssetListForAPI(results) {
      const { status, total_amount, total_assets, ...data } = results

      Object.keys(data).forEach((elem) => {
        data[elem] = {
          id: "asset",
          to: null,
          from: null,
          type: null,
          data: {
            ...data[elem],
            id: elem
          }
        }
      })

      return Object.values(data)
    },
    async getDraftMovements() {
      await this.apiGetDraftMovements('trade')

      if (this.programDraftMovementId) {
        await this.apiGetDraftMovementsChildren({type: 'trade', id: this.programDraftMovementId})
      }
    },
    parsePayloadCriteria(data){
      let payload

      Object.keys(data).forEach((key) => {
        payload = {
          ...payload,
          [`criteria_${key}`]: data[key]
        }
      })

      return payload
    },
    async sendCriteria(){
      this.sendingSimulation = true

      let payloadFilters = {}
      this.filterList.filters.forEach(filter => {
        payloadFilters[filter.key] = filter.values.length == 1 ? filter.values[0] : filter.values
      });

      const payloadApi = {
        sync: true,
        category: 'asset',
        data: {
          action: 'simulate',
          data: payloadFilters
        }
      }

      try {
        let result = await this.apiPatchProgramMovements(payloadApi)

        if(result.data.status == 'success'){
          const payloadCreateDraft = {
            type: 'trade',
            data: {
              action: 'start',
              data: {
                total_assets: result.data.total_assets,
                ...this.parsePayloadCriteria(payloadFilters)
              }
            }
          }

          if (this.programDraftMovementId) {
            await this.apiSendDraftMovementChildren({type: 'trade', id: this.programDraftMovementId, data: this.dataAssetListForAPI(result.data)})
          } else {
            const resultCreateDraft = await this.apiCreateDraftMovement(payloadCreateDraft)
            await this.getDraftMovements()
            await this.apiSendDraftMovementChildren({type: 'trade', id: this.programDraftMovementId, data: this.dataAssetListForAPI(result.data)})
            await this.getDraftMovements()
          }

        } else if(result.data.status == 'error'){
          this.showFeedback({
            message: result.data.error_message,
            color: 'error'
          })
        }
        this.sendingSimulation = false
      } catch (error) {
        this.sendingSimulation = false
        this.showFeedback({
          message: 'Ocorreu um erro ao fazer a simulação.',
          color: 'error'
        })
      }
    }
  }
}
</script>

<style>
.loading-animation {
  animation: rotating 2s linear infinite;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
