<template>
  <div class="template-modal">
    <v-dialog
      :value="isModalActive"
      persistent
      transition="app-transition-to-right"
      content-class="app-transition-to-right-additional-configs"
      @input="updateModalActive"
    >
      <template v-slot:activator="{ on }">
        <BaseButton
          type="text"
          qa-identification-name="qa-id-name-configuration-create"
          color="blueff"
          class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
          v-on="on"
        >
          Adicionar
        </BaseButton>
      </template>
      <BaseModal
        style="height: 100vh; flex-wrap: nowrap;"
        :show-indeterminate-progress="modalSignStep.showIndeterminateProgress"
        :top-slot-content-title="modalSignStep.topSlotContentTitle"
        :remove-button-close="modalSignStep.removeButtonClose"
        :middle-slot-content-text-highlight="modalSignStep.middleSlotContentTextHighlight"
        :middle-slot-content-text="modalSignStep.middleSlotContentText"
        :middle-check-agree="modalSignStep.middleCheckAgree"
        :bottom-buttons-align="modalSignStep.bottomButtonsAlign"
        :bottom-slot-content-button-cancel-label="modalSignStep.bottomSlotContentButtonCancelLabel"
        :bottom-slot-content-button-confirm-label="modalSignStep.bottomSlotContentButtonConfirmLabel"
        :remove-button-cancel="modalSignStep.removeButtonCancel"
        :remove-button-confirm="modalSignStep.removeButtonConfirm"
        body-background-color="#F8F8F8"
        @on-cancel="closeModal()"
        @on-confirm="() => null"
      >
        <div
          class="mt-6"
          style="max-width: 645px; margin: 0 auto;"
        >
          <v-form
            ref="envelopeTemplateForm"
            v-model="templateValid"
          >
            <div class="mt-3">
              <v-row dense>
                <v-col sm="4">
                  <BaseInput
                    ref="name"
                    v-model="template.name"
                    class="mr-2"
                    label="Name"
                    :rules="[isValid]"
                  />
                </v-col>
                <v-col sm="4">
                  <BaseInput
                    ref="id"
                    v-model="template.id"
                    class="mr-2"
                    label="Id"
                    :rules="[isValid]"
                  />
                </v-col>
              </v-row>
              <v-row
                dense
                style="align-items: center;"
              >
                <v-col
                  v-if="!participantIsExpression"
                  sm="8"
                >
                  <v-combobox
                    ref="participants"
                    v-model="template.participants"
                    :items="template.participants"
                    label="Participants"
                    hint="Ao digitar cada participante aperte 'enter' para confirmar."
                    persistent-hint
                    multiple
                    chips
                    :rules="[isValidArray]"
                    name="qa-id-name-template-participants"
                    class="step-name-configuration_combobox"
                  />
                </v-col>
                <v-col
                  v-if="participantIsExpression"
                  sm="12"
                >
                  Participants_expression
                  <codemirror
                    v-model="template.participants_expression"
                    :options="cmOptions"
                  />
                </v-col>
                <v-col sm="4">
                  <BaseButton
                    type="text"
                    qa-identification-name="qa-id-name-participant-expression"
                    color="blueff"
                    class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
                    @click="participantIsExpression = !participantIsExpression"
                  >
                    Alterar para {{ participantIsExpression ? 'formulário' : 'expressão' }}
                  </BaseButton>
                </v-col>
              </v-row>
              <BaseCard
                :base-card-container-classes="'mt-4'"
                :has-main-container="false"
                :base-card-title-class="'font-weight-bold base-card-title-medium'"
              >
                <template v-slot:header-title>
                  Attributes
                </template>
                <v-row
                  dense
                  style="align-items: center;"
                  class="px-4"
                >
                  <v-col
                    sm="12"
                  >
                    <codemirror
                      v-model="attributeObject"
                      :options="cmOptions"
                    />
                  </v-col>
                  <v-col>
                    <BaseButton
                      type="text"
                      color="blueff"
                      class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
                      :is-disabled="!attributeObject"
                      @click="addAttribute(attributeObject)"
                    >
                      Adicionar
                    </BaseButton>
                  </v-col>
                </v-row>
                <template v-for="item, index in template.attributes">
                  <div
                    v-if="item"
                    :key="index"
                  >
                    <tr>
                      <td 
                        class="app-align-items-center px-6"
                      >
                        <BaseTextTooltip
                          :text-item="item"
                          width-item="500"
                        />
                      </td>
                      <td
                        class="pr-2"
                      >
                        <BaseButton
                          type="text"
                          color="blueff"
                          class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0 app-align-items-center"
                          @click="editAttribute(item, index, attributeObject)"
                        >
                          <v-icon small>
                            edit
                          </v-icon>
                        </BaseButton>
                      </td>
                      <td>
                        <BaseButton
                          type="text"
                          color="blueff"
                          class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0 app-align-items-center"
                          @click="deleteAttribute(index)"
                        >
                          <v-icon small>
                            delete
                          </v-icon>
                        </BaseButton>
                      </td>
                    </tr>
                    <v-divider />
                  </div>
                </template>
              </BaseCard>
            </div>

            <BaseCard
              :base-card-container-classes="'mt-4'"
              :has-main-container="false"
              :base-card-title-class="'font-weight-bold base-card-title-medium'"
            >
              <template v-slot:header-title>
                Documentos
              </template>
              <template v-slot:header-actions>
                <BaseButton
                  type="text"
                  qa-identification-name="qa-id-name-add-document"
                  color="blueff"
                  :is-disabled="showFormAddDocument"
                  class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
                  @click="showFormAddDocument = true"
                >
                  Adicionar
                </BaseButton>
              </template>
              <v-form
                ref="documentTemplateForm"
                v-model="documentValid"
              >
                <template v-if="showFormAddDocument">
                  <div class="mt-3 mx-4">
                    <v-row dense>
                      <v-col sm="3">
                        <v-select
                          ref="datasource"
                          v-model="document.datasource"
                          :items="['transaction','movement', 'empty', 'queue', 'generator']"
                          label="Datasource"
                          class="mr-2 ml-2"
                          name="qa-id-name-document-datasource"
                          :rules="[isValid]"
                        />
                      </v-col>
                      <v-col sm="3">
                        <v-select
                          ref="preview"
                          v-model="document.preview"
                          :items="[true, false]"
                          label="Preview"
                          class="mr-2"
                          name="qa-id-name-document-preview"
                          :rules="[isValidBoolean]"
                        />
                      </v-col>
                      <v-col sm="3">
                        <BaseInput
                          ref="sequence"
                          v-model="document.sequence"
                          class="mr-2"
                          label="Sequence"
                          :rules="[isValid, isOnlyNumber]"
                        />
                      </v-col>
                      <v-col sm="3">
                        <BaseInput
                          ref="type"
                          v-model="document.type"
                          class="mr-2"
                          label="Type"
                          :rules="[isValid]"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col sm="12">
                        <BaseInput
                          ref="template"
                          v-model="document.template"
                          class="mr-2 ml-2"
                          label="Template"
                          :rules="[isValid]"
                        />
                      </v-col>
                    </v-row>
                  </div>
                  <div class="mt-3 mx-4">
                    <v-row dense>
                      <v-col sm="12">
                        signature_expression
                        <codemirror
                          v-model="document.signature_expression"
                          :options="cmOptions"
                        />
                      </v-col>
                    </v-row>
                    <v-divider class="my-6" />
                    <div class="app-align-items-center">
                      <h2 class="mx-1 my-4">
                        Assinantes
                      </h2>
                      <v-spacer />
                      <BaseButton
                        type="text"
                        qa-identification-name="qa-id-name-participant-expression"
                        color="blueff"
                        class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
                        @click="recipientIsExpression = !recipientIsExpression"
                      >
                        Alterar assinantes para {{ recipientIsExpression ? 'formulário' : 'expressão' }}
                      </BaseButton>
                    </div>
                    <v-row
                      dense
                      class="mt-4"
                    >
                      <v-col
                        v-if="recipientIsExpression"
                        sm="12"
                      >
                        recipients_expression
                        <codemirror
                          v-model="document.recipients_expression"
                          :options="cmOptions"
                        />
                      </v-col>
                    </v-row>
                  </div>
                  <RecipientTemplate
                    v-if="!recipientIsExpression"
                    :recipients-array="recipientList"
                    @onUpdateRecipient="onUpdateRecipient"
                    @onUpdateForm="onUpdateForm"
                  />
                  <v-row
                    dense
                    justify="end"
                  >
                    <v-col
                      cols="auto"
                      class="mb-4"
                    >
                      <BaseButton
                        type="text"
                        color="primary"
                        :is-disabled="disableCancelButton"
                        @click="closeFormDocument()"
                      >
                        Cancelar
                      </BaseButton>
                      <BaseButton
                        type="depressed"
                        color="primary"
                        class="mr-4"
                        :is-disabled="disableButton || disableCancelButton"
                        @click="addDocument(document)"
                      >
                        {{ indexEditingDocument != null ? 'EDITAR' : 'SALVAR' }} DOCUMENTO
                      </BaseButton>
                    </v-col>
                  </v-row>
                </template>
              </v-form>
              <template v-if="!showFormAddDocument && template.documents.length">
                <BaseDataTable
                  :headers="['', '']"
                  :items="template.documents"
                  :hide-actions="true"
                  :hide-headers="true"
                  background-color="transparent"
                >
                  <template v-slot:inject-items="scopedItems">
                    <tr
                      v-for="value in scopedItems"
                      :key="value.index"
                    >
                      <td>
                        {{ value.item.datasource }}
                      </td>
                      <td>
                        {{ value.item.type }}
                      </td>
                      <td
                        class="text-right app-align-items-center"
                        style="justify-content: flex-end; min-width: 130px;"
                      >
                        <BaseButton
                          type="text"
                          color="blueff"
                          class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
                          @click="editDocument(value.item, value.index)"
                        >
                          Editar
                        </BaseButton>
                      </td>
                    </tr>
                  </template>
                </BaseDataTable>
              </template>
            </BaseCard>
            <v-row
              dense
              justify="end"
            >
              <v-col
                cols="auto"
                class="mb-4"
              >
                <BaseButton
                  type="text"
                  color="primary"
                  class="mr-0"
                  :is-disabled="showFormAddDocument"
                  @click="closeModal()"
                >
                  Cancelar
                </BaseButton>
                <BaseButton
                  type="depressed"
                  color="primary"
                  class="mr-0"
                  :is-disabled="!templateValid || !template.documents.length || showFormAddDocument || (participantIsExpression && !template.participants_expression)"
                  @click="addTemplate(template)"
                >
                  SALVAR TEMPLATE
                </BaseButton>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </BaseModal>
    </v-dialog>
    <BaseSnackbar
      :active-snackbar="feedback"
      :text-snackbar="feedbackMessage"
      :type-snackbar="feedbackColor"
      @closeSnackbar="feedback = !feedback"
    />
  </div>
</template>

<script>
import BaseModal from '../../BaseModal'
import BaseSnackbar from '../../BaseSnackbar'
import BaseSnackbarConfiguration from '../../../mixins/BaseSnackbarConfiguration'
import BaseDataTable from '../../BaseDataTable'
import { mapState, mapActions } from 'vuex'
import RecipientTemplate from './RecipientTemplate'
import { codemirror } from 'vue-codemirror'
import BaseTextTooltip from '../../BaseTextTooltip.vue'

export default {
  name: 'EnvelopeTemplateModal',
  components: {
    BaseModal,
    BaseDataTable,
    RecipientTemplate,
    BaseSnackbar,
    codemirror,
    BaseTextTooltip
  },
  mixins: [ BaseSnackbarConfiguration ],
  props: {
    openModalEnvelope: {
      type: Boolean,
      required: false,
      default: false
    },
    templateObject: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      recipientIsExpression: true,
      isModalActive: false,
      pagination: {
        page: 1,
        itemsPerPage: 5
      },
      recipientList: [],
      attributeObject: '',
      participantIsExpression: false,
      showFormAddDocument: false,
      disableCancelButton: false,
      templateValid: false,
      template: {
        documents: [],
        attributes: []
      },
      documentValid: false,
      document:  {
        recipients: [],
        recipients_expression: null,
      },
      indexEditingDocument: null,
      modalSignStep: {
        showIndeterminateProgress: false,
        topSlotContentTitle: "Template de Formalização",
        removeButtonClose: false,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: "",
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "Cancelar",
        bottomSlotContentButtonConfirmLabel: "Salvar",
        removeButtonCancel: true,
        removeButtonConfirm: true,
        onCancel: this.closeModal,
        onConfirm: null,
      },
      cmOptions: {
        tabSize: 4,
        mode: 'groovy',
        theme: 'base16-dark',
        lineNumbers: true,
        line: true
      }
    }
  },
  computed: {
    ...mapState({
      envelopeTemplateList: state => state.platformConfiguration.envelopeTemplateList
    }),
    disableButton(){
      return !this.documentValid  || !this.document.signature_expression || (!this.document.recipients_expression && this.document.recipients.length == 0)
    }
  },
  watch: {
    openModalEnvelope() {
      if(this.openModalEnvelope){
        this.isModalActive = this.openModalEnvelope
        this.template =  this.templateObject
        this.participantIsExpression = this.template.participants_expression ? true : false
      }
    }
  },
  methods: {
    ...mapActions('platformConfiguration',
      ['apiGetEnvelopeTemplateList', 'apiUpdateEnvelopeTemplate', '', 'apiCreateEnvelopeTemplate']
    ),
    async getEnvelopeTemplateList() {
      let payload  = {
        filter: '',
        page: 1,
        size: 25
      }
      await this.apiGetEnvelopeTemplateList(payload);
    },
    closeModal() {
      this.$emit('onCloseModalEdit')
      this.isModalActive = false
      this.$refs['envelopeTemplateForm'].reset()
      this.$refs['documentTemplateForm'].reset()
      Object.assign(this.template, {
        documents: [],
        participants_expression: '',
        attributes: []
      })
      this.attributeObject = ''
      this.document.signature_expression = ''
      this.document.recipients_expression = ''
      this.showFormAddDocument = false
    },
    updateModalActive(value) {
      this.template.documents = []
      this.isModalActive = value
    },
    onUpdateRecipient(data) {
      this.document.recipients = data
    },
    onUpdateForm(data) {
      this.disableCancelButton = data
    },
    closeFormDocument() {
      this.showFormAddDocument = false
      this.$refs['documentTemplateForm'].reset()
      this.indexEditingDocument = null
      this.document.signature_expression = ''
      this.recipientList = []
    },
    addDocument(document) {
      const newDocument = { ...document }
      newDocument.sequence = parseInt(newDocument.sequence)
      this.removeTabsForm(newDocument)

      if (this.indexEditingDocument != null) {
        this.template.documents[this.indexEditingDocument] = newDocument
      } else {
        this.template.documents.push(newDocument)
      }
      this.closeFormDocument()
    },
    async addTemplate(template) {
      const newTemplate = { ...template }
      this.removeTabsForm(newTemplate)
      
      this.template = {
        ...newTemplate,
        participants: newTemplate.participants_expression ? [] : newTemplate.participants
      }
      try {
        if(this.templateObject && this.templateObject.id){
          await this.apiUpdateEnvelopeTemplate(this.template)
        } else {
          await this.apiCreateEnvelopeTemplate(this.template)
        }
        this.showFeedback({
          message: 'Template foi salvo com sucesso.',
          color: 'success'
        })
      } catch(e){
        this.showFeedback({
          message: 'Erro ao salvar template.',
          color: 'error'
        })
      } finally {
        this.getEnvelopeTemplateList()
        this.$refs['envelopeTemplateForm'].reset()
        this.template.participants_expression = ''
        this.attributeObject = ''
        this.template.attributes = []
        this.closeFormDocument()
        this.closeModal()
      }
    },
    addAttribute(value) {
      this.template.attributes.push(value)
      this.attributeObject = ''
    },
    deleteAttribute(index){
      this.template.attributes.splice(index, 1)
    },
    editAttribute(value, index, newValue){
        this.attributeObject = value
        if(index !== -1) {
          this.template.attributes[index] = newValue
          this.deleteAttribute(index)
        }    
    },
    async deleteTemplate(id) {
      await this.apiDeleteEnvelopeTemplate
    },
    editDocument(data, index) {
      const { sequence, ...document } = data
      this.document = {
        sequence: sequence.toString(),
        ...document
      }
      this.recipientList = document.recipients
      this.onUpdateRecipient(this.recipientList)
      this.showFormAddDocument = true
      this.indexEditingDocument = index
      this.recipientIsExpression = this.document.recipients_expression ? true : false
    }
  }
}
</script>

<style>
.CodeMirror.cm-s-base16-dark {
  height: 90px!important;
}
</style>
