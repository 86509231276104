<template>
  <div>
    <v-text-field
      v-if="configOfVueTheMask.mask"
      :ref="refName"
      v-mask="configOfVueTheMask"
      class="base-input"
      :name="qaIdentificationName"
      v-bind="{ ...$attrs, ...$props }"
      :rules="rulesToApply"
      :value="inputValue"
      :maxlength="maxLength"
      v-on="inputListeners"
      @blur="handleBlur"
    >
      <template
        v-for="(slotValue, slotName) in $slots"
        v-slot:[slotName]
      >
        <slot :name="slotName" />
      </template>
    </v-text-field>

    <v-text-field
      v-else
      :ref="refName"
      :name="qaIdentificationName"
      class="base-input"
      v-bind="{ ...$attrs, ...$props }"
      :rules="rulesToApply"
      v-on="$listeners"
      @blur="handleBlur"
    >
      <template
        v-for="(slotValue, slotName) in $slots"
        v-slot:[slotName]
      >
        <slot :name="slotName" />
      </template>
    </v-text-field>
  </div>
</template>

<script>

import TheMask from '../../plugin/vueTheMask'

export default {
  name: 'BaseInput',
  components: {
    /* eslint-disable vue/no-unused-components */
    TheMask
  },
  props: {
    ...TheMask.props,
    value: {
      type: String,
      required: false,
    },
    qaIdentificationName: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    refName: {
      type: String,
      required: false,
      default: ''
    },
    rules: {
      type: Array,
      required: false,
      default: () => []
    },
    maxLength: {
      type: String,
      required: false,
      default: () => ''
    }
  },
  data() {
    return {
      valid: true,
      inputValue: '',
      outputValue: '',
      errors: [],
    }
  },
  computed: {
    ...TheMask.computed,
    rulesToApply() {
      return this.rules.map((fn, index) => {
        const isLastFn = index === this.rules.length - 1 ? true : false

        return (value) => this.validatorInput(value, fn, isLastFn)
      })
    }
  },
  methods: {
    reset() {
      if (this.refName) {
        this.$refs[this.refName].reset()
      }
    },
    handleBlur(event) {
      this.$emit("blur", {valid: this.valid, event})
    },
    validatorInput(value, fnValidation, isLastFn) {
      const result = fnValidation(value)

      this.updateValuesOfShowOrHideInputErrors(result, isLastFn)

      return result
    },
    updateValuesOfShowOrHideInputErrors(value, isLastFn) {
      const result = value === true ? true : false
      this.updateErrors(result)

      const isValid = this.errors.indexOf(false) !== -1 ? false : true
      this.setValid(isValid)

      if (isLastFn) {
        this.updateErrors([])
      }
    },
    updateErrors(value) {
      if (Array.isArray(value)) {
        this.errors = value
      } else {
        this.errors.push(value)
      }
    },
    setValid(value) {
      this.valid = value
    }
  },
  ...TheMask.hooks
}
</script>

<style scoped>
.base-input >>> .v-input__slot {
  align-items: center;
}

.base-input >>> .v-input__append-inner,
.base-input >>> .v-input__prepend-inner {
  align-self: auto;
}
</style>
