<template>
  <div
    class="base-dinamic-filters app-align-items-center"
    style="flex-wrap: wrap;"
  >
    <template v-for="filter, index in filterListSelected">
      <v-menu
        v-if="filter.input_type == 'date' && filter.default"
        :key="index"
        :close-on-content-click="false"
        :min-width="288"
        offset-y
        attach
      >
        <template v-slot:activator="{ on }">
          <v-chip
            class="ma-2 ml-0 font-weight-bold"
            label
            text-color="primary"
            color="secondary"
            close
            :disabled="loadPage"
            v-on="on"
            @click:close="removeFilterList(index)"
          >
            <v-icon
              left
              small
            >
              calendar_today
            </v-icon>
            <span v-if="filter.modelRadioDate == 'customDate'">
              {{
                filter.values[0] | getDate
              }} - {{ filter.values[1] | getDate }}
            </span>
            <span v-else>
              {{ filter.default.type == 'past' ? 'Últimos' : 'Próximos' }} {{ filter.modelRadioDate || filter.default.date }} dias
            </span>
            &nbsp; ({{ filter.name }})
            <v-icon
              left
              class="mx-1"
            >
              arrow_drop_down
            </v-icon>
          </v-chip>
        </template>
        <v-card>
          <v-list
            class="px-4"
          >
            <v-radio-group
              v-model="filter.modelRadioDate"
              hide-details
              class="my-3"
              @change="changeDateFilter(filter.modelRadioDate,[filter.modelFilterBetweenStart, filter.modelFilterBetweenEnd], filter)"
            >
              <v-radio
                v-for="option, i in radioDateOptions"
                :key="i"
                :label="getRadioDateOptions(option, filter.default.type)"
                :value="option"
              />
            </v-radio-group>
            <div v-if="filter.modelRadioDate == 'customDate'">
              <div class="app-align-items-center">
                <BaseDatePicker
                  name-placeholder="Data Inicial"
                  :date-value="filter.modelFilterBetweenStart"
                  :max="filter.modelFilterBetweenEnd"
                  :max-width="118"
                  locale="pt-br"
                  time-zone="UTC"
                  @on-input-date-picker="(value) => { updateFilter(value, 'modelFilterBetweenStart', index) }"
                />
                <v-spacer />
                <BaseDatePicker
                  name-placeholder="Data Final"
                  :date-value="filter.modelFilterBetweenEnd"
                  :max="null"
                  :max-width="118"
                  locale="pt-br"
                  time-zone="UTC"
                  @on-input-date-picker="(value) => { updateFilter(value, 'modelFilterBetweenEnd', index) }"
                />
              </div>
              <BaseButton
                :is-disabled="disableCustomDateFilter(filter)"
                @click="changeDateFilter(filter.modelRadioDate,[filter.modelFilterBetweenStart,filter.modelFilterBetweenEnd], filter); filterDateMenu = false"
              >
                Aplicar
              </BaseButton>
            </div>
          </v-list>
        </v-card>
      </v-menu>

      <div
        v-else
        :key="filter.key"
      >
        <v-chip
          class="ma-2 ml-0"
          label
          text-color="primary"
          color="secondary"
          :disabled="loadPage"
          close
          @click:close="removeFilterList(index)"
        >
          <span class="font-weight-bold">{{ filter.name }}:&nbsp;</span>
          <template v-if="filter.values.length > 1">
            <span v-if="filter.input_type == 'date' || filter.input_type == 'amount' || filter.input_type == 'percent'">
              {{ formatFields(0, filter.values, filter.input_type) }} - {{ formatFields(1, filter.values, filter.input_type) }}
            </span>
            <span v-else>
              {{ filter.values.join(' - ') }}
            </span>
          </template>
          <template v-else>
            {{ formatFields(0, filter.values, filter.input_type) }}
          </template>
        </v-chip>
      </div>
    </template>

    <template>
      <v-form
        ref="formFilters"
        v-model="formValid"
      >
        <div
          :id="`id-selected-filter-${scope}`"
          class="text-center"
          :style="{'width' : !filterMenu? '0' : 'unset', 'position': 'relative' }"
        >
          <v-menu
            v-model="filterMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            max-width="408"
            offset-y
            :attach="`#id-selected-filter-${scope}`"
          >
            <template v-slot:activator="{ on }">
              <v-chip
                class="ma-2 font-weight-bold"
                label
                text-color="primary"
                :disabled="!filterSelected.name"
                :color="filterMenu ? 'secondary' : 'transparent'"
                v-on="on"
              >
                {{ filterSelected.name }}
              </v-chip>
            </template>

            <v-card
              width="340"
              class="px-4 pb-0 pt-4 text-left"
            >
              <template
                v-if="filterSelected.field.input_type == 'text' && filterSelected.condition != 'between'"
              >
                <BaseInput
                  v-model="modelFilter"
                  :rules="[isValid]"
                  :label="filterSelected.name"
                  class="mr-2"
                />
              </template>

              <template v-if="filterSelected.field.input_type == 'amount'">
                {{ filterSelected.name }}
                <template v-if="filterSelected.condition == 'between'">
                  <div class="app-align-items-center mt-4">
                    <BaseInput
                      ref="amountFilter"
                      v-model="modelFilterBetween.start"
                      v-money="amountMask"
                      :rules="executeValidation(modelFilterBetween.start)"
                      label="Inicial (R$)*"
                      class="mr-3"
                    />
                    <BaseInput
                      ref="amountFilter"
                      v-model="modelFilterBetween.end"
                      v-money="amountMask"
                      :rules="executeValidation(modelFilterBetween.end)"
                      label="Final (R$)*"
                    />
                  </div>
                </template>
                <template v-else>
                  <BaseInput
                    ref="amountFilter"
                    v-model="modelFilter"
                    v-money="amountMask"
                    :rules="executeValidation(modelFilter)"
                    label="Valor (R$)*"
                  />
                </template>
              </template>

              <template v-if="filterSelected.field.input_type == 'percent'">
                {{ filterSelected.name }}
                <template v-if="filterSelected.condition == 'between'">
                  <div class="app-align-items-center mt-4">
                    <BaseInput
                      ref="percentFilter"
                      v-model="modelFilterBetween.start"
                      v-money="rateMask"
                      :rules="executeValidation(modelFilterBetween.start)"
                      label="Inicial (%)*"
                      class="mr-3"
                    />
                    <BaseInput
                      ref="percentFilter"
                      v-model="modelFilterBetween.end"
                      v-money="rateMask"
                      :rules="executeValidation(modelFilterBetween.end)"
                      label="Final (%)*"
                    />
                  </div>
                </template>
                <template v-else>
                  <BaseInput
                    ref="percentFilter"
                    v-model="modelFilter"
                    v-money="rateMask"
                    :rules="executeValidation(modelFilter)"
                    label="Valor (%)*"
                  />
                </template>
              </template>

              <template
                v-if="filterSelected.field.input_type == 'text' && filterSelected.condition == 'between'"
              >
                {{ filterSelected.name }}
                <BaseInput
                  v-model="modelFilterBetween.start"
                  :rules="[isValid]"
                  label="Inicial"
                  class="mr-2"
                />
                <BaseInput
                  v-model="modelFilterBetween.end"
                  :rules="[isValid]"
                  label="Final"
                  class="mr-2"
                />
              </template>

              <template v-if="filterSelected.field.input_type == 'date'">
                {{ filterSelected.name }}
                <div class="app-align-items-center mt-4">
                  <div class="mr-3">
                    <BaseDatePicker
                      name-placeholder="Data Inicial"
                      :date-value="modelFilterBetween.start"
                      :max="null"
                      :max-width="118"
                      locale="pt-br"
                      time-zone="UTC"
                      @on-input-date-picker="(value) => { updateFilter(value, 'start') }"
                    />
                  </div>
                  <BaseDatePicker
                    name-placeholder="Data Final"
                    :date-value="modelFilterBetween.end"
                    :max="null"
                    :max-width="118"
                    locale="pt-br"
                    time-zone="UTC"
                    @on-input-date-picker="(value) => { updateFilter(value, 'end') }"
                  />
                </div>
              </template>

              <template
                v-if="filterSelected.field.input_type == 'select' || filterSelected.field.input_type == 'multiselect'"
              >
                <v-select
                  v-model="modelFilter"
                  :items="suggestions[filterSelected.field.key]"
                  :label="filterSelected.name"
                  class="mr-2"
                  @click="suggestValues()"
                />
              </template>

              <v-card-actions class="px-0">
                <BaseButton
                  v-if="filterSelected.condition == 'between'"
                  :is-disabled="!modelFilterBetween.start || !modelFilterBetween.end || !formValid"
                  class="ml-0"
                  @click="addFilterInList([modelFilterBetween.start, modelFilterBetween.end]); filterMenu = false"
                >
                  Aplicar
                </BaseButton>
                <BaseButton
                  v-else-if="filterSelected.condition != undefined"
                  :is-disabled="!modelFilter || !formValid"
                  class="ml-0"
                  @click="addFilterInList([modelFilter]); filterMenu = false"
                >
                  Aplicar
                </BaseButton>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>
      </v-form>
    </template>

    <v-tooltip
      v-if="showResetDefaultFilters && showActionButtons"
      bottom
    >
      <template
        v-slot:activator="{ on }"
      >
        <span
          v-on="on"
        >
          <v-chip
            class="ml-2 pa-2 font-weight-bold"
            label
            text-color="primary"
            color="secondary"
            :disabled="loadPage"
            @click="setDefaultFilters"
          >
            <v-icon
              small
            >
              close
            </v-icon>
          </v-chip>
        </span>
      </template>
      <span>Restaurar filtros padrões</span>
    </v-tooltip>

    <v-tooltip
      v-if="showActionButtons"
      bottom
    >
      <template
        v-slot:activator="{ on }"
      >
        <span
          v-on="on"
        >
          <v-chip
            class="ml-2 pa-2 font-weight-bold"
            label
            text-color="primary"
            color="secondary"
            :disabled="loadPage"
            @click="applyFilter"
          >
            <v-icon
              small
            >
              mdi-reload
            </v-icon>
          </v-chip>
        </span>
      </template>
      <span>Atualizar tabela</span>
    </v-tooltip>


    <template v-if="filterListWithoutDate.length > 0">
      <div
        :id="`id-list-filter-${scope}`"
        class="text-center"
        :style="{'position': 'relative'}"
      >
        <v-menu
          offset-y
          :max-height="300"
          :nudge-width="124"
          :attach="`#id-list-filter-${scope}`"
        >
          <template v-slot:activator="{ on }">
            <BaseButton
              type="text"
              class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
              v-on="on"
            >
              <v-icon>
                add
              </v-icon>
              <span class="ml-1">{{ labelAddFilter }}</span>
            </BaseButton>
          </template>
          <v-list
            class="overflow-y-auto text-left"
            style="max-height: 300px"
          >
            <v-list-item
              v-for="(item, index) in filterListWithoutDate"
              :key="index"
              link
              @click="filterSelected = item; filterMenu = true"
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
    <MenuConfigurationSchemaField
      v-if="isAdminEditing"
      menu-title="Configurar filtro"
      name="configurationFilterAsset"
      :config-list="filterList[scope] ? filterList[scope] : []"
      :form-field-list="['condition', 'input_type', 'default']"
      :scope-schema="filterScope"
      @removeItemSchema="removeItemSchema"
      @addOrEditItemSchema="addOrEditItemSchema"
      @changeOrderItemSchema="changeOrderItemSchema"
    />
    <BaseSnackbar
      :active-snackbar="feedback"
      :type-snackbar="feedbackColor"
      :text-snackbar="feedbackMessage"
      @closeSnackbar="feedback = !feedback"
    />
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import BaseDatePicker from './BaseDatePicker'
import {amountMask, rateMask} from '../directives/moneyMasks'
import MenuConfigurationSchemaField from '../components/Platform/MenuConfigurationSchemaField'
import BaseSnackbarConfiguration from '../mixins/BaseSnackbarConfiguration'
import BaseSnackbar from './BaseSnackbar'

export default {
  components: { BaseDatePicker, MenuConfigurationSchemaField, BaseSnackbar},
  mixins: [BaseSnackbarConfiguration],
  props: {
    isAdminEditing: {
      type: Boolean,
      required: false,
      default: false
    },
    filterScope: {
      type: String,
      required: false,
      default: ''
    },
    loadPage: {
      type: Boolean,
      required: true
    },
    scope: {
      type: String,
      required: true
    },
    pagination: {
      type: Object,
      required: true
    },
    labelAddFilter: {
      type: String,
      required: false,
      default: 'Filtro'
    },
    showActionButtons: {
      type: Boolean,
      required: false,
      default: true
    },
    valuesValidation: {
      type: Object,
      required: false,
      default: ()=> {}
    }
  },
  data: () => ({
    amountMask,
    rateMask,
    filterSelected: {
      field: {}
    },
    filterData: {
      filters: [],
      pagination: {}
    },
    filterListSelected: [],
    filterMenu: false,
    filterDateMenu: false,
    radioDateOptions: [ "7", "15", "30", "customDate"],
    modelFilter: '',
    modelFilterBetween: {
      start: '',
      end: ''
    },
    suggestions: {},
    showResetDefaultFilters: false,
    filterList: {},
    formValid: false
  }),
  computed: {
    ...mapState({
      suggestionList: (state) => state.programFilters.suggestions,
      filteredData: (state) => state.programFilters.filteredData,
      configSchemaRole: (state) => state.platformConfiguration.configSchemaRole,
    }),
    hasUrlQuery(){
      return this.$route.query.date || this.$route.query.number
    },
    filterListWithoutDate() {
      return this.filterList[this.scope] ? this.filterList[this.scope].filter(filter => {
        if(filter.field.input_type == 'date'){
          return !this.filterListSelected.filter(f => f.key == filter.field.key).length
        } else {
          return filter
        }
      }) : []
    }
  },
  watch: {
    filterSelected: {
      handler() {
        this.clearModels()
        if (new Date(this.modelFilterBetween.start).getTime() > new Date(
          this.modelFilterBetween.end).getTime()) {
          this.modelFilterBetween.start = ''
        }
      },
      deep: true
    },
    filterMenu() {
      if (!this.filterMenu) {
        this.cancelSelectedFilter()
      }
    },
    filterDateMenu() {
      if (!this.filterDateMenu) {
        this.cancelSelectedFilter()
      }
    },
    async configSchemaRole(){
      await this.getProgramFilters()
      this.setDefaultFilters()
    },
  },
  async created() {
    await this.getProgramFilters()
    if(this.hasUrlQuery){
      this.filterByUrlQuery()
    } else {
      this.setDefaultFilters()
    }
  },
  methods: {
    ...mapActions('programFilters',
      ['apiGetProgramFilters', 'apiPostSuggestValues', 'apiPostMovementsFilters', 'apiUpdateProgramFilters']),
    ...mapMutations('programMovements', ['setProgramMovements']),
    executeValidation(value){
      var validation = this.filterSelected.field.validation
      var input = value
      var values = this.valuesValidation
      var filters = this.filterListSelected

      if(validation){
        return [() => eval(validation)]
      } else {
        if(this.filterSelected.input_type == 'amount'){
          return [this.isValid, this.isAmountValid]
        } else if (this.filterSelected.input_type == 'percent'){
          return [this.isValidLengthForTax]
        }
      }
    },
    getRadioDateOptions(option, type){
      if(option == 'customDate'){
        return 'Personalizado'
      } else {
        return `${type == 'past' ? 'Últimos' : 'Próximos'} ${option} dias`
      }
    },
    async getProgramFilters(){
      const payload = {
        scope: this.scope,
        role: this.isAdminEditing ? this.configSchemaRole : null
      }
      let result = await this.apiGetProgramFilters(payload)

      this.filterList = Object.assign({}, {
        ...this.filterList,
        [this.scope]: result
      })
    },
    disableCustomDateFilter(filter) {
      return !filter.modelRadioDate || (filter.modelRadioDate == 'customDate' && (!filter.modelFilterBetweenStart || !filter.modelFilterBetweenEnd))
    },
    setDateValuesFromType(type, date){
      if(type == 'past'){
        return [
          this.toLocalDate(this.minusDays(new Date(), date)).toISOString().substr(0, 10),
          this.toLocalDate(new Date()).toISOString().substr(0, 10)
        ]
      } else {
        return [
          this.toLocalDate(new Date()).toISOString().substr(0, 10),
          this.toLocalDate(this.plusDays(new Date(), date)).toISOString().substr(0, 10)
        ]
      }
    },
    setDefaultFilters(){
      this.filterListSelected = []
      this.filterList[this.scope].forEach((filter)=> {

        if(filter.default) {
          let defaultValue = filter.default
          this.filterSelected = filter

          if(filter.default.startsWith('{')){
            let jsonDefault = JSON.parse(filter.default)

            this.filterSelected = Object.assign({}, {
              ...this.filterSelected,
              modelRadioDate: jsonDefault.date,
              modelFilterBetweenStart: this.toLocalDate(new Date()).toISOString().substr(0, 10),
              modelFilterBetweenEnd: this.toLocalDate(new Date()).toISOString().substr(0, 10)
            })

            defaultValue = this.setDateValuesFromType(jsonDefault.type, jsonDefault.date)

          } else if(filter.default.startsWith('[')){
            defaultValue = JSON.parse(filter.default)
          }
          this.addFilterInList(defaultValue, true)
        }
      })
      this.applyFilter()
      this.showResetDefaultFilters = false
    },
    filterByUrlQuery() {
      if(this.$route.query.date) {

        this.filterSelected = this.filterList[this.scope].filter(filter => filter.field.key === 'date')[0] || { field: {} }

        if (this.findFilterDateApplied(this.filterSelected.field.key) > -1) {
          this.removeFilterList(this.findFilterDateApplied(this.filterSelected.field.key), true)
        }

        this.filterSelected = Object.assign({}, {
          ...this.filterSelected,
          modelRadioDate: 'customDate',
          modelFilterBetweenStart: this.$route.query.date,
          modelFilterBetweenEnd: this.$route.query.date
        })

        this.changeDateFilter('customDate',[this.$route.query.date, this.$route.query.date], this.filterSelected, true)
      }

      if(this.$route.query.number) {
        this.filterSelected = this.filterList[this.scope].filter(filter => filter.field.key === 'number')[0] || { field: {} }
        this.addFilterInList([this.$route.query.number], true)
      }
      this.applyFilter()
      this.$router.replace({ query: {} })
    },
    isAmountValid(value) {
      return this.parseNumberDecimal(value) > 0 || 'Valor inválido'
    },
    toLocalDate(date) {
      return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    },
    minusDays(date, days) {
      return new Date(date.setDate(date.getDate() - parseInt(days)))
    },
    plusDays(date, days){
      return new Date(date.setDate(date.getDate() + parseInt(days)))
    },
    cancelSelectedFilter() {
      this.filterMenu = false
      this.filterSelected = {
        field: {}
      }
    },
    findFilterDateApplied(filterKey) {
      return this.filterListSelected.findIndex(filter => filter.key === filterKey)
    },
    changeDateFilter(model, values, filter, notApplyFilter = false) {
      let dateValues = null

      if(model !== 'customDate'){
        dateValues = this.setDateValuesFromType(filter.default.type, model)
      } else {
        dateValues = values
      }

      if (this.findFilterDateApplied(filter.key) > -1) {
        this.$set(this.filterListSelected, this.findFilterDateApplied(filter.key), {
          key: filter.key,
          field: filter.field,
          condition: filter.condition,
          type: filter.field.type,
          default: filter.default,
          input_type: filter.input_type,
          name: filter.name,
          values: dateValues,
          modelRadioDate: filter.modelRadioDate,
          modelFilterBetweenStart: filter.modelFilterBetweenStart,
          modelFilterBetweenEnd: filter.modelFilterBetweenEnd
        })
        this.applyFilter()
      } else {
        this.addFilterInList(dateValues, notApplyFilter)
      }

    },
    executeActionFilter() {
      this.$emit('executeActionFilter', this.filterData)
    },
    clearModels() {
      this.modelFilter = ''
      this.modelFilterBetween = {
        start: '',
        end: ''
      }
      if (this.$refs.amountFilter) {
        this.forceResetInputValue(this, 'amountFilter')
      }
    },
    updateFilter(value, prop, index = null) {
      if(index != null){
        this.$set(this.filterListSelected[index], prop, value)
      } else {
        this.$set(this.modelFilterBetween, prop, value)
        this.filterSelected.modelRadioDate = 'customDate'
        this.filterSelected['modelFilterBetween'+ this.capitalize(prop)] = value
      }
    },
    capitalize(text){
      return text[0].toUpperCase() + text.slice(1);
    },
    async suggestValues() {
      await this.apiPostSuggestValues({category: this.scope, key: this.filterSelected.field.key})
      this.$set(this.suggestions, this.filterSelected.field.key, this.suggestionList);

    },
    removeFilterList(index, notApplyFilter = false) {
      this.filterListSelected.splice(index, 1);
      if (!notApplyFilter) {
        this.applyFilter()
      }
    },
    parseFiltersPayloadForAPI(){
      let newFilterData = []
      this.filterListSelected.forEach((filter)=> {
        const newFilter = Object.assign({}, {...filter})

        if(filter.input_type == 'date'){
          delete newFilter.modelRadioDate
          delete newFilter.modelFilterBetweenStart
          delete newFilter.modelFilterBetweenEnd
        }
        delete newFilter.field

        newFilterData.push(newFilter)
      })
      return newFilterData
    },
    async applyFilter() {
      Object.assign(this.filterData, {
        filters: this.parseFiltersPayloadForAPI(),
        pagination: {
          page: this.pagination.page,
          size: this.pagination.itemsPerPage
        },
        category: this.scope
      })

      this.executeActionFilter()
      this.showResetDefaultFilters = true
    },
    clearFilterList() {
      this.filterListSelected = []
      this.applyFilter()
    },
    addFilterInList(values, notApplyFilter = false) {
      const {field, condition, name} = this.filterSelected
      let fieldDefault = null
      if (field.input_type == 'amount') {
        values[0] = this.parseNumberDecimal(values[0]).toFixed(2)
        if(values[1])
        values[1] = this.parseNumberDecimal(values[1]).toFixed(2)
      } else if(field.input_type == 'percent') {
        values[0] = this.convertToDecimal(this.parseNumberDecimal(values[0])).toFixed(6)
        if(values[1])
        values[1] = this.convertToDecimal(this.parseNumberDecimal(values[1])).toFixed(6)
      }

      if(this.filterSelected.default){
        fieldDefault = typeof this.filterSelected.default == 'string' ? JSON.parse(this.filterSelected.default) : this.filterSelected.default
      } else {
        fieldDefault = ''
      }

      let filter = {
        key: field.key,
        field,
        condition,
        type: field.type,
        default: fieldDefault,
        input_type: field.input_type,
        name,
        values: values.map(value => {
          if(typeof value == 'string' && field.input_type != 'select') {
            return value.toUpperCase()
          }
          return value
        })
      }

      if (this.filterSelected.modelRadioDate){
        filter = {
          ...filter,
          modelRadioDate: this.filterSelected.modelRadioDate,
          modelFilterBetweenStart: this.filterSelected.modelFilterBetweenStart,
          modelFilterBetweenEnd: this.filterSelected.modelFilterBetweenEnd
        }
      }

      this.filterListSelected.push(filter)
      this.clearModels()
      this.filterSelected = {
        field: {}
      }
      if (!notApplyFilter) {
        this.applyFilter()
      }
    },
    removeObjectField(field){
      field.field.input_type = field.input_type
      delete field.input_type
      field.field.sequence = field.sequence;
      delete field.sequence
      return field
    },
    async removeItemSchema({ field, role }){
      const payloadRoles = field.roles.filter(elem => elem != role)
      const payload = this.removeObjectField(field)

      try {
        await this.apiUpdateProgramFilters({
          ...payload,
          roles: payloadRoles
        })
        this.showFeedback({
          message: 'Filtro removido com sucesso!',
          color: 'success'
        })
      } catch (error) {
        this.showFeedback({
          color: "error",
          message: 'Erro ao remover filtro!',
        })
      } finally {
        await this.getProgramFilters()
        this.setDefaultFilters()
      }
    },
    async addOrEditItemSchema({ data, role }){
      let field = {}

      try {

        if(data.roles && data.roles.length){
          field = {
            ...data.field,
            input_type: data.input_type || data.field.input_type
          }
          data.roles = [...new Set([role, ...data.roles])]
        } else {
          const filterObject = this.filterList[this.scope].filter(filter => filter.field.key === data.key)

          if(filterObject.length){
            field = {
              ...filterObject[0].field,
              input_type: data.input_type
            }

            data.roles = [...new Set([role, ...filterObject[0].roles])]
          } else {
            field = data
            data.roles = role
          }

          data.sequence = this.filterList[this.scope].length + 1
        }

        const payload = {
          scope: this.scope,
          name: data.name,
          condition: data.condition,
          roles: data.roles,
          default: data.default ? JSON.stringify(data.default) : '',
          field: {
            id: field.id,
            key: field.key,
            name: data.name,
            sequence: data.sequence,
            input_type: field.input_type
          }
        }
        await this.updateFilterSchema(payload)
      } catch (error) {
        this.showFeedback({
          color: "error",
          message: 'Erro ao adicionar filtro!',
        })
      } finally {
        await this.getProgramFilters()
        this.setDefaultFilters()
      }
    },
    async updateFilterSchema(payload){
      try {
        await this.apiUpdateProgramFilters(payload)
        this.showFeedback({
          message: 'Filtro editado com sucesso!',
          color: 'success'
        })
      } catch (error) {
        this.showFeedback({
          color: "error",
          message: 'Erro ao editar filtro!',
        })
      }
    },
    async changeOrderItemSchema({ data }){
      data.forEach(field => {
        field.field.sequence = field.sequence;
        delete field.sequence
        this.updateFilterSchema(field)
      });
      await this.getProgramFilters()
    }
  }
}
</script>

<style lang="scss">
.base-dinamic-filters {
  .v-btn.app-button__link-text[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
  }
  .v-list {
    background: white!important;
  }
}
.filter-radio-option .v-label {
  font-size: 14px!important;
}
</style>
