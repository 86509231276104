<template>
  <BaseCard
    :base-card-container-classes="'mt-4 mx-4 my-4'"
    :has-main-container="false"
    :base-card-title-class="'font-weight-bold base-card-title-medium'"
  >
    <template v-slot:header-title>
      Lista de assinantes
    </template>
    <template v-slot:header-actions>
      <BaseButton
        type="text"
        qa-identification-name="qa-id-name-add-recipient"
        color="blueff"
        :is-disabled="showFormAddRecipient"
        class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
        @click="changeValueShowForm(true)"
      >
        Adicionar
      </BaseButton>
    </template>

    <v-form
      ref="recipientTemplateForm"
      v-model="recipient.valid"
    >
      <template v-if="showFormAddRecipient">
        <div class="mt-3 mx-4">
          <v-row dense>
            <v-col sm="6">
              <BaseInput
                ref="recipient_name"
                v-model="recipient.name"
                class="mr-2 ml-2"
                label="Name"
                :rules="[isValid]"
              />
            </v-col>
            <v-col sm="3">
              <BaseInput
                ref="recipient_id_number"
                v-model="recipient.id_number"
                class="mr-2"
                label="Id number"
                :rules="[isValid]"
              />
            </v-col>
            <v-col sm="3">
              <BaseInput
                ref="recipient_role"
                v-model="recipient.role"
                class="mr-2"
                label="Role"
                :rules="[isValid]"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col sm="6">
              <EmailInput
                ref="recipient_email"
                v-model="recipient.email"
                class="mr-2 ml-2"
                label="Email"
                :rules="[isValid]"
              />
            </v-col>
            <v-col sm="6">
              <BaseInput
                ref="recipient_participant"
                v-model="recipient.participant"
                class="mr-2"
                label="Participant"
                :rules="[isValid]"
              />
            </v-col>
          </v-row>
        </div>
        <v-row
          dense
          justify="end"
        >
          <v-col
            cols="auto"
            class="mb-4"
          >
            <BaseButton
              color="primary"
              type="text"
              @click="closeAddRecipient"
            >
              Cancelar
            </BaseButton>
            <BaseButton
              type="depressed"
              color="primary"
              class="mr-4"
              :is-disabled="!recipient.valid"
              @click="addRecipient(recipient)"
            >
              {{ indexEditingRecipient != null ? 'EDITAR' : 'SALVAR' }} ASSINANTE
            </BaseButton>
          </v-col>
        </v-row>
      </template>
    </v-form>
    <template v-if="!showFormAddRecipient && recipientList.length">
      <BaseDataTable
        :headers="['', '', '']"
        :items="recipientList"
        :hide-actions="true"
        :hide-headers="true"
        background-color="transparent"
      >
        <template v-slot:inject-items="scopedItems">
          <tr
            v-for="value in scopedItems"
            :key="value.index"
          >
            <td>
              {{ value.item.name }}
            </td>
            <td>
              {{ value.item.email }}
            </td>
            <td
              class="text-right app-align-items-center"
              style="justify-content: flex-end; min-width: 130px;"
            >
              <BaseButton
                type="text"
                color="blueff"
                class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
                @click="editRecipient(value.item, value.index)"
              >
                Editar
              </BaseButton>
            </td>
          </tr>
        </template>
      </BaseDataTable>
    </template>
  </BaseCard>
</template>

<script>
import BaseDataTable from '../../BaseDataTable'
import EmailInput from '../../Input/EmailInput'

export default {
  name:'RecipientTemplate',
  components: { EmailInput, BaseDataTable },
  props: {
    recipientsArray: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      showFormAddRecipient: false,
      indexEditingRecipient: null,
      recipientList: [],
      recipient: {
        name: '',
        id_number: '',
        role: '',
        email: '',
        participant: '',
        valid: false
      }
    }
  },
  mounted(){
    this.recipientList = this.recipientsArray
  },
  methods:{
    clearFormRecipient() {
      this.indexEditingRecipient = null
      this.$refs['recipientTemplateForm'].reset()
    },
    closeAddRecipient(){
      this.clearFormRecipient()
      this.changeValueShowForm(false)
    },
    addRecipient(recipient) {
      const {valid, ...newRecipient} = recipient
      this.removeTabsForm(newRecipient)

      if (this.indexEditingRecipient != null) {
        this.recipientList[this.indexEditingRecipient] = newRecipient
      } else {
        this.recipientList.push(newRecipient)
      }

      this.$emit("onUpdateRecipient", this.recipientList)
      this.closeAddRecipient()
    },
    changeValueShowForm(value) {
      this.recipient = {}
      this.showFormAddRecipient = value
      this.$emit("onUpdateForm", value)
    },
    editRecipient(data, index) {
      this.recipient = { ...data }
      this.showFormAddRecipient = true
      this.indexEditingRecipient = index
      this.$emit("onUpdateForm", true)
    }
  }
}
</script>

<style>
</style>
