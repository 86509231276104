<template>
  <ul
    class="diagram"
    :style="translateDiagram"
  >
    <li
      v-for="(step, i) in diagramJSON.steps"
      :key="i"
      :class="[ step.status.toLowerCase() ]"
    >
      <DiagramOperationListItem
        :step="step"
        :parent="i.toString()"
      />
    </li>
  </ul>
</template>

<script>
import DiagramOperationListItem from "./DiagramOperationListItem"

export default {
  name: "DiagramOperationList",
  components: { DiagramOperationListItem },
  props: {
    diagramJSON: {
      type: Object,
      default: () => {}
    },
    diagramX: {
      type: Number,
      default: 0
    },
  },
  computed: {
    translateDiagram(){ return 'transform: translateX(' +  this.diagramX + 'px)'}
  }
}
</script>
