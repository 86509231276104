<template>
  <div class="operation-data">
    <BaseDataTable
      :items="operationOverview"
      :hide-headers="true"
      :hide-actions="operationHideActions"
      background-color="transparent"
      :total-items="listRegisterOperationTotalItens"
      @update-pagination="updatePagination"
    >
      <template v-slot:no-data-text-message>
        Nenhuma operação existente no momento.
      </template>

      <template v-slot:inject-items="scopedItems">
        <tr
          v-for="props in scopedItems"
          :key="props.index"
          :class="disabledClickOperation"
          @click="onClickOperationData(props.index)"
        >
          <td
            class="operation-data__overview pa-0"
            :class="$options.filters.transformToLowerCase(props.item.status)"
          >
            <p class="operation-data__overview__text">
              <span class="code"><v-icon>{{ parseIconCode(props.item.status) }}</v-icon></span>
              <span class="status">{{ parseStatusMessage(props.item.status) }}</span>
            </p>
          </td>
          <td class="operation-data__details py-0 px-6">
            <div class="operation-data__info">
              <h6 class="font-weight-regular title mb-1">
                {{ props.item.description }}
              </h6>
              <p class="operation-data__info__text">
                <span class="date">
                  <v-icon>access_time</v-icon>Data: {{ props.item.createdAt | getDate }}
                  <span class="time">Horário: {{ props.item.createdAt | getTime }}</span>
                </span>
              </p>
            </div>
          </td>
        </tr>
      </template>
    </BaseDataTable>
  </div>
</template>

<script>
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'
import loader from '../global/loader'
import BaseDataTable from './BaseDataTable'

export default {
  name: "OperationData",
  components: {BaseDataTable},
  props: {
    operationHideActions: {type: Boolean, default: false},
    operationListRegisterExecution: {type: Array},
    operationDisableEventClick: {type: Boolean, default: false}
  },
  data() {
    return {
      disabledFirstLoadUpdateDataTable: true
    }
  },
  computed: {
    ...mapGetters(['getCurrentRegisterOperation']),
    ...mapState({
      listRegisterOperationTotalItens: (state) => {
        return state.operationRegister.listRegisterOperation.totalItems
      },
      pagination: (state) => {
        return state.operationRegister.pagination
      },
      currentOperationInfo: (state) => state.operationRegister.currentOperationInfo
    }),
    disabledClickOperation() {
      return {'click': !this.operationDisableEventClick}
    },
    operationOverview(){
      if(this.operationListRegisterExecution.length){
        return this.operationListRegisterExecution
      } else {
        return  this.getCurrentRegisterOperation ? [ this.getCurrentRegisterOperation ] : []
      }
    },
  },
  created() {
    this.apiGetRegisterOperation()
  },
  methods: {
    ...mapMutations(['setCurrentOperationInfo', 'setPagination']),
    ...mapActions(['apiGetRegisterOperation']),
    onClickOperationData(index) {
      if (!this.operationDisableEventClick) {
        this.$emit("onClickOperationData", this.operationListRegisterExecution[index].id)
      } else {
        return
      }
    },
    updatePagination(pagination) {
      const reducePaginationAttributes = {
        page: pagination.page,
        rowsPerPage: pagination.rowsPerPage
      }
      this.setPagination(reducePaginationAttributes)

      if (this.disabledFirstLoadUpdateDataTable) {
        this.disabledFirstLoadUpdateDataTable = false
        return
      }

      loader.startProgressLoadPage()
      this.apiGetRegisterOperation().then(() => {
        loader.stopProgressLoadPage()
      })
    },
    parseIconCode(status) {
      switch (status.toLowerCase()) {
        case "ok":
          return "check_circle"
        case "error":
          return "cancel"
        case "pending":
          return "lens"
        case "running":
          return "timelapse"
      }
    },
    parseStatusMessage(status) {
      switch (status.toLowerCase()) {
        case "ok":
          return "Sucesso"
        case "error":
          return "Erro"
        case "pending":
          return "Pendente"
        case "running":
          return "Em andamento"
        default:
          return status.charAt(0).toUpperCase() + status.toLowerCase().substring(1)
      }
    }
  }
}
</script>

<style>

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > .operation-data__details:last-child {
  border-bottom: none !important;
}

.operation-data .v-datatable {
  background-color: transparent !important;
}

.operation-data table {
  background-color: transparent !important;
  display: block;
}

.operation-data thead,
.operation-data tbody {
  display: flex;
  flex-flow: row wrap;
  flex: 0 0 100%;
}

.operation-data tbody {
  align-content: flex-start;
}

.operation-data tr {
  align-items: center;
  background-color: var(--v-white-base);
  border-radius: 2px;
  box-shadow: 0px 1px 3px var(--custom-theme-black-038);
  display: flex;
  flex-flow: row wrap;
  flex: 0 0 calc(100% - 6px);
  margin: 3px 3px 10px 3px;
}

.operation-data tr:hover {
  background: inherit !important;
}

.operation-data tr[class*="v-datatable__progress"] {
  margin: 0 !important;
}

.operation-data tr[class*="v-datatable__progress"] > * {
  width: 100%;
}

.operation-data tr > * {
  display: inline-block;
  width: 100%;
}

.operation-data tbody tr {
  height: auto;
}

.operation-data tbody tr > * {
  height: auto !important;
}

.operation-data__overview {
  flex: 0 0 165px;
  margin: 1px;
}

.operation-data__details {
  flex: 1 1 calc(100% - 167px);
}

.operation-data__overview__text {
  align-content: center;
  align-items: center;
  color: var(--custom-theme-black-038);
  display: flex;
  flex-wrap: wrap;
  height: 115px;
  justify-content: center;
  margin-bottom: 0;
  text-align: center;
}

.operation-data__overview__text > * {
  flex: 1 1 100%;
}

.operation-data__overview__text .status {
  font-size: 16px;
}

.operation-data__info__text {
  color: var(--custom-theme-black-038);
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 0;
  position: relative;
}

.operation-data__info__text > * {
  display: block;
  padding-left: 16px;
  position: relative;
}

.operation-data__info__text.date > p {
  margin-bottom: 0;
}

.operation-data__info__text > * i {
  font-size: 12px !important;
  position: absolute !important;
  left: 0;
  top: 4px;

}

.operation-data__info__text > * *:not(i) {
  display: block;
}

.operation-data__info__text i {
  font-size: 12px;
  vertical-align: middle;
}

.operation-data__info__text .file > *:not(i) {
  display: inline-block;
  color: var(--custom-theme-black-038);
}

.operation-data__overview__text .status {
  display: block;
  padding-top: 5px;
}

.operation-data .click {
  cursor: pointer;
}

.operation-data .click:hover {
  background: var(--v-white-base) !important;
}
</style>
