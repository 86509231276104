import api from '../../services/api'

export default {
  actions: {
    async apiHashes(context, payload) {
      const {uuid, certificate, page, size} = payload
      const result = await api.get("/hashes", {
        params: {
          id: uuid,
          page: page,
          size: size
        },
        headers: {
          'x-sign-certificate': certificate
        },
        validateStatus: () => {
          return true;
        }
      })

      return result
    },
    async apiSign(context, payload){
      const result = await api.post("/sign", payload)

      return result
    }
  }
}
