<template>
  <div class="diagram-operation pt-12">
    <p class="body-1 text--secondary mb-0">
      <span
        class="subtitle-1 text--primary font-weight-bold pb-1"
        style="display:block;"
      >
        Fluxo da Operação</span> {{ lengthStepsInDiagramJSON }}
    </p>
    <div
      class="diagram-container"
      :class="{ 'diagram-button-hide-after' : diagramHiddenNavigationAfter, 'diagram-button-hide-before' : diagramHiddenNavigationBefore }"
    >
      <BaseButton
        :absolute="true"
        :dark="true"
        type="fab"
        size="small"
        color="primary"
        class="diagram-button diagram-button--before"
        @click="diagramButton(true)"
      >
        <v-icon>navigate_before</v-icon>
      </BaseButton>
      <DiagramOperationList
        :diagram-j-s-o-n="diagramJSON"
        :diagram-x="diagramX"
      />
      <BaseButton
        :absolute="true"
        :dark="true"
        type="fab"
        size="small"
        color="primary"
        class="diagram-button diagram-button--after"
        @click="diagramButton()"
      >
        <v-icon>navigate_next</v-icon>
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DiagramOperationList from "./DiagramOperationList"

export default {
  name: 'DiagramOperation',
  components: { DiagramOperationList },
  data(){
    return {
      diagramContainer: null,
      diagram: null,
      diagramX: 0,
      diagramHiddenNavigationBefore: true,
      diagramHiddenNavigationAfter: false,
    }
  },
  methods: {
    diagramResize(){
      this.diagramX = 0;
      this.diagramHiddenNavigationBefore = !this.diagramRuleBefore();
      this.diagramHiddenNavigationAfter = this.diagramRuleAfter();
    },
    diagramRuleBefore(){
      if(this.diagramX < 0){
        return true;
      }
      else {
        return false;
      }
    },
    diagramRuleAfter(){
      if(this.diagram.scrollWidth > (this.diagram.offsetWidth + Math.abs(this.diagramX)) ){
        return false;
      }
      else {
        return true;
      }
    },
    diagramButton(isBack=false){
      if(isBack){
        //back
        if(this.diagramRuleBefore()){
          this.diagramX += 134;
          this.diagramHiddenNavigationBefore = false;
        }
        else {
          this.diagramX = 0;
          this.diagramHiddenNavigationBefore = true;
        }
        this.diagramHiddenNavigationAfter = this.diagramRuleAfter();
      }
      else {
        //go
        if(!this.diagramRuleAfter()){
          this.diagramX -= 134;
          this.diagramHiddenNavigationAfter = false;
        }
        else {
          let diference = (this.diagram.offsetWidth + Math.abs(this.diagramX)) - this.diagram.scrollWidth;
          this.diagramX = this.diagramX + diference;
          this.diagramHiddenNavigationAfter = true;
        }

        this.diagramHiddenNavigationBefore  = !this.diagramRuleBefore();
      }
    },
  },
  computed: {
    ...mapGetters(['getCurrentOperationInfoDiagramParseArray']),
    diagramJSON() {
      return { steps: this.getCurrentOperationInfoDiagramParseArray }
    },
    lengthStepsInDiagramJSON() {
      const length = this.getCurrentOperationInfoDiagramParseArray.length
      return length > 1 ? length + " etapas neste fluxo" :
        length + " etapa neste fluxo"
    }
  },
  mounted() {
    this.diagramContainer = document.querySelector(".diagram-container");
    this.diagram = document.querySelector(".diagram");
    this.diagramHiddenNavigationAfter = this.diagramRuleAfter();

    window.addEventListener("resize", this.diagramResize);
  }
}
</script>

<style>
  /* CSS Diagram */

  /* Diagram Settings */
  :root {
    --diagram-item-thick-border: 2px;
    --diagram-item-width: 112px;
    --diagram-item-heigth: 35px;
    --diagram-item-connection-width: 38px;
  }
  /* === */

  /* Diagram Container */

  .diagram-container {
    left: -15px;
    overflow: hidden;
    padding: 30px;
    position: relative;
    width: calc(100% + 30px);
  }

  .diagram-container:after,
  .diagram-container:before {
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    width: 80px;
    z-index: 1;
  }

  .diagram-container:after {
    background-image: linear-gradient(to left, var(--v-grayf8-base) 50%, rgba(255, 255, 255, 0));
    right: 0;
  }

  .diagram-container:before {
    background-image: linear-gradient(to right, var(--v-grayf8-base), rgba(255, 255, 255, 0));
    left: 0;
  }

  .diagram-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }

  .diagram-button--before {
    left: 15px;
  }

  .diagram-button--after {
    right: 15px;
  }

  .diagram-container.diagram-button-hide-before:before,
  .diagram-container.diagram-button-hide-after:after {
    content: none;
  }

  .diagram-container.diagram-button-hide-before .diagram-button--before,
  .diagram-container.diagram-button-hide-after .diagram-button--after {
    display: none;
  }
  /* === */

  /* Diagram Structure */

  .diagram {
    align-items: center;
    display: flex;
    overflow: visible;
    transition: transform 0.2s ease-in-out;
  }

  .diagram.focus li:not(.focus){
    opacity: 0.6;
  }

  .diagram .diagram--step-name,
  .diagram .diagram--step-name > * {
    font-size: 11px;
    line-height: 1;
    padding-bottom: 3px;
    vertical-align: text-top;
  }

  .diagram .diagram--step-status,
  .diagram .diagram--step-status > * {
    color: var(--custom-theme-black-038);
    font-size: 8px;
    line-height: 1;
    vertical-align: text-top;
  }

  .diagram .diagram--step-status {
    padding-left: 12px;
  }

  .diagram .diagram--step-button {
    height: 100%;
    left: 0;
    position: absolute;
    outline: none;
    top: 0;
    width: 100%;
  }

  .diagram p {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .v-application .diagram li {
    border-radius: 2px;
    background-color: var(--v-white-base) !important;
    border-width: var(--diagram-item-thick-border);
    border-style: solid;
    display: inline-block;
    flex: 0 0 var(--diagram-item-width);
    height: calc( var(--diagram-item-heigth) + var(--diagram-item-thick-border));
    max-width: var(--diagram-item-width);
    padding: 4px 5px;
    position: relative;
    text-align: left;
    user-select: none;
  }

  .v-application .diagram li:not(:last-child) {
    margin-right: var(--diagram-item-connection-width);
  }

  .v-application .diagram li:not(:last-child):after {
    background-color: var(--v-pending-base);
    content: "";
    height: var(--diagram-item-thick-border);
    position: absolute;
    right: calc(var(--diagram-item-thick-border) * -1);
    top: 50%;
    transform: translate(100%, -50%);
    width: var(--diagram-item-connection-width);
  }
  /* === */

  /* Diagram Status */

  .diagram .done .diagram--step-name > i {
    color: var(--v-done-base) !important;
  }

  .diagram .executing .diagram--step-name > i {
    color: var(--v-executing-base) !important;
  }

  .diagram .error .diagram--step-name > i {
    color: var(--v-error-base) !important;
  }

  .diagram > li.done:not(:last-child):after {
    background-color: var(--v-done-base);
  }

  .diagram > li.executing:not(:last-child):after {
    background-color: var(--v-executing-base);
  }

  .diagram > li.error:not(:last-child):after {
    background-color: var(--v-error-base);
  }

  /* === */
</style>
