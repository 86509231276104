<template>
  <div>
    <BaseInput
      :ref="refName"
      v-bind="{ ...$attrs, ...propsToApply }"
      v-on="$listeners"
    >
      <template
        v-for="(slotValue, slotName) in $slots"
        v-slot:[slotName]
      >
        <slot :name="slotName" />
      </template>
    </BaseInput>
  </div>
</template>

<script>
import BaseInput from './BaseInput.vue'

export default {
  name: 'EmailInput',
  components: { BaseInput },
  props: {
    ...BaseInput.props
  },
  data() {
    return {
      privateRules: []
    }
  },
  computed: {
    rulesOfProps() {
      const { rules } = this.$props

      return [...this.privateRules, ...rules]
    },
    propsToApply() {
      const { ...props } = this.$props

      Object.assign(props, { rules: this.rulesOfProps })

      return props
    }
  },
  created() {
    this.privateRules.push(this.isValid)
    this.privateRules.push((value) => !!String(value).match(/^((?!\.)[a-z0-9_.-]*[^A-Z., ])(@[a-z0-9_-]+)(\.[a-z0-9]+(\.[a-z0-9]+)?[^.A-Z0-9_ \t])$/gm) || 'Email inválido. Verifique os espaços em branco.')
  },
  methods: {
    reset() {
      this.$refs[this.refName].reset()
    }
  }
}
</script>

<style>

</style>
