import {mapActions} from 'vuex'
import CryptoJS from 'crypto-js';

const BaseModalSignSteps = () => {
  return {
    data() {
      return {
        isModalActive: false,
        modalSignSteps: null,
        modalSignStep: null,
        modalSignStepIndexActived: 0,
        modalSignStepListMiddleCheckAgree: [],
        selected: [],
        isEcnpj: false,
        isSelectActive: true
      }
    },
    computed: {
      transformedModalSignStepsToList() {

        const list = [];

        (function recursive(modalSignSteps) {
          let {nextStep, ...object} = {...modalSignSteps}
          list.push(object)
          if (nextStep !== undefined) {
            return recursive(nextStep)
          } else {
            return
          }
        })(this.modalSignSteps)

        return list
      },
      modalSignStepActived() {
        return this.transformedModalSignStepsToList[this.modalSignStepIndexActived]
      },
      hasSelected() {
        return (this.selected.length > 1 && this.selected.every(envelope => envelope.provider === 'CLICKSIGN'))
        ||
        (this.selected.length > 0 && this.selected.every(envelope => envelope.provider === 'IDTRUST'))
      }
    },
    methods: {
      ...mapActions([
        'apiHashes',
        'apiSign'
      ]),
      async getCertificate(thumbprint) {
        let promise = new Promise((resolve, reject) => {
          try {
            this.pki.readCertificate(thumbprint).success(cert => {
              resolve(cert)
            })
          } catch (e) {
            reject(e)
          }
        })
        return await promise
      },
      async getHashes(params) {
        let response = await this.apiHashes(params);
        return response
      },
      encryptedHash(hash) {
        let hexHash = this.atoh(hash.hash);
        let correctHash = hexHash.substring(hexHash.length - 64);
        let correctHex = CryptoJS.enc.Hex.parse(correctHash)
        return CryptoJS.enc.Base64.stringify(correctHex);
      },
      async sign(evt) {

        let promise = await new Promise((resolve, reject) => {
          try {
            this.pki.signHash({
              thumbprint: evt.thumbprint,
              hash: evt.hash,
              digestAlgorithm: evt.hashAlgo,
            }).success((signature) => {
              resolve(signature)
            })
          } catch (e) {
            reject(e)
          }
        })

        let signature = await promise

        let response = await this.apiSign({
          event_id: evt.event_id,
          signature: signature
        });

        return response
      },
      async initSign() {
        this.nextStepModal();
        this.isModalActive = true;
        for (const [_, elem] of this.selected.entries()) {

          this.isSelectActive = false
          let certificate = await this.getCertificate(this.thumbprint)
          let response = await this.getHashes({
            uuid: elem.uuid,
            certificate: certificate,
            page: 0,
            size: 100
          })

          let hashes = response.data
          let hashesPage = 1
          switch (response.status) {
            case 500:
              this.modalSignStep = this.modalSignStep.errorECNPJ
              return
            case 200:
              while (response.status == 200) {

                for (let i = 0; i < hashes.length; i++) {
                 let signResponse = await this.sign({
                    event_id: hashes[i].event_id,
                    hash: this.encryptedHash(hashes[i]),
                    hashAlgo: hashes[i].hash_alg,
                    thumbprint: this.thumbprint
                  })

                  if (signResponse.status != 200) {
                    this.modalSignStep = this.modalSignStep.error
                    return
                  }
                }

                response = await this.getHashes({
                  uuid: elem.uuid,
                  certificate: certificate,
                  page: hashesPage++,
                  size: 100
                })

                hashes = response.data
              }
              break
            case 204:
              break
            default:
              this.modalSignStep = this.modalSignStep.error
              return
          }
        }
        this.modalSignStep = this.modalSignStep.success
      },
      stopSign() {
        this.modalSignStepListMiddleCheckAgree.forEach(
          elem => elem.isChecked = false)
        this.isModalActive = false
        this.isInitStarted = false
        setTimeout(() => {
          this.modalSignStepListMiddleCheckAgree = [],
            this.selected = [],
            this.modalSignStepIndexActived = 0,
            this.modalSignStep = {...this.modalSignStepActived}
        }, 200)
      },
      atoh(base64String) {
        const byteString = window.atob(base64String);
        let result = "";

        for (let i = 0; i < byteString.length; i++) {
          let h = "00" + byteString.charCodeAt(i).toString(16).toUpperCase();
          result += h.substring(h.length - 2);
        }

        return result;
      },
      nextStepModal() {
        this.modalSignStepIndexActived++
        this.modalSignStep = {...this.modalSignStepActived}
      }
      ,
      checkAgree(value) {
        this.modalSignStepListMiddleCheckAgree.push(
          this.modalSignStep.middleCheckAgree)
        this.modalSignStep.middleCheckAgree.isChecked = value
      }
      ,
      reload() {
        location.reload()
      }
      ,
    },
    created() {

      const uuid = this.$route.params.uuid

      if (uuid) {
        this.selected.push({uuid})
      }

      this.modalSignSteps = {
        showIndeterminateProgress: false,
        selected: this.selected,
        topSlotContentTitle: "Selecionar Certificado",
        removeButtonClose: false,
        certificates: this.certificates,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: `<p>Selecione um certificado na lista abaixo</p>`,
        middleCheckAgree: {
          "text": "Estou ciente de que após clicar no botão assinar, a ação não poderá ser desfeita.",
          "isChecked": false,
        },
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "Cancelar",
        bottomSlotContentButtonConfirmLabel: "Assinar",
        removeButtonCancel: false,
        removeButtonConfirm: false,
        onCancel: this.stopSign,
        onConfirm: this.initSign,
        onCheck: this.checkAgree,
        nextStep: {
          showIndeterminateProgress: true,
          topSlotContentTitle: "",
          removeButtonClose: true,
          middleImage: {
            'src': 'loading.svg',
            'height': "164",
            'maxWidth': "157",
            'width': "157",
          },
          middleSlotContentTextHighlight: "<p class='text-sm-center'>Realizando a assinatura…</p>",
          middleSlotContentText: "<p class='text-sm-center'>Por favor, aguarde.</p>",
          middleCheckAgree: null,
          bottomButtonsAlign: "right",
          bottomSlotContentButtonCancelLabel: "",
          bottomSlotContentButtonConfirmLabel: "",
          removeButtonCancel: true,
          removeButtonConfirm: true,
          onCancel: null,
          onConfirm: null,
          onCheck: null,
          error: {
            showIndeterminateProgress: false,
            topSlotContentTitle: "",
            removeButtonClose: false,
            middleImage: {
              'src': "error.svg",
              'height': "105",
              'maxWidth': "82",
              'width': "82",
            },
            middleSlotContentTextHighlight: "<p class='text-sm-center'>Não foi possível assinar</p>",
            middleSlotContentText: `<p class='text-sm-center mb-0'>Ocorreu um erro que impediu a assinatura, tente novamente mais tarde.
                Se o problema persistir, entre em contato pelo <strong>chat</strong>
                  no canto da tela ou pelo telefone <strong>(16) 3106-9005</strong></p>.`,
            middleCheckAgree: null,
            bottomButtonsAlign: "center",
            bottomSlotContentButtonCancelLabel: "",
            bottomSlotContentButtonConfirmLabel: "OK",
            removeButtonCancel: true,
            removeButtonConfirm: false,
            onCancel: this.reload,
            onConfirm: this.reload,
            onCheck: null,
          },
          errorECNPJ: {
            showIndeterminateProgress: false,
            topSlotContentTitle: "",
            removeButtonClose: false,
            middleImage: {
              'src': "error.svg",
              'height': "105",
              'maxWidth': "82",
              'width': "82",
            },
            middleSlotContentTextHighlight: "<p class='text-sm-center'>Não foi possível assinar</p>",
            middleSlotContentText: `<p class='text-sm-center mb-0'>Verifique seu certificado e tente novamente mais tarde. <br/>
                <strong>Atenção: Se você estiver utilizando e-cnpj não será possível assinar.</strong>
                <br/>Se o problema persistir, entre em contato com o
                <br/> atendimento pelo e-mail atendimento@idtrust.com.br,
                <br/>chat da plataforma ou pelo telefone (16) 3106 9005.</p>`,
            middleCheckAgree: null,
            bottomButtonsAlign: "center",
            bottomSlotContentButtonCancelLabel: "",
            bottomSlotContentButtonConfirmLabel: "OK",
            removeButtonCancel: true,
            removeButtonConfirm: false,
            onCancel: this.reload,
            onConfirm: this.reload,
            onCheck: null,
          },
          success: {
            showIndeterminateProgress: false,
            topSlotContentTitle: "",
            removeButtonClose: false,
            middleImage: {
              'src': "success.svg",
              'height': "105",
              'maxWidth': "82",
              'width': "82",
            },
            middleSlotContentTextHighlight: "<p class='text-sm-center'>Assinatura realizada com sucesso</p>",
            middleSlotContentText: "",
            middleCheckAgree: null,
            bottomButtonsAlign: "center",
            bottomSlotContentButtonCancelLabel: "",
            bottomSlotContentButtonConfirmLabel: "OK",
            removeButtonCancel: true,
            removeButtonConfirm: false,
            onCancel: this.reload,
            onConfirm: this.reload,
            onCheck: null
          }
        }
      }
      this.modalSignStep = {...this.modalSignStepActived}
    }
  }
}

export default BaseModalSignSteps()
