import { render, staticRenderFns } from "./MembersList.vue?vue&type=template&id=536d98ac"
import script from "./MembersList.vue?vue&type=script&lang=js"
export * from "./MembersList.vue?vue&type=script&lang=js"
import style0 from "./MembersList.vue?vue&type=style&index=0&id=536d98ac&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VChip,VDialog,VRadio,VRadioGroup,VSelect,VSpacer,VTextarea})
