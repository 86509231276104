<template>
  <div>
    <div class="color--text-black-054">
      <h2>{{ title }}</h2>
      <p class="body-1">
        {{ subtitle }}
      </p>
      <v-divider class="my-4" />
    </div>
    <div class="app-align-items-center mb-6">
      <v-spacer />
      <div class="d-flex text-caption text-right app-apply-blur">
        <div>
          <p class="font-weight-bold primary--text">
            Valor total selecionado
          </p>
          <p class="font-weight-bold primary--text text-h4">
            {{ 10000 | formatCurrency }}
          </p>
        </div>
      </div>
    </div>

    <div class="app-align-items-center">
      <v-autocomplete
        id="qa-id-name-organization-list"
        v-model="modelSelect"
        :items="['a', 'b']"
        clearable
        label="Selecione "
        hint="Filtre por Razão Social ou CNPJ"
        no-data-text="Organização não encontrada"
        persistent-hint
        :rules="[isValid]"
        style="max-width: 350px;"
        class="my-6 mr-6 app-apply-blur"
      />
      <MenuConfigurationSchemaField
        v-if="!showTableCriteria"
        ref="configuration-destination"
        menu-title="Configurar"
        menu-align="left"
        name="configurationDraftMovement0"
        :config-list="programMovementDynamicAssetFields"
        :form-field-list="['name', 'datasource']"
        scope-schema="destination"
        @removeItemSchema="removeItemSchema"
        @addOrEditItemSchema="addOrEditItemSchema"
        @changeOrderItemSchema="changeOrderItemSchema"
      />
    </div>
    <div class="assets-collection__actions--container mt-6 mx-1 mb-2">
      <div class="assets-collection__actions d-flex align-center justify-space-between">
        <p class="font-weight-bold text-body-2 primary--text pl-6 app-apply-blur">
          {{ 10 }} selecionados
        </p>
        <div class="app-apply-blur">
          <BaseButton
            type="text"
            class="primary--text app-font-semibold"
          >
            Excluir
          </BaseButton>
          <BaseButton
            color="primary"
            class="white--text app-font-semibold"
            :is-disabled="true"
          >
            Avançar
          </BaseButton>
        </div>
      </div>
      <BaseDataTable
        :headers="tableHeaders"
        :hide-headers="true"
        :is-admin-editing="true"
        :items="tableHeaders"
        class="color--text-black-054"
      >
        <template v-slot:inject-header="{headers}">
          <tr>
            <template
              v-for="(header, index) in headers.props.headers"
            >
              <th
                v-if="header.value != 'admin-config'"
                :key="index"
                style="white-space: nowrap;"
              >
                {{ translateRoleType(header.text) }}
              </th>
              <th
                v-if="header.value == 'admin-config'"
                :key="index"
                class="text-right"
                :class="header.class"
              >
                <MenuConfigurationSchemaField
                  v-if="showTableCriteria"
                  ref="configuration-criteria-table"
                  menu-title="Configurar"
                  menu-align="left"
                  name="configuration-criteria-table"
                  :config-list="programMovementDynamicAssetFields"
                  :form-field-list="['name', 'hidden']"
                  scope-schema="criteria-table"
                  @removeItemSchema="removeItemSchema"
                  @addOrEditItemSchema="addOrEditItemSchema"
                  @changeOrderItemSchema="changeOrderItemSchema"
                />
              </th>
            </template>
          </tr>
        </template>
        <template v-slot:inject-items="scopedItems">
          <tr
            v-for="props in scopedItems"
            :key="props.item.id"
            class="app-apply-blur"
          >
            <template
              v-for="header, index in tableHeaders"
            >
              <td
                v-if="header.value != 'admin-config'"
                :key="index"
                class="text-left"
              >
                <div>
                  {{ header.text }}
                </div>
              </td>
              <td
                v-if="header.value == 'admin-config'"
                :key="index"
                class="text-right"
              >
                <BaseButton
                  type="text"
                  :has-icon="true"
                >
                  <v-icon class="primary--text">
                    delete
                  </v-icon>
                </BaseButton>
              </td>
            </template>
          </tr>
        </template>
      </BaseDataTable>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import permissions from "@/mixins/permissions";
import ProgramRoles from '../../mixins/ProgramRoles'
import BaseDataTable from '../BaseDataTable'
import MenuConfigurationSchemaField from './MenuConfigurationSchemaField'
import BaseSnackbarConfiguration from '../../mixins/BaseSnackbarConfiguration'

export default {
  components: { BaseDataTable, MenuConfigurationSchemaField },
  mixins: [ permissions, ProgramRoles, BaseSnackbarConfiguration ],
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    scopeSchema: {
      type: String,
      required: true
    }
  },
  data: () => ({
    tableHeaders: [],
    modelSelect: ''
  }),
  computed:{
    ...mapState({
      programMovementDynamicAssetFields: state => state.programMovements.programMovementDynamicAssetFields
    }),
    showTableCriteria(){
      return this.scopeSchema === 'criteria-table' ? true : false
    }
  },
  watch: {
    programMovementDynamicAssetFields: {
      handler(val) {
        if(this.scopeSchema === 'criteria-table'){
          this.parseHeadersAssetTable()
        }
      },
      deep: true
    }
  },
  async mounted() {
    await this.apiGetSchemas({ scope: this.scopeSchema })
    if(this.scopeSchema === 'criteria-table'){
      this.parseHeadersAssetTable()
    }
  },
  methods: {
    ...mapActions('programMovements', ['apiGetSchemas']),
    ...mapActions(['apiGetSchemasFields', 'apiUpdateSchemaField', 'apiCreateSchemaField']),
    async removeItemSchema({ field, role, schemaName, programName }) {
      const payloadList = this.programMovementDynamicAssetFields.filter(elem => elem.key !== field.key)
      try {
        await this.apiUpdateSchemaField({
          name: schemaName,
          scope: this.scopeSchema,
          fields: payloadList,
          role: role,
          program: programName
        })
        this.showFeedback({
          message: 'Campo removido com sucesso!',
          color: 'success'
        })
      } catch (error) {
        this.showFeedback({
          message: 'Erro ao remover campo.',
          color: 'error'
        })
      } finally {
        await this.apiGetSchemasFields()
        await this.apiGetSchemas({ scope: this.scopeSchema, role })
        if(this.scopeSchema === 'criteria-table'){
          this.parseHeadersAssetTable()
        }
      }
    },
    addOrEditItemSchema({key, role, schemaName, programName, data }){
      const payload = {
        name: schemaName,
        scope: this.scopeSchema,
        fields: [],
        role,
        program: programName
      }

      if(this.programMovementDynamicAssetFields.length) {
        const payloadList = this.programMovementDynamicAssetFields.filter(field => field.key !== key)
        payloadList.push(data)

        Object.assign(payload, {
          ...payload,
          fields: payloadList
        })
        this.updateSchema(payload)
      } else {
        Object.assign(payload, {
          ...payload,
          fields: [data]
        })
        this.createSchema(payload)
      }
    },
    async createSchema(payload) {
      try {
        await this.apiCreateSchemaField(payload)
      } catch (error) {
        this.showFeedback({
          color: "error",
          message: 'Erro ao criar schema!',
        })
      } finally {
        await this.apiGetSchemas({ scope: payload.scope, role: payload.role })
        if(this.scopeSchema === 'criteria-table'){
          this.parseHeadersAssetTable()
        }
      }
    },
    async updateSchema(payload){
      try {
        await this.apiUpdateSchemaField(payload)
      } catch (error) {
        this.showFeedback({
          color: "error",
          message: 'Erro ao atualizar schema!',
        })
      } finally {
        await this.apiGetSchemas({ scope: payload.scope, role: payload.role })
        if(this.scopeSchema === 'criteria-table'){
          this.parseHeadersAssetTable()
        }
      }
    },
    changeOrderItemSchema({ role, schemaName, programName, data }){
      const payload = {
        name: schemaName,
        scope: this.scopeSchema,
        fields: data,
        role,
        program: programName
      }
      this.updateSchema(payload)
    },
    parseHeadersAssetTable(){
      let tableHeaderFields = this.programMovementDynamicAssetFields || []
      let fields = []

      tableHeaderFields.forEach(header => {
        fields.push({
          hidden: header.hidden,
          text: header.name,
          value: header.key,
          class: header.key
        })
      })
      fields.push({
        hidden: false,
        text: '',
        value: 'admin-config',
        class: ''
      })
      this.tableHeaders = [...fields]
    }
  }
}
</script>

<style>

</style>
