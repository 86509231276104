<template>
  <v-tooltip
    bottom
    :disabled="step.name.length <= 15"
  >
    <template v-slot:activator="{ on }">
      <div
        class="diagram-operation-list-item"
        v-on="on"
      >
        <p class="diagram--step-name">
          <v-icon>
            {{ parseIconCodeStep(step.status) }}
          </v-icon>
          {{ step.name }}
        </p>
        <p class="diagram--step-status">
          <v-icon>query_builder</v-icon> {{ parseStatusMessageStep(step.updatedAt, step.status) }}
        </p>
        <button
          v-if="step.status.toLowerCase() === 'error'"
          class="diagram--step-button"
          @click="getStepDiagram"
        />
      </div>
    </template>
    <span>{{ step.name }}</span>
  </v-tooltip>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
export default {
  name: "DiagramOperationListItem",
  props: {
    step: {
      type: Object,
      default: () => {}
    },
  },
  computed: {
    ...mapState({
      currentOperationInfoStepNodeElement: (state) => {
        return state.operationInfo.currentOperationInfoStepNodeElement
      }
    }),
    ...mapGetters(['getCurrentOperationInfoDiagramParseArray'])
  },
  methods: {
    ...mapMutations(['setCurrentOperationInfoStepNode', 'setCurrentOperationInfoStepNodeElement']),
    getStepDiagram(event){
      let index = 0
      let nodeFirstSibling = event.target.parentNode.parentNode
      while((nodeFirstSibling = nodeFirstSibling.previousSibling) != null) {
        index++;
      }
      this.setCurrentOperationInfoStepNode(this.getCurrentOperationInfoDiagramParseArray[index])
      if(this.currentOperationInfoStepNodeElement){
        this.currentOperationInfoStepNodeElement.classList.remove('focus')
      }
      event.target.parentNode.parentNode.classList.add('focus')
      event.target.parentNode.parentNode.parentNode.classList.add('focus')
      this.setCurrentOperationInfoStepNodeElement(event.target.parentNode.parentNode)
    },
    parseIconCodeStep(status){
      switch(status.toLowerCase()){
        case "done":
          return "check_circle"
        case "error":
          return "cancel"
        case "pending":
          return "lens"
        case "executing":
          return "timelapse"
      }
    },
    parseStatusMessageStep(updatedAt, status){
      switch(status.toLowerCase()){
        case "done":
          return this.$options.filters.getTime(updatedAt)
        case "error":
          return this.$options.filters.getTime(updatedAt)
        case "pending":
          return "Não iniciado"
        case "executing":
          return "Em andamento"
        default:
          return status.charAt(0).toUpperCase() + status.toLowerCase().substring(1)
      }
    }
  }
}
</script>
