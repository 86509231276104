<template>
  <div class="platform-organization-profile-account">
    <BaseTour
      :tour-name="tourName"
      :steps="dataSteps"
      :start-on-mounted="false"
      @onFinish="finishTour"
    />
    <BaseCard
      :is-header-actions="true"
      :has-main-container="true"
      :has-divider="true"
      base-card-title-class="app-font-semibold title mb-1"
      data-v-step="3"
    >
      <template v-slot:header-title>
        Contas bancárias
      </template>
      <template v-slot:header-actions>
        <v-dialog
          :value="isModalActive"
          persistent
          transition="app-transition-to-right"
          content-class="app-transition-to-right-additional-configs"
          @input="updateModalActive"
        >
          <template v-slot:activator="{ on }">
            <BaseButton
              type="text"
              color="blueff"
              class="app-button__link-text app-button--hide-ripple app-button--no-hover"
              v-on="on"
              @click="stopTour(tourName)"
            >
              Adicionar
            </BaseButton>
          </template>
          <BaseModal
            style="height: 100vh; flex-wrap: nowrap;"
            :show-indeterminate-progress="modalManageAccount.showIndeterminateProgress"
            :top-slot-content-title="modalManageAccount.topSlotContentTitle"
            :remove-button-close="modalManageAccount.removeButtonClose"
            :middle-slot-content-text-highlight="modalManageAccount.middleSlotContentTextHighlight"
            :middle-slot-content-text="modalManageAccount.middleSlotContentText"
            :middle-check-agree="modalManageAccount.middleCheckAgree"
            :bottom-buttons-align="modalManageAccount.bottomButtonsAlign"
            :bottom-slot-content-button-cancel-label="modalManageAccount.bottomSlotContentButtonCancelLabel"
            :bottom-slot-content-button-confirm-label="modalManageAccount.bottomSlotContentButtonConfirmLabel"
            :label-top-action="modalManageAccount.labelTopAction"
            :remove-button-cancel="modalManageAccount.removeButtonCancel"
            :remove-button-confirm="modalManageAccount.removeButtonConfirm"
            body-background-color="#F8F8F8"
            @on-cancel="modalManageAccount.onCancel"
            @on-confirm="() => null"
            @header-button-action="modalManageAccount.onConfirmTopAction"
          >
            <div
              class="mt-6"
              style="max-width: 645px; margin: 0 auto;"
            >
              <BaseCard
                :has-main-container="true"
                :is-header-title-description="true"
              >
                <template v-slot:header-title-description>
                  <div class="pl-4">
                    Adicione uma conta bancária para a organização
                  </div>
                </template>
                <v-form
                  :ref="formName"
                  v-model="bankAccount.valid"
                >
                  <v-container>
                    <v-row>
                      <v-col
                        class="pt-0"
                        cols="auto"
                        sm="9"
                      >
                        <BaseInput
                          v-model="bankAccount.name"
                          label="Nome da conta*"
                          qa-identification-name="qa-id-name-bank-name"
                          :rules="[isValid]"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="auto"
                        sm="4"
                      >
                        <v-autocomplete
                          v-model="bankAccount.bank"
                          return-object
                          :items="items"
                          item-value="value"
                          :item-text="getBankLabel"
                          label="Banco*"
                          qa-identification-name="qa-id-name-bank"
                          :rules="[isValid]"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="auto"
                        sm="4"
                      >
                        <BaseInput
                          v-model="bankAccount.agency"
                          label="Agência*"
                          max-length="4"
                          qa-identification-name="qa-id-name-bank-agency"
                          :rules="[isValid, isOnlyNumber]"
                        />
                      </v-col>
                      <v-col
                        cols="auto"
                        sm="2"
                      >
                        <BaseInput
                          v-model="bankAccount.agency_digit"
                          label="Dígito"
                          max-length="1"
                          qa-identification-name="qa-id-name-bank-agency-digit"
                          :rules="[isDigitNumber]"
                        />
                      </v-col>
                      <v-col
                        cols="auto"
                        sm="4"
                      >
                        <BaseInput
                          v-model="bankAccount.number"
                          label="Conta*"
                          max-length="11"
                          qa-identification-name="qa-id-name-bank-number"
                          :rules="[isValid, isOnlyNumber]"
                        />
                      </v-col>
                      <v-col
                        cols="auto"
                        sm="2"
                      >
                        <BaseInput
                          v-model="bankAccount.digit"
                          label="Dígito*"
                          qa-identification-name="qa-id-name-bank-digit"
                          max-length="1"
                          :rules="[isValid, isDigitNumber]"
                        />
                      </v-col>
                    </v-row>
                    <v-row
                      dense
                      justify="end"
                    >
                      <v-col cols="auto">
                        <BaseButton
                          color="primary"
                          type="text"
                          qa-identification-name="qa-id-name-bank-btn-cancel"
                          @click="onCancelForm"
                        >
                          Cancelar
                        </BaseButton>
                        <BaseButton
                          data-submit
                          type="depressed"
                          qa-identification-name="qa-id-name-bank-btn-confirm"
                          color="primary"
                          :is-disabled="!bankAccount.valid || bankAccount.noFocus"
                          @click="submitBankAccountForm"
                        >
                          Salvar
                        </BaseButton>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </BaseCard>
            </div>
          </BaseModal>
        </v-dialog>
      </template>
      <div>
        <BankAccountTableList
          :bank-account-list="platformOrganizationBankAccounts"
          context-actions="manage"
          :is-modal-change-account="isModalChangeAccount"
          :modal-change-account="modalChangeAccount"
          @onSwitchToPrimaryAccount="switchToPrimaryAccount"
          @onUpdateBankAccountFormFields="updateBankAccountFormFields"
        />
      </div>
    </BaseCard>
    <v-dialog
      v-model="isModalChangeAccount"
      max-width="560"
      :retain-focus="false"
      persistent
    >
      <BaseModal
        :show-indeterminate-progress="modalChangeAccount.showIndeterminateProgress"
        :top-slot-content-title="modalChangeAccount.topSlotContentTitle"
        :remove-button-close="modalChangeAccount.removeButtonClose"
        :middle-slot-content-text-highlight="modalChangeAccount.middleSlotContentTextHighlight"
        :middle-slot-content-text="modalChangeAccount.middleSlotContentText"
        :bottom-buttons-align="modalChangeAccount.bottomButtonsAlign"
        :bottom-slot-content-button-cancel-label="modalChangeAccount.bottomSlotContentButtonCancelLabel"
        :bottom-slot-content-button-confirm-label="modalChangeAccount.bottomSlotContentButtonConfirmLabel"
        :remove-button-cancel="modalChangeAccount.removeButtonCancel"
        :remove-button-confirm="modalChangeAccount.removeButtonConfirm"
        @on-cancel="() => typeof modalChangeAccount.onConfirm === 'function' && modalChangeAccount.onCancel()"
        @on-confirm="() => typeof modalChangeAccount.onConfirm === 'function' && modalChangeAccount.onConfirm()"
      />
    </v-dialog>
    <v-dialog
      v-model="isModalDeleteAccountActive"
      max-width="560"
      :retain-focus="false"
      persistent
    >
      <BaseModal
        :show-indeterminate-progress="modalDeleteAccount.showIndeterminateProgress"
        :top-slot-content-title="modalDeleteAccount.topSlotContentTitle"
        :remove-button-close="modalDeleteAccount.removeButtonClose"
        :middle-slot-content-text-highlight="modalDeleteAccount.middleSlotContentTextHighlight"
        :middle-slot-content-text="modalDeleteAccount.middleSlotContentText"
        :bottom-buttons-align="modalDeleteAccount.bottomButtonsAlign"
        :bottom-slot-content-button-cancel-label="modalDeleteAccount.bottomSlotContentButtonCancelLabel"
        :bottom-slot-content-button-confirm-label="modalDeleteAccount.bottomSlotContentButtonConfirmLabel"
        :remove-button-cancel="modalDeleteAccount.removeButtonCancel"
        :remove-button-confirm="modalDeleteAccount.removeButtonConfirm"
        @on-cancel="() => typeof modalDeleteAccount.onCancel === 'function' && modalDeleteAccount.onCancel()"
        @on-confirm="() => typeof modalDeleteAccount.onConfirm === 'function' && modalDeleteAccount.onConfirm()"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { utilsShowErrorInDevelopmentEnvironment, getBanks } from '../../../utils'
import BaseCard from '../../BaseCard'
import BaseModal from '../../BaseModal'
import UtilsBankAccount from '../../../mixins/UtilsBankAccount'
import BankAccountTableList from '../OrganizationProfile/BankAccountTableList'
import ProgramTour from '../../../mixins/ProgramTour'

export default {
  components: { BaseCard, BaseModal, BankAccountTableList },
  mixins: [UtilsBankAccount, ProgramTour],
  data() {
    return {
      isModalActive: false,
      isModalChangeAccount: false,
      isModalDeleteAccountActive: false,
      isEditingBankAccount: false,
      organizationId: '',
      formName: 'formToAddOrUpdateBankAccount',
      items: (() => getBanks())(),
      modalManageAccount: {
        showIndeterminateProgress: false,
        topSlotContentTitle: "Conta bancária",
        removeButtonClose: false,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: '',
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "Cancelar",
        bottomSlotContentButtonConfirmLabel: "Salvar",
        labelTopAction: null,
        removeButtonCancel: true,
        removeButtonConfirm: true,
        onCancel: this.onCancelForm,
        onConfirm: null,
        onConfirmTopAction: this.confirmTopAction
      },
      modalChangeAccount: {
        showIndeterminateProgress: false,
        topSlotContentTitle: "Conta principal",
        removeButtonClose: false,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: "",
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "Cancelar",
        bottomSlotContentButtonConfirmLabel: "Alterar",
        removeButtonCancel: false,
        removeButtonConfirm: false,
        onCancel: () => this.isModalChangeAccount = false,
        onConfirm: this.changeToPrimaryAccount
      },
       modalDeleteAccount: {
        showIndeterminateProgress: false,
        topSlotContentTitle: "Excluir Conta",
        removeButtonClose: false,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: "",
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "Cancelar",
        bottomSlotContentButtonConfirmLabel: "Excluir",
        removeButtonCancel: false,
        removeButtonConfirm: false,
        onCancel: () => this.isModalDeleteAccountActive = false,
        onConfirm: this.deleteBankAccount
      },
      bankAccount: {
        valid: false,
        noFocus: true,
        bank: '',
        agency: '',
        number: '',
        primary: false,
        name: '',
        id: '',
        organizationId: ''
      },
      accountForUpdatePrimary: {},
      tourName: 'bank-account',
      dataSteps: [
        {
          target: '[data-v-step="3"]',
          content: 'Para cadastrar a conta bancária de sua empresa, utilize o botão <b>“Adicionar”</b> abaixo.',
          layoutStep: "simple",
          params: {
            placement: 'top'
          }
        },
      ]
    }
  },
  computed: {
    ...mapState({
      platformOrganizationBankAccounts: state => state.platformOrganization.platformOrganizationBankAccounts
    }),
    getPrimaryAccount() {
      return this.platformOrganizationBankAccounts.filter(account => account.primary === true)[0]
    },
  },
  mounted() {
    this.startTour(this.tourName)
  },
  created() {
    this.organizationId = this.$route.params.id
    this.bankAccount.organizationId = this.organizationId
    this.getBankAccounts()
  },
  methods: {
    ...mapActions('platformOrganization', ['apiCreatePlatformBankAccount', 'apiGetPlatformBankAccounts', 'apiUpdatePlatformBankAccount', 'apiDeletePlatformBankAccount']),
    finishTour(){
      this.$router.push('/platform-home')
    },
    updateModalActive(value) {
      this.isModalActive = value
      this.isEditingBankAccount = false
      this.addFocusEventOnInputs()
    },
    onCancelForm() {
      this.cancelForm(this, this.formName)
      this.isModalActive = false
      this.modalManageAccount.labelTopAction = null
      this.bankAccount.primary = false
    },
    async getBankAccounts() {
     try{
        await this.apiGetPlatformBankAccounts(this.organizationId)
     }catch(err) {
       utilsShowErrorInDevelopmentEnvironment(err)
     }
    },
    async deleteBankAccount(account) {
      const { id, organizationId } = this.bankAccount
      try{
        await this.apiDeletePlatformBankAccount({organizationId, id})

        this.getBankAccounts()

        this.$emit('onSubmitForm', {
          message: 'Conta excluída com sucesso',
          color: 'success'
        })

        this.isModalDeleteAccountActive = false
      }catch(err) {
        this.$emit('onSubmitForm', {
          message: 'Erro ao excluir conta',
          color: 'error'
        })
        utilsShowErrorInDevelopmentEnvironment(err)
      }
    },
    confirmTopAction() {
      this.isModalActive = false
      this.modalManageAccount.labelTopAction = null
      this.modalDeleteAccount.middleSlotContentText = `Você tem certeza que deseja excluir <b> ${ this.bankAccount.name }</b>?<br />Esta ação não poderá ser desfeita.`
      this.isModalDeleteAccountActive = true
    },
    async submitBankAccountForm() {
      this.bankAccount.bank_code = this.bankAccount.bank.value
      this.bankAccount.bank_name = this.bankAccount.bank.label
      const { valid, noFocus, bank, ...data } = this.bankAccount
      try{
        if(!this.isEditingBankAccount){
          await this.apiCreatePlatformBankAccount(data)
        } else {
          await this.apiUpdatePlatformBankAccount(data)
        }

        this.getBankAccounts()

        this.$emit('onSubmitForm', {
          message: 'Conta salva com sucesso',
          color: 'success'
        })
        this.updateBankAccountFormFields(this.bankAccount)
        this.modalManageAccount.labelTopAction = this.isEditingBankAccount && this.platformOrganizationBankAccounts.length > 1 ? "Excluir" : null
      }catch(err) {
        this.$emit('onSubmitForm', {
          message: 'Erro ao salvar conta',
          color: 'error'
        })
      }

    },
    async changeToPrimaryAccount() {
      try{
        let accountReference = Object.assign({}, this.accountForUpdatePrimary)
        Object.assign(accountReference, {organizationId: this.organizationId, primary: true})
        await this.apiUpdatePlatformBankAccount(accountReference)
        this.getBankAccounts()
      }catch(err) {
        utilsShowErrorInDevelopmentEnvironment(err)
      }
      this.isModalChangeAccount = false
    },
    onFocusCallback() {
      this.bankAccount.noFocus = false
    },
    updateBankAccountFormFields(account) {
      const {bank_name, bank_code, agency, agency_digit, number, digit, name, id, primary} = account
      Object.assign(this.bankAccount, {bank: {value: bank_code, label: bank_name}, agency, agency_digit, number, digit, name, primary, id, noFocus: true})
      this.isModalActive = true
      this.isEditingBankAccount = true
      this.modalManageAccount.labelTopAction = this.platformOrganizationBankAccounts.length > 1 ? "Excluir" : null
      this.addFocusEventOnInputs()
    },
    addFocusEventOnInputs(){
      this.$nextTick(() => {
        this.$refs.formToAddOrUpdateBankAccount.$el.querySelectorAll('input').forEach(elem => {
          elem.addEventListener('focus', (ev) => this.onFocusCallback())
        })
      })
    },
    switchToPrimaryAccount(account) {
      this.isModalChangeAccount = true
      this.accountForUpdatePrimary = account
      if(!account.primary){
         this.changeValueOfModalChangeAccount([
          {
            property: "middleSlotContentText",
            value: `Deseja alterar a conta principal de <strong>${this.getPrimaryAccount.name}</strong> para <strong>${account.name}</strong>?`
          },
          {
            property: "removeButtonConfirm",
            value: false
          }
        ])

      }else {
        this.changeValueOfModalChangeAccount([
          {
            property: "middleSlotContentText",
            value: "Você não pode alterar uma conta primária"
          },
          {
            property: "removeButtonConfirm",
            value: true
          }
        ])
      }

    },
    changeValueOfModalChangeAccount(list) {
      list.forEach(({property, value}) => {
        this.modalChangeAccount[property] = value
      })
    }
  }
}
</script>

<style>

.platform-organization-profile-account--switch .v-input__slot {
  margin-bottom: 0!important;
}

.platform-organization-profile-account--switch .v-messages {
  display: none !important;
}

</style>
