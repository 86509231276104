<template>
  <div class="create-program-steps d-flex justify-center">
    <v-stepper
      v-model="step"
      class="grayf8"
      width="820"
    >
      <v-stepper-header class="justify-center font-weight-bold">
        <v-stepper-step
          :complete="step > 1"
          class="pr-1"
          step="1"
        />
        <v-divider v-if="step > 1" />
        <v-stepper-step
          :complete="step > 2"
          class="pl-3 pr-1"
          step="2"
        />
        <v-divider v-if="step > 2" />
        <v-stepper-step
          :complete="step > 3"
          class="pl-3 pr-1"
          step="3"
        />
        <v-divider v-if="step > 3" />
        <v-stepper-step
          :complete="step > 4"
          class="pl-3 pr-1"
          step="4"
        />
        <v-divider v-if="step > 4" />
        <v-stepper-step
          :complete="step > 5"
          class="pl-3"
          step="5"
        />
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <StepProgramName
            @onNextStep="nextStep"
            @onSubmitForm="showFeedback"
          />
        </v-stepper-content>
        <v-stepper-content
          step="2"
          class="pa-0"
        >
          <StepProgramAssets
            @onNextStep="nextStep"
            @onPreviousStep="previousStep"
          />
        </v-stepper-content>
        <v-stepper-content
          step="3"
          class="pa-0"
        >
          <StepProgramParticipants
            @onNextStep="nextStep"
            @onPreviousStep="previousStep"
            @onSubmitForm="showFeedback"
          />
        </v-stepper-content>
        <v-stepper-content
          step="4"
          class="pa-0"
        >
          <StepProgramParticipantsDocuments
            @onNextStep="nextStep"
            @onPreviousStep="previousStep"
            @onSubmitForm="showFeedback"
          />
        </v-stepper-content>
        <v-stepper-content
          step="5"
          class="pa-0"
        >
          <StepProgramDocuments
            @onNextStep="nextStep"
            @onPreviousStep="previousStep"
            @onSubmitForm="showFeedback"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <BaseSnackbar
      :active-snackbar="feedback"
      :text-snackbar="feedbackMessage"
      :type-snackbar="feedbackColor"
      @closeSnackbar="feedback = !feedback"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import StepProgramName from './StepProgramName'
import StepProgramAssets from './StepProgramAssets.vue'
import StepProgramParticipants from './StepProgramParticipants'
import StepProgramParticipantsDocuments from './StepProgramParticipantsDocuments'
import StepProgramDocuments from './StepProgramDocuments'
import BaseSnackbar from '../../BaseSnackbar'
import BaseSnackbarConfiguration from '../../../mixins/BaseSnackbarConfiguration'

export default {
  name: 'CreateProgramSteps',
  components: { StepProgramName, StepProgramAssets, StepProgramParticipants, StepProgramParticipantsDocuments, StepProgramDocuments, BaseSnackbar },
  mixins:  [ BaseSnackbarConfiguration ],
  data() {
    return {
      step: 1,
      programId: ''
    }
  },
  created(){
    if(this.$route.params.id){
      this.programId = this.$route.params.id
      this.setProgramId(this.programId)
    }
  },
  methods: {
    ...mapMutations(["setDisableNavigation", "setUseActionFooter", "setProgramId"]),
    nextStep(step) {
      this.step = step
    },
    previousStep(step) {
      if(this.step == 1)
        return
      this.step = step
    }
  },
  beforeRouteLeave(to, from, next) {
    this.setUseActionFooter(false)
    this.setDisableNavigation(false)
    this.setProgramId(null)
    next()
  }
}
</script>

<style scoped>
.v-divider{
  width: 30px !important;
  flex: none !important;
  border-color: var(--v-primary-base) !important;
  border-width: medium;
}

</style>
