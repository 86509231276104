<template>
  <v-progress-circular
    v-if="isLoading"
    v-bind="{ ...$attrs, ...propsToApply }"
  />
</template>

<script>

export default {
  name: 'LoadingInput',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    indeterminate: {
      type: Boolean,
      default: true
    },
    size: {
      type: Number,
      default: 10,
    },
    width: {
      type: Number,
      default: 1,
    }
  },
  computed: {
    propsToApply() {
      const {...props} = this.$props
      return props
    }
  }
}
</script>

<style>

</style>
