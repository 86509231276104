<template>
  <v-footer
    class="app-footer mt-4"
    :style="applyPadding"
  >
    <p class="app-footer__text mr-4">
      Powered by <strong>iDtrust.</strong>
    </p>
    <a 
      href="https://www.idtrust.com.br/politicaprivacidade.html"
      target="_blank"
      class="app-footer__text mr-4"
    >
      Política de Privacidade
    </a>
    <v-dialog
      v-model="isModalTermsActive"
      persistent
    >
      <template v-slot:activator="{ on }">
        <a
          class="app-footer__text  mr-4"
          v-on="on"
        >
          Termos de Uso
        </a>
        <ModalTermsOfUse 
          :modal-active="isModalTermsActive"
          :term-footer="true"
          @update:modal-active="isModalTermsActive = $event"
        />
      </template>
    </v-dialog>
  </v-footer>
</template>

<script>
import ModalTermsOfUse from './Platform/ModalTermsOfUse';

export default {
  components: { ModalTermsOfUse },
  props: {
    noPaddingX: {
      type: Boolean,
      required: false
    },
    paddingLeft: {
      type: Boolean,
      required: false,
    },
    paddingRight:  {
      type: Boolean,
      required: false
    },
    navigationMini: {
      type: Boolean,
      required: false
    }
  },
  data(){
    return {
      isModalTermsActive: false
    }
  },
  computed: {
    applyPadding(){
      return {
        'padding-left' : this.applyPaddingLeft,
        'padding-right' :  this.paddingRight ? '452px' : '24px'
      }
    },
    applyPaddingLeft() {
      if(!this.noPaddingX || !this.paddingLeft) {
        return '24px'
      } else if(this.navigationMini){
        return '96px'
      } else {
        return '280px'
      }
    }
  }
}
</script>

<style scoped>
  .app-footer {
    background-color: inherit;
  }

  .app-footer__text {
    color: var(--custom-theme-black-054);
  }
</style>
