<template>
  <div class="platform-account-connection">
    <BaseCard
      :has-main-container="true"
    >
      <div class="ma-6">
        <v-form
          :ref="formName"
        >
          <v-row>
            <v-col
              class="pt-0"
              cols="auto"
              sm="5"
            >
              <BaseInput
                v-model="configurationName"
                label="Títudo da configuração"
                :rules="[isValid]"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="pt-0"
              cols="auto"
              sm="5"
            >
              <v-select
                v-model="selectedConnetionStepOption"
                :items="connectionStepsOptions"
                item-text="name"
                item-value="id"
                label="Escolha uma etapa"
                :rules="[isValid]"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="pt-0"
              cols="auto"
              sm="5"
            >
              <v-select
                v-model="selectedConnectionParticipantsTypes"
                :items="connectionParticipantsTypes"
                item-text="name"
                item-value="id"
                label="Tipo de participante"
                :rules="[isValid]"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="pt-0"
              cols="auto"
              sm="5"
            >
              <v-select
                v-model="selectedConnectionProgram"
                :items="allProgramsOptions"
                item-text="name"
                item-value="id"
                label="Selecione o programa"
                :rules="[isValid]"
              />
            </v-col>
          </v-row>
        </v-form>

        <BaseCard
          :is-header-actions="true"
          :has-main-container="true"
          :has-divider="true"
          base-card-title-class="app-font-semibold title mb-1"
          base-card-header-actions-class="app-align-items-center"
        >
          <template v-slot:header-title>
            Documentos obrigatórios
          </template>

          <template v-slot:header-title-description>
            Defina quais documentos são obrigatórios
          </template>

          <template v-slot:header-actions>
            <v-select
              ref="addDocumentSelected"
              :disabled="disableDocumentSelected"
              :items="filterDocumentsRequired"
              item-text="name"
              item-value="id"
              label="Selecione o documento"
              return-object
              class="mt-2"
              style="min-width: 180px; max-width: 300px;"
              @change="setAndEnableAddDocumentSelected"
            />

            <BaseButton
              :is-disabled="disableAddDocument"
              color="primary"
              @click="addDocumentRequired()"
            >
              Adicionar
            </BaseButton>
          </template>

          <BaseDataTable
            v-if="documentsRequired.length > 0"
            :hide-actions="true"
            :hide-headers="true"
            :items="documentsRequired"
            background-color="transparent"
          >
            <template v-slot:inject-items="scopedItems">
              <tr
                v-for="props in scopedItems"
                :key="props.index"
              >
                <td>
                  {{ props.item.documentSelected.name }}
                  <span class="pl-6">
                    {{ props.item.signatureRequired ? '(É necessário assinar)' : '' }}
                  </span>
                </td>
                <td class="text-right">
                  {{ props.item.templateSelected.name }}
                </td>
                <td
                  class="text-right"
                  width="140px"
                >
                  <div
                    class="d-flex"
                    style="align-items:center;"
                  >
                    <v-checkbox
                      :input-value="props.item.isCheck"
                      primary
                      hide-details
                      @click="openDialogTemplateDocument(props.item)"
                    />
                    <p class="mb-0">
                      Formalizar
                    </p>
                  </div>
                </td>
                <td
                  class="text-right"
                  width="100px"
                >
                  <a
                    class="body-2 blueff--text app-font-semibold"
                    @click="removeDocumentRequired(props.item.documentSelected.id)"
                  >
                    Excluir
                  </a>
                </td>
              </tr>
            </template>
          </BaseDataTable>
          <div class="text-center">
            <v-dialog
              v-model="isOpen"
              width="500"
              persistent
            >
              <v-card>
                <v-card-title
                  class="headline lighten-2"
                  primary-title
                >
                  Template de formalização
                  <v-spacer />
                  <a
                    class="body-2"
                    color="primary"
                    @click="closeDialogTemplateDocument"
                  >
                    <v-icon>close</v-icon>
                  </a>
                </v-card-title>

                <v-card-text>
                  Escolha o template de formalização do documento
                  <v-form
                    ref="documentTemplateForm"
                    class="mt-4"
                  >
                    <v-select
                      :items="connectionTemplates"
                      item-text="name"
                      item-value="id"
                      label="Selecione o template"
                      return-object
                      @change="setTemplateSelected"
                    />

                    <v-checkbox
                      v-model="currentDocumentReference.signatureRequired"
                      label="É necessário minha assinatura"
                    />
                  </v-form>
                </v-card-text>

                <v-divider />

                <v-card-actions class="app-modal-footer-background">
                  <v-spacer />
                  <BaseButton
                    color="primary"
                    type="text"
                    @click="closeDialogTemplateDocument"
                  >
                    Cancelar
                  </BaseButton>
                  <BaseButton
                    color="primary"
                    :is-disabled="!validateSelectedTemplateInCurrentDocumentReferenceContext"
                    @click="confirmDialogTemplateDocument(currentDocumentReference)"
                  >
                    Salvar
                  </BaseButton>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </BaseCard>
        <div class="mt-6 text-right">
          <BaseButton
            color="primary"
            type="text"
            @click="$emit('onCancel')"
          >
            Cancelar
          </BaseButton>
          <BaseButton
            color="primary"
            :is-disabled="!validateConnectionConfigurationFields"
            @click="saveConnectionConfiguration"
          >
            Salvar
          </BaseButton>
        </div>
      </div>
    </BaseCard>
  </div>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex'
import {utilsTestTypeAttribute} from '../../../utils/index'
import BaseCard from '../../BaseCard.vue'
import BaseDataTable from '../../BaseDataTable'

export default {
  components: {BaseDataTable, BaseCard},
  props: {
    active: {
      default: false,
      type: Boolean
    },
    isEditing: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      formName: 'formConnectionConfiguration',
      configurationName: "",
      isOpen: false,
      disableAddDocumentSelected: true,
      currentDocumentReference: {
        documentSelected: {
          id: '',
          name: '',
        },
        signatureRequired: false,
        templateSelected: {
          id: '',
          name: ''
        },
        isCheck: false
      },
      currentDocument: {
        documentSelected: {
          id: '',
          name: '',
        },
        signatureRequired: false,
        templateSelected: {
          id: '',
          name: ''
        },
        isCheck: false
      },
      selectedConnetionStepOption: "",
      selectedConnectionParticipantsTypes: "",
      selectedConnectionProgram: "",
      documentsRequired: [],
    }
  },
  computed: {
    ...mapState({
      connectionStepsOptions: (state) => {
        return state.platformAccount.connectionStepsOptions
      },
      connectionParticipantsTypes: (state) => {
        return state.platformAccount.connectionParticipantsTypes
      },
      allPrograms: (state) => {
        return state.platformProgram.allPrograms
      },
      connectionDocumentsTypes: (state) => {
        return state.platformAccount.connectionDocumentsTypes
      },
      connectionTemplates: (state) => {
        return state.platformAccount.connectionTemplates
      },
      connectionDetails: (state) => {
        return state.platformAccount.connectionConfigurationDetails
      },
      keycloak: state => state.auth.keycloak
    }),
    mapDocumentsRequired() {
      return this.documentsRequired.map((elem) => {
        return JSON.stringify(elem.documentSelected)
      })
    },
    filterDocumentsRequired() {
      return this.connectionDocumentsTypes.filter((elem) => {
        return this.mapDocumentsRequired.indexOf(JSON.stringify(elem)) === -1
      })
    },
    disableDocumentSelected() {
      return this.filterDocumentsRequired.length === 0
    },
    disableAddDocument() {
      return this.disableDocumentSelected || this.disableAddDocumentSelected
    },
    allProgramsOptions() {
      return [...this.allPrograms, {
        "name": "Todos",
        "id": null
      }]
    },
    validateDocumentSelectedInCurrentDocumentContext() {
      return Object.values(this.currentDocument.documentSelected).every((value) => {
        if (!value) {
          return false
        }
        return true
      })
    },
    validateSelectedTemplateInCurrentDocumentReferenceContext() {
      return Object.values(this.currentDocumentReference.templateSelected).every((value) => {
        if (!value) {
          return false
        }
        return true
      })
    },
    validateConnectionConfigurationFields() {
      return !!this.configurationName &&
        !!this.selectedConnetionStepOption &&
        !!this.selectedConnectionParticipantsTypes &&
        (!!this.selectedConnectionProgram || this.selectedConnectionProgram === null) &&
        this.documentsRequired.length > 0
    }
  },
  watch: {
    filterDocumentsRequired() {
      this.disableAddDocumentSelected = true
    },
    active() {
      this.updateConnectionFormFields();
    }
  },
  created() {
    this.updateConnectionFormFields();
  },
  methods: {
    ...mapMutations(["setDisableNavigation", "setOriginPath", "setConnectionConfigurationDetails"]),
    ...mapActions(["apiSaveConnectionConfigurations", "apiUpdateConnectionConfigurations"]),
    setAndEnableAddDocumentSelected(document) {
      const {id, name} = document
      Object.assign(this.currentDocument.documentSelected,
        {
          id,
          name
        }
      )

      this.disableAddDocumentSelected = false
    },
    setTemplateSelected(template) {
      const {id, name} = template
      Object.assign(this.currentDocumentReference.templateSelected,
        {
          id,
          name
        }
      )
    },
    addDocumentRequired() {
      const object = JSON.parse(JSON.stringify(this.currentDocument))

      this.documentsRequired.push(object)

      Object.assign(this.currentDocument.documentSelected, {id: '', name: ''})
    },
    removeDocumentRequired(id) {
      this.documentsRequired = this.documentsRequired.filter((elem) => {
        return elem.documentSelected.id !== id
      })
      this.$refs.addDocumentSelected.reset()
    },
    openDialogTemplateDocument(document) {

      if (document.isCheck) {
        const result = []

        this.documentsRequired.every((elem) => {
          if (elem.documentSelected.id === document.documentSelected.id) {
            document.signatureRequired = false
            document.isCheck = false

            Object.assign(document.templateSelected, {
              id: '',
              name: ''
            })

            return false
          } else {
            result.push(elem)

            return true
          }
        })

      } else {
        this.currentDocumentReference = document
        this.isOpen = true

        this.$refs.documentTemplateForm.reset()
      }
    },
    closeDialogTemplateDocument() {
      this.isOpen = false

      Object.assign(this.currentDocumentReference, {
        signatureRequired: false,
        templateSelected: {
          id: '',
          name: ''
        },
      })
    },
    confirmDialogTemplateDocument(document) {
      this.documentsRequired.every((elem) => {

        if (elem.documentSelected.id === document.documentSelected.id) {
          Object.assign(elem, {
            isCheck: true,
          })

          return false
        }

        return true
      })

      this.isOpen = false
    },
    mapConnectionConfigurationData() {
      const mappingData = {
        "id": this.keycloak.tokenParsed.initiator,
        "name": this.configurationName,
        "step_id": this.selectedConnetionStepOption,
        "participant_type_id": this.selectedConnectionParticipantsTypes,
        "program_id": this.selectedConnectionProgram,
        "documents": (() => {
          return this.documentsRequired.map((elem) => {
            return {
              "template_id": elem.templateSelected.id,
              "document_type_id": elem.documentSelected.id,
              "signature_required": elem.signatureRequired
            }
          })
        })()
      }
      return mappingData
    },
    async saveConnectionConfiguration() {
      try {
        const validData = this.mapConnectionConfigurationData()
        if (this.$route.params.id) {
          const data = {id_configuration: this.connectionDetails.id, ...validData}
          await this.apiUpdateConnectionConfigurations(data)
        } else {
          await this.apiSaveConnectionConfigurations(validData)
        }
        this.$emit('onSubmitForm', {
          message: 'Configuração salva com sucesso',
          color: 'success',
        })
      } catch(error) {
        this.$emit('onSubmitForm', {
          message: 'Erro ao salvar configuração',
          color: 'error',
        })
      }
    },
    findItemInList(iterate, match) {
      let result = null
      utilsTestTypeAttribute('iterate', iterate, 'array')
      iterate.every((item) => {
        if (item.id === match) {
          result = item
          return false
        } else {
          return true
        }
      })
      return result
    },
    updateConnectionFormFields() {
      if(this.isEditing && Object.values(this.connectionDetails).length > 0) {
        this.configurationName = this.connectionDetails.name
        this.selectedConnetionStepOption = this.connectionDetails.step_id
        this.selectedConnectionParticipantsTypes = this.connectionDetails.participant_type_id
        this.selectedConnectionProgram = this.connectionDetails.program_id || null
        this.connectionDetails.documents.forEach((document) => {
          const documentStructure = {
            documentSelected: {
              id: document.document_type_id,
              name: !document.document_type_id ? null : this.findItemInList(
                this.filterDocumentsRequired, document.document_type_id).name,
            },
            signatureRequired: document.signature_required,
            templateSelected: {
              id: document.template_id,
              name: !document.template_id ? null : this.findItemInList(this.connectionTemplates,
                document.template_id).name,
            },
            isCheck: document.template_id ? true : false
          }
          this.documentsRequired.push(documentStructure)
        })
      } else {
        if(this.$refs[this.formName]){
          this.$refs[this.formName].reset()
        }
        this.documentsRequired = []
        this.$nextTick(() => {
          this.selectedConnectionProgram = ""
        })
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.setDisableNavigation(false)
    next()
  }
}
</script>

<style scoped>
</style>
