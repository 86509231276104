<template>
  <section class="platform-banner-account">
    <div class="app-align-items-center pa-6">
      <div>
        <h2
          class="font-weight-regular headline white--text mb-1"
          v-html="bannerTitle"
        />
        <p
          class="body-1 mb-0 white--text"
          style="max-width: 442px;"
        >
          {{ bannerText }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props:{
    bannerTitle: {
      type: String,
      required: false,
      default: ''
    },
    bannerText: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<style>
.platform-banner-account {
  background: var(--v-primary-base) url('../assets/bg-banner-account.svg') no-repeat right/contain;
  border-radius: 5px;
}
</style>
