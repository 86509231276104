<template>
  <div>
    <PersonalData
      icon="place"
      title="Localização"
      :template="getTypeTemplate"
    >
      <template
        v-slot:[getTypeTemplate]
      >
        <p v-html="getPlatformOrganizationAddress" />
      </template>
    </PersonalData>
  </div>
</template>

<script>
import PersonalData from './PersonalData'
import UtilsPlatformOrganizationProfileTemplate from '../../../mixins/UtilsPlatformOrganizationProfileTemplate'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'PlatformOrganizationProfileAddressTemplate',
  components: { PersonalData },
  mixins: [ UtilsPlatformOrganizationProfileTemplate ],
  props: {
    context: {
      type: String,
      default: '',
      required: false
    }
  },
  computed: {
    ...mapState('platformOrganization', ['platformOrganizationAddress']),
    ...mapState('programMembers', ['memberAddress']),
    ...mapGetters('platformOrganization', ['hasPlatformOrganizationAddress']),
    ...mapGetters('programMembers', ['hasMemberAddress']),
    isContextMembers(){
      if(this.context == 'members') return true
      else return false
    },
    platformOrganizationAddressStreet() {
      if(this.isContextMembers) {
        return this.memberAddress[0].street ? this.memberAddress[0].street : '-'
      } else {
        return this.platformOrganizationAddress.content[0].street ? this.platformOrganizationAddress.content[0].street : '-'
      }
    },
    platformOrganizationAddressNumber() {
      if(this.isContextMembers){
        return this.memberAddress[0].number ? this.memberAddress[0].number : '-'
      } else {
        return this.platformOrganizationAddress.content[0].number ? this.platformOrganizationAddress.content[0].number : '-'
      }
    },
    platformOrganizationAddressComplement() {
      if(this.isContextMembers) {
        return this.memberAddress[0].complement ? this.memberAddress[0].complement : '-'
      } else {
        return this.platformOrganizationAddress.content[0].complement ? this.platformOrganizationAddress.content[0].complement : '-'
      }
    },
    platformOrganizationAddressNeighborhood() {
      if(this.isContextMembers) {
        return this.memberAddress[0].neighborhood ? this.memberAddress[0].neighborhood : '-'      
      } else {
        return this.platformOrganizationAddress.content[0].neighborhood ? this.platformOrganizationAddress.content[0].neighborhood : '-'
      }
    },
    platformOrganizationAddressCity() {
      if(this.isContextMembers) {
        return this.memberAddress[0].city ? this.memberAddress[0].city : '-'
      } else {
        return this.platformOrganizationAddress.content[0].city ? this.platformOrganizationAddress.content[0].city : '-'
      }
    },
    platformOrganizationAddressPostalCode() {
      if(this.isContextMembers) {
        return this.memberAddress[0].postal_code ? this.memberAddress[0].postal_code : '-'
      } else {
        return this.platformOrganizationAddress.content[0].postal_code ? this.platformOrganizationAddress.content[0].postal_code : '-'
      }
    },
    platformOrganizationAddressState() {
      if(this.isContextMembers) {
        return this.memberAddress[0].state ? this.memberAddress[0].state : '-'
      } else {
        return this.platformOrganizationAddress.content[0].state ? this.platformOrganizationAddress.content[0].state : '-'
      }
    },
    getPlatformOrganizationAddress(){
      if(this.hasPlatformOrganizationAddress || this.hasMemberAddress) {
        return `
          Endereço ${this.wrapTextWithHtmlTag(this.platformOrganizationAddressStreet)}<br/>
          Número ${this.wrapTextWithHtmlTag(this.platformOrganizationAddressNumber)}<br/>
          Complemento ${this.wrapTextWithHtmlTag(this.platformOrganizationAddressComplement)}<br/>
          CEP ${
            this.wrapTextWithHtmlTag(
              this.$options.filters.maskCEP(
                this.platformOrganizationAddressPostalCode
              )
            )
          }<br/>
          Bairro ${this.wrapTextWithHtmlTag(this.platformOrganizationAddressNeighborhood)}<br/>
          Cidade ${this.wrapTextWithHtmlTag(this.platformOrganizationAddressCity)}<br/>
          Estado ${this.wrapTextWithHtmlTag(this.platformOrganizationAddressState)}
        `
      } else {
        if (this.isContextMembers)
          return `Não há dados para exibir.`
        return `Clique em <strong>editar</strong> para cadastrar o endereço.`
      }
    },
    getTypeTemplate() {
      return this.hasPlatformOrganizationAddress ? 'data-template' : 'no-data-template'
    }
  }
}
</script>

<style>

</style>
