import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

//Third party features
import vuetifyOptions from './plugin/vuetify'
import VueFileAgent from 'vue-file-agent';
import VueTour from 'vue-tour'
import VueRouter from 'vue-router'
import VueAnalytics from 'vue-ua'

//CSS files
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import './assets/css/global.css'
import 'vue-file-agent/dist/vue-file-agent.css'
import 'vue-tour/dist/vue-tour.css'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/groovy/groovy'
import 'codemirror/theme/base16-dark.css'

//Custom features
import component from './global/component'
import filter from './global/filter'
import directive from './global/directive'

//Project dependencies
import router from './router'
import store from './store'
import keycloak from './services/keycloak'

import utilsGlobal from './mixins/UtilsGlobal'

import App from './App.vue'

Vue.mixin(utilsGlobal)

Vue.use(Vuetify)
Vue.use(VueFileAgent)
Vue.use(VueTour)

const gaRouter = new VueRouter()
Vue.use(VueAnalytics, {
  appName: 'mutant-portal-v2',
  appVersion: '0.1.0',
  trackingId: 'UA-123153501-7',
  debug: false,
  vueRouter: gaRouter,
  trackPage: true
})

Vue.config.productionTip = process.env.NODE_ENV === 'production'

component()
filter()
directive()

keycloak().then(() => {
  new Vue({
    vuetify: new Vuetify(vuetifyOptions),
    router,
    store,
    render: h => h(App)
  }).$mount("#app")
})








