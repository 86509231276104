<template>
  <div class="platform-organization-profile-partner-detail">
    <BaseCard
      v-for="partner in partnerList"
      :key="partner.id"
      :has-divider="true"
      :has-main-container="true"
      base-card-title-class="app-font-semibold title mb-1"
      base-card-container-classes="mb-5"
      base-card-header-actions-class="d-flex align-self-start text-right"
    >
      <template v-slot:header-title>
        <v-icon class="platform-organization-profile-partner-detail__icon pr-2">
          {{ partner.party_type === 'person' ? 'account_circle' : 'domain' }}
        </v-icon>
        {{ partner.name }}
      </template>
      <template v-slot:header-actions>
        <BaseButton
          v-if="!scopeMember"
          type="text"
          color="blueff"
          class="app-button__link-text app-button--hide-ripple app-button--no-hover pa-0 ma-0"
          @click="editPartner(partner)"
        >
          Editar
        </BaseButton>
      </template>
      <template v-slot:header-title-description>
        <p v-html="getPartnerData(partner)" />
      </template>
      <BaseCard
        :has-main-container="true"
        :has-divider="true"
        base-card-title-class="app-font-semibold title mb-0"
        base-card-container-classes="no-border ma-0 pa-0"
        base-card-container-title="pt-3"
      >
        <template v-slot:header-title>
          Documentos
        </template>
        <template v-slot:header-actions>
          <a
            class="body-2 app-font-semibold ml-4"
          >
            <v-icon
              v-if="partnerDocuments[partner.id]"
              class="primary--text mr-1"
              @click="hideDocuments(partner.id)"
            >
              expand_less
            </v-icon>
            <v-icon
              v-else
              class="primary--text mr-1"
              @click="showDocuments(partner.id)"
            >
              expand_more
            </v-icon>
          </a>
        </template>
        <DocumentList
          v-if="partnerDocuments[partner.id]"
          :document-list="partnerDocuments[partner.id]"
          :organization-id="organizationId"
          context-actions="read"
          :only-download="scopeMember"
          class="border-bottom"
        />
      </BaseCard>

      <BaseCard
        v-if="partner.party_type !== 'person'"
        :has-main-container="true"
        :has-divider="true"
        base-card-title-class="app-font-semibold title mb-0"
        base-card-container-classes="no-border ma-0 pa-0"
        base-card-container-title="pt-3"
      >
        <template v-slot:header-title>
          Sócios da empresa
        </template>
        <template v-slot:header-actions>
          <BaseButton
            v-if="!scopeMember"
            type="text"
            color="blueff"
            class="app-button__link-text app-button--hide-ripple app-button--no-hover pa-0 ma-0"
            @click="createPartnerChild(partner.tax_number)"
          >
            Adicionar
          </BaseButton>
          <a
            class="body-2 app-font-semibold ml-4"
          >
            <v-icon
              v-if="partnerChildren[partner.id]"
              class="primary--text mr-1"
              @click="hidePartnerChindren(partner.id)"
            >
              expand_less
            </v-icon>
            <v-icon
              v-else
              class="primary--text mr-1"
              @click="showPartnerChildren(partner)"
            >
              expand_more
            </v-icon>
          </a>
        </template>
        <v-row v-if="partnerChildren[partner.id]">
          <v-col
            v-for="children in partnerChildren[partner.id]"
            :key="children.id"
          >
            <PartnerDetail
              :organization-id="organizationId"
              :partner-list="[children]"
              :is-child="true"
              :scope-member="scopeMember"
              @onEditingPartner="editPartner"
            />
          </v-col>
        </v-row>
      </BaseCard>
    </BaseCard>
  </div>
</template>

<script>
import BaseCard from '../../BaseCard'
import { mapActions, mapState, mapMutations } from 'vuex'
import UtilsPlatformOrganizationProfileTemplate from '../../../mixins/UtilsPlatformOrganizationProfileTemplate'
import DocumentList from './DocumentList'

export default {
  name: 'PartnerDetail',
  components: { BaseCard, DocumentList },
  mixins: [ UtilsPlatformOrganizationProfileTemplate ],
  props:{
    partnerList: {
      type: Array,
      required: false,
      default: () => []
    },
    isChild: {
      type: Boolean,
      required: false,
    },
    scopeMember: {
      type: Boolean,
      required: false,
      default: false
    },
    organizationId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      expandDocuments: {},
      expandPartnerChildren: {},
    }
  },
  computed: {
    ...mapState({
      platformOrganizationPartnerDocuments: state => state.platformOrganization.platformOrganizationPartnerDocuments,
      platformOrganizationPartnerChildren: state => state.platformOrganization.platformOrganizationPartnerChildren,
      idPartnerToUpdateDocumentList: state => state.platformOrganization.idPartnerToUpdateDocumentList
    }),
    partnerDocuments(){
      return this.expandDocuments
    },
    partnerChildren(){
      return this.expandPartnerChildren
    }
  },
  watch: {
    idPartnerToUpdateDocumentList(newValue){
      if(newValue){
        this.showDocuments(newValue)
      }
    }
  },
  methods: {
    ...mapActions('platformOrganization', ['apiGetPlatformOrganizationPartnerChildren', 'apiGetPlatformOrganizationPartnerDocuments']),
    ...mapMutations('platformOrganization', ['setPlatformOrganizationPartnerChildContext']),
    getPartnerData(partner){
      return `
        Email ${this.wrapTextWithHtmlTag(partner.email)}<br/>
        Participação ${this.wrapTextWithHtmlTag(`${partner.share.replace(".", ",")}%`)}<br/>
        ${partner.party_type === 'person' ?
          `Assina pela empresa ${this.wrapTextWithHtmlTag(partner.is_signer == "true" ? 'Sim' : 'Não')}<br/>` : ''
        }
        ${partner.party_type === 'person' ?
          `Assina sozinho ${this.wrapTextWithHtmlTag(partner.cosigner  == "true" ? 'Sim' : 'Não')}<br/>` : ''
        }
      `
    },
    async showDocuments(id){
      await this.apiGetPlatformOrganizationPartnerDocuments({organizationId: this.organizationId, partnerId: id, scopeMember: this.scopeMember})
      this.$set(this.expandDocuments, id, this.platformOrganizationPartnerDocuments)
    },
    hideDocuments(id){
      this.expandDocuments[id] = null;
    },
    async showPartnerChildren(partner){
      await this.apiGetPlatformOrganizationPartnerChildren({organizationId: this.organizationId, partnerTaxNumber: { tax_number: partner.tax_number}, scopeMember: this.scopeMember})
      this.$set(this.expandPartnerChildren, partner.id, this.platformOrganizationPartnerChildren)
    },
    hidePartnerChindren(id){
      this.expandPartnerChildren[id] = null
    },
    createPartnerChild(parentTaxNumber) {
      this.setPlatformOrganizationPartnerChildContext(true)
      this.$emit('onCreatingPartnerChild', {active: true, parentTaxNumber})
    },
    editPartner(partner) {
      if(this.isChild) {
        this.setPlatformOrganizationPartnerChildContext(true)
      }

      this.apiGetPlatformOrganizationPartnerDocuments({
        organizationId: this.organizationId,
        partnerId: partner.id,
        scopeMember: this.scopeMember
      })

      const partnerNameWhenEditing = partner.name
      const data = {...partner, partnerNameWhenEditing}

      this.$emit('onEditingPartner', data)
    }
  }
}
</script>

<style>
.platform-organization-profile-partner-detail .no-border {
  border: none!important;
}
.platform-organization-profile-partner-detail .border-bottom {
  border: none!important;
  border-bottom: 1px solid var(--custom-theme-black-020)!important;
}

.platform-organization-profile-partner-detail .platform-organization-profile-partner-detail__icon {
  font-size: 18px;
}
</style>
