<template>
  <v-data-table
    :headers="headers"
    :hide-default-footer="hideActions"
    :hide-default-header="hideHeaders"
    :items="items"
    :item-key="itemKey"
    class="base-data-table"
    :class="classes"
    :style="{ backgroundColor }"
    :show-group-by="showGroupBy"
    :group-by="groupBy"
    :show-select="selectAll"
    :server-items-length="totalItems"
    :footer-props="{
      'items-per-page-options': [5, 10, 25, 50],
      'items-per-page-text': 'Linhas por página:'
    }"
    :options="options"
    :single-expand="singleExpand"
    :value="tableModel"
    @pagination="updatePagination"
    @input="updateModel"
    v-on="$listeners"
  >
    <template
      v-if="items.length > 0 || userIsAdmin"
      v-slot:header="props"
    >
      <thead class="v-data-table-header">
        <slot
          name="inject-header"
          :headers="props"
        />
      </thead>
    </template>

    <template v-slot:item="props">
      <slot
        name="inject-items"
        :items="props"
      >
        <tr>
          <td
            v-for="(value, key, index) in props.item"
            :key="index"
          >
            {{ value }}
          </td>
        </tr>
      </slot>
    </template>

    <template v-slot:no-data>
      <slot
        v-if="isCustomTextNoData"
        name="custom-text-no-data"
      />
      <div
        v-else
        :class="{ 'app-apply-blur' : isAdminEditing }"
      >
        <img
          src="@/assets/no-itens.svg"
          width="126"
          height="114"
        >
        <p class="no-result">
          <slot name="no-data-text-message">
            Nenhum item para exibir.
          </slot>
        </p>
      </div>
    </template>

    <template v-slot:no-results>
      <div :class="{ 'app-apply-blur' : isAdminEditing }">
        <img
          src="@/assets/no-itens.svg"
          width="126"
          height="114"
        >
        <p>
          <slot name="no-result-text-message">
            Nenhum item para exibir.
          </slot>
        </p>
      </div>
    </template>

    <template v-slot:expanded-item="props">
      <tr class="tr--child-expanded">
        <td
          style="padding: 0 !important"
          :colspan="props.headers.length"
        >
          <slot
            name="inject-expand"
            :items="props"
          />
        </td>
      </tr>
    </template>

    <template v-slot:body.append>
      <slot name="inject-append" />
    </template>

    <template v-slot:footer.page-text="props">
      <span v-if="hasInfinitePagination"> Linhas {{ props.pageStart }} - {{ props.pageStop }}  de muitos</span>
      <span v-else> Linhas {{ props.pageStart }} - {{ props.pageStop }}  de {{ props.itemsLength }}</span>
    </template>
  </v-data-table>
</template>

<script>
import permissions from "../mixins/permissions";

export default {
  name: "BaseDataTable",
  mixins: [permissions],
  props: {
    options: {
      required: false,
      type: Object
    },
    items: {
      required: true,
      type: Array
    },
    backgroundColor: {
      required: false,
      type: String
    },
    hideActions: {
      required: false,
      type: Boolean,
      default: false
    },
    hideHeaders: {
      required: false,
      type: Boolean,
      default: false
    },
    headers: {
      type: Array,
      required: false
    },
    classes: {
      type: String,
      required: false
    },
    totalItems: {
      type: Number,
      required: false
    },
    executeAction: {
      type: Function,
      required: false,
      default: () => {
      }
    },
    showGroupBy: {
      type: Boolean,
      required: false
    },
    groupBy: {
      type: String,
      required: false
    },
    tableModel: {
      type: Array,
      required: false
    },
    selectAll: {
      type: Boolean,
      required: false
    },
    hasInfinitePagination: {
      type: Boolean,
      required: false
    },
    itemKey: {
      type: String,
      required: false
    },
    expand: {
      type: Boolean,
      required: false
    },
    singleExpand: {
      type: Boolean,
      required: false,
      default: false
    },
    isAdminEditing: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    isCustomTextNoData() {
      return !!this.$slots['custom-text-no-data']
    },
    hidePagination() {
      return !this.totalItems || this.hideActions
    }
  },
  methods: {
    updatePagination(param) {
      this.$emit('update-pagination', param);
    },
    updateModel(event) {
      this.$emit('update-model', event);
    }
  }
}
</script>

<style scoped>
/* >>> means 'deep' in vue-loader */
.base-data-table >>> tr > td,
.base-data-table >>> tr > th {
  padding: 0px 15px !important;
}

.base-data-table >>> th {
  font-weight: 400;
}

.base-data-table >>> table {
  background-color: inherit;
}

.base-data-table >>> tbody tr {
  border-bottom: 1px solid var(--custom-theme-black-012);
}

.v-data-table__empty-wrapper,
.base-data-table >>> tbody tr.v-data-table__empty-wrapper {
  min-height: 50vh !important;
  height: 50vh !important;
  border: none !important;
  text-align: center !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.v-data-table__empty-wrapper:hover {
  background-color: inherit !important;
}

.v-data-table__empty-wrapper p {
  color: var(--custom-theme-black-054) !important;
  font-size: 14px !important;
  font-weight: 700 !important;
}

.v-data-table__empty-wrapper td {
  text-align: center !important;
}

.base-data-table >>> tbody tr.v-datatable__expand-row {
  margin: 0 !important;
}

.base-data-table >>> .v-simple-checkbox .theme--light.v-icon.v-icon.v-icon--disabled {
  color: rgba(0, 0, 0, 0.2)!important;
}

.base-data-table >>> .no-result h4,
.base-data-table >>> .no-result h5,
.base-data-table >>> .no-result h6 {
  color: var(--v-primary-base);
  font-size: 24px;
  font-weight: 700;
}

.base-data-table >>> .no-result h4 + *,
.base-data-table >>> .no-result h5 + *,
.base-data-table >>> .no-result h6 + * {
  font-size: 14px;
  font-weight: 400;
}

</style>
