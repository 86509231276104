<template>
  <div class="platform-configuration">
    <BaseContainer
      :active-spacer="true"
      :permission-list="['admin']"
    >
      <template v-slot:header-title>
        Configurações
      </template>
      <template v-slot:header-title-description>
        Cadastre e gerencie as configurações gerais da plataforma.
      </template>
      <v-row>
        <v-col
          sm="3"
          xl="2"
          class="pa-0"
        >
          <v-list
            dense
          >
            <v-list-item-group
              v-model="selectedItem"
            >
              <v-list-item
                v-for="(item, index) in menuItems"
                :key="index"
                class="px-0"
              >
                <BaseLink
                  :to="`/platform-configuration/${item.to}`"
                  style="width: 100%; height: 100%; min-height:40px;"
                >
                  <v-list-item-content class="px-2 py-3">
                    <v-list-item-title class="color--text-black-054">
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </BaseLink>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>

        <v-col>
          <router-view @onSubmitForm="showFeedback" />
        </v-col>
      </v-row>
    </BaseContainer>
    <BaseSnackbar
      :active-snackbar="feedback"
      :text-snackbar="feedbackMessage"
      :type-snackbar="feedbackColor"
      @closeSnackbar="feedback = !feedback"
    />
  </div>
</template>

<script>
import BaseSnackbar from '../../BaseSnackbar'
import BaseSnackbarConfiguration from '../../../mixins/BaseSnackbarConfiguration'

export default {
  name: 'PlatformConfiguration',
  components: { BaseSnackbar },
  mixins : [ BaseSnackbarConfiguration ],
  data() {
    return {
      selectedItem: 0,
      menuItems : [
        {
          name: "Templates de formalização",
          to: `envelope-template`
        }
      ]
    }
  },
  async created() {
    this.changeSelectedItem()
  },
  methods: {
    changeSelectedItem(){
      const routePath = this.$route.path.split('/').reverse()[0]
      const routeIndex = this.menuItems.findIndex(el => el.to === routePath)
      this.selectedItem = routeIndex > -1 ? routeIndex : 0
    }
  }
}
</script>

<style scoped>
.platform-configuration .v-list {
  background: transparent;
}

.v-list-item-group .v-list-item--active >>> .v-list-item__title{
  font-weight: bold;
}
</style>
