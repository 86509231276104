import store from '../store'

const loader = {
  createLoader(){
    let idInterval = null
    return {
      startProgressLoadPage(fn=null){
        store.commit('setLoadPage', true)
        idInterval = setInterval(() => {
          let value = store.state.progressLoadPage
          if(value <= 100)
            store.commit('setProgressLoadPage', ++value)
        },100)

        if(fn){
          fn()
        }
      },
      stopProgressLoadPage(fn=null, path=null){
        store.commit('setProgressLoadPage', 100)
        clearInterval(idInterval)
        setTimeout(() => {
          store.commit('setLoadPage', false)
          store.commit('setProgressLoadPage', 0)
        }, 1000)

        if(fn) {
          path ? fn(path) : fn()
        }
      }
    }
  },
}

export default loader.createLoader()
