import api from '../../services/api';

export default {
  namespaced: true,
  state: {
    envelopeTemplateList: [],
    envelopeTemplate: null,
    configSchemaRole: null
  },
  mutations: {
    setEnvelopeTemplateList(state, payload) {
      state.envelopeTemplateList = payload
    },
    setEnvelopeTemplate(state, payload) {
      state.envelopeTemplate = payload
    },
    setConfigSchemaRole(state, payload) {
      state.configSchemaRole = payload
    }
  },
  actions: {
    async apiGetEnvelopeTemplateList({commit}, payload) {
      const { filter, page, size } = payload
      const result = await api.get(`/envelopes/templates?q=${filter}&page=${page-1}&size=${size}`)
      commit("setEnvelopeTemplateList", result.data)
    },
    async apiGetEnvelopeTemplate({commit}, payload) {
      const result = await api.get(`/envelopes/templates/${payload}`)
      commit("setEnvelopeTemplate", result.data)
    },
    async apiUpdateEnvelopeTemplate({commit}, payload) {
      await api.put(`/envelopes/templates/${payload.id}`, payload)
    },
    async apiDeleteEnvelopeTemplate({commit}, payload) {
      await api.delete(`/envelopes/templates/${payload}`)
    },
    async apiCreateEnvelopeTemplate({commit}, payload) {
      const result = await api.post(`/envelopes/templates`, payload)
      return result
    }
  }
}
