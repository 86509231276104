<template>
  <div class="operation-register">
    <OperationRegisterFilter v-if="userIsAdmin" />
    <BaseContainer
      :active-spacer="true"
      :permission-list="['owner', 'admin']"
    >
      <template slot="header-title">
        Lista de Operações
      </template>
      <template
        v-if="userIsAdmin"
        slot="header-actions"
      >
        <a
          class="body-2 primary--text text-uppercase"
          style="display:inline-block;"
          @click="onClickButtonFilterOperationRegister"
        >
          <v-icon
            style="vertical-align: middle;"
            color="primary"
          >filter_list</v-icon>
          <span
            class="pl-2"
            style="display: inline-block; vertical-align: middle;"
          >FILTROS</span>
        </a>
      </template>
      <OperationData
        :operation-list-register-execution="listRegisterOperationExecution"
        @onClickOperationData="clickedOperationData"
      />
    </BaseContainer>
  </div>
</template>

<script>
import OperationData from './OperationData'
import OperationRegisterFilter from './OperationRegisterFilter'

import { mapState, mapMutations } from 'vuex'
import permissions from "@/mixins/permissions";

export default {
  name: "OperationRegister",
  mixins: [permissions],
  computed: {
    ...mapState({
      isClickButtonFilterOperationRegister: (state) => {
        return state.operationRegister.isClickButtonFilterOperationRegister
      },
      listRegisterOperationExecution: (state) => {
        return state.operationRegister.listRegisterOperation.executions
      }
    }),
  },
  methods: {
    ...mapMutations(['setIsClickButtonFilterOperationRegister']),
    onClickButtonFilterOperationRegister(){
      this.setIsClickButtonFilterOperationRegister(!this.isClickButtonFilterOperationRegister)
    },
    clickedOperationData(id){
      this.$router.push({ path: `/operation-register/${id}`})
    }
  },
  components: { OperationData, OperationRegisterFilter },
}
</script>
