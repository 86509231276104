<template>
  <div class="platform-personal-data">
    <BaseTour
      :tour-name="tourName"
      :steps="dataSteps"
      @onFinish="finishTour"
    />
    <BaseContainer :active-spacer="true">
      <template v-slot:header-title>
        Dados pessoais
      </template>
      <template v-slot:header-title-description>
        Edite seus dados e configurações pessoais.
      </template>
      <BaseCard
        :background-color="'transparent'"
        :has-main-container="false"
        :base-card-title-class="'font-weight-bold base-card-title-medium'"
      >
        <template v-slot:header-title>
          {{ hideData(keycloak.name, true) }}
        </template>
        <template v-slot:header-title-description>
          {{ hideData(keycloak.email, true) }}
        </template>
      </BaseCard>
      <BaseCard
        :base-card-container-classes="'mt-4'"
        :has-main-container="true"
        :base-card-title-class="'font-weight-bold base-card-title-medium'"
      >
        <template v-slot:header-title>
          Alterar Senha
        </template>
        <template v-slot:header-title-description>
          Clique no botão abaixo para redefinir sua senha
        </template>
        <div class="pa-6">
          <BaseButton
            color="primary"
            button-class="ma-0"
            :href="createUpdatePasswordUrl()"
          >
            Redefinir senha
          </BaseButton>
        </div>
      </BaseCard>
    </BaseContainer>

    <v-dialog
      v-model="isModalActive"
      max-width="560"
      persistent
    >
      <BaseModal
        :show-indeterminate-progress="modalInfo.showIndeterminateProgress"
        :top-slot-content-title="modalInfo.topSlotContentTitle"
        :remove-button-close="modalInfo.removeButtonClose"
        :middle-slot-content-text-highlight="modalInfo.middleSlotContentTextHighlight"
        :middle-slot-content-text="modalInfo.middleSlotContentText"
        :bottom-buttons-align="modalInfo.bottomButtonsAlign"
        :bottom-slot-content-button-cancel-label="modalInfo.bottomSlotContentButtonCancelLabel"
        :bottom-slot-content-button-confirm-label="modalInfo.bottomSlotContentButtonConfirmLabel"
        :remove-button-cancel="modalInfo.removeButtonCancel"
        :remove-button-confirm="modalInfo.removeButtonConfirm"
        @on-cancel="() => typeof modalInfo.onCancel === 'function' && modalInfo.onCancel()"
        @on-confirm="() => typeof modalInfo.onConfirm === 'function' && modalInfo.onConfirm()"
      />
    </v-dialog>

    <div class="highlight-container">
      <v-col
        v-for="(card, index) in highlightCards"
        :key="index"
        cols="auto"
        class="highlight-cards mt-6 pt-0 pr-0"
      >
        <v-card
          :height="150"
          :data-v-step="index"
        >
          <div
            class="app-align-items-center"
            style="height: 109px"
          >
            <v-img
              :src="card.image"
              :width="card.imageSize"
              :max-width="card.imageSize"
              style="margin: 17px 13px"
            />

            <v-card-title style="white-space: nowrap">
              <div>
                <h3 class="headline mb-0">
                  {{ card.title }}
                </h3>
                <div class="headline subtitle-1">
                  {{ card.subtitle }}
                </div>
              </div>
            </v-card-title>
          </div>

          <v-divider />
          <v-card-actions>
            <BaseLink
              :to="card.link"
              class="app-align-items-center ml-6"
            >
              <v-icon class="blueff--text">
                {{ card.icon }}
              </v-icon>
              <span class="ml-1">{{ card.linkLabel }}</span>
            </BaseLink>
          </v-card-actions>
        </v-card>
      </v-col>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import BaseModal from '../BaseModal'
import ProgramTour from '../../mixins/ProgramTour'
import permissions from '../../mixins/permissions'

export default {
  components: {
    BaseModal
  },
  mixins: [ProgramTour, permissions],
  data() {
    return {
      isModalActive: false,
      modalInfo: {
        showIndeterminateProgress: false,
        topSlotContentTitle: "Redefinir Senha",
        removeButtonClose: false,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: "Sua senha foi redefinida com sucesso!",
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "",
        bottomSlotContentButtonConfirmLabel: "Fechar",
        removeButtonCancel: true,
        removeButtonConfirm: false,
        onCancel: () => {
          this.isModalActive = false;
          this.removeKeycloakParams()
        },
        onConfirm: () => {
          this.isModalActive = false;
          this.removeKeycloakParams()
        }
      },
      tourName: 'personal-data',
      dataSteps: [
        {
          target: '#v-step-0',
          header: {
            title: 'Minha Conta'
          },
          content: 'Nessa página você consegue alterar sua senha de acesso e acessar sua organização.',
          layoutStep: "starter",
          params: {
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="0"]',
          header: {
            title: 'Organizações'
          },
          content: 'Para operar no programa, você deve configurar sua organização.'
        }
      ],
      highlightCards: [
      {
          image: `${require("@/assets/img-organizations.svg")}`,
          imageSize: "80",
          title: "Organizações",
          subtitle: "Editar organizações, usuário e perfis.",
          icon: "domain",
          link: "/platform-organizations",
          linkLabel: "Gerenciar organizações",
        },
      ],
    }
  },
  computed: {
    ...mapState({
      keycloak: state => state.auth.keycloak.tokenParsed,
    })
  },
  created() {
    if (this.getKeycloakActionStatus() === "success") {
      this.isModalActive = true;
    } else {
      this.removeKeycloakParams();
    }

      if (this.userHasGroup(['master'])) {
      this.highlightCards.push({
        image: `${require("@/assets/img-personal-data.svg")}`,
        imageSize: "114",
        title: "Gestão de usuários",
        subtitle: "Cadastre usuários e gerencie suas permissões.",
        icon: "account_circle",
        link: "/permissions",
        linkLabel: "Gerenciar usuários",
      });

      this.dataSteps.push({
          target: '[data-v-step="1"]',
          header: {
            title: 'Gestão de usuários'
          },
          content: 'Aqui você pode cadastrar usuários e gerenciar suas permissões dentro da plataforma.'
      })
    }
  },
  methods: {
    createUpdatePasswordUrl() {
      const {
        VUE_APP_KEYCLOAK_URL,
        VUE_APP_KEYCLOAK_REALM,
        VUE_APP_KEYCLOAK_CLIENT_ID
      } = process.env;
      const origin = window.location.origin;
      return `${VUE_APP_KEYCLOAK_URL}/realms/${VUE_APP_KEYCLOAK_REALM}/protocol/openid-connect/auth?response_type=code&client_id=${VUE_APP_KEYCLOAK_CLIENT_ID}&redirect_uri=${origin}%2F%23%2Fplatform-personal-data&kc_action=UPDATE_PASSWORD`;
    },
    getKeycloakActionStatus() {
      return new URL(window.location.href).searchParams.get("kc_action_status")
    },
    removeKeycloakParams() {
      window.history.pushState({}, '', window.location.origin + '/#/platform-personal-data');
    },
    finishTour(){
      this.$router.push('/platform-organizations')
    },
  }
}
</script>

<style>
.base-card-title-medium {
  font-size: 22px !important;
}
</style>
