import { utilsTestTypeAttribute } from '../utils/'

const queryParamsFilter = {
  createQueryParamsFilter() {
    const that = this

    this.filterProps = {
      start_date: toLocalDate(minusDays(new Date(), 30)).toISOString().substr(0, 10),
      end_date: toLocalDate(new Date()).toISOString().substr(0, 10),
      page: 0,
      size: 100
    }

    function toLocalDate(date) {
      return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    }

    function minusDays(date, days) {
      return new Date(date.setDate(date.getDate() - days))
    }

    return {
      getFilterProps(prop) {
        try {
          if(Object.keys(that.filterProps).indexOf(prop) === -1) {
            throw `key ${prop} does not exists in filter props`
          }

          return that.filterProps[prop]
        } catch (error) {
          throw error
        }
      },
      setFilterProps(prop, value) {
        try {
          if(Object.keys(that.filterProps).indexOf(prop) === -1) {
            throw `key ${prop} does not exists in filter props`
          }

          Object.assign(that.filterProps, { [prop] : value })
        } catch (error) {
          throw error
        }
      },
      setAdditionalProps(props) {
        try {
          utilsTestTypeAttribute('props', props, 'object')

          const keysOfFilterProps = Object.keys(that.filterProps)

          let propFound = ''

          Object.keys(props).every((prop) => {
            const result = keysOfFilterProps.indexOf(prop) === -1
            if(!result) propFound = prop

            return result
          })

          if(propFound) {
            throw `prop ${propFound} Already exists inside of filter. Does not possible overwrite then`
          }

          Object.assign(that.filterProps, props)
        } catch (error) {
          throw error
        }
      },
      getFilterProps() {
        return that.filterProps
      },
      setIntervalDatesToFilter(start_date='', end_date='') {
        try {
          if(!start_date) {
            start_date = toLocalDate(minusDays(new Date(), 30)).toISOString().substr(0, 10)
          }

          if (!end_date) {
            end_date = toLocalDate(new Date()).toISOString().substr(0, 10);
          }

          const startDateTime = new Date(start_date).getTime()
          const endDateTime = new Date(end_date).getTime()

          if(startDateTime > endDateTime) {
            throw 'start_date must be less or equal than end_date'
          }

          Object.assign(that.filterProps, {
            start_date,
            end_date
          })
        } catch(error) {
          throw error
        }
      },
      getPaginationsOptionsToBaseDataTable() {
        return {
          page: that.filterProps.page,
          itemsPerPage: 10
        }
      }
    }
  },
}

export default queryParamsFilter
