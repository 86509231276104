<template>
  <div>
    <BaseInput
      mask="##.###.###/####-##"
      use-pattern="\D"
      v-bind="{ ...$attrs, ...propsToApply }"
      v-on="$listeners"
    >
      <template
        v-for="(slotValue, slotName) in $slots"
        v-slot:[slotName]
      >
        <slot :name="slotName" />
      </template>
    </BaseInput>
  </div>
</template>

<script>
import BaseInput from './BaseInput.vue'

import { utilsTranformValueToOnlyNumber, utilsIsValidLength } from '../../utils'
export default {
  name: 'CnpjInput',
  components: { BaseInput },
  props: {
    ...BaseInput.props,
    isRequired: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      privateRules: []
    }
  },
  computed: {
    rulesOfProps() {
      const { rules } = this.$props

      return [...this.privateRules, ...rules]
    },
    propsToApply() {
      const { ...props } = this.$props

      Object.assign(props, {
        rules: this.rulesOfProps,
      })

      return props
    }
  },
  created() {
    if (this.isRequired) {
      this.privateRules.push(this.isValid)
      this.privateRules.push(this.isValidLength)
    }
  },
  methods: {
    isValidLength(value) {
      const transformedValue = value ? utilsTranformValueToOnlyNumber(value) : ''

      return utilsIsValidLength(transformedValue, 14) || 'Cnpj inválido'
    },
    reset() {
      this.$refs[this.refName].reset()
    }
  }
}
</script>

<style>

</style>
