<template>
  <v-list class="py-0">
    <template v-for="(item, index) in menuList">
      <v-subheader
        v-if="!!item.name && item.itens.length > 0 && !navigationMini"
        :key="index"
        class="app-navigation__sub-header primary--text"
      >
        {{ item.name }}
      </v-subheader>
      <v-list-item
        v-for="subItem in item.itens"
        :id="`qa-id-name-${getIdName(subItem.execute)}`"
        :key="subItem.text"
        class="app-navigation__item"
      >
        <BaseLink
          :to="subItem.execute"
          class="app-navigation__link mx-2"
          type-link="programLink"
        >
          <v-list-item-action>
            <v-icon>{{ subItem.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content v-if="!navigationMini">
            <v-list-item-title class="color--text-black-054">
              {{ subItem.text }}
            </v-list-item-title>
          </v-list-item-content>
        </BaseLink>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
export default {
  props: {
    menuList: {
      type: Array,
      required: true
    },
    navigationMini: {
      type: Boolean,
      required: true
    },
  },
  methods: {
    getIdName(name){
      return name.replace('/', '')
    }
  }
}
</script>

<style scoped>
.app-navigation__sub-header {
  font-size: 14px;
  font-weight: 400;
  height: 24px;
  padding: 0 24px;
  margin: 8px 0px;
}

.app-navigation__link {
  display: flex;
  text-decoration: none;
  width: 100%;
}

.router-link-active:before {
  position: absolute;
  top: 0px;
  left: 0px;
  content: "";
  width: 4px;
  height: 100%;
  background-color: #737373;
}

.router-link-active:after {
  position: absolute;
  top: 0px;
  left: 0px;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.06)
}

.app-navigation__item {
  position: relative;
}
</style>
