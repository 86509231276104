<template>
  <div class="base-file-input">
    <v-file-input
      v-show="false"
      :ref="`baseFileInput${idInput}`"
      :rules="[isFileValid]"
      :accept="acceptTypes"
      :name="qaIdentificationName"
      @change="uploadFile"
    />
    <BaseButton
      type="text"
      color="blueff"
      class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0 ma-0"
      style="height: unset!important"
      :qa-identification-name="qaIdentificationName"
      @click="selectFile"
    >
      {{ primaryText }}
      <v-progress-circular
        v-if="showLoader"
        indeterminate
        class="app-button--is-loading mr-0 ml-2"
      />
      <v-icon
        v-if="showSuccess"
        right
      >
        check_circle
      </v-icon>
    </BaseButton>
    <p
      v-if="showErrorText"
      class="caption mb-1 error--text"
    >
      {{ errorText }}
    </p>
    <p
      v-if="subText"
      class="body-1 mb-4"
    >
      {{ subText }}
    </p>
  </div>
</template>

<script>

export default {
  name:"BaseFileInput",
  props: {
    primaryText: {
      type: String,
      required: false,
      default: null
    },
    subText: {
      type: String,
      required: false,
      default: null
    },
    errorText: {
      type: String,
      required: false,
      default: null
    },
    showLoader: {
      type: Boolean,
      required: true
    },
    showSuccess: {
      type: Boolean,
      required: false,
      default: false
    },
    acceptTypes: {
      type: String,
      required: true
    },
    rulesTypes: {
      type: Array,
      required: true
    },
    idInput: {
      type: String,
      required: true
    },
    qaIdentificationName: {
      type: String,
      required: false,
      default: null
    }
  },
  data: () => ({
    showErrorText: false
  }),
  methods: {
    selectFile(){
      this.showErrorText = false
      this.$refs[`baseFileInput${this.idInput}`].$el.querySelector('input').click()
    },
    uploadFile(value) {
      if(value){
        if(this.isFileValid(value)) {
          this.showErrorText = false
          this.$emit("uploadFileInput", value)
        } else {
          this.showErrorText = true
        }
      }
    },
    isFileValid(value) {
      return (value && (this.rulesTypes.includes(value.type))) || false
    },
  }
}
</script>
<style scoped>
</style>
