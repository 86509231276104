<template>
  <div>
    <template v-if="invoiceList.length">
      <BaseContainer
        v-for="invoiceDetail,index in invoiceList"
        :key="index"
        :active-spacer="true"
        base-container-classes="mb-6"
      >
        <template v-slot:header-title>
          <span class="text-capitalize">
            {{ getInvoiceMonth(invoiceDetail.payment_date) }}/{{ getInvoiceYear(invoiceDetail.payment_date) }}
          </span>
        </template>
        <template v-slot:header-title-description>
          Total: {{ invoiceDetail.total | formatCurrency }}
        </template>
            
        <template v-slot:header-actions>
          <BaseButton
            type="text"
            color="primary"
            context-button="program"
            @click="downloadCSV"
          >
            <v-icon
              left
              dark
            >
              cloud_download
            </v-icon>
            Download CSV
          </BaseButton>
        </template>

        <BaseDataTable
          :headers="headers"
          background-color="white"
          :items="invoiceDetail.statements"
          classes="elevation-2"
        >
          <template v-slot:inject-items="scopedItems">
            <tr
              v-for="props in scopedItems"
              :key="props.index"
            >
              <td>
                {{ props.item.product_name }}
              </td>
              <td>
                {{ props.item.service_name }}
              </td>
              <td>
                {{ props.item.quantity }}
              </td>
              <td>
                {{ props.item.value | formatCurrency }}
              </td>
            </tr>
          </template>
        </BaseDataTable>
      </BaseContainer>
    </template>
    <div
      v-else
      class="d-flex align-center justify-center text-center mb-4"
      style="height: 300px;"
    >
      <div>
        <img
          src="@/assets/no-itens.svg"
          width="126"
          height="114"
        >
        <p>Nenhum detalhe para exibir.</p>
        <p>Selecione uma organização ou verifique se ela participa de um programa.</p>
      </div>
    </div>
  </div>
</template>
<script>
import BaseDataTable from "../BaseDataTable"
import BaseContainer from "../BaseContainer"
import loader from '../../global/loader'
import { mapActions } from "vuex"

export default {
    components: { BaseContainer, BaseDataTable },
    props: {
        invoiceList: {
            type: Array,
            required: true
        },
        organizationId: {
            type: String,
            required: true
        }
    },
    data: () => ({
        headers: [
            { text: 'Produto', value: 'product_name' },
            { text: 'Serviço', value: 'service_name' },
            { text: 'Quantidade', value: 'quantity' },
            { text: 'Valor', value: 'value' }
        ]
    }),
    methods: {
        ...mapActions('platformOrganization',['apiDownloadBillFile']),
        getInvoiceMonth(date){
            let newDate = new Date(date.split('-')[0], date.split('-')[1]-1, date.split('-')[2]);
            let month = newDate.toLocaleString('pt-BR', { month: 'long' });
            return month
        },
        getInvoiceYear(date){
            let newDate = new Date(date.split('-')[0], date.split('-')[1], date.split('-')[2]);
            let year = newDate.getFullYear();
            return year
        },
        async downloadCSV(){
            try {
                await this.apiDownloadBillFile({
                  organizationId: this.organizationId,
                  date: 'last',
                  name: 'consumo',
                  type: 'text/csv', 
                  extension: 'csv'
                })
            } catch (error) {
                this.$emit('onError', 'Não foi possível fazer o download do arquivo.')
                loader.stopProgressLoadPage()
            }
        
        }
    }
}
</script>