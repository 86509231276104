<template>
  <div class="base-card-simple">
    <v-card
      outlined
      :class="cardClass"
      :height="cardHeight"
    >
      <div
        class="d-flex flex-column justify-space-between align-center pa-4"
        style="height:60px"
      >
        <v-img
          max-height="60"
          position="center center"
          :src="require(`@/assets/${cardImage}`)"
        />
      </div>

      <v-card-title class="d-flex flex-column align-center font-weight-bold app-headline">
        {{ cardTitle }}
      </v-card-title>

      <v-card-text
        class="text-center"
        style="height: calc(100% - 200px)"
      >
        {{ cardText }}
      </v-card-text>

      <v-card-actions class="d-flex flex-column align-center ma-4 pa-0">
        <slot name="card-action" />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "BaseCardSimple",
  props: {
    cardImage:{
      type: String,
      required: false,
      default: ''
    },
    cardTitle: {
      type: String,
      required: false,
      default: ''
    },
    cardText: {
      type: String,
      required: false,
      default: ''
    },
    cardClass: {
      type: String,
      required: false,
      default: ''
    },
    cardHeight: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>
<style lang="scss">
.base-card-simple {
  .v-sheet--outlined {
    border: 2px solid rgba(0, 0, 0, 0.20)!important;
    border-radius: 5px!important;
  }
  .v-card {
    background: transparent!important;
  }
  .v-card.selected {
    background: rgba(38, 70, 116, 0.1)!important;
    border: 2px solid var(--v-primary-base)!important;
    .v-card__title, .v-card__text {
      color: var(--v-primary-base);
    }
  }
  .v-card.hover {
    background: rgba(38, 70, 116, 0.03)!important;
  }
}
</style>
