<template>
  <div class="envelope">
    <Warning
      icon="notifications"
      type="info"
      classes="envelope__notification"
      :value="!isExtensionInstalled"
    >
      É necessário ter o plugin
      <span class="envelope__notification__highlight">Web PKI</span> para realizar assinaturas,
      <a
        target="_blank"
        @click="redirectToInstallPage"
      >clique aqui para instalar</a>.
    </Warning>

    <BaseContainer>
      <template slot="header-title">
        <BaseLink
          to="/envelopes"
          class="envelope_back-link"
          type-link="programLink"
        >
          <v-icon class="envelope_back-link__icon">
            chevron_left
          </v-icon>
        </BaseLink>
        Formalização
      </template>

      <div class="envelope__container">
        <div class="envelope__container__details">
          <div class="envelope__details__header mb-4">
            <div
              :class="{
                envelope__done : envelopeDetails.status == 'DONE',
                envelope__cancelled : envelopeDetails.status == 'CANCELLED' || envelopeDetails.status == 'ERROR',
                'envelope__details__box-title': true
              }"
            >
              <h4 class="envelope__details__title">
                {{ envelopeDetails.name }}
              </h4>

              <p
                class="body-1"
                :class="{
                  envelope__done__text : envelopeDetails.status == 'DONE',
                  envelope__cancelled__text: envelopeDetails.status == 'CANCELLED' || envelopeDetails.status == 'ERROR'
                }"
              >
                {{ envelopeStatusText }}
              </p>
              <p
                v-if="envelopeDetails.status == 'CANCELLED' || envelopeDetails.status == 'ERROR'"
                class="body-1 mt-2 envelope__cancelled__text"
              >
                <b>Erro: </b>{{ envelopeErrorText }}
              </p>
            </div>

            <div class="envelope__details__header--actions align-start pl-4">
              <v-dialog
                v-model="isModalActive"
                max-width="560"
                persistent
              >
                <template v-slot:activator="{ on }">
                  <BaseButton
                    v-if="showButtonSign && envelopeDetails.provider == 'IDTRUST'"
                    :is-disabled="disableButtonSign"
                    context-button="program"
                    color="primary"
                    v-on="on"
                  >
                    Assinar
                  </BaseButton>
                </template>

                <BaseModal
                  :show-indeterminate-progress="modalSignStep.showIndeterminateProgress"
                  :top-slot-content-title="modalSignStep.topSlotContentTitle"
                  :remove-button-close="modalSignStep.removeButtonClose"
                  :middle-image="modalSignStep.middleImage"
                  :middle-slot-content-text-highlight="modalSignStep.middleSlotContentTextHighlight"
                  :middle-slot-content-text="modalSignStep.middleSlotContentText"
                  :middle-check-agree="modalSignStep.middleCheckAgree"
                  :bottom-buttons-align="modalSignStep.bottomButtonsAlign"
                  :bottom-slot-content-button-cancel-label="modalSignStep.bottomSlotContentButtonCancelLabel"
                  :bottom-slot-content-button-confirm-label="modalSignStep.bottomSlotContentButtonConfirmLabel"
                  :remove-button-cancel="modalSignStep.removeButtonCancel"
                  :remove-button-confirm="modalSignStep.removeButtonConfirm"
                  @on-cancel="() => typeof modalSignStep.onCancel === 'function' && modalSignStep.onCancel()"
                  @on-confirm="() => typeof modalSignStep.onConfirm === 'function' && modalSignStep.onConfirm()"
                  @input="(value) => typeof modalSignStep.onCheck === 'function' && modalSignStep.onCheck(value)"
                >
                  <template
                    v-if="isSelectActive"
                    v-slot:inject-form
                  >
                    <v-select
                      v-model="thumbprint"
                      :items="certificates"
                      item-text="subjectName"
                      item-value="thumbprint"
                      filled
                      dense
                    />
                  </template>
                </BaseModal>
              </v-dialog>

              <v-menu
                offset-y
                attach
              >
                <template v-slot:activator="{ on }">
                  <BaseButton
                    v-if="authCancel"
                    :has-icon="true"
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </BaseButton>
                </template>

                <v-list-item
                  @click="onCancelEnvelope"
                >
                  <v-list-item-title>{{ cancelEnvelope.elementName }}</v-list-item-title>

                  <v-dialog
                    v-model="cancelEnvelope.dialog"
                    persistent
                    max-width="540"
                  >
                    <v-card>
                      <v-progress-linear
                        v-show="cancelEnvelope.loading"
                        class="my-0"
                        :indeterminate="cancelEnvelope.loading"
                      />

                      <template
                        v-if="envelopeHasSuccessTokenCancel && (canCancelDocument || userIsAdmin)"
                      >
                        <div v-show="!cancelEnvelope.cancelCorfirm">
                          <v-card-title class="headline">
                            Cancelar formalização
                            <v-spacer />
                            <BaseButton
                              :is-disabled="cancelEnvelope.loading"
                              type="text"
                              :has-icon="true"
                              @click="() => { onCancelCancelate(); resetCancelEnvelope() }"
                            >
                              <v-icon>close</v-icon>
                            </BaseButton>
                          </v-card-title>

                          <v-card-text class="envelope__details__header--cancelled">
                            Informe o motivo do cancelamento no campo abaixo:
                          </v-card-text>

                          <v-form
                            ref="form"
                            v-model="cancelEnvelope.form"
                            class="pa-4 pt-6"
                          >
                            <v-textarea
                              v-model="cancelEnvelope.reason"
                              name="reason"
                              label="motivo*"
                              :rules="[
                                cancelEnvelope.rules.required,
                                cancelEnvelope.rules.length(127)
                              ]"
                              auto-grow
                              :counter="127"
                              :disabled="cancelEnvelope.loading"
                            />
                          </v-form>

                          <v-card-actions>
                            <v-spacer />
                            <BaseButton
                              color="primary darken-1"
                              context-button="program"
                              :is-disabled="cancelEnvelope.loading"
                              type="text"
                              @click="() => { onCancelCancelate(); resetCancelEnvelope() }"
                            >
                              Fechar
                            </BaseButton>
                            <BaseButton
                              :is-disabled="!cancelEnvelope.form || cancelEnvelope.loading"
                              color="primary darken-1"
                              context-button="program"
                              @click="onCancelCorfirm"
                            >
                              Confirmar
                            </BaseButton>
                          </v-card-actions>
                        </div>

                        <div v-show="cancelEnvelope.cancelCorfirm">
                          <v-card-title class="headline">
                            {{
                              !cancelEnvelope.hasSuccessCancelCorfirm ? "Erro ao Cancelar" :
                              "Sucesso ao Cancelar"
                            }}
                            <v-spacer />
                            <BaseButton
                              type="text"
                              :has-icon="true"
                              @click="onFinishCancel"
                            >
                              <v-icon>close</v-icon>
                            </BaseButton>
                          </v-card-title>

                          <v-card-text class="envelope__details__header--cancelled">
                            {{ showMessageErrorCancelConfirm }}
                          </v-card-text>


                          <v-card-actions>
                            <v-spacer />

                            <BaseButton
                              :is-disabled="!cancelEnvelope.form"
                              color="primary darken-1"
                              context-button="program"
                              @click="onFinishCancel"
                            >
                              OK
                            </BaseButton>
                          </v-card-actions>
                        </div>
                      </template>

                      <template v-else>
                        <v-card-title class="headline">
                          Erro ao cancelar
                          <v-spacer />
                          <BaseButton
                            type="text"
                            :has-icon="true"
                            @click="cancelEnvelope.dialog = false"
                          >
                            <v-icon>close</v-icon>
                          </BaseButton>
                        </v-card-title>
                        <v-card-text class="envelope__details__header--cancelled">
                          {{ envelopeTokenCancel.message }}
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer />
                          <BaseButton
                            color="primary darken-1"
                            context-button="program"
                            @click="cancelEnvelope.dialog = false"
                          >
                            Ok
                          </BaseButton>
                        </v-card-actions>
                      </template>
                    </v-card>
                  </v-dialog>
                </v-list-item>
              </v-menu>
            </div>
          </div>
          <BaseDataTable
            classes="envelope__details__table elevation-1"
            :headers="filterEnvelopeTableHeader"
            :items="envelopeTableData"
            :expand="expand"
            :hide-actions="true"
            item-key="type"
            background-color="white"
          >
            <template v-slot:inject-items="scopedItems">
              <tr
                v-for="props in scopedItems"
                :key="props.index"
              >
                <td>{{ props.item.status }}</td>
                <td>
                  <v-tooltip
                    v-if="!props.item.is_signer"
                    bottom
                  >
                    <template v-slot:activator="{ on }">
                      <span
                        style="cursor: help; vertical-align: middle;"
                        v-on="on"
                      >
                        <v-icon color="#c9c9c9">info</v-icon>
                      </span>
                    </template>
                    <span>Você não precisa assinar esse documento</span>
                  </v-tooltip>
                  {{ props.item.type }}
                </td>

                <td>{{ props.item.count }}</td>

                <td v-if="userIsAdmin">
                  {{ envelopeDetails.provider }}
                </td>

                <td>
                  <BaseButton
                    type="text"
                    :has-icon="true"
                    color="#264674"
                    @click="props.expand(!props.isExpanded)"
                  >
                    <v-icon v-if="props.isExpanded">
                      expand_less
                    </v-icon>
                    <v-icon v-else>
                      expand_more
                    </v-icon>
                  </BaseButton>
                </td>
              </tr>
            </template>

            <template v-slot:inject-expand="scopedItems">
              <BaseDataTable
                classes="envelope__details__table envelope__details__table--inner-table"
                :headers="filterEnvelopeTableInnerHeader"
                :items="scopedItems.items.item.signers"
                :hide-actions="true"
              >
                <template v-slot:inject-items="scopedItems">
                  <tr
                    v-for="props in scopedItems"
                    :key="props.index"
                  >
                    <td>{{ props.item.status_signer }}</td>
                    <td>
                      <v-tooltip
                        bottom
                        :disabled="props.item.signer.length < 20"
                      >
                        <template v-slot:activator="{ on }">
                          <p v-on="on">
                            {{ props.item.signer }}
                          </p>
                        </template>
                        {{ props.item.signer }}
                      </v-tooltip>
                    </td>

                    <td>{{ props.item.role }}</td>

                    <td
                      v-if="userIsAdmin"
                      style="text-align: center"
                    >
                      <v-dialog
                        v-model="isModalInfoActive"
                        max-width="560"
                      >
                        <template v-slot:activator="{}">
                          <v-icon
                            @click="() => {selectedSigner = props.item; isModalInfoActive = true}"
                          >
                            info
                          </v-icon>
                        </template>
                        <BaseModal
                          :show-indeterminate-progress="modalInfo.showIndeterminateProgress"
                          :top-slot-content-title="modalInfo.topSlotContentTitle"
                          :remove-button-close="modalInfo.removeButtonClose"
                          :middle-slot-content-text-highlight="modalInfo.middleSlotContentTextHighlight"
                          :middle-slot-content-text="modalInfo.middleSlotContentText()"
                          :is-middle-slot-content-text-centered="false"
                          :bottom-buttons-align="modalInfo.bottomButtonsAlign"
                          :bottom-slot-content-button-cancel-label="modalInfo.bottomSlotContentButtonCancelLabel"
                          :bottom-slot-content-button-confirm-label="modalInfo.bottomSlotContentButtonConfirmLabel"
                          :remove-button-cancel="modalInfo.removeButtonCancel"
                          :remove-button-confirm="modalInfo.removeButtonConfirm"
                          @on-cancel="() => typeof modalInfo.onCancel === 'function' && modalInfo.onCancel()"
                        />
                      </v-dialog>
                    </td>
                  </tr>
                </template>
              </BaseDataTable>
            </template>

            <template
              v-if="envelopeDetails.participants"
              v-slot:inject-append
            >
              <tr>
                <td colspan="5">
                  <p class="envelope__details__participants mb-0">
                    <strong>Participantes: </strong>
                    <v-tooltip
                      v-if="participantes > -1"
                      bottom
                    >
                      <template v-slot:activator="{ on }">
                        <span
                          style="cursor: help;"
                          v-on="on"
                        >
                          {{ envelopeDetails.participants.slice(0, participantes).join(", ") }}
                          <strong
                            class="envelope__details__participants__highlight"
                          >e mais...</strong>
                        </span>
                      </template>
                      <span>{{ participantesText }}</span>
                    </v-tooltip>
                    <template v-else>
                      {{ participantesText }}
                    </template>
                  </p>
                </td>
              </tr>
            </template>
          </BaseDataTable>
        </div>

        <div
          id="envelope-carousel"
          class="envelope__container__files"
        >
          <v-carousel
            v-model="carouselItemActive"
            :height="825"
            :hide-delimiters="true"
          >
            <template v-slot:prev="{ on, attrs }">
              <BaseButton
                :is-disabled="disablePrevButton"
                :has-icon="true"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>chevron_left</v-icon>
              </BaseButton>
            </template>
            <template v-slot:next="{ on, attrs }">
              <BaseButton
                :is-disabled="disableNextButton"
                :has-icon="true"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>chevron_right</v-icon>
              </BaseButton>
            </template>

            <v-carousel-item
              v-for="(item,i) in envelopeFiles"
              :key="i"
            >
              <template v-if="isClickSign">
                <div
                  id="clicksign"
                  class="clicksign__container"
                />
              </template>
              <template v-else>
                <div class="envelope__files__details">
                  <div class="envelope__details__carousel-itens-text">
                    Arquivo {{ carouselActiveItem }} de {{ envelopeFiles.length }}
                  </div>

                  <BaseButton
                    type="text"
                    color="primary"
                    context-button="program"
                    style="color: #264674!important;"
                    @click="prepareDownloadFiles(i)"
                  >
                    <v-icon
                      left
                      dark
                    >
                      cloud_download
                    </v-icon>
                    baixar
                    <template v-if="envelopeDetails.status === 'DONE'">
                      manifesto
                    </template>
                    <template v-else>
                      arquivo
                    </template>
                  </BaseButton>
                </div>
                <embed
                  :src="`${ item }#toolbar=0&navpanes=0`"
                  width="650"
                  height="734"
                  type="application/pdf"
                >
              </template>
            </v-carousel-item>
          </v-carousel>
        </div>
      </div>
    </BaseContainer>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'
import BaseContainer from './BaseContainer'
import loader from '../global/loader'
import BaseModal from './BaseModal'
import modalSignSteps from '../mixins/BaseModalSignSteps'
import BaseDataTable from './BaseDataTable'
import Warning from './Program/Warning.vue'
import permissions from "../mixins/permissions";
import webpki from "../mixins/webpki";

export default {
  components: {BaseContainer, BaseModal, BaseDataTable, Warning},
  mixins: [modalSignSteps, permissions, webpki],
  data() {
    return {
      /*global process*/
      /*eslint no-undef: "error"*/
      clickSignUrl: process.env.VUE_APP_CLICKSIGN_URL,
      cancelEnvelope: {
        elementName: "Cancelar",
        dialog: false,
        loading: false,
        reason: "",
        form: false,
        rules: {
          length: len => v => (v || '').length <= len
            || `Quantidade de caracteres inválido (máximo: ${len})`,
          required: v => !!v || 'Este campo é obrigatório'
        },
        hasSuccessCancelCorfirm: false,
        cancelCorfirm: null
      },
      envelopeStatusText: null,
      envelopeErrorText: null,
      isSigner: false,
      recipientStatus: null,
      providerSignatureKey: null,
      showButtonSign: true,
      isViewAllFiles: false,
      carouselItemActive: 0,
      carouselItemBeforeActive: 0,
      errorSign: null,
      envelopeTableHeader: [
        {
          text: 'Status',
          value: 'status',
          class: 'status'
        },
        {
          text: 'Tipo',
          value: 'type',
          class: 'type'
        },
        {
          text: 'Quantidade',
          value: 'count',
          class: 'count'
        },
        /*{
          text: 'Detalhes',
          value: 'details',
          class: 'details'
        },*/
        {
          text: 'Provider',
          value: 'provider',
          class: 'provider',
        },
        {
          text: '',
          value: 'see',
          class: 'see'
        },
      ],

      envelopeTableInnerHeader: [
        {
          text: 'Status',
          value: 'status_signer',
          class: 'status-signer'

        },
        {
          text: 'Signatário',
          value: 'signer',
          class: 'signer'
        },
        {
          text: 'Papel',
          value: 'role',
          class: 'role'
        },
        {
          text: 'Detalhes',
          value: 'details',
          class: 'details',
          sortable: false,
          align: 'center'
        }
      ],
      expand: false,
      isModalInfoActive: false,
      selectedSigner: {},
      modalInfo: null,
    }
  },
  computed: {
    ...mapState({
      keycloak: state => state.auth.keycloak,
      envelope: "envelope",
      envelopeDetails: "envelopeDetails",
      envelopeFiles: "envelopeFiles",
      envelopeHasSuccessTokenCancel: "envelopeHasSuccessTokenCancel",
      envelopeTokenCancel: "envelopeTokenCancel",
    }),
    ...mapGetters(['envelopeMapUrlFiles']),
    disableButtonSign() {
      return !this.isExtensionInstalled && !this.isViewAllFiles && this.envelopeDetails.status == 'DONE'
    },
    isClickSign() {
      return this.envelopeDetails.provider === 'CLICKSIGN' && this.recipientStatus == 'NOT_SIGNED'
        && this.envelopeDetails.status == 'IN_PROGRESS'
    },
    carouselActiveItem() {
      return this.carouselItemActive + 1;
    },
    orderEnvelopeDetailsByFiles() {
      let files = {}
      this.envelopeDetails["summary"].forEach((elem) => {
        files[elem.type] = elem
        files[elem.type].sign = []
      })

      this.envelopeDetails["recipients"].forEach((elem) => {
        files[elem.document_type].sign.push(elem.signature_status)
        files[elem.document_type].status = files[elem.document_type].sign.indexOf("NOT_SIGNED")
        === -1 || this.envelopeDetails.status === 'DONE' ?
          "Assinado" : "Não Assinado"
      })

      return files
    },
    orderEnvelopeDetailsBySigner() {
      let signer = {}

      this.envelopeDetails["recipients"].forEach(elem => {
        if (!Object.prototype.hasOwnProperty.call(signer, elem.document_type)) {
          signer[elem.document_type] = {is_signer: false, signers: []}
        }

        signer[elem.document_type].signers.push({
          status_signer: elem.signature_status === "NOT_SIGNED" ? "Não Assinado" : "Assinado",
          signer: elem.name,
          role: elem.role,
          email: elem.email,
          id_number: elem.id_number
        })

        signer[elem.document_type].is_signer = elem.email.toLowerCase()
        === this.keycloak.tokenParsed.email.toLowerCase()
          ? true : false

      })

      return signer
    },
    envelopeTableData() {
      let data = {...this.orderEnvelopeDetailsByFiles}

      Object.keys(data).forEach((elem) => {
        data[elem].signers = this.orderEnvelopeDetailsBySigner[elem] ? this.orderEnvelopeDetailsBySigner[elem].signers : []
        data[elem].is_signer = this.orderEnvelopeDetailsBySigner[elem] ? this.orderEnvelopeDetailsBySigner[elem].is_signer : false
      })

      return Object.values(data)
    },
    participantes() {
      let text = this.envelopeDetails.participants
      let index = 0

      for (let i = 0; i < text.length; i++) {
        if (text.slice(0, i).join(", ").length > 30) {
          break
        }
        index = i
      }

      return index === 0 || index === text.length ? -1 : index
    },
    participantesText() {
      return this.envelopeDetails.participants.join(", ")
    },
    authCancel() {
      return !!this.keycloak.tokenParsed.initiator &&
        this.envelope[0]["datasource"] !== "movement" &&
        this.envelopeDetails.status === 'IN_PROGRESS'
    },
    showMessageErrorCancelConfirm() {
      return !!this.cancelEnvelope.cancelCorfirm &&
        this.cancelEnvelope.cancelCorfirm.message
    },
    filterEnvelopeTableHeader() {
      const filteredHeaders = this.envelopeTableHeader.filter(envelopeHeader =>
        this.userIsAdmin ? this.envelopeTableHeader : envelopeHeader.value !== 'provider'
      )
      return filteredHeaders
    },
    filterEnvelopeTableInnerHeader() {
      const filteredHeaders = this.envelopeTableInnerHeader.filter(envelopeHeader =>
        this.userIsAdmin ? this.envelopeTableInnerHeader : envelopeHeader.value !== 'details'
      )

      return filteredHeaders
    },
    renderSignerInformationHTML() {
      return `
            <ul>
              <li style="color: var(--custom-theme-black-087)"><strong>CPF:</strong> ${this.selectedSigner.id_number}</li>
              <li style="color: var(--custom-theme-black-087)"><strong>Nome:</strong> ${this.selectedSigner.signer}</li>
              <li style="color: var(--custom-theme-black-087)"><strong>E-mail:</strong> ${this.selectedSigner.email}</li>
              <li style="color: var(--custom-theme-black-087)"><strong>Telefone:</strong></li>
            </ul>`
    },
    disablePrevButton() {
      return this.isClickSign || this.envelopeFiles.length === 0 || this.envelopeFiles.length === 1
        || this.carouselItemActive === 0
    },
    disableNextButton() {
      this.checkViewedFiles()

      return this.isClickSign || this.envelopeFiles.length === 0 || this.envelopeFiles.length === 1
        || this.envelopeMapUrlFiles.length - 1 === this.carouselItemActive
    }
  },
  methods: {
    ...mapActions([
      "apiGetURLFiles",
      "apiGetDownloadFiles",
      "apiGetTokenCancelEnvelope",
      "apiDeleteTokenCancelEnvelope",
      "apiConfirmTokenCancelEnvelope"
    ]),
    ...mapMutations(["setDisableNavigation"]),
    checkViewedFiles() {
      if (this.isClickSign || this.envelopeFiles.length === 1 || this.envelopeMapUrlFiles.length - 1
        === this.carouselItemActive) {
        this.isViewAllFiles = true
      }
    },
    checkErrorEnvelope(){
        const indexError = this.envelopeDetails.error_message.includes('"errors":["');
        this.envelopeErrorText = indexError ? this.envelopeDetails.error_message.split('"errors":["').pop().replace('"]}', '') : this.envelopeDetails.error_message;
    },
    changeStatusEnvelope() {
      const statusEnvelope = {
        "DONE" : "Formalização concluída",
        "CANCELLED" : "Formalização cancelada",
        "ERROR" : "Erro para reprocessamento"
      }
      if(Object.keys(statusEnvelope).includes(this.envelopeDetails.status)) {
        this.envelopeStatusText = statusEnvelope[this.envelopeDetails.status]
        this.showButtonSign = false
        this.checkErrorEnvelope()
      } else {
        this.envelopeStatusText = 'Formalização em andamento'
        this.showButtonSign = false
        if (this.isSigner && this.recipientStatus != 'SIGNED') {
          this.envelopeStatusText = 'Você precisa assinar essa formalização'
          this.showButtonSign = true
        }
      }
    },
    changeFlagSigned() {
      let flagSigned = true

      for (let i = 0; i < this.envelopeDetails.recipients.length; i++) {
        let recipient = this.envelopeDetails.recipients[i];
        if (recipient.email.toLowerCase() == this.keycloak.tokenParsed.email.toLowerCase()) {
          this.isSigner = true
          flagSigned = flagSigned && (recipient.signature_status != 'NOT_SIGNED')
          this.providerSignatureKey = recipient.provider_signature_key;
        }
      }
      this.recipientStatus = flagSigned ? 'SIGNED' : 'NOT_SIGNED'
    },
    prepareDownloadFiles(index) {
      this.apiGetDownloadFiles({
        envelopeId: this.$route.params.uuid,
        documentId: this.envelope[index].id,
        nameEnvelope: `${this.envelopeDetails.name}_${index + 1}`
      })
    },
    resetCancelEnvelope() {
      this.cancelEnvelope.dialog = false
      this.$refs.form.reset()
      this.cancelEnvelope.loading = false
      this.cancelEnvelope.hasSuccessCancelCorfirm = false
    },
    async onCancelEnvelope() {
      loader.startProgressLoadPage()

      await this.apiGetTokenCancelEnvelope(this.$route.params.uuid)

      loader.stopProgressLoadPage()
      this.cancelEnvelope.dialog = true
    },
    async onCancelCancelate() {
      this.cancelEnvelope.loading = true

      await this.apiDeleteTokenCancelEnvelope(this.$route.params.uuid)

      this.cancelEnvelope.loading = false
    },
    async onCancelCorfirm() {
      this.cancelEnvelope.loading = true;
      try {

        await this.apiConfirmTokenCancelEnvelope({
          reason: this.cancelEnvelope.reason,
          uuid: this.$route.params.uuid
        })

        this.cancelEnvelope.hasSuccessCancelCorfirm = true

        this.cancelEnvelope.cancelCorfirm = {
          message: "Cancelamento efetuado com successo"
        }
      } catch (error) {
        this.cancelEnvelope.hasSuccessCancelCorfirm = false
        this.cancelEnvelope.cancelCorfirm = error.response.data
      } finally {
        this.cancelEnvelope.loading = false;
      }
    },
    async onFinishCancel() {
      if (this.cancelEnvelope.hasSuccessCancelCorfirm) {
        this.reload()
      } else {
        await this.onCancelCancelate();

        this.resetCancelEnvelope()

        setTimeout(() => {
          this.cancelEnvelope.cancelCorfirm = null
        }, 200)
      }
    },
    showSignerInformation() {
      return this.renderSignerInformationHTML
    }
  },
  watch: {
    carouselItemActive(currValue, oldValue) {
      this.carouselItemBeforeActive = oldValue
    }
  },
  created() {
    this.modalSignStepsActived = this.modalSignSteps
    this.modalInfo = {
      showIndeterminateProgress: false,
      topSlotContentTitle: "Detalhes",
      removeButtonClose: false,
      middleSlotContentTextHighlight: "",
      middleSlotContentText: this.showSignerInformation,
      bottomButtonsAlign: "right",
      bottomSlotContentButtonCancelLabel: "Fechar",
      bottomSlotContentButtonConfirmLabel: "Assinar",
      removeButtonCancel: false,
      removeButtonConfirm: true,
      onCancel: () => this.isModalInfoActive = false
    }
  },
  mounted() {
    this.changeFlagSigned()
    this.changeStatusEnvelope()
    this.checkViewedFiles()

    this.$nextTick(function () {

      if (this.isClickSign) {
        /*global Clicksign*/
        /*eslint no-undef: "error"*/
        let widget = new Clicksign(this.providerSignatureKey);

        widget.endpoint = this.clickSignUrl;
        widget.origin = "https://finan4.com.br"
        setTimeout(() => {
          widget.mount('clicksign');
        }, 1);

        let self = this;

        widget.on('signed', function () {
          setTimeout(() => {
            self.changeStatusEnvelope();
            self.changeFlagSigned();
            self.reload();
          }, 2000)
        });

      }
    });

  },
  beforeRouteLeave(to, from, next) {
    this.setDisableNavigation(false)
    next()
  }
}
</script>

<style>
.envelope__notification {
  font-size: 16px;
  margin: -40px 0 18px -24px;
  width: calc(100% + 48px);
}

.envelope_back-link {
  margin-right: 10px;
}

.envelope_back-link__icon {
  font-size: 50px;
  height: 32px;
}

.envelope__notification__highlight {
  color: #264674;
  font-weight: 700;
  text-decoration: underline;
}

.envelope__notification__highlight--error {
  color: #b90404;
}

.envelope__notification a {
  color: #264674;
}

.envelope__container {
  margin: 0 auto;
  max-width: 870px;
  width: 100%;
}

.envelope__container__details {
  margin: 0 auto 30px;
  max-width: 650px;
}

.envelope__details__header {
  display: flex;
  justify-content: space-between;
}

.envelope__details__table--inner-table table {
  background-color: #F5F5F5 !important;
  border-top: 2px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.envelope__details__table--inner-table p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 160px;
  word-break: keep-all;
  word-wrap: normal;
}

.envelope__details__table--expand {
  text-align: right;
}

.envelope__container .v-dialog__container {
  display: none !important;
}

.envelope__container__files {
  text-align: center;
}

.envelope__files__details {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 650px;
}

.envelope__container__files .disabled {
  display: none;
}

.envelope__container__files > .v-carousel {
  box-shadow: none;
}

.envelope__container__files .v-window__prev i,
.envelope__container__files .v-window__next i {
  font-size: 105px !important;
}

.envelope__container__files .v-window__next,
.envelope__container__files .v-window__prev {
  background: transparent;
}
.envelope__container__files .v-btn {
  color: transparent !important;
}

.envelope__container__files .v-btn:before {
  content: none !important;
}

.envelope__container__files .v-btn--icon.v-btn--small {
  width: 18px;
}

.envelope__container__files .v-carousel__controls__item {
  margin: 0 4px !important;
}

.envelope__container__files .v-carousel__controls {
  background-color: transparent;
}

.envelope__container__files i {
  color: #264674 !important;
}

.envelope__container__files .v-carousel__controls__item.v-btn--active .v-icon {
  opacity: 1 !important;
}

.envelope__agree-terms .v-input__slot {
  align-items: flex-start;
}

.envelope__done {
  margin-bottom: 20px;
}

.envelope__details__title {
  color: #264674;
  font-size: 24px;
}

.envelope__details__summary {
  margin-bottom: 0;
}

.envelope__details__participants {
  font-size: 12px;
}

.envelope__details__participants__highlight {
  color: #264674;
  font-weight: 700;
}

.envelope__details__carousel-itens-text {
  font-size: 14px;
  color: #264674;
  margin: 15px 0;
}

.envelope__done__text,
.envelope__cancelled__text {
  padding-left: 30px;
  color: #264674;
}

.envelope__done h4 {
  background: url('../assets/icon-ok.svg') no-repeat center left/24px;
  padding-left: 30px;
}

.envelope__cancelled h4 {
  background: url('../assets/icon-error.svg') no-repeat center left/24px;
  padding-left: 30px;
}

.envelope__details__header--cancelled {
  color: rgba(0, 0, 0, 0.54);
  font-size: 16px;
}

.envelope__details__header--actions {
  flex-basis: 176px;
  min-width: 176px;
  text-align: right;
}

.clicksign__container {
  margin: 0 auto;
  width: 650px;
  height: 734px
}

</style>
