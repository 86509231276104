<template>
  <div class="program-configuration">
    <BaseContainer
      :permission-list="['admin']"
    >
      <div class="app-align-items-center">
        <v-dialog
          v-model="isModalActive"
          persistent
          transition="slide-y-reverse-transition"
          content-class="v-dialog--fullscreen"
        >
          <BaseModal
            style="height: 100vh; flex-wrap: nowrap;"
            :show-indeterminate-progress="modalInfo.showIndeterminateProgress"
            :top-slot-content-title="modalInfo.topSlotContentTitle"
            :remove-button-close="modalInfo.removeButtonClose"
            :middle-slot-content-text-highlight="modalInfo.middleSlotContentTextHighlight"
            :middle-slot-content-text="modalInfo.middleSlotContentText"
            :bottom-buttons-align="modalInfo.bottomButtonsAlign"
            :bottom-slot-content-button-cancel-label="modalInfo.bottomSlotContentButtonCancelLabel"
            :bottom-slot-content-button-confirm-label="modalInfo.bottomSlotContentButtonConfirmLabel"
            :remove-button-cancel="modalInfo.removeButtonCancel"
            :remove-button-confirm="modalInfo.removeButtonConfirm"
            @on-cancel="closeModal"
            @on-confirm="() => null"
          >
            <div class="my-4">
              <p class="mb-4">
                {{ programName }}
              </p>
              Perfil:
              <v-select
                v-model="roleSelected"
                :items="roleList"
                :item-text="({role}) => `Perfil ${translateRoles(role)}`"
                item-value="role"
                background-color="#808080"
                class="select-role pb-4 pt-0 font-weight-bold body-2 rounded-sm"
                height="45px"
                style="width:229px;"
                @change="changeSchemaRole"
              />

              <v-tabs
                v-model="tabIndex"
                vertical
                fixed-tabs
                height="800"
                class="schema-modal--tabs"
                @change="clearMovementList"
              >
                Meus Arquivos
                <v-tab>
                  Cadastrar Ativo
                </v-tab>
                <v-tab-item :transition="false">
                  <v-card
                    v-if="tabIndex === 0"
                    class="color--text-black-054"
                    :elevation="0"
                  >
                    <h2>Meus Arquivos</h2>
                    <p>Cadastro de Ativos</p>
                    <v-card-text style="max-width: 440px; margin: 0 auto;">
                      <div>
                        <div class="body-1 text-center">
                          <v-img
                            :src="require('@/assets/upload-file.svg')"
                            height="89"
                            width="132"
                            class="mb-2 mt-2"
                            style="margin: 0 auto;"
                          />
                          <h3 class="headline mb-4 text-center font-weight-bold">
                            Cadastro de Ativos
                          </h3>
                          <p class="mb-6">
                            Cadastre seus ativos digitando os dados no formulário abaixo.
                          </p>
                        </div>
                        <div
                          class="mt-6"
                          style="max-width: 645px; margin: 0 auto;"
                        >
                          <CreateOrEditAsset
                            scope-schema="create-asset"
                            context-actions="create"
                            :is-admin-editing="true"
                            :clear-form="!isModalActive"
                          />
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                Ativos
                <v-tab>
                  Tabela Ativos
                </v-tab>
                <v-tab-item :transition="false">
                  <v-card
                    v-if="tabIndex === 1"
                    class="color--text-black-054"
                    :elevation="0"
                  >
                    <v-card-text>
                      <ConfigurationMovementSchemaTable
                        header-title="Ativos"
                        header-title-description="Edite as colunas da tabela de ativos."
                        scope-schema="asset-table"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab>
                  Filtros de Ativos
                </v-tab>
                <v-tab-item :transition="false">
                  <v-card
                    v-if="tabIndex === 2"
                    class="color--text-black-054"
                    :elevation="0"
                  >
                    <v-card-text>
                      <AssetList
                        header-title="Ativos"
                        header-title-description="Edite os filtros da tela de ativos."
                        :is-admin-editing="true"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab
                  :disabled="getTradeType !== 'dynamic'"
                >
                  Critérios de simulação
                </v-tab>
                <v-tab-item :transition="false">
                  <v-card
                    v-if="tabIndex === 3"
                    class="color--text-black-054 d-flex justify-center"
                    :elevation="0"
                  >
                    <v-card-text
                      style="width: 650px; border: 1px solid var(--custom-theme-black-020); padding: 25px;"
                    >
                      <DynamicTradeCriteria
                        :is-admin-editing="true"
                        header-title="Simular negociação"
                        header-title-description="Edite os critérios de simulação."
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab :disabled="getTradeType !== 'dynamic'">
                  Dados de pagamento de seleção de ativos
                </v-tab>
                <v-tab-item :transition="false">
                  <v-card
                    v-if="tabIndex === 4"
                    class="color--text-black-054"
                    :elevation="0"
                  >
                    <v-card-text style="max-width: 630px; margin: 0 auto; border: 1px solid #ccc;">
                      <ConfigurationDraftMovement
                        class="ma-4"
                        title="Seleção de ativos"
                        subtitle="Configure os dados de pagamento"
                        scope-schema="destination"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab>
                  Tabela de seleção de ativos
                </v-tab>
                <v-tab-item :transition="false">
                  <v-card
                    v-if="tabIndex === 5"
                    class="color--text-black-054"
                    :elevation="0"
                  >
                    <v-card-text style="max-width: 630px; margin: 0 auto; border: 1px solid #ccc;">
                      <ConfigurationDraftMovement
                        class="ma-4"
                        title="Seleção de ativos"
                        subtitle="Configure as colunas da tabela do carrinho"
                        scope-schema="criteria-table"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab>
                  Detalhes do Ativo
                </v-tab>
                <v-tab-item :transition="false">
                  <v-card
                    v-if="tabIndex === 6"
                    class="color--text-black-054 d-flex justify-center"
                    :elevation="0"
                  >
                    <v-card-text
                      style="width: 650px;"
                    >
                      <CreateOrEditAsset
                        context-actions="edit"
                        :header-title="'Detalhes do Ativo'"
                        :header-title-description="'Veja os dados do ativo'"
                        :asset-object="null"
                        scope-schema="asset"
                        :is-admin-editing="true"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab>
                  Detalhes de Registro
                </v-tab>
                <v-tab-item :transition="false">
                  <v-card
                    v-if="tabIndex === 7"
                    class="color--text-black-054 d-flex justify-center"
                    :elevation="0"
                  >
                    <v-card-text
                      style="width: 650px;"
                    >
                      <CreateOrEditAsset
                        context-actions="read"
                        :header-title="'Dados do Registro'"
                        :header-title-description="'Veja logs, parcelas e dados de registro do ativo'"
                        :asset-object="null"
                        scope-schema="register"
                        :is-admin-editing="true"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                Negociações
                <v-tab>
                  Tabela de Negociações
                </v-tab>
                <v-tab-item :transition="false">
                  <v-card
                    v-if="tabIndex === 8"
                    class="color--text-black-054"
                    :elevation="0"
                  >
                    <v-card-text>
                      <ConfigurationTradeSchemaTable
                        header-title="Tabela Negociações"
                        header-title-description="Edite as colunas da tabela de negociações."
                        scope-schema="trade-table"
                        config-table-slot="header"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab>
                  Filtros de Negociações
                </v-tab>
                <v-tab-item :transition="false">
                  <v-card
                    v-if="tabIndex === 9"
                    class="color--text-black-054"
                    :elevation="0"
                  >
                    <v-card-text>
                      <TradeList
                        header-title="Negociações"
                        header-title-description="Edite os filtros da tela de negociações."
                        :is-admin-editing="true"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab>
                  Ativo da Negociação
                </v-tab>
                <v-tab-item :transition="false">
                  <v-card
                    v-if="tabIndex === 10"
                    class="color--text-black-054 d-flex justify-center"
                    :elevation="0"
                  >
                    <v-card-text
                      style="width: 650px;"
                    >
                      <CreateOrEditAsset
                        context-actions="edit"
                        :header-title="'Detalhes do Ativo da Negociação'"
                        :header-title-description="'Veja os dados do ativo da negociação'"
                        :asset-object="null"
                        scope-schema="trade-assets"
                        :is-admin-editing="true"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab>
                  Criar Proposta
                </v-tab>
                <v-tab-item :transition="false">
                  <v-card
                    v-if="tabIndex === 11"
                    class="color--text-black-054"
                    :elevation="0"
                  >
                    <v-card-text>
                      <TradeList
                        header-title="Negociações"
                        header-title-description="Edite os campos da criação de proposta"
                        :is-admin-editing="true"
                        :open-modal-offer="true"
                      />

                      <v-navigation-drawer
                        :value="true"
                        app
                        right
                        clipped
                        floating
                        class="px-4 mb-12 pt-7"
                        color="#f3f3f5"
                        width="428"
                        mobile-breakpoint="990"
                        disable-resize-watcher
                      >
                        <FormCreateOffer
                          :is-admin-editing="true"
                          scope-schema="offer"
                        />
                      </v-navigation-drawer>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab>
                  Tabela Ativo da Negociação
                </v-tab>
                <v-tab-item :transition="false">
                  <v-card
                    v-if="tabIndex === 12"
                    class="color--text-black-054"
                    :elevation="0"
                  >
                    <v-card-text class="pa-0">
                      <ConfigurationTradeSchemaTable
                        header-title="Tabela Ativo"
                        header-title-description="Edite as colunas da tabela de ativos da negociação"
                        scope-schema="trade-asset-table"
                        :tab-index="0"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab>
                  Tabela Proposta da Negociação
                </v-tab>
                <v-tab-item :transition="false">
                  <v-card
                    v-if="tabIndex === 13"
                    class="color--text-black-054"
                    :elevation="0"
                  >
                    <v-card-text class="pa-0">
                      <ConfigurationTradeSchemaTable
                        header-title="Tabela Proposta"
                        header-title-description="Edite as colunas da tabela de propostas da negociação"
                        scope-schema="offer-table"
                        :tab-index="1"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                Recebimentos
                <v-tab>
                  Filtros de Recebimentos
                </v-tab>
                <v-tab-item :transition="false">
                  <v-card
                    v-if="tabIndex === 14"
                    class="color--text-black-054"
                    :elevation="0"
                  >
                    <v-card-text>
                      <ReceiptList
                        header-title="Recebimentos"
                        header-title-description="Edite os filtros da tela de recebimentos."
                        :is-admin-editing="true"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab>
                  Detalhes de Recebimentos
                </v-tab>
                <v-tab-item :transition="false">
                  <v-card
                    v-if="tabIndex === 15"
                    class="color--text-black-054 d-flex justify-center"
                    :elevation="0"
                  >
                    <v-card-text
                      style="width: 650px;"
                    >
                      <CreateOrEditAsset
                        context-actions="edit"
                        :header-title="'Detalhes do recebimento'"
                        :header-title-description="'Veja ou edite dados do recebimento'"
                        :asset-object="null"
                        scope-schema="receipts"
                        :is-admin-editing="true"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </div>
          </BaseModal>
        </v-dialog>
      </div>
    </BaseContainer>
  </div>
</template>

<script>

import { mapState, mapActions, mapMutations } from 'vuex'
import BaseContainer from '../BaseContainer.vue'
import BaseModal from '../BaseModal.vue'
import CreateOrEditAsset from '../Program/Files/CreateOrEditAsset.vue'
import permissions from "@/mixins/permissions";
import ProgramRoles from '../../mixins/ProgramRoles'
import AssetList from '../Assets/AssetList'
import TradeList from '../TradeList'
import FormCreateOffer from '../FormCreateOffer'
import ConfigurationTradeSchemaTable from '../Platform/ConfigurationTradeSchemaTable'
import ConfigurationMovementSchemaTable from '../Platform/ConfigurationMovementSchemaTable'
import ReceiptList from '../Receipts/ReceiptList';
import DynamicTradeCriteria from '../Assets/DynamicTradeCriteria';
import ConfigurationDraftMovement from './ConfigurationDraftMovement'

export default {
  components: {BaseContainer, BaseModal, CreateOrEditAsset, AssetList, TradeList, FormCreateOffer, ConfigurationTradeSchemaTable, ConfigurationMovementSchemaTable, ReceiptList, DynamicTradeCriteria, ConfigurationDraftMovement },
  mixins: [permissions, ProgramRoles],
  data: () => ({
    isModalActive: true,
    roleSelected: "owner",
    roleList: [
      { "role": "owner" },
      { "role": "seller" },
      { "role": "buyer" },
      { "role": "trading-partner" }
    ],
    tabIndex: '',
    modalInfo: {
      showIndeterminateProgress: false,
      topSlotContentTitle: "Editar Interface",
      removeButtonClose: false,
      middleSlotContentTextHighlight: "",
      middleSlotContentText: "",
      bottomButtonsAlign: "right",
      bottomSlotContentButtonCancelLabel: "",
      bottomSlotContentButtonConfirmLabel: "",
      removeButtonCancel: true,
      removeButtonConfirm: true,
      onCancel: null,
      onConfirm: null
    }
  }),
  computed:{
    ...mapState({
      programInfo: (state) => state.platformProgram.programInfo,
      programConfiguration: (state) => state.platformProgram.programInfo ? state.platformProgram.programInfo.configuration : null,
    }),
    programName() {
      return this.programInfo ? this.programInfo.name : ''
    },
    getTradeType(){
      return this.programConfiguration ? this.programConfiguration['trade_type'] : ''
    },
  },
  async created() {
    this.changeSchemaRole()
  },
  methods: {
    ...mapMutations('programMovements', ['setProgramMovements', 'setProgramMovementDynamicAssetFields']),
    ...mapMutations('platformConfiguration', ['setConfigSchemaRole']),
    closeModal(){
      this.isModalActive = false
      this.$router.go(-1)
    },
    changeSchemaRole(){
      this.setConfigSchemaRole(this.roleSelected)
    },
    clearMovementList(){
      this.setProgramMovements([])
      this.setProgramMovementDynamicAssetFields([])
    }
  }
}
</script>
<style lang="scss">
.schema-modal--tabs {
  .v-tab {
    min-width: 229px;
    height: 40px!important;
    max-height: 40px!important;
    display: block;
    text-align: left;
    padding: 12px 16px;
    text-transform: none;
    letter-spacing: unset;
  }
  .v-tab--active {
    color: var(--custom-theme-black-054)!important;
    background: rgba(0, 0, 0, 0.06);
    font-weight: 600;
  }
  .v-tabs-slider-wrapper {
    display: none;
  }
  .v-item-group.v-tabs-items {
    margin-top: -120px;
    margin-left: 20px;
  }
}
</style>
