import api from '../../services/api';
import {utilsCreateTemporaryLinkToDownload} from "@/utils";

export default {
  namespaced: true,
  state: {
    platformOrganizations: [],
    platformOrganization: null,
    platformOrganizationAddress: null,
    platformOrganizationContact: null,
    platformOrganizationsByTaxnumber: [],
    platformOrganizationBankAccounts: [],
    platformOrganizationDocuments: [],
    platformOrganizationPartners: [],
    platformOrganizationPartnerChildren: [],
    platformOrganizationPartnerDocuments: [],
    platformOrganizationPartnerChildContext: null,
    platformOrganizationBranches: [],
    idPartnerToUpdateDocumentList: null,
    isOrganizationBranch: false,
    invoiceList: []
  },
  getters: {
    hasPlatformOrganization(state) {
      return !!state.platformOrganization
    },
    getPlatformOrganizationName(state, {hasPlatformOrganization}) {
      if (hasPlatformOrganization) {
        return state.platformOrganization.name ? state.platformOrganization.name
          : '-'
      } else {
        return false
      }
    },
    hasPlatformOrganizationAddress(state) {
      if (state.platformOrganizationAddress) {
        return state.platformOrganizationAddress.content.length !== 0
      } else {
        return false
      }
    },
    hasPlatformOrganizationContact(state) {
      if (state.platformOrganizationContact) {
        return state.platformOrganizationContact.content.length !== 0
      } else {
        return false
      }
    },
    platformOrganizationAddressCityAndState(state,
      {hasPlatformOrganizationAddress}) {
      if (hasPlatformOrganizationAddress) {
        return `${state.platformOrganizationAddress.content[0].city}/${state.platformOrganizationAddress.content[0].state}`
      }
    },
    isPartnerChild(state) {
      return !!state.platformOrganizationPartnerChildContext
    }
  },
  mutations: {
    setPlatformOrganizations(state, payload) {
      state.platformOrganizations = payload
    },
    setPlatformOrganization(state, payload) {
      state.platformOrganization = payload
    },
    setPlatformOrganizationAddress(state, payload) {
      state.platformOrganizationAddress = payload
    },
    setPlatformOrganizationContact(state, payload) {
      state.platformOrganizationContact = payload
    },
    setPlatformOrganizationsByTaxnumber(state, payload) {
      state.platformOrganizationsByTaxnumber = payload
    },
    setPlatformBankAccounts(state, payload) {
      state.platformOrganizationBankAccounts = payload
    },
    setPlatformOrganizationDocuments(state, payload) {
      state.platformOrganizationDocuments = payload
    },
    setPlatformOrganizationPartners(state, payload) {
      state.platformOrganizationPartners = payload
    },
    setPlatformOrganizationPartnerChildren(state, payload) {
      state.platformOrganizationPartnerChildren = payload
    },
    setPlatformOrganizationPartnerDocuments(state, payload) {
      state.platformOrganizationPartnerDocuments = payload
    },
    setPlatformOrganizationPartnerChildContext(state, payload) {
      state.platformOrganizationPartnerChildContext = payload
    },
    setPlatformOrganizationBranches(state, payload) {
      state.platformOrganizationBranches = payload
    },
    setIdPartnerToUpdateDocumentList(state, payload) {
      state.idPartnerToUpdateDocumentList = payload
    },
    setIsOrganizationBranch(state, payload) {
      state.isOrganizationBranch = payload
    },
    setInvoiceList(state, payload){
      state.invoiceList = payload
    }
  },
  actions: {
    async apiGetPlatformOrganizations({commit}) {
      const params = {
        page: 0,
        limit: 50
      }

      const result = await api.get('/organizations', {params})
      commit("setPlatformOrganizations", result.data.content)

      return result
    },
    async apiGetPlatformOrganization({commit}, payload) {
      const result = await api.get(`/organizations/${payload}`)
      commit("setPlatformOrganization", result.data)

      return result
    },
    async apiUpdatePlatformOrganization({commit}, payload) {
      const {id, ...data} = payload
      await api.put(`/organizations/${id}`, data)
    },
    async apiDeletePlatformOrganization({commit}, payload) {
      await api.delete(`/organizations/${payload}`)
    },
    async apiCreatePlatformOrganization({commit}, payload) {
      const result = await api.post(`/organizations`, payload)
      return result
    },
    async apiCreatePlatformOrganizationBranch({commit}, payload) {
      const {id, ...data} = payload
      const result = await api.patch(`/organizations/${id}/branches`, data)
      return result
    },
    async apiDeletePlatformOrganizationBranch({commit}, payload) {
      const {organizationId, branchId} = payload
      await api.delete(`/organizations/${organizationId}/branches/${branchId}`)
    },
    async apiGetPlatformOrganizationAddress({commit}, payload) {
      const result = await api.get(`/organizations/${payload}/address`, {
        params: {
          type: 'main'
        }
      })
      commit("setPlatformOrganizationAddress", result.data)

      return result
    },
    async apiCreatePlatformOrganizationAddress({commit}, payload) {
      const {id, ...data} = payload
      const result = await api.post(`/organizations/${id}/address`, data)
      return result
    },
    async apiUpdatePlatformOrganizationAddress({commit}, payload) {
      const {id, idAddress, ...data} = payload
      await api.put(`/address/${idAddress}`, data)
    },
    async apiGetPlatformOrganizationContact({commit}, payload) {
      const result = await api.get(`/organizations/${payload}/contacts`, {
        params: {
          primary: true
        }
      })
      commit("setPlatformOrganizationContact", result.data)

      return result
    },
    async apiCreatePlatformOrganizationContact({commit}, payload) {
      const {id, ...data} = payload
      data.primary = true

      const result = await api.post(`/organizations/${id}/contacts`, data)
      return result
    },
    async apiUpdatePlatformOrganizationContact({commit}, payload) {
      const {id, idContact, ...data} = payload

      await api.put(`/contacts/${idContact}`, data)
    },
    async apiGetPlatformOrganizationsByTaxnumber({commit}, payload) {
      const params = {
        tax_number: payload,
        page: 0,
        limit: 1
      }
      const result = await api.get('/organizations', {params})
      commit("setPlatformOrganizationsByTaxnumber", result.data.content)
      return result.data.content
    },
    async apiGetOrganizationBranches({commit}, payload) {
      const data = await api.get(`/organizations/${payload}/branches`);
      commit("setPlatformOrganizationBranches", data.data.content);
      return data;
    },
    async apiGetPlatformBankAccounts({commit}, payload) {
      const result = await api.get(`/organizations/${payload}/bank_accounts`)
      commit("setPlatformBankAccounts", result.data.content)
      return result
    },
    async apiCreatePlatformBankAccount({commit}, payload) {
      const {organizationId, ...data} = payload

      await api.patch(`/organizations/${organizationId}/bank_accounts`, data)
    },
    async apiUpdatePlatformBankAccount({commit}, payload) {
      const {organizationId, ...data} = payload

      await api.patch(`/organizations/${organizationId}/bank_accounts/${data.id}`,
        data)
    },
    async apiDeletePlatformBankAccount({commit}, payload) {
      const {organizationId, ...data} = payload
      await api.delete(`/organizations/${organizationId}/bank_accounts/${data.id}`, data)
    },
    async apiGetPlatformOrganizationDocuments({commit}, payload) {
      const {organizationId, ...data} = payload
      const result = await api.get(`/organizations/${organizationId}/documents`, {params: data})
      commit("setPlatformOrganizationDocuments", result.data.content)
      return result
    },
    async apiUploadPlatformOrganizationPendingDocument({dispatch, commit},
      payload) {
      const {organizationId, documentTypeId} = payload

      await dispatch('apiUploadDocument', payload).then(async id => {
        await api.put(`/organizations/${organizationId}/documents`, {
          document_type: {id: documentTypeId},
          file_uri: id
        })
      })

    },
    async apiUploadPlatformOrganizationPartnerPendingDocument({
        dispatch,
        commit
      },
      payload) {
      const {organizationId, documentTypeId, partnerId} = payload

      await dispatch('apiUploadDocument', payload).then(async id => {
        await api.put(
          `/organizations/${organizationId}/partners/${partnerId}/documents`,
          {
            document_type: {id: documentTypeId},
            file_uri: id
          })
      })

    },
    async apiDeletePlatformOrganizationPartnerPendingDocument({commit},
      payload) {
      const {organizationId, documentId, partnerId} = payload
      await api.delete(
        `/organizations/${organizationId}/partners/${partnerId}/documents/${documentId}`)
    },
    async apiDeletePlatformOrganizationDocument({commit}, payload) {
      const {organizationId, documentId} = payload
      await api.delete(
        `/organizations/${organizationId}/documents/${documentId}`)
    },
    async apiGetPlatformOrganizationPartners({commit}, payload) {
      const result = await api.get(`/organizations/${payload}/partners`)
      commit("setPlatformOrganizationPartners", result.data.content)
      return result
    },
    async apiGetPlatformOrganizationPartnerChildren({commit}, payload) {
      const {organizationId, partnerTaxNumber, scopeMember} = payload
      const scope = scopeMember ? 'members' : 'organizations'
      const result = await api.get(`/${scope}/${organizationId}/partners`,
        {params: partnerTaxNumber})
      commit("setPlatformOrganizationPartnerChildren", result.data.content)
      return result
    },
    async apiGetPlatformOrganizationPartnerDocuments({commit}, payload) {
      const {organizationId, partnerId, scopeMember} = payload
      const scope = scopeMember ? 'members' : 'organizations'
      const result = await api.get(
        `/${scope}/${organizationId}/partners/${partnerId}/documents`)
      commit("setPlatformOrganizationPartnerDocuments", result.data.content)
      return result
    },
    async apiCreateOrganizationPartner({commit}, payload) {
      const {organizationId, ...data} = payload
      await api.put(`/organizations/${organizationId}/partners`, data)
    },
    async apiUpdatePlatformOrganizationPartner({commit}, payload) {
      const {organizationId, id, ...data} = payload
      await api.put(`/organizations/${organizationId}/partners/${id}`, data)
    },
    async apiUploadDocument({commit}, payload) {
      let uploaded = await api.post(`/documents/${payload.organizationId}`, payload.formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      if (uploaded.status === 201) {
        return `storage://${uploaded.data.id}`
      }

      throw new Error(`Error when trying to upload ${payload.formData.file}`);

    },
    async apiCheckPendingRegistration({commit}, payload) {
      const { organizationId } = payload
      const result = await api.get(`/organizations/${organizationId}/check`)
      return result.data
    },
    async apiReviewOrganization({commit}, payload){
      const { organizationId, action, status, data } = payload
      await api.patch(`/organizations/${organizationId}/actions/${action}?status=${status}`, { data })
    },
    async apiGetBillDetails({commit}, payload) {
      const { organizationId, date } = payload
      const result = await api.get(`/organizations/${organizationId}/bills/${date}/details`)
      commit("setInvoiceList",result.data.invoices)
      return result.data.invoices
    },
    async apiDownloadBillFile({commit}, payload){
      const { organizationId, date, name, type, extension } = payload
      return new Promise((resolve, reject) => {
        api.get(`organizations/${organizationId}/bills/${date}/${extension}`,
          { responseType: 'blob' }).then(data => {
          utilsCreateTemporaryLinkToDownload(name, data.data, type, extension)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    async apiInviteCustomer({commit}, payload) {
      const result = await api.post(`/platforms/invite`, payload)
      return result
    }
  }
}
