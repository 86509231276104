<template>
  <BaseCard
    class="mb-6"
    :has-main-container="true"
  >
    <template v-slot:header-title>
      Organização
    </template>
    <template v-slot:header-title-description>
      Os dados marcados com * são obrigatórios
    </template>
    <v-form
      :ref="formName"
      v-model="organization.valid"
    >
      <v-container class="px-6">
        <v-row
          dense
          style="max-width: 420px;"
        >
          <v-col
            cols="auto"
            sm="12"
          >
            <BaseInput
              v-model="organization.name"
              :rules="[isValid]"
              label="Razão social*"
              qa-identification-name="qa-id-name-organization-name"
              class="mr-4"
            />
          </v-col>
          <v-col
            cols="auto"
            sm="5"
          >
            <CnpjInput
              v-model="organization.tax_number"
              label="CNPJ*"
              class="mr-4"
              qa-identification-name="qa-id-name-organization-tax-number"
              disabled
            />
          </v-col>
        </v-row>
        <v-row
          dense
          justify="end"
        >
          <v-col cols="auto">
            <BaseButton
              color="primary"
              type="text"
              qa-identification-name="qa-id-name-organization-cancel"
              @click="onCancelForm"
            >
              Cancelar
            </BaseButton>
            <BaseButton
              data-submit
              type="depressed"
              qa-identification-name="qa-id-name-organization-confirm"
              color="primary"
              :is-disabled="!organization.valid || organization.noFocus"
              @click="submitOrganizationForm()"
            >
              Salvar
            </BaseButton>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </BaseCard>
</template>

<script>
import BaseCard from '../../BaseCard'

import CnpjInput from '../../Input/CnpjInput.vue'
import UtilsPlatformOrganizationProfileForm from '../../../mixins/UtilsPlatformOrganizationProfileForm'
import { utilsTranformValueToOnlyNumber } from '../../../utils'

import {mapState, mapActions} from 'vuex'

export default {
  name: 'PlatformOrganizationProfileOrganizationForm',
  components: {BaseCard, CnpjInput},
  mixins: [UtilsPlatformOrganizationProfileForm],
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formName: 'formUpdateOrganization',
      organization: {
        name: '',
        tax_number: '',
        valid: false,
        noFocus: true,
      },
    }
  },
  computed: {
    ...mapState('platformOrganization', ['platformOrganization'])
  },
  methods: {
    ...mapActions('platformOrganization', [
      'apiGetPlatformOrganization', 'apiUpdatePlatformOrganization',
    ]),
    onCancelForm() {
      this.cancelForm(this, this.formName)
    },
    onFocusCallback() {
      this.organization.noFocus = false
    },
    clearFields() {
      this.$refs[this.formName].reset()
    },
    updateOrganizationFormFields() {
      const {name, tax_number} = this.platformOrganization

      Object.assign(this.organization, {name, tax_number, noFocus: true})
    },
    async submitOrganizationForm() {
      try {
        const {name, tax_number, ...data} = this.platformOrganization

        const payload = {
          ...data,
          name: this.organization.name,
          tax_number: utilsTranformValueToOnlyNumber(this.organization.tax_number),
          organizationId: this.$route.params.id
        }
        await this.apiUpdatePlatformOrganization(payload)
        await this.apiGetPlatformOrganization(payload.id)

        this.$emit('onSubmitOrganization', {
          message: 'Atualização feita com sucesso',
          color: 'success',
        })
        this.updateOrganizationFormFields()
      } catch (error) {
        this.$emit('onSubmitOrganization', {
          message: 'Erro na Atualização',
          color: 'error',
        })
      }
    }
  },
  watch: {
    active(isCurrentTrue) {
      if (isCurrentTrue) {
        this.updateOrganizationFormFields()
      }
    }
  },
  created() {
    this.updateOrganizationFormFields()
  }
}
</script>

<style>

</style>
