<template>
  <div class="member-list">
    <Warning
      v-if="showWarningMessage"
      icon="notifications"
      type="info"
      classes="member-list__notification"
    >
      O relatório está sendo gerado e em breve estará disponível na página de Relatórios,
      <router-link
        to="/files/reports"
      >
        clique aqui para ir
      </router-link>.
    </Warning>

    <BaseContainer :active-spacer="true">
      <template v-slot:header-title>
        Participantes
      </template>
      <template v-slot:header-title-description>
        Lista de participantes do programa
      </template>
      <template slot="header-actions">
        <BaseButton
          color="primary"
          @click="openModalInvite"
        >
          Convidar
        </BaseButton>
      </template>
      <div class="member-list__header__actions">
        <div class="mr-2">
          <v-select
            v-model="filter.status"
            :items="statuses"
            label="Status"
            item-value="value"
            item-text="text"
            class="mr-2"
            style="max-width: 150px;"
          />
        </div>
        <div class="mr-2">
          <v-select
            v-model="filter.role"
            :items="getRoleTypes()"
            label="Papel"
            item-value="value"
            item-text="text"
            class="mr-2"
            style="max-width: 150px;"
          />
        </div>
        <div class="mr-2">
          <CnpjInput
            v-model="filter.tax_number"
            label="CNPJ"
            :masked="true"
            :rules="[validField]"
            :is-required="false"
          />
        </div>
        <div class="mr-2">
          <BaseInput
            v-model="filter.name"
            label="Nome"
            class="mr-2 envelope-list__filter--field"
            @change="changeRemoveSpaceWhite"
          />
        </div>
        <BaseButton
          type="text"
          color="gray74"
          context-button="program"
          @click="filterMembers"
        >
          FILTRAR
        </BaseButton>
        <v-spacer />
        <BaseButton
          type="text"
          color="blueff"
          class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0 text-decoration-underline text-uppercase"
          button-class="app-font-regular"
          @click="executeExportData"
        >
          Gerar Relatório
        </BaseButton>
      </div>

      <BaseDataTable
        :headers="filteredHeaders"
        background-color="white"
        :items="dataMemberList"
        item-key="id"
        classes="elevation-2 mt-6"
        :options="{sortBy:['name']}"
      >
        <template v-slot:inject-items="scopedItems">
          <tr
            v-for="props in scopedItems"
            :key="props.index"
          >
            <td>
              <v-chip
                class="member-list__item--status font-weight-medium"
                :color="props.item.statusColor"
              >
                {{ props.item.status }}
              </v-chip>
            </td>
            <td class="text-left">
              {{ props.item.tax_number | maskCNPJ }}
            </td>
            <td class="text-left">
              {{ props.item.name }}
            </td>

            <td class="text-left">
              {{ props.item.role }}
            </td>
            <td
              v-if="isTradingPartner()"
              class="text-center"
            >
              <BaseButton
                type="text"
                :color="props.item.connectionStatus"
                class="
                  app-button__link-text
                  app-button--hide-ripple
                  app-button--no-hover
                  px-0
                  ma-0
                "
                @click="
                  openConnectionModal(props.item.id, props.item.connection)
                "
              >
                {{
                  props.item.connection == "DONE" ? "Desconectar" : "Conectar"
                }}
              </BaseButton>
            </td>
            <td
              v-if="!isSeller()"
              class="text-center"
            >
              <BaseButton
                type="text"
                color="blueff"
                class="
                  app-button__link-text
                  app-button--hide-ripple
                  app-button--no-hover
                  px-0
                  text-decoration-underline
                "
                button-class="app-font-regular"
                @click="openDocumentModal(props.item)"
              >
                + detalhes
              </BaseButton>
            </td>
          </tr>
        </template>
      </BaseDataTable>
      <v-dialog
        v-model="openModalConnection"
        max-width="560"
        persistent
      >
        <BaseModal
          :show-indeterminate-progress="connectionModal.showIndeterminateProgress"
          :top-slot-content-title="connectionModal.topSlotContentTitle"
          :remove-button-close="connectionModal.removeButtonClose"
          :middle-image="connectionModal.middleImage"
          :middle-slot-content-text-highlight="connectionModal.middleSlotContentTextHighlight"
          :middle-slot-content-text="connectionModal.middleSlotContentText"
          :middle-check-agree="connectionModal.middleCheckAgree"
          :bottom-buttons-align="connectionModal.bottomButtonsAlign"
          :bottom-slot-content-button-cancel-label="connectionModal.bottomSlotContentButtonCancelLabel"
          :bottom-slot-content-button-confirm-label="connectionModal.bottomSlotContentButtonConfirmLabel"
          :remove-button-cancel="connectionModal.removeButtonCancel"
          :remove-button-confirm="connectionModal.removeButtonConfirm"
          :custom-enable-bottom-button-confirm-label="connectionModal.customEnableBottomButtonConfirmLabel"
          @on-cancel="() => typeof connectionModal.onCancel === 'function' && connectionModal.onCancel()"
          @on-confirm="() => typeof connectionModal.onConfirm === 'function' && connectionModal.onConfirm()"
        />
      </v-dialog>
    </BaseContainer>

    <v-dialog
      :value="isModalDocumentsActive"
      persistent
      transition="app-transition-to-right"
      content-class="app-transition-to-right-additional-configs"
    >
      <BaseModal
        style="height: 100vh; flex-wrap: nowrap;"
        :show-indeterminate-progress="documentsModal.showIndeterminateProgress"
        :top-slot-content-title="documentsModal.topSlotContentTitle"
        :remove-button-close="documentsModal.removeButtonClose"
        :middle-slot-content-text-highlight="documentsModal.middleSlotContentTextHighlight"
        :middle-slot-content-text="documentsModal.middleSlotContentText"
        :middle-check-agree="documentsModal.middleCheckAgree"
        :bottom-buttons-align="documentsModal.bottomButtonsAlign"
        :bottom-slot-content-button-cancel-label="documentsModal.bottomSlotContentButtonCancelLabel"
        :bottom-slot-content-button-confirm-label="documentsModal.bottomSlotContentButtonConfirmLabel"
        :remove-button-cancel="documentsModal.removeButtonCancel"
        :remove-button-confirm="documentsModal.removeButtonConfirm"
        body-background-color="#F8F8F8"
        @on-cancel="() => isModalDocumentsActive = false"
        @on-confirm="() => null"
      >
        <div
          class="mt-6"
          style="max-width: 645px; margin: 0 auto;"
        >
          <BaseCard>
            <div class="mx-6">
              <OrganizationTemplate context="members" />
              <ContactTemplate context="members" />
              <AddressTemplate context="members" />
            </div>
          </BaseCard>
          <template v-if="memberDocuments.length > 0">
            <h1 class="app-font-semibold title mb-5 mt-5">
              Documentos da organização
            </h1>
            <BaseCard :has-main-container="true">
              <DocumentList
                :document-list="memberDocuments"
                :organization-id="memberId"
                context-actions="read"
                :only-download="true"
              />
            </BaseCard>
          </template>
          <template v-if="memberPartners.length > 0">
            <h1 class="app-font-semibold title my-5">
              Sócios e administradores
            </h1>
            <PartnerDetail
              :organization-id="memberId"
              :partner-list="memberPartners"
              :scope-member="true"
            />
          </template>
          <template v-if="memberBankAccounts.length > 0">
            <h1 class="app-font-semibold title my-5">
              Contas Bancárias
            </h1>
            <BaseCard :has-main-container="true">
              <BankAccountTableList
                :bank-account-list="memberBankAccounts"
                context-actions="read"
              />
            </BaseCard>
          </template>
          <div
            class="text-right mt-4"
            style="justify-content: flex-end"
          >
            <BaseButton
              v-if="isReviewer() && memberStatus === 'UNDER_REVIEW'"
              color="primary"
              class="mr-0"
              @click="openReviewDocsModal"
            >
              Revisar documentos
            </BaseButton>
            <BaseButton
              v-if="enableInactiveOrActiveSeller || enableReproveSeller || roleOwnerInactiveOrActiveAllMembers"
              color="primary"
              class="mr-0"
              @click="openActiveOrInactiveSellerModal"
            >
              {{ getBuyerButtonLabel(memberStatus) }}
            </BaseButton>
            <BaseButton
              v-if="enableAproveSeller"
              color="primary"
              class="mr-0"
              @click="openActiveOrInactiveSellerModal('approve')"
            >
              Aprovar
            </BaseButton>
          </div>
        </div>
      </BaseModal>
    </v-dialog>

    <v-dialog
      v-model="isModalReviewDocsActive"
      persistent
      max-width="560"
    >
      <BaseModal
        :show-indeterminate-progress="modalReviewDocs.showIndeterminateProgress"
        :top-slot-content-title="modalReviewDocs.topSlotContentTitle"
        :remove-button-close="modalReviewDocs.removeButtonClose"
        :middle-slot-content-text-highlight="modalReviewDocs.middleSlotContentTextHighlight"
        :middle-slot-content-text="modalReviewDocs.middleSlotContentText"
        :is-middle-slot-content-text-centered="false"
        :bottom-buttons-align="modalReviewDocs.bottomButtonsAlign"
        :bottom-slot-content-button-cancel-label="modalReviewDocs.bottomSlotContentButtonCancelLabel"
        :bottom-slot-content-button-confirm-label="modalReviewDocs.bottomSlotContentButtonConfirmLabel"
        :remove-button-cancel="modalReviewDocs.removeButtonCancel"
        :remove-button-confirm="modalReviewDocs.removeButtonConfirm"
        :custom-enable-bottom-button-confirm-label="enableButtonConfirm"
        @on-cancel="closeReviewDocsModal"
        @on-confirm="modalReviewDocs.onConfirm"
      >
        <template
          v-slot:inject-form
        >
          <v-radio-group
            v-if="showRadioGroup"
            v-model="modalReviewDocs.radio"
            row
            class="mt-4"
          >
            <v-radio
              value="reprove"
              label="Não"
            />
            <v-radio
              value="approve"
              label="Sim"
            />
          </v-radio-group>

          <template v-if="modalReviewDocs.radio === 'reprove' && !hasReviewedMember">
            <template v-if="memberStatus !== 'WAITING_FOR_APPROVAL'">
              Selecione a categoria abaixo que contém um ou mais documentos inválidos*
              <v-select
                v-model="modalReviewDocs.select"
                :items="['Documentos da organização', 'Sócios e administradores', 'Outros']"
                label="Selecione uma categoria"
                class="mr-2 mt-4"
                style="width:280px"
              />
              Descreva no campo abaixo quais documentos ou informações não estão de acordo com as diretrizes do programa*
            </template>
            <v-textarea
              v-model="modalReviewDocs.reason"
              class="mt-4"
              :counter="500"
              label="Motivo"
              :rules="[isValid]"
              rows="4"
              auto-grow
            />
          </template>
          <template v-if="modalReviewDocs.radio === 'cancel' && !hasReviewedMember">
            <v-textarea
              v-model="modalReviewDocs.reason"
              class="mt-4"
              :counter="500"
              placeholder="Digite o motivo pelo qual deseja desativar esse participante* (o mesmo será notificado via e-mail com a sua justificativa)."
              :rules="[isValid]"
              rows="4"
              auto-grow
            />
          </template>
        </template>
      </BaseModal>
    </v-dialog>

    <BaseSnackbar
      :active-snackbar="feedback"
      :type-snackbar="feedbackColor"
      :text-snackbar="feedbackMessage"
      @closeSnackbar="feedback = !feedback"
    />
    <ModalInviteMembers
      :modal-info="modalInviteInfo"
      context="program"
      :is-modal-active="isModalInviteActive"
      :program="selectedProgram"
      @onCloseModalInvite="closeModalInvite"
    />
  </div>
</template>

<script>
import { utilsTranformValueToOnlyNumber } from "../../utils"
import CnpjInput from "../Input/CnpjInput"
import { mapState, mapActions, mapMutations, mapGetters } from "vuex"
import loader from "../../global/loader"
import BaseDataTable from "../BaseDataTable"
import BaseDatePicker from "../BaseDatePicker"
import BaseModal from "../BaseModal"
import BaseSnackbarConfiguration from "../../mixins/BaseSnackbarConfiguration"
import BaseSnackbar from "../BaseSnackbar"
import ProgramRoles from "@/mixins/ProgramRoles"
import { utilsShowErrorInDevelopmentEnvironment } from "../../utils/index"
import DocumentList from "../Platform/OrganizationProfile/DocumentList"
import PartnerDetail from "../Platform/OrganizationProfile/PartnerDetail"
import OrganizationTemplate from "../Platform/OrganizationProfile/OrganizationTemplate"
import ContactTemplate from "../Platform/OrganizationProfile/ContactTemplate"
import AddressTemplate from "../Platform/OrganizationProfile/AddressTemplate"
import BankAccountTableList from "../Platform/OrganizationProfile/BankAccountTableList"
import ModalInviteMembers from "../Platform/ModalInviteMembers"
import Warning from '../Program/Warning'

export default {
  components: {
    CnpjInput,
    BaseDataTable,
    BaseModal,
    BaseSnackbar,
    DocumentList,
    PartnerDetail,
    ContactTemplate,
    AddressTemplate,
    OrganizationTemplate,
    BankAccountTableList,
    ModalInviteMembers,
    Warning
  },
  mixins: [ProgramRoles, BaseSnackbarConfiguration],
  data() {
    return {
      statusColor: {
        ENROLLED: "ok",
        INACTIVE: "error",
        PENDING: "progress",
        REVIEWED: "progress",
        ACCEPTED: "progress",
        UNDER_REVIEW: "progress",
        WAITING_FOR_APPROVAL: "progress",
        REPROVED: "error",
        CHANGES_REQUESTED: "progress"
      },
      statuses: [
        { text: "Todos", value: "" },
        { text: "Aguardando análise" , value: "UNDER_REVIEW"},
        { text: "Aguardando assinatura" , value: "REVIEWED"},
        { text: "Alterações solicitadas" , value: "CHANGES_REQUESTED"},
        { text: "Aprovação Pendente", value: "WAITING_FOR_APPROVAL"},
        { text: "Ativo", value: "ENROLLED" },
        { text: "Documentação pendente" , value: "ACCEPTED"},
        { text: "Inativo", value: "INACTIVE" },
        { text: "Pendente", value: "PENDING" },
        { text: "Reprovado", value: "REPROVED" },
      ],
      filter: {
        status: "",
        role: "",
        tax_number: "",
        name: "",
        page: 0,
        size: 0,
      },
      pagination: {
        page: 1,
        itemsPerPage: 25,
      },
      headers: [
        { text: "Status", value: "status", width: 177 },
        { text: "CNPJ", value: "tax_number", width: 160 },
        { text: "Nome", value: "name", class: "text-left" },
        { text: "Papel", value: "role", class: "text-left" },
        {
          text: "Relacionamento",
          value: "connection",
          width: 154,
          class: "text-left",
          permissions: ["trading-partner"],
        },
        {
          text: "",
          value: "documents",
          width: 114,
          class: "text-center",
          sortable: false,
          permissions: ["trading-partner", "owner", "buyer", "reviewer"],
        },
      ],
      openModalConnection: false,
      connectionDetails: {
        memberId: null,
        isConnected: false,
      },
      connectionModal: {
        showIndeterminateProgress: false,
        topSlotContentTitle: "Conectar",
        removeButtonClose: false,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: "Deseja efetuar conexão com esta organização?",
        middleCheckAgree: null,
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "Cancelar",
        bottomSlotContentButtonConfirmLabel: "Conectar",
        removeButtonCancel: false,
        removeButtonConfirm: false,
        onCancel: this.closeModal,
        onConfirm: this.startOrCancelConnection,
      },
      memberId: "",
      memberStatus: "",
      memberRole: "",
      isModalDocumentsActive: false,
      documentsModal: {
        showIndeterminateProgress: false,
        topSlotContentTitle: "Documentos do participante",
        removeButtonClose: false,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: "",
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "",
        bottomSlotContentButtonConfirmLabel: "",
        removeButtonCancel: true,
        removeButtonConfirm: true,
        onCancel: null,
        onConfirm: null,
      },
      isModalInviteActive: false,
      modalInviteInfo: {
        showIndeterminateProgress: false,
        topSlotContentTitle: "Convidar Participantes",
        removeButtonClose: false,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: "",
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "Cancelar",
        bottomSlotContentButtonConfirmLabel: "",
        removeButtonCancel: true,
        removeButtonConfirm: true,
        onCancel: null,
        onConfirm: null,
      },
      selectedProgram: {},
      hasReviewedMember: false,
      isModalReviewDocsActive: false,
      modalReviewDocs: {
        select: null,
        radio: null,
        reason: null,
        showIndeterminateProgress: false,
        topSlotContentTitle: "Revisar Documentos",
        removeButtonClose: false,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: "Todos os documentos estão de acordo com as diretrizes do programa?*",
        middleCheckAgree: null,
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "Cancelar",
        bottomSlotContentButtonConfirmLabel: "Enviar",
        removeButtonCancel: false,
        removeButtonConfirm: false,
        onConfirm: this.sendActionReviewMember
      },
      showWarningMessage: false
    };
  },
  computed: {
    ...mapState({
      memberList: (state) => {
        return state.programMembers.programMembers;
      },
      ...mapGetters({
        activeRole: "getActiveRole",
      }),
      memberDocuments: (state) => state.programMembers.memberDocuments,
      memberPartners: (state) => state.programMembers.memberPartners,
      memberBankAccounts: (state) => state.programMembers.memberBankAccounts,
      programInfo: (state) => state.platformProgram.programInfo,
    }),
    dataMemberList() {
      return this.memberList
        ? this.memberList.map((member) => {
            return {
              id: member.id,
              status: this.getStatus(member.status),
              statusName: member.status,
              statusColor: this.getStatusColor(member.status),
              tax_number: member.organization.tax_number,
              name: member.organization.name,
              owner_email: member.organization.account ? (member.organization.account.email ? member.organization.account.email : '-') : '-',
              role: this.translateRoleType(member.role),
              roleName: member.role,
              connection: member.connection ? member.connection.status : "",
              connectionStatus: member.connection
                ? this.getConnectionStatus(member.connection.status)
                : "primary",
            };
          })
        : [];
    },
    filteredHeaders() {
      return this.headers.filter(
        (header) =>
          !header.permissions ||
          header.permissions.findIndex(
            (permission) => permission === this.activeRole
          ) > -1
      );
    },
    enableButtonConfirm(){
      let validateReason = this.modalReviewDocs.reason && this.modalReviewDocs.reason.length <= 500 ? true : false

      switch(this.modalReviewDocs.radio){
        case "approve":
          return true
        case "reprove":
          if (this.memberStatus === 'WAITING_FOR_APPROVAL'){
            return validateReason
          } else {
            return this.modalReviewDocs.select && validateReason ? true : false
          }
        case "cancel":
          return validateReason
        case "enable":
          return true
        case "verify":
          return true
        default:
          return false
      }
    },
    enableInactiveOrActiveSeller() {
      return this.roleBuyerAndMemberSeller && (this.memberStatus == "INACTIVE" || this.memberStatus == "ENROLLED")
    },
    enableAproveSeller() {
      return this.roleBuyerAndMemberSeller && (this.memberStatus == "WAITING_FOR_APPROVAL" || this.memberStatus == 'REPROVED')
    },
    enableReproveSeller() {
      return this.roleBuyerAndMemberSeller && this.memberStatus == "WAITING_FOR_APPROVAL"
    },
    roleBuyerAndMemberSeller() {
      return this.isBuyer() && this.memberRole === 'seller'
    },
    roleOwnerInactiveOrActiveAllMembers(){
      return this.isOwner() && (this.memberStatus == "INACTIVE" || this.memberStatus == "ENROLLED")
    },
    showRadioGroup(){
      return !this.hasReviewedMember && this.modalReviewDocs.radio !== 'cancel' && this.modalReviewDocs.radio !== 'enable' && this.memberStatus !== 'WAITING_FOR_APPROVAL' && this.memberStatus !== 'REPROVED'
    }
  },
  methods: {
    ...mapActions("programMembers", [
      "apiGetProgramMembers",
      "apiStartConnection",
      "apiDeleteConnection",
      "apiGetMemberDocuments",
      "apiGetMemberPartners",
      "apiGetMemberContact",
      "apiGetMemberAddress",
      "apiGetMemberBankAccounts",
      "apiMemberAction"
    ]),
    ...mapActions(["apiGetProgramInfo", "apiGetProgramRoleTypes"]),
    ...mapMutations(["setDisableNavigation"]),
    ...mapMutations("programMembers", ["setMemberOrganization"]),
    getStatusColor(status) {
      return this.statusColor[status]
    },
    validField(value) {
      return value.length === 0 || value.length === 18 ? true : false
    },
    getConnectionStatus(status) {
      const statusColor = {
        DONE: "cancelled",
        NOT_CONNECTED: "primary",
      };
      return statusColor[status]
    },
    getStatus(value) {
      return this.statuses.filter(status => status.value === value).length > 0 ? this.statuses.filter(status => status.value === value)[0].text : ''
    },
    async executeExportData(){
      const payload = {
        memberId: '_',
        action: 'report',
        data: {
        }
      }
      await this.apiMemberAction(payload)
      this.showWarningMessage = true
    },
    async sendActionReviewMember(){
      const { radio, select, reason } = this.modalReviewDocs
      const payload = {
        memberId: this.memberId,
        action: radio,
        data: {
          reason: select && reason ? `${select} - ${reason}` : reason || ''
        }
      }
      const errorMsg = {
        "approve": "aprovar",
        "reprove": "reprovar",
        "cancel": "desativar",
        "enable": "reativar",
        "verify": "aprovar"
      }
      try {
        await this.apiMemberAction(payload)

        let modifyModal = {
          approve: () => {
            Object.assign(this.modalReviewDocs, {
              ...this.modalReviewDocs,
              topSlotContentTitle: "Documentos revisados",
              middleSlotContentText: "Agora só falta assinar o atestado de conferência.<br />Clique no botão para ir a tela de assinatura.",
              bottomSlotContentButtonConfirmLabel: "Ver atestado",
              removeButtonCancel: true,
              onConfirm: () => {
                this.closeReviewDocsModal()
                setTimeout(()=>{
                  this.$router.push('/envelopes')
                },100)
              }
            })
          },
          reprove: () => {
            Object.assign(this.modalReviewDocs, {
              ...this.modalReviewDocs,
              topSlotContentTitle: "Fornecedor reprovado",
              middleSlotContentText: "A adesão do fornecedor ao programa foi recusada no momento e uma notificação foi enviada ao mesmo.",
              bottomSlotContentButtonConfirmLabel: "Fechar",
              removeButtonCancel: true,
              onConfirm: () => this.closeReviewDocsModal()
            })
          },
          cancel: () => {
            Object.assign(this.modalReviewDocs, {
              ...this.modalReviewDocs,
              topSlotContentTitle: "Participante desativado!",
              middleSlotContentText: "O participante teve seu acesso ao programa bloqueado e foi notificado através de um e-mail com sua justificativa.",
              bottomSlotContentButtonConfirmLabel: "Fechar",
              removeButtonCancel: true,
              onConfirm: () => this.closeReviewDocsModal()
            })
          },
          enable: () => {
            Object.assign(this.modalReviewDocs, {
              ...this.modalReviewDocs,
              topSlotContentTitle: "Participante reativado!",
              middleSlotContentText: "O participante teve seu acesso ao programa liberado e foi notificado através de um e-mail.",
              bottomSlotContentButtonConfirmLabel: "Fechar",
              removeButtonCancel: true,
              onConfirm: () => this.closeReviewDocsModal()
            })
          },
          verify: () => {
            Object.assign(this.modalReviewDocs, {
              ...this.modalReviewDocs,
              topSlotContentTitle: "Fornecedor aprovado!",
              middleSlotContentText: "A adesão do fornecedor foi aprovada. O jurídico foi notificado através de um e-mail para fazer a validação de seus documentos.",
              bottomSlotContentButtonConfirmLabel: "Fechar",
              removeButtonCancel: true,
              onConfirm: () => this.closeReviewDocsModal()
            })
          }
        }

      modifyModal[this.modalReviewDocs.radio]()
      this.hasReviewedMember = true
      } catch(e) {
        this.showFeedback({
          color: 'error',
          message: `Erro ao ${errorMsg[this.modalReviewDocs.radio]} participante.`
        })
      }
    },
    getBuyerButtonLabel(status){
      let label = {
        'INACTIVE': 'Reativar',
        'ENROLLED': 'Desativar',
        'WAITING_FOR_APPROVAL': 'Reprovar'
      }
      return label[status]
    },
    async closeReviewDocsModal(){
      await this.getMembers()
      this.isModalReviewDocsActive = false
      this.hasReviewedMember = false
      Object.assign(this.modalReviewDocs, {
        ...this.modalReviewDocs,
        topSlotContentTitle: "Revisar Documentos",
        middleSlotContentText: "Todos os documentos estão de acordo com as diretrizes do programa?*",
        bottomSlotContentButtonCancelLabel: "Cancelar",
        bottomSlotContentButtonConfirmLabel: "Enviar",
        removeButtonConfirm: false,
        removeButtonCancel: false,
        onConfirm: this.sendActionReviewMember,
        radio: null,
        reason: null,
        select: null
      })
    },
    openReviewDocsModal(){
      this.isModalDocumentsActive = false
      setTimeout(() => {
        this.isModalReviewDocsActive = true
      }, 100)
    },
    changeModalVerifySeller(){
      Object.assign(this.modalReviewDocs, {
        ...this.modalReviewDocs,
        radio: 'verify',
        topSlotContentTitle: "Aprovar fornecedor",
        middleSlotContentText: "Você está prestes a aprovar a adesão deste fornecedor para este programa. Ao fazer isso, o mesmo passará pela aprovação do jurídico antes de ter o acesso.",
        bottomSlotContentButtonConfirmLabel: "Aprovar"
      })
    },
    openActiveOrInactiveSellerModal(action = null){

      let modifyModal = {
        INACTIVE: () => {
          Object.assign(this.modalReviewDocs, {
            ...this.modalReviewDocs,
            radio: 'enable',
            topSlotContentTitle: "Reativar participante",
            middleSlotContentText: "Você está prestes a reativar esse participante. Ao fazer isso, o mesmo terá acesso ao programa e poderá operar novamente.",
            bottomSlotContentButtonConfirmLabel: "Reativar"
          })
        },
        ENROLLED: () => {
          Object.assign(this.modalReviewDocs, {
            ...this.modalReviewDocs,
            radio: 'cancel',
            topSlotContentTitle: "Desativar participante",
            middleSlotContentText: "Você está prestes a desativar um participante. Ao fazer isso, o mesmo não terá mais acesso ao programa e suas negociações serão automaticamente canceladas.",
            bottomSlotContentButtonConfirmLabel: "Desativar"
          })
        },
        WAITING_FOR_APPROVAL: () => {
          if(action === 'approve'){
            this.changeModalVerifySeller()
          } else {
            Object.assign(this.modalReviewDocs, {
              ...this.modalReviewDocs,
              radio: 'reprove',
              topSlotContentTitle: "Reprovar fornecedor",
              middleSlotContentText: "Você está prestes a reprovar a adesão deste fornecedor para o programa, o mesmo será notificado via e-mail com a sua justificativa.",
              bottomSlotContentButtonConfirmLabel: "Reprovar"
            })
          }
        },
        REPROVED: () => {
          this.changeModalVerifySeller()
        }
      }
      modifyModal[this.memberStatus]()
      this.openReviewDocsModal()
    },
    openConnectionModal(id, connection) {
      this.openModalConnection = true
      Object.assign(this.connectionDetails, {
        memberId: id,
        isConnected: connection == "DONE" ? true : false,
      });
      this.changeModalMessages()
    },
    closeModal() {
      this.openModalConnection = false
    },
    closeModalInvite() {
      this.isModalInviteActive = false
    },
    async openModalInvite() {
      await this.apiGetProgramRoleTypes
      this.isModalInviteActive = true
      this.selectedProgram = this.programInfo
    },
    changeModalMessages() {
      if (this.connectionDetails.isConnected) {
        this.changeValueOfModalConnection([
          {
            property: "bottomSlotContentButtonConfirmLabel",
            value: `Desconectar`,
          },
          {
            property: "middleSlotContentText",
            value: "A conexão com a organização será cancelada.",
          },
        ]);
      } else {
        this.changeValueOfModalConnection([
          {
            property: "bottomSlotContentButtonConfirmLabel",
            value: "Conectar",
          },
          {
            property: "middleSlotContentText",
            value: "Deseja efetuar conexão com esta organização?",
          },
        ]);
      }
    },
    changeValueOfModalConnection(list) {
      list.forEach(({ property, value }) => {
        this.connectionModal[property] = value;
      });
    },
    async startOrCancelConnection() {
      let snackBarConfiguration = {
        color: "success",
        message: ""
      };
      try {
        if (this.connectionDetails.isConnected) {
          await this.apiDeleteConnection(this.connectionDetails.memberId)
          snackBarConfiguration.message = "A conexão foi cancelada!"
        } else {
          await this.apiStartConnection(this.connectionDetails.memberId)
          snackBarConfiguration.message = "Conexão realizada com sucesso!"
        }
        this.showFeedback({
          color: snackBarConfiguration.color,
          message: snackBarConfiguration.message
        });
      } catch (err) {
        snackBarConfiguration.color = "error"
        if (this.connectionDetails.isConnected)
          snackBarConfiguration.message = "Erro ao cancelar conexão!"
        else snackBarConfiguration.message = "Erro ao realizar conexão!"

        this.showFeedback({
          color: snackBarConfiguration.color,
          message: snackBarConfiguration.message
        });

        this.utilsShowErrorInDevelopmentEnvironment(err)
      } finally {
        this.getMembers()
        Object.assign(this.connectionDetails, {
          memberId: null,
          isConnected: false
        });
        this.closeModal()
      }
    },
    updateMembersList() {
      setTimeout(() => {
        this.getMembers()
      }, 4000)
    },
    getMembers() {
      this.setPaginationToFilter()
      loader.startProgressLoadPage()
      this.apiGetProgramMembers(this.filter).then(() => {
        loader.stopProgressLoadPage()
      });
    },
    async openDocumentModal(member) {
      const { id, name, tax_number, statusName, roleName, owner_email } = member
      this.memberId = id
      this.memberStatus = statusName
      this.memberRole = roleName
      this.setMemberOrganization({ name, tax_number, owner_email })
      try {
        await this.apiGetMemberContact(this.memberId)
        await this.apiGetMemberAddress(this.memberId)
        await this.apiGetMemberDocuments(this.memberId)
        await this.apiGetMemberPartners(this.memberId)
        await this.apiGetMemberBankAccounts(this.memberId)
        this.isModalDocumentsActive = true
      } catch (e) {
        this.showFeedback({
          color: "error",
          message: "Erro ao buscar detalhes."
        });
      }
    },
    toLocalDate(date) {
      return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    },
    minusDays(date, days) {
      return new Date(date.setDate(date.getDate() - days))
    },
    setPaginationToFilter() {
      this.filter.page = this.pagination.page;
      this.filter.size = this.pagination.itemsPerPage;
    },
    updateFilter(value, attribute) {
      this.filter[attribute] = value;
    },
    filterMembers() {
      loader.startProgressLoadPage()
      this.filter.tax_number = utilsTranformValueToOnlyNumber(
        this.filter.tax_number
      );
      this.apiGetProgramMembers(this.filter).then(() => {
        loader.stopProgressLoadPage()
      });
    },
    changeRemoveSpaceWhite() {
      this.filter.name = this.filter.name.trim()
    },
  },
  mounted() {
    this.getMembers()
  },
};
</script>

<style lang="scss">
.member-list__header__actions {
  display: flex;
  align-items: center;
}

.member-list {
  .member-list__notification {
    font-size: 16px;
    margin: -40px 0 18px -24px;
    width: calc(100% + 48px);
  }
  .base-data-table th {
    font-weight: 600 !important;
  }

  .v-data-footer {
    border-top: thin solid rgba(0, 0, 0, 0.12) !important;
  }

  .member-list__item--status {
    color: var(--custom-theme-black-054) !important;
    display: inline-block;
    font-size: 10px !important;
    height: 24px !important;
    min-width: 120px;
    margin: 4px !important;
    text-align: center;
  }
}
</style>
