<template>
  <div class="asset-posting-list">
    <BaseDataTable
      :items="programMovementPostings"
      :headers="postingHeaders"
      :hide-headers="programMovementPostings.length == 0"
      :hide-actions="programMovementPostings.length == 0"
      background-color="transparent"
    >
      <template v-slot:no-data-text-message>
        Nenhum lançamento contábil relacionado
      </template>

      <template v-slot:inject-items="items">
        <tr
          v-for="field in items"
          :key="field.item.id"
        >
          <td class="text-left">
            {{ field.item.posted_at | getDate }}
          </td>
          <td class="text-left">
            {{ field.item.account.split(':').slice(4).join(":") }}
          </td>
          <td class="text-left">
            {{ field.item.description }}
          </td>
          <td class="text-left">
            {{ field.item.amount | formatCurrency }}
          </td>
        </tr>
      </template>
    </BaseDataTable>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import BaseDataTable from '../BaseDataTable.vue'

export default {
  components: {BaseDataTable},
  props: {
    selectedMovement: {
      type: Object,
      required: true
    },
    movementCategory: {
      type: String,
      required: false
    }
  },
  data: () => ({
    postingHeaders: [
      {text: 'Data', value: 'date', class: 'text-left date'},
      {text: 'Conta', value: 'account', class: 'text-left account'},
      {text: 'Descrição', value: 'description', class: 'text-left description'},
      {text: 'Valor', value: 'amount', class: 'text-right amount'},
    ],
  }),
  computed: {
    ...mapState({
      programMovementPostings: (state) => {
        return state.programMovements.programMovementPostings
      }
    }),
  },
  watch: {
    selectedMovement: {
      handler() {
        this.getPostings()
      },
      deep: true
    }
  },
  created(){
    this.getPostings()
  },
  methods: {
    ...mapActions('programMovements', ['apiGetProgramMovementPostings']),
    async getPostings() {
      const {id, date} = this.selectedMovement
      await this.apiGetProgramMovementPostings({id, category: this.movementCategory, date, 'external-id': this.selectedMovement['external-id']});
    },
  }
}
</script>

