import api from '../../services/api';

export default {
  namespaced: true,
  state: {
    filterList: [],
    filteredData: null,
    suggestions: {},
  },
  mutations: {
    setProgramFilters(state, payload) {
      state.filterList = payload
    },
    setFilteredData(state, payload) {
      state.filteredData = payload
    },
    setSuggestions(state, payload) {
      state.suggestions = payload
    }
  },
  actions: {
    async apiPostSuggestValues({commit}, payload) {
      const {category, ...data} = payload
      const result = await api.post(`movements/${category}/suggestions/values`,
        data)
      commit('setSuggestions', result.data)
      return result
    },
    async apiGetProgramFilters({commit}, payload) {
      const { scope, role } = payload
      const path = role ? `/configs/filters/${scope}?role=${role}` : `/configs/filters/${scope}`
      const result = await api.get(path)
      commit("setProgramFilters", result.data.sort((a, b) => a.field.sequence - b.field.sequence))
      return result.data.sort((a, b) => a.field.sequence - b.field.sequence)
    },
    async apiUpdateProgramFilters({commit}, payload) {
      const { scope, ...data } = payload
      await api.put(`configs/filters/${scope}`, data)
    },
    async apiPostMovementsFilters({commit}, payload) {
      if (payload.pagination.page) {
        payload.pagination.page = payload.pagination.page - 1;
      }
      const {category, ...data} = payload
      const result = await api.post(`/movements/${category}/search`, data)
      commit("setFilteredData", result)
      return result
    }
  }
}
