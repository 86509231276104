<template>
  <div class="platform-billing">
    <BaseContainer
      :permission-list="['!sales', '!admin']"
    >
      <template v-slot:header-title>
        Faturamento e Consumo
      </template>
      <template v-slot:header-title-description>
        Faça o download das últimas faturas e consulte o consumo de serviços da sua organização.
      </template>
      <div>
        <v-autocomplete
          v-if="organizationList.length > 1"
          id="qa-id-name-organization-list"
          v-model="selectedOrganization"
          :items="organizationList"
          clearable
          item-value="id"
          item-text="nameAndTaxnumber"
          label="Selecione a organização"
          hint="Filtre por Razão Social ou CNPJ"
          no-data-text="Organização não encontrada"
          persistent-hint
          :rules="[isValid]"
          style="max-width: 250px;"
          class="my-6"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.tax_number | maskCNPJ }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </div>
      <v-tabs
        v-model="tab"
        background-color="transparent"
        class="my-file-list--tab mt-2"
      >
        <v-tab>Faturas</v-tab>
        <v-tab>Consumo</v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tab"
        style="background:transparent;"
      >
        <v-tab-item class="pt-6">
          <v-row v-if="selectedOrganization">
            <v-col 
              v-for="invoice,index in invoices"
              :key="index"
            >
              <v-card>
                <v-card-text style="max-width: 440px; margin: 0 auto;">
                  <div>
                    <div class="color--text-black-054 body-1 text-center">
                      <v-icon 
                        style="font-size: 100px;"
                        class="my-4"
                      >
                        request_quote
                      </v-icon>
                      <h3 class="headline mb-4 text-center font-weight-bold text-capitalize">
                        {{ invoice.month }}/{{ invoice.year }}
                      </h3>
                      <BaseButton
                        type="text"
                        color="primary"
                        context-button="program"
                        @click="downloadPDF(invoice.date)"
                      >
                        <v-icon
                          left
                          dark
                        >
                          cloud_download
                        </v-icon>
                        Download fatura
                      </BaseButton>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <div
            v-else
            class="d-flex align-center justify-center text-center mb-4"
            style="height: 300px;"
          >
            <div>
              <img
                src="@/assets/no-itens.svg"
                width="126"
                height="114"
              >
              <p>Nenhuma fatura para exibir.</p>
              <p>Selecione uma organização ou verifique se ela participa de um programa.</p>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item class="pt-6">
          <BillInvoiceDetails
            :organization-id="selectedOrganization"
            :invoice-list="lastBillDetails"
            @onError="onError"
          />
        </v-tab-item>
      </v-tabs-items>
      <BaseSnackbar
        :active-snackbar="feedback"
        :type-snackbar="feedbackColor"
        :text-snackbar="feedbackMessage"
        @closeSnackbar="feedback = !feedback"
      />
    </BaseContainer>
  </div>
</template>

<script>
import BaseContainer from "../BaseContainer"
import { mapState, mapActions } from "vuex"
import BaseSnackbarConfiguration from '../../mixins/BaseSnackbarConfiguration'
import BaseSnackbar from '../BaseSnackbar'
import BillInvoiceDetails from "./BillInvoiceDetails"
import loader from '../../global/loader'

export default {
  components: { BaseContainer, BaseSnackbar, BillInvoiceDetails },
  mixins: [BaseSnackbarConfiguration],
  data: () => ({
    tab: 0,
    invoices: [
      { 
        month: new Date().toLocaleString('pt-BR', { month: 'long' }),
        year: new Date().getFullYear(),
        date: 'last'
      },
      { 
        month: new Date(new Date().getFullYear(), new Date().getUTCMonth()-1, 1).toLocaleString('pt-BR', { month: 'long' }),
        year: new Date().getFullYear(),
        date: 'last_but_one'
      }
    ],
    lastBillDetails: [],
    organizationList: [],
    selectedOrganization: null
  }),
  computed: {
    ...mapState({
      platformOrganizations: state => state.platformOrganization.platformOrganizations
    })
  },  
  watch:{
    selectedOrganization(){
      this.getBillDetails()
    }
  },
  async created(){
    await this.apiGetPlatformOrganizations()
    this.addHasBranchInOrganizationList()
  },
  methods:{
    ...mapActions('platformOrganization',['apiGetBillDetails', 'apiGetPlatformOrganizations', 'apiGetOrganizationBranches', 'apiDownloadBillFile']),
    onError(message){
      this.showFeedback({
        message: message,
        color: 'error'
      })
    },
    addNameAndTaxnumber(org){
      return org.map(obj => {
        return {
          ...obj,
          nameAndTaxnumber: obj.tax_number ? `${obj.name} - ${obj.tax_number}` : obj.name
        }
      })
    },
    async addHasBranchInOrganizationList(){
      const organizations = []
      let result = {}
      for(let i in this.platformOrganizations) {
        try {
          organizations.push(...this.addNameAndTaxnumber([this.platformOrganizations[i]]))
          result = await this.apiGetOrganizationBranches(this.platformOrganizations[i].id)
          organizations.push(...this.addNameAndTaxnumber(result.data.content))
        } catch(err) {
          this.onError('Não foi possível encontrar sua organização.')
        } 
      }
      this.organizationList = organizations.sort((a, b) => a.name.localeCompare(b.name))
      if(this.organizationList.length == 1){
        this.selectedOrganization = this.organizationList[0].id
      }
    },
    async getBillDetails(){
      if(this.selectedOrganization){
        try {
          this.lastBillDetails = await this.apiGetBillDetails({
            organizationId: this.selectedOrganization,
            date: 'last'
          })
        } catch(e) {
          loader.stopProgressLoadPage()
        }
      }
    },
    async downloadPDF(date){
      try {
        await this.apiDownloadBillFile({
          organizationId: this.selectedOrganization,
          date,
          name: 'fatura',
          type: 'application/pdf', 
          extension: 'pdf'
        })
      } catch (error) {
        this.onError('Não foi possível fazer o download do arquivo. Verifique se existe fatura do mês selecionado.')
        loader.stopProgressLoadPage()
      }
        
    }
  }
}
</script>
