export default {
  computed: {
    authKeycloak: (state) => {
      return state.$store.state.auth.keycloak
    },
    userIsAdmin() {
      return this.authKeycloak.hasResourceRole('admin')
    },
    userIsSales() {
      return this.authKeycloak.hasResourceRole('sales')
    },
    canCancelDocument: (state) => {
      return state.$store.state.auth.keycloak.hasResourceRole('user') &&
        state.$store.state.auth.keycloak.hasResourceRole('envelopes.write')
    }
  },
  methods: {
    userHasResourceRole(roles){
      var permissionList = roles.filter(role => role.includes("!") && !this.authKeycloak.hasResourceRole(role.replace('!', '')))
      return roles.find(role => this.authKeycloak.hasResourceRole(role)) || permissionList.length === roles.length
    },
    userHasGroup(groupList){
      let userGroup = this.authKeycloak.tokenParsed.group

      return groupList.find(group => {

        // o usuário que não tiver a propriedade 'group' é considerado master
        if(group === 'master' && ((userGroup && userGroup === 'master') || !userGroup)) {
          return true
        }
        return userGroup && userGroup === group ? true : false
      })

    }
  }
};
