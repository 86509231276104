<template>
  <div class="operation-register-filter">
    <v-navigation-drawer
      :value="isClickButtonFilterOperationRegister"
      app
      right
      clipped
      ligth
      fixed
      width="428"
      mobile-breakpoint="990"
      disable-resize-watcher
      class="app-navigation"
      @input="resizeWatcher"
    >
      <v-toolbar
        color="primary mb-6"
        dark
      >
        <v-toolbar-title class="font-weight-regular">
          Filtros
        </v-toolbar-title>
        <v-spacer />
        <BaseButton
          :has-icon="true"
          @click="onClickButtonCloseOperationRegisterFilter"
        >
          <v-icon>close</v-icon>
        </BaseButton>
      </v-toolbar>
      <v-container
        grid-list-lg
        py-0
        px-6
      >
        <v-row>
          <v-col
            sm="12"
            py-0
          >
            <p class="primary--text subtitle-1 font-weight-bold text-uppercase mb-0">
              Originador
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="operation-register-filter--flex-text-field-initiator">
            <v-select
              v-model="initiator"
              :items="getInitiators"
              label="Originador"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="operation-register-filter--flex-btn-filter"
            text-right
          >
            <BaseButton
              type="text"
              context-button="program"
              button-class="primary--text operation-register-filter__btn-filter"
              @click="filterOperationRegister"
            >
              Filtrar
            </BaseButton>
          </v-col>
        </v-row>
      </v-container>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex'
import loader from '../global/loader'

export default {
  name: "OperationRegisterFilter",
  methods: {
    ...mapMutations(['setIsClickButtonFilterOperationRegister', 'setFilter']),
    ...mapActions(['apiGetRegisterOperation', 'apiGetInitiators']),
    onClickButtonCloseOperationRegisterFilter() {
      this.setIsClickButtonFilterOperationRegister(false)
    },
    resizeWatcher(value) {
      this.setIsClickButtonFilterOperationRegister(value)
    },
    filterOperationRegister() {
      this.setFilter({initiator: this.initiator})
      loader.startProgressLoadPage()
      this.apiGetRegisterOperation().then(() => {
        loader.stopProgressLoadPage()
      })
    }
  },
  computed: {
    ...mapGetters(['getInitiators']),
    ...mapState({
      isClickButtonFilterOperationRegister: (state) => {
        return state.operationRegister.isClickButtonFilterOperationRegister
      }
    }),
    initiator: {
      get() {
        return this.$store.state.operationRegister.filter.initiator
      },
      set(value) {
        this.setFilter({initiator: value ? value : ''})
      }
    }
  },
  created() {
    this.apiGetInitiators()
  }
}
</script>

<style scoped>
.operation-register-filter--flex-date-picker,
.operation-register-filter--flex-text-field-initiator {
  flex-basis: 58%;
  flex-grow: 0;
  max-width: 58%;
}

.operation-register-filter--flex-btn-filter {
  align-self: center;
  flex-basis: 23.2323232323%;
  flex-grow: 0;
  max-width: 23.2323232323%;
}

.operation-register-filter__btn-filter {
  margin: 0;
  min-width: auto;
  width: 100%;
}
</style>
