import LacunaWebPKI from "web-pki";

let webPkiLicense = 'AjQAYXBwLmlkdHJ1c3QuY29tLmJyLGZpbmFuNC5jb20uYnIsaWNjYi5pZHRydXN0LmNvbS5icnQAZmluYW40Lm5ldCxmaW5hbmZvci5hcHAsaXA0OjEwLjAuMC4wLzgsaXA0OjEyNy4wLjAuMC84LGlwNDoxNzIuMTYuMC4wLzEyLGlwNDoxOTIuMTY4LjAuMC8xNixwbGF5LWljY2IuaWR0cnVzdC5jb20uYnICAENBCAAAGMUIrnbdCAAB0e3E5hl5POtG4+OfzlAYina9pluZmT0RhTmZE3oXVsQ/g6qjcLY72yFbsEoOIZHuCisT5mWELbxj2X+Lr1GZ2eVot969DLHxQeEudixELS3EIBzRGxoRHYJBX0q7iCuJhGHObQ7dLoV7KvOmnCuvlFygy1CdYg9WbvQVAjwJ61YtHMpY9f7NJ8O4rXzLLH0YqXzB+zSwB60cDskOHlfH+ARQnkcPCRu4q+PXpjvEgNmd5BpuQrsVYqSm7sOSswWIAC7u7wzYO5vXVHQqiKWibVMwjLSAsPFjNCPUx/3EQMcSHh2vFycd1h2RbnFU0WBjghm28ofntMfem/4D52nO8g==';

export default {
  data() {
    return {
      pki: {},
      isExtensionInstalled: true,
      certificates: [],
      thumbprint: {}
    }
  },
  created: function () {
    this.pki = new LacunaWebPKI(webPkiLicense)
    this.pki.init({
      ready: this.onWebPkiReady,
      notInstalled: this.onWebPkiNotInstalled
    })
  },
  methods: {
    onWebPkiReady: function () {
      this.isExtensionInstalled = true
      this.loadCertificates()
    },
    loadCertificates() {
      this.pki.listCertificates().success(certs => {
        this.certificates = certs;
      })
    },
    readCertificate(thumbprint) {
      return this.pki.readCertificate(thumbprint)
    },
    onWebPkiNotInstalled: function () {
      this.isExtensionInstalled = false
    },
    redirectToInstallPage() {
      this.pki.redirectToInstallPage()
    }
  }
}
