import api from '../../services/api';

export default {
  namespaced: true,
  state: {
    unreadNotifications: [],
    notificationList: {}
  },
  mutations: {
    setUnreadNotifications(state, payload) {
      state.unreadNotifications = payload
    },
    setNotifications(state, payload) {
      state.notificationList = payload
    }
  },
  actions: {
    async apiGetUnreadNotifications({commit}, payload) {
      const { context, ...params } = payload
      const result = await api.get(`/${context}_notifications`, { params: params })
      commit("setUnreadNotifications", result.data.content)
    },
    async apiGetNotifications({commit}, payload) {
      const { context, ...params } = payload
      if (params.page) {
        params.page = params.page - 1;
      }
      const result = await api.get(`/${context}_notifications`, { params: params })
      commit("setNotifications", result.data)
    }
  }
}
