<template>
  <PersonalData
    icon="account_circle"
    title="Contato"
    :template="getTypeTemplate"
  >
    <template
      v-slot:[getTypeTemplate]
    >
      <p
        :template="getTypeTemplate"
        v-html="getPlatformOrganizationContact"
      />
    </template>
  </PersonalData>
</template>

<script>
import PersonalData from './PersonalData'
import UtilsPlatformOrganizationProfileTemplate from '../../../mixins/UtilsPlatformOrganizationProfileTemplate'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'PlatformOrganizationProfileContactTemplate',
  components: { PersonalData },
  mixins: [ UtilsPlatformOrganizationProfileTemplate ],
  props: {
    context: {
      type: String,
      default: '',
      required: false
    }
  },
  computed: {
    ...mapState('platformOrganization', ['platformOrganizationContact']),
    ...mapState('programMembers', ['memberContacts']),
    ...mapGetters('platformOrganization', ['hasPlatformOrganizationContact']),
    ...mapGetters('programMembers', ['hasMemberContacts']),
    isContextMembers(){
      if(this.context == 'members') return true
      else return false
    },
    platformOrganizationContactEmail() {
      if(this.isContextMembers){
        return this.memberContacts[0].email ? this.memberContacts[0].email : '-'
      }else {
        return this.platformOrganizationContact.content[0].email ? this.platformOrganizationContact.content[0].email : '-'
      }
    },
    platformOrganizationContactName() {
      if(this.isContextMembers){
        return this.memberContacts[0].name ? this.memberContacts[0].name : '-'
      }else {
        return this.platformOrganizationContact.content[0].name ? this.platformOrganizationContact.content[0].name : '-'
      }
    },
    platformOrganizationContactPhoneNumber() {
      if(this.isContextMembers) {
        return  this.memberContacts[0].phone_number ? this.memberContacts[0].phone_number : '-'
      }else {
        return this.platformOrganizationContact.content[0].phone_number ? this.platformOrganizationContact.content[0].phone_number : '-'
      }
    },
    getPlatformOrganizationContact(){
      if(this.hasPlatformOrganizationContact || this.hasMemberContacts) {
        return `
          ${this.platformOrganizationContactName}<br/>
          Telefone ${
            this.wrapTextWithHtmlTag(
              this.$options.filters.maskPhone(
                this.platformOrganizationContactPhoneNumber
              )
            )
          }<br/>
          E-mail <span style="line-break: anywhere;">${this.wrapTextWithHtmlTag(this.platformOrganizationContactEmail)}</span>
        `
      } else {
        if(this.isContextMembers)
          return `Não há dados para exibir.`
        return `Clique em <strong>editar</strong> para adicionar um contato responsável.`
      }
    },
    getTypeTemplate() {
      return this.hasPlatformOrganizationContact ? 'data-template' : 'no-data-template'
    }
  }
}
</script>

<style>

</style>
