<template>
  <div class="platform-permissions">
    <BaseContainer
      :active-spacer="true"
      :permission-list="['master']"
    >
      <template v-slot:header-title>
        Gestão de usuários
      </template>
      <template v-slot:header-title-description>
        Cadastre usuários e gerencie suas permissões.
      </template>
      <BaseAlert>
        <b>Atenção:</b> O usuário criado será vinculado a todas as organizações matrizes e filiais que você possui em sua conta.
      </BaseAlert>
      <template v-slot:header-actions>
        <v-dialog
          :value="isModalActive"
          persistent
          transition="slide-y-reverse-transition"
          content-class="v-dialog--fullscreen"
          @input="updateModalActive"
        >
          <template v-slot:activator="{ on }">
            <BaseButton
              color="primary"
              v-on="on"
            >
              Adicionar usuário
            </BaseButton>
          </template>

          <BaseModal
            style="height: 100vh; flex-wrap: nowrap"
            :middle-image="modalInfo.middleImage"
            :show-indeterminate-progress="modalInfo.showIndeterminateProgress"
            :top-slot-content-title="modalInfo.topSlotContentTitle"
            :remove-button-close="modalInfo.removeButtonClose"
            :middle-slot-content-text-highlight="modalInfo.middleSlotContentTextHighlight"
            :middle-slot-content-text="modalInfo.middleSlotContentText"
            :middle-check-agree="modalInfo.middleCheckAgree"
            :bottom-buttons-align="modalInfo.bottomButtonsAlign"
            :bottom-slot-content-button-cancel-label="modalInfo.bottomSlotContentButtonCancelLabel"
            :bottom-slot-content-button-confirm-label="modalInfo.bottomSlotContentButtonConfirmLabel"
            :remove-button-cancel="modalInfo.removeButtonCancel"
            :remove-button-confirm="modalInfo.removeButtonConfirm"
            body-background-color="#F8F8F8"
            @on-cancel="cancelUserForm"
            @on-confirm="() => null"
          >
            <div style="margin: 0 auto">
              <v-form
                ref="formCreateOrEditUser"
                v-model="valid"
              >
                <v-container>
                  <BaseAlert>
                    <b>Atenção:</b> O usuário criado será vinculado a todas as organizações matrizes e filiais que você possui em sua conta.
                  </BaseAlert>
                  <v-row dense>
                    <h2 class="mt-6">
                      Dados pessoais
                    </h2>
                    <v-spacer />
                    <v-switch
                      id="qa-id-name-attributes-enabled"
                      v-model="user.attributes.enabled"
                      class="platform-organization-profile-account--switch mt-6"
                      color="primary"
                      :label="user.attributes.enabled ? 'Ativado' : 'Desativado'"
                    />
                  </v-row>

                  <v-divider class="my-4" />

                  <v-row dense>
                    <v-col sm="5">
                      <BaseInput
                        ref="userName"
                        v-model="user.name"
                        qa-identification-name="qa-id-name-user-name"
                        label="Nome*"
                      />
                    </v-col>

                    <v-col sm="5">
                      <BaseInput
                        ref="userName"
                        v-model="user.last_name"
                        qa-identification-name="qa-id-name-last-name"
                        label="Sobrenome*"
                      />
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col sm="5">
                      <EmailInput
                        ref="email"
                        v-model="user.email"
                        qa-identification-name="qa-id-name-email"
                        label="E-mail*"
                      />
                    </v-col>
                  </v-row>

                  <h2 class="mt-6">
                    Tipo de acesso e permissões
                  </h2>
                  <v-divider class="my-4" />

                  <v-row>
                    <v-col
                      v-for="card, index in cardItems"
                      :key="index"
                      cols="12"
                      sm="6"
                      md="4"
                      lg="3"
                      class="pl-0 pr-6"
                    >
                      <v-hover
                        v-slot="{ hover }"
                      >
                        <BaseCardSimple
                          :card-image="card.image"
                          :card-title="card.title"
                          :card-text="card.text"
                          card-height="288"
                          :card-class="card.value === user.attributes.group ? 'selected' : hover ? 'hover' : ''"
                        >
                          <template v-slot:card-action>
                            <v-checkbox
                              v-model="user.attributes.group"
                              :name="`qa-id-name-attributes-group-${card.value}`"
                              :multiple="false"
                              label="Selecionar"
                              :value="card.value"
                            />
                          </template>
                        </BaseCardSimple>
                      </v-hover>
                    </v-col>
                  </v-row>

                  <v-row
                    dense
                    justify="end"
                  >
                    <v-col cols="auto">
                      <BaseButton
                        qa-identification-name="qa-id-name-button-cancel"
                        color="primary"
                        type="text"
                        @click="cancelUserForm"
                      >
                        Cancelar
                      </BaseButton>
                      <BaseButton
                        qa-identification-name="qa-id-name-button-confirm"
                        type="depressed"
                        color="primary"
                        :is-disabled="!valid || !user.attributes.group || loadPage"
                        @click="submitUserForm()"
                      >
                        {{ isEditingUser ? 'Salvar' : 'Cadastrar' }}
                      </BaseButton>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </div>
          </BaseModal>
        </v-dialog>
      </template>
      <BaseDataTable
        :items="authzUserList"
        :headers="headers"
        :hide-actions="true"
        :options="{sortBy:['name']}"
        background-color="transparent"
      >
        <template v-slot:inject-items="scopedItems">
          <tr
            v-for="value in scopedItems"
            :key="value.index"
          >
            <td style="width: 100%">
              <div class="app-align-items-center">
                <v-icon class="mr-2">
                  account_circle
                </v-icon>
                {{ value.item.name }} {{ value.item.last_name }}
              </div>
            </td>
            <td style="min-width: 82px">
              {{ translateUserGroup(value.item.attributes.group) }}
            </td>
            <td style="min-width: 184px">
              {{ getUserStatus(value.item.attributes) }}
            </td>
            <td
              style="min-width:60px"
              class="text-right"
            >
              <BaseButton
                :qa-identification-name="`qa-id-name-button-edit-${value.index}`"
                type="text"
                color="blueff"
                class="app-button__link-text app-button--hide-ripple app-button--no-hover px-0"
                @click="editUser(value.item)"
              >
                Editar
              </BaseButton>
            </td>
          </tr>
        </template>
      </BaseDataTable>

      <BaseSnackbar
        :active-snackbar="feedback"
        :text-snackbar="feedbackMessage"
        :type-snackbar="feedbackColor"
        @closeSnackbar="feedback = !feedback"
      />
    </BaseContainer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import permissions from '../../mixins/permissions'
import BaseDataTable from '../BaseDataTable'
import BaseModal from '../BaseModal'
import EmailInput from '../Input/EmailInput'
import BaseCardSimple from '../BaseCardSimple'
import BaseSnackbar from "../BaseSnackbar"
import BaseSnackbarConfiguration from "../../mixins/BaseSnackbarConfiguration"
import loader from '../../global/loader'
import BaseAlert from '../BaseAlert'

export default {
  components: { BaseDataTable, BaseModal, EmailInput, BaseCardSimple, BaseSnackbar, BaseAlert },
  mixins: [ permissions, BaseSnackbarConfiguration ],
  data() {
    return {
      headers: [
        { text: "Usuário", value: "name" },
        { text: "Tipo de acesso", value: "attributes.group", width: 150 },
        { text: "Status", value: "attributes.enabled" },
        { text: "", sortable: false }
      ],
      isModalActive: false,
      valid: false,
      modalInfo: {
        showIndeterminateProgress: false,
        topSlotContentTitle: "Cadastrar usuário",
        removeButtonClose: false,
        middleSlotContentTextHighlight: "",
        middleSlotContentText: "",
        bottomButtonsAlign: "right",
        bottomSlotContentButtonCancelLabel: "Cancelar",
        bottomSlotContentButtonConfirmLabel: "Cadastrar",
        removeButtonCancel: true,
        removeButtonConfirm: true,
        onCancel: null,
        onConfirm: null,
      },
      user: {
        name: "",
        last_name: "",
        email: "",
        attributes: {
          group: "",
          enabled: true
        }
      },
      cardItems: [
        {
          image: "person-icon.png",
          title: "Master",
          text: "Pode criar, editar e desativar usuários; realizar operações na plataforma, além de consultas e extração de relatórios no programa.",
          value: "master"
        },
        {
          image: "person-icon.png",
          title: "Operador",
          text: "Pode realizar operações na plataforma, além de consultas e extração de relatórios no programa.",
          value: "operator"
        },
        {
          image: "person-icon.png",
          title: "Consultante",
          text: "Pode realizar consultas e extrair relatórios no programa.",
          value: "consultant"
        }
      ],
      isEditingUser: null
    }
  },
  computed: {
    ...mapState({
      authzUserList: (state) => state.programAuthz.authzUserList,
      loadPage: (state) => state.loadPage
    })
  },
  methods: {
    ...mapActions('programAuthz', ["apiGetAuthzUsers", "apiCreateAuthzUsers", "apiUpdadeAuthzUsers"]),
    getUserStatus(attributes){
      if(attributes.enabled == "false" || attributes.enabled == false) {
        return  "Desativado"
      } else {
        return "Ativado"
      }
    },
    translateUserGroup(group) {
      let groupList = {
        "consultant": "Consultante",
        "master": "Master",
        undefined: "Master",
        "operator": "Operador",
      }
      return groupList[group]
    },
    updateModalActive(value) {
      this.isModalActive = value
    },
    cancelUserForm(){
      this.isModalActive = false
      this.isEditingUser = false
      this.$nextTick(() => {
        Object.assign(this.user, {
          name: "",
          last_name: "",
          email: "",
          attributes: {
            group: "",
            enabled: true
          }
        })
      })
    },
    editUser(selectedUser){
      this.isEditingUser = true

      this.$set(selectedUser.attributes, 'enabled',
      selectedUser.attributes.hasOwnProperty('enabled') ?
        selectedUser.attributes.enabled == "false" || selectedUser.attributes.enabled == false ? false : true
      : true)

       Object.assign(this.user, {
          name: selectedUser.name,
          last_name: selectedUser.last_name,
          email: selectedUser.email,
          attributes: {
            group: selectedUser.attributes.group,
            enabled: selectedUser.attributes.enabled
          }
       })
      this.isModalActive = true
    },
    async submitUserForm() {
      let feedbackContext = this.isEditingUser ? ['editado', 'editar'] : ['cadastrado','cadastrar']
      this.user.attributes.enabled = this.user.attributes.enabled ? "true" : "false"

      try {
        if(this.isEditingUser){
          await this.apiUpdadeAuthzUsers(this.user)
        } else {
          await this.apiCreateAuthzUsers(this.user)
        }

        this.cancelUserForm()
        this.apiGetAuthzUsers()

        this.showFeedback({
          color: "success",
          message: `O usuário foi ${feedbackContext[0]} com sucesso na organização!`,
        })
      } catch(error) {
        loader.stopProgressLoadPage()
        this.showFeedback({
          color: "error",
          message: error.response.data.message || `Erro ao ${feedbackContext[1]} usuário!`,
        })
      }
    }
  }
}
</script>

<style>
</style>
