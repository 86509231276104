<template>
  <div class="base-resume-infos primary--text">
    <v-row
      v-for="info, index in resumeInfos"
      :key="index"
      justify="space-between"
      class="mb-3"
    >
      <v-col class="pa-0">
        <v-icon class="primary--text">
          {{ info.icon }}
        </v-icon>
        <span class="ml-2">{{ info.iconText }}</span>
      </v-col>

      <v-col class="pa-0 text-right subtitle-1 font-weight-bold primary--text">
        <p v-if="info.valueType === 'currency'">
          {{ info.value | formatCurrency }}
        </p>
        <p v-else>
          {{ info.value }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    resumeInfos: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.base-resume-infos .v-icon {
  font-size: 16px;
}
</style>
