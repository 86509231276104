<template>
  <v-card
    class="base-template-success"
    elevation="0"
    :color="backgroundColor"
  >
    <v-card-title>
      <slot name="header" />
    </v-card-title>

    <v-card-text class="body-1 text-center">
      <slot>
        <v-img
          :src="image"
          alt="sucesso ao enviar proposta"
          max-width="144"
          max-height="149"
          style="margin: 0 auto"
        />
        <h4 class="base-template-success__message-title my-3 primary--text">
          {{ messageTitle }}
        </h4>
        <p v-html="messageText" />
      </slot>
    </v-card-text>

    <v-card-actions class="justify-center">
      <BaseButton
        v-if="actionText"
        button-class="mr-2"
        color="primary"
        @click="(ev) => $emit('click', ev)"
      >
        {{ actionText }}
      </BaseButton>
    </v-card-actions>
  </v-card>
</template>

<script>
import BaseButton from "./BaseButton.vue"

import successImage from "../assets/action-success.svg"

export default {
  name: "BaseTemplateSuccess",
  components: { BaseButton },
  props: {
    backgroundColor: {
      type: String,
      required: false,
      default: '#FFF'
    },
    messageTitle: {
      type: String,
      required: false,
      default: 'Deu certo!'
    },
    messageText: {
      type: String,
      required: false,
      default: 'A ação foi concluida com sucesso.'
    },
    actionText: {
      type: String,
      required: false,
      default: ''
    },
  },
  data() {
    return {
      image: ''
    }
  },
  created() {
    this.image = successImage
  }
}
</script>

<style scoped>
  .base-template-success__message-title {
    font-size: 24px;
    font-weight: 700;
  }
</style>
