<template>
  <div class="platform-configure-program">
    <BaseBannerAccount
      :banner-title="bannerTitle"
      banner-text="Pronto para configurar seu primeiro programa?"
    />

    <div class="text-center">
      <h1 class="font-weight-bold title mb-1 mt-6 color--text-black-054">
        Configure seu programa
      </h1>
      <p class="mb-6 color--text-black-054 body-2">
        Comece escolhendo a categoria de programa.
      </p>
    </div>

    <v-row>
      <v-col
        v-for="card, index in cardItems"
        :key="index"
      >
        <v-hover
          v-slot="{ hover }"
        >
          <BaseCardSimple
            :card-image="card.image"
            :card-title="card.title"
            :card-text="card.text"
            :card-class="card.value === templateProgram ? 'selected' : hover ? 'hover' : ''"
          >
            <template v-slot:card-action>
              <v-radio-group v-model="templateProgram">
                <v-radio :value="card.value" />
              </v-radio-group>
            </template>
          </BaseCardSimple>
        </v-hover>
      </v-col>
    </v-row>

    <BaseActionFooter
      :button-cancel="cancelButton"
      :button-confirm="confirmButton"
    />

    <BaseSnackbar
      :active-snackbar="feedback"
      :text-snackbar="feedbackMessage"
      :type-snackbar="feedbackColor"
      @closeSnackbar="feedback = !feedback"
    />
  </div>
</template>

<script>
import {mapMutations, mapState, mapActions} from 'vuex'
import BaseActionFooter from '../../BaseActionFooter'
import BaseCardSimple from '../../BaseCardSimple'
import BaseBannerAccount from '../../BaseBannerAccount'
import BaseSnackbar from '../../BaseSnackbar'
import BaseSnackbarConfiguration from '../../../mixins/BaseSnackbarConfiguration'

export default {
  components: {BaseActionFooter, BaseCardSimple, BaseBannerAccount, BaseSnackbar},
  mixins: [ BaseSnackbarConfiguration ],
  data() {
    return {
      templateProgram: null,
      cardItems: [
        {
          image: "icon-factory.svg",
          title: "Risco Sacado",
          text: "Antecipação de recebíveis para obtenção de capital de giro com bancos e fundos.",
          value: "template1"
        },
        {
          image: "icon-credit-card.svg",
          title: "Cartão de Crédito",
          text: "Financiamento de recebíveis de cartão de crédito através de fundos de investimento.",
          value: "template2"
        },
        {
          image: "icon-coin.svg",
          title: "Personalizado",
          text: "Explore a flexibilidade da plataforma personalizando sua operação de ponta a ponta.",
          value: "template3"
        }
      ],
      cancelButton: {
        action: ()=>{ this.$router.push('/platform-home') },
        text: 'Pular configuração',
        disabled: false
      },
      confirmButton: {
        action: this.selectTemplate,
        text: 'Avançar',
        disabled: true
      }
    }
  },
  computed: {
    ...mapState({
      keycloak: state => state.auth.keycloak.tokenParsed,
    }),
    bannerTitle(){
      return `Olá, <span class="font-weight-bold pl-1">${this.keycloak.name}!</span>`
    }
  },
  watch:{
    templateProgram() {
      this.confirmButton.disabled = this.templateProgram === null ? true : false
    }
  },
  methods:{
    ...mapMutations(["setDisableNavigation", "setUseActionFooter"]),
    ...mapActions(['apiCreateProgram']),
   selectTemplate() {
      try {
        //por enquanto não tem templape personalizado. Quando tiver, enviar o this.templateProgram
        this.$router.push('/create-program-steps')
      } catch(e) {
        this.showFeedback({message:'Erro ao configurar programa.', color: 'error'})
      }

    }
  },
  beforeRouteLeave(to, from, next) {
    this.setUseActionFooter(false)
    this.setDisableNavigation(false)
    next()
  }
}
</script>

<style scoped>
.platform-configure-program {
  max-width:745px;
  margin: 0 auto;
}
.platform-configure-program >>> .v-messages {
  display: none;
}
</style>
