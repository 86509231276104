<template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <BaseButton
          type="text"
          context-button="program"
          button-class="pa-2 ml-4 button-default-style"
          style="font-weight: 400!important; min-width: 24px;"
          v-on="on"
        >
          <v-icon color="gray74">
            {{ menuOptionsIcon }}
          </v-icon>
          <span
            v-if="menuOptionsText"
            class="ml-2 text-left"
          >{{ hideData(menuOptionsText, true) }}
            <br>
            <span
              v-if="menuOptionsSubtitle"
              class="text-left text-capitalize caption gray74--text"
            >
              {{ hideData(menuOptionsSubtitle, true) }}
            </span>
          </span>
        </BaseButton>
      </template>
      <v-list
        class="overflow-y-auto"
        style="max-height: 400px"
        :class="{'pt-0': navigationContext == 'program'}"
      >
        <template v-for="(item, index) in menuOptionsItens">
          <v-list-item
            :key="`item-${index}`"
            :two-line="item.twoLine"
            @click="item.execute"
          >
            <v-list-item-title>
              {{ item.text }}
              <p
                v-if="item.subtitle"
                class="text-left text-capitalize caption gray74--text"
              >
                {{ item.subtitle }}
              </p>
            </v-list-item-title>
          </v-list-item>
          <v-divider
            v-if="item.hasDivider"
            :key="`divider-${index}`"
          />
        </template>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProgramRoles from '../mixins/ProgramRoles'
export default {
  mixins: [ProgramRoles],
  props: {
    menuOptionsIcon: {
      type: String,
      required: true
    },
    menuOptionsText: {
      type: String,
      default: ""
    },
    menuOptionsSubtitle: {
      type: String,
      required: false,
      default: ""
    },
    menuOptionsItens: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState({
      navigationContext: state => state.theNavigation.navigationContext,
    })
  }
}
</script>
